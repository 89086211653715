import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param email
 */
//  const getAmc = async (email: string) => {
//   const url = `${Config.baseUrl}/amc?email=${email}`;
//   const result: any = await axios.get(url, {
//     headers: {
//       Authorization: sessionStorage.getItem('token') || '',
//     },
//   });
//   return result;
// };
const getAmc = async (email: string, page_number: string = '', page_size: string = '', filter_email: string = '') => {
  const url = `${Config.baseUrl}/amc?email=${email}&page_number=${page_number}&page_size=${page_size}&filter_email=${filter_email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAmc(email, page_number, page_size, filter_email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const getAmcByCode = async (email: string, code: string) => {
  const url = `${Config.baseUrl}/amc/by-code?email=${email}&code=${code}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAmcByCode(email, code)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};
/**
 * @param email
 */
const getBrokersByAmc = async (email: string, amc_code: string) => {
  const url = `${Config.baseUrl}/amc/get-brokers?email=${email}&amc_code=${amc_code}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getBrokersByAmc(email, amc_code)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 *
 * @param search_value
 */
const searchAPI = async (email: string, search_value: string) => {
  const url = `${Config.baseUrl}/amc?email=${email}&search_value=${search_value}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await searchAPI(email, search_value)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

export { getAmc, searchAPI, getAmcByCode, getBrokersByAmc };
