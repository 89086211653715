import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param email
 * @param bank_name
 * @param branch_name
 * @param city
 * @param area
 * @param branch_code
 * @param branch_address
 *
 */
const addBranch = async (
  email: string,
  bank_name: string,
  branch_name: string,
  city: string,
  area: string,
  branch_code: string,
  branch_address: string
) => {
  const url = `${Config.baseUrl}/branch/`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        bank_name,
        branch_name,
        city,
        area,
        branch_code,
        branch_address,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await addBranch(email, bank_name, branch_name, city, area, branch_code, branch_address)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param bankName
 *
 */
const getOfficer = async (email: string, rolname: string) => {
  const url = `${Config.baseUrl}/user/get-all?email=${email}&role_name=${rolname}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getOfficer(email, rolname)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param name
 * @param role
 * @param description
 * @param user_email
 */
const addUser = async (
  email: string,
  name: string,
  role: number,
  description: string,
  user_email: string
) => {
  const url = `${Config.baseUrl}/user/add`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        name,
        role,
        description,
        user_email,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await addUser(email, name, role, description, user_email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param name
 * @param role
 */
const editUser = async (email: string, name: string, role: string) => {
  const url = `${Config.baseUrl}/user/update-info`;
  try {
    const result: any = await axios.post(
      url,
      { email, name, role },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await editUser(email, name, role)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 */
const getAllUsers = async (email: string) => {
  const url = `${Config.baseUrl}/user/get-all?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAllUsers(email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param old_password
 * @param new_password
 */
const changePassword = async (
  email: string,
  old_password: string,
  new_password: string
) => {
  const url = `${Config.loginRegisterUrl}/user/change-password`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        old_password,
        new_password,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await changePassword(email, old_password, new_password)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 */
const updateUserStatus = async (
  email: string,
  user_email: string,
  status: string
) => {
  const url = `${Config.baseUrl}/user/update-status`;
  try {
    const result: any = await axios.post(
      url,
      { email, user_email, status },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await updateUserStatus(email, user_email, status)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};
export {
  addBranch,
  updateUserStatus,
  getOfficer,
  addUser,
  getAllUsers,
  changePassword,
  editUser,
};
