import React from 'react';
// import Header from '../components/Header';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Container, Col, Row } from 'reactstrap';
import { getAllBanks, updateBankStatus } from '../stores/services/bank.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import {
  getNotifications,
  updateNotification,
} from './../stores/services/notification.service';
import { getAllPendingTransactions } from './../stores/services/transactions.service';
import Config from './../config/index';
// const io = require('socket.io-client');

const NotificattionScreen = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const [Loading, setLoading] = useState(false);
  const [readNotfication, setReadNotification] = useState<any>([]);
  const [unreadNotfication, setUnreadNotification] = useState<any>([]);
  React.useEffect(() => {
    const getBankList = async () => {
      setLoading(true);
      try {
        const response = await getNotifications(email);
        setReadNotification(response.data.read);
        setUnreadNotification(response.data.unread);
      } catch (error:any) {
        toast.error(error.response.data.message[0]);
      }
      setLoading(false);
    };
    getBankList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   const socket = io(Config.socketBaseUrl);
  //   // socket;
  //   socket.on('connect', function () {
  //     socket.emit('test', 'Hello From dccl');
  //   });
  //   socket.on('error', function (error) {
  //     socket.emit('error', 'Hello From dccl');
  //   });
  //   socket.on(email.replace('@', '%40'), function (data) {
  //     setTimeout(() => {
  //       GetNotificationData();
  //     }, 5000);
  //   });
  // }, []);
  const GetNotificationData = async () => {
    setLoading(true);
    try {
      const response = await getAllBanks(email);
      setReadNotification(response.data.data.read);
      setUnreadNotification(response.data.data.unread);
    } catch (error:any) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };
  const renderUnreadNotification = () => {
    return unreadNotfication.map((element: any) => {
      return (
        <tr
          className="unread-noti pointer"
          onClick={() => {
            searchFilters(element.txn_id.replace('TXN_','')).then(() => {});
            updateStatusOfNotification(element.notification_id);
          }}
        >
          <td>{element.notification_id}</td>
          <td>{element.message}</td>
          <td>{element.txn_id.replace('TXN_','')}</td>
          <td>{moment(element.created_at).format('DD-MM-YYYY')}</td>
        </tr>
      );
    });
  };

  const searchFilters = (searchVar: string) => {
    return new Promise((resolve, reject) => {
      getAllPendingTransactions(
        '',
        sessionStorage.getItem('email') || '',
        '',
        searchVar,
        ''
      )
        .then((response) => {
          if (response.data.data[0].txn_status === 'REJECTED-AMC') {
            sessionStorage.setItem(
              'rejectedTxObj',
              JSON.stringify(response.data.data[0])
            );
            sessionStorage.setItem(
              'rejectedTxName',
              response.data.data[0].txnr_type
            );
            sessionStorage.setItem('rejectedFlag', 'true');
            history.replace(
              `/admin/beta-transaction/${response.data.data[0].txnr_type}`
            );
          } else {
            sessionStorage.setItem(
              'csab-txn',
              JSON.stringify(response.data.data[0])
            );
            history.replace(
              `/admin/signatory-a/${response.data.data[0].txn_status.toLowerCase()}`
            );
          }
          resolve('Success');
        })
        .catch((err) => {
          reject('Error');
        });
    });
  };
  const updateStatusOfNotification = async (notification_id: string) => {
    try {
      const response = await updateNotification(email, notification_id);
      try {
        const response = await getNotifications(email);
        setReadNotification(response.data.read);
        setUnreadNotification(response.data.unread);
      } catch (error) {}
    } catch (error) {}
  };
  const renderReadNotification = () => {
    return readNotfication.map((element: any) => {
      return (
        <tr
          className="pointer"
          onClick={() => {
            searchFilters(element.txn_id.replace('TXN_','')).then(() => {});
          }}
        >
          <td>{element.notification_id}</td>
          <td>{element.message}</td>
          <td>{element.txn_id.replace('TXN_','')}</td>
          <td>{moment(element.created_at).format('DD MMM YYYY')}</td>
        </tr>
      );
    });
  };
  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [currentBankStatus, setCurrentBankStatus] = useState('');
  const [code, setCode] = useState('');
  const [bankLoading, setbankLoading] = useState(false);
  const updateStatus = async () => {
    setbankLoading(true);
    let status = '';
    if (currentBankStatus === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {
      const response = await updateBankStatus(email, code, status);
      toast.success(response.data.message);
      setStatusSetPopup(false);
      GetNotificationData();
    } catch (error) {}
    setbankLoading(false);
  }; 
  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
 
  const renderData = () => {
    return res.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.bank_name}</td>
          <td>{items.bank_code}</td>
          <td>{moment(items.creation_date).format('DD-MMM-YYYY')}</td>
          <td className="captilize">{items.status}</td>
          <td className="d-flex justify-content-center">
            <div
              className="mr-3"
              onClick={() => {
                setStatusSetPopup(true);
                setCurrentBankStatus(items.status);
                setCode(items.bank_code);
              }}
            >
              {items.status !== 'active' ? (
                <img src="assets/ban.svg" alt="" width="16" />
              ) : (
                <img src="assets/check.svg" alt="" width="16" />
              )}
            </div>
            <img
              src="assets/edit.svg"
              alt=""
              width="16"
              onClick={() => {
                sessionStorage.setItem('bankObj', JSON.stringify(items));
                history.replace(`/edit-bank`);
              }}
            />
          </td>
        </tr>
      );
    });
  };
  return (
    <>
<div className="content">
  <Row>
    <Col md="12">
    <Container fluid>
        <ToastContainer limit={1} />
        {/* <Header /> */}
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Notifications</h4>
          </div>
          <div className="card-body">
            <table
              className="table"
              data-pagination={true}
              data-page-size={5}
            >
              <thead>
                <tr>
                  <th>Notification Id</th>
                  <th>Notifications</th>
                  <th>Transaction Id</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                {unreadNotfication.length === 0 ? (
                  <tr>
                    <td>No Notifications</td>
                  </tr>
                ) : (
                  <>
                    {renderUnreadNotification()}
                    {/* {renderReadNotification()} */}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
   

    </Col>
  </Row>
</div>

   
    </>
  );
};

export default NotificattionScreen;
