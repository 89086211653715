import React from 'react';
// import Header from './../components/Header';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container,Row, Col } from 'reactstrap';
import { getCoListing } from './../stores/services/transactions.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';

const OfficerListing = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const obj = JSON.parse(sessionStorage.getItem('loggedInUser') || '');

  const [Loading, setLoading] = useState(false);
  const [officer, setOfficer] = useState<any>({});
  const [subOfficer, setsubOfficer] = useState<any>({});
  React.useEffect(() => {
    const getOfficerListing = async () => {
      setLoading(true);
      try {
        const response = await getCoListing(email, obj.amc_code);
        setOfficer(response.data.data.concerned_officer);
        setsubOfficer(response.data.data.subtitute_concerned_officer);
      } catch (err:any) {
        // toast.error(error.response.data.message[0]);
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);
        }else {
          toast.error("Request Failed!")
        }
      }
      setLoading(false);
    };
    getOfficerListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
 <div className="content">
        <Row>
          <Col md="12">

      <Container fluid>
        <ToastContainer limit={1} />
        {/* <Header /> */}
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Listing</h4>
          </div>
          {!Loading ? (
            <div className="card-body">
              <div className='table-responsive'>
              <table className="table" id="myTable">
                <thead>
                  <tr>
                    <th>Concerned Officer</th>
                    <th>Concerned Officer Email</th>
                    <th>Substitute Officer</th>
                    <th>Substitute Officer Email</th>
                  </tr>
                </thead>
                <tbody id="myUL">
                  <tr>
                    <td>{officer.name}</td>
                    <td>{officer.email}</td>
                    <td>{subOfficer.name}</td>
                    <td>{subOfficer.email}</td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          ) : (
            <div className="d-flex my-4 justify-content-center my-4"> 
              <i className="fa fa-spinner fa-spin fa-2x ml-3" aria-hidden="true"></i> 
             
            </div>
          )}
        </div>
      </Container>
    
      </Col>
        </Row>
      </div>
    </>
  );
};

export default OfficerListing;
