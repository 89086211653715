import React from 'react';
import { useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Container, Row, Col } from 'reactstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadFundDividendPayoutTransaction } from './../stores/services/beta-transaction.service';
import {JsonToTable} from 'react-json-to-table';
import * as fs from 'fs';
import { Modal } from 'react-bootstrap';
import xls from "xlsx";
import { getAmc } from './../stores/services/amc.service';
import { getFundByAmc } from './../stores/services/funds.service';
import { getAccountByFund, getActiveAccountByFund } from './../stores/services/account.service';
import moment from 'moment';

let csvToJson = require('convert-csv-to-json');
const csv = require('csvtojson/v2');

let xlsxToJson = require('convert-excel-to-json');
//const xlsx = require('xlsx-to-json');

const FundDividendPayout = () => {
  const history = useHistory();
  let date = new Date();
  const [loading, setLoading] = useState(false);
  const [fileData, setfileData] = useState<any>([]);
  const[fileDataCount, setFileDataCount]=useState(0);
  const myRef1 = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const [file, setFile] = useState('');
  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
  const email = sessionStorage.getItem('email') || '';
  const [allFunds, setAllFunds] = useState<any>([]);
  const [allFundAccounts, setAllFundsAccounts] = useState<any>([]);
  const [fund, setFund] = useState('');
  const [fundAccount, setFundAccount] = useState('');
  const [accountTitle, setAccountTitle] = useState('');
  const [accountno, setAccountNo] = useState('');
  const [bank, setBank] = useState('');
  const [branch, setBranch] = useState('');
  const [errorData, setErrorData] = useState<any>([]);
  const [errorShow, setErrorShow] = useState(false);
  const amcCode = sessionStorage.getItem('amc_code') || '';


  React.useEffect(()=>{
      const getFunds=async()=>{
        try{
            const response = await getFundByAmc(email, amcCode);
            setAllFunds(response.data.data);
        }catch(error){
    
        }
    }

    getFunds();
  },[])

    const renderFundsDropDown=()=>{
        return allFunds.map((item: any, index: number) => {
            return (
                <option key={index} value={item.symbol_code}>
                {item.fund_name} - {item.symbol_code}
                </option>
            );
      });
    }

const getFundAccounts=async(fund_code)=>{
    try{
      allFundAccounts.length=0;
      setAllFundsAccounts(allFundAccounts);
      const accResponse = await getActiveAccountByFund(email, fund_code);
      setAllFundsAccounts(accResponse.data.data);
    }catch(error){

    }
  }

  const renderFundAccountsDropDown=()=>{
    return allFundAccounts.map((item: any, index: number) => {
        return (
            <option key={index} value={item.account_no}>
            {item.bank_name} ({item.account_title})
            </option>
        );
  });
  }
 
   const getFundAccountData=(accountno)=>{
    allFundAccounts.map((item, index) => {
        if (item.account_no === accountno) {
          setFundAccount(item.account_code.replaceAll('ACCOUNT_',''));
          setAccountTitle(item.account_title);
          setAccountNo(item.account_no);
          setBank(item.bank_name);
          setBranch(item.branch_name);
        }  
    });

   };

     //retrun error border class
  const ErrorBorder = (value) => {
    if (errorShow && value === '')  {
      return 'required-border';
    } else {
      return '';
    }
  };

  // here to upload the transation bulk upload
  const UploadTxn = async () => {
    if(fund==='' || fundAccount===''){
        setErrorShow(true);
    }else{
    if (file) {
      setLoading(true);
      try {
         
        const res = await uploadFundDividendPayoutTransaction(email, fileData, amcCode, fund, fundAccount,accountTitle, accountno, bank, branch);
        if(res.data.message==="Transaction upload started"){
          setFile('');
          setFileName('');
          setfileData(null);
          setFileDataCount(0);
          toast.success('Transaction upload completed');
          errorData.length = 0;
          setErrorData(errorData);
          setfileData([]);
          setFileDataCount(0);
          setFileName('');
          setFileError('');
          setFund('');
          setFundAccount('');
          setAccountTitle('');
          setAccountNo('');
          setBank('');
          setBranch('');
          setBranch('');
        }else if(res.data.status===404){
          toast.error(res.data.message);
        } else{
          toast.error('Error! Transaction is not uploaded');
        } 
      } catch (error:any) {
        if(error.response.data.status==500){
          toast.error(error.response.data.message);
        }else if(error.response.data.status==400){
          setErrorBoolean(true);
        setErrorData(error.response.data.data);
        }else{
          toast.error(error.response.data.message);
        }
        
      }
      setLoading(false);
    } else {
      toast.error('Kindly Upload File');
    }
}
  };

  const upload = async (e: any) => {
    setFileError('');
    setfileData(null);
    let file = e?.target.files[0];
    let filesize=parseInt(file.size);
    if(filesize<=2097152)
    {
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
     if(type==='xlsx' || type==='csv'){
      setFile(file);
      setFileName(file.name);
      //start
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target?.result;
      const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data :any = xls.utils.sheet_to_json(ws, { header: 2,
        raw: false,
        defval: "",
        dateNF: 'YYYY-MM-DD', });
      /* Update state */
      for (var i=0;i<data.length;i++){
        data[i].ExecutionDate = moment(data[i].ExecutionDate).format('YYYY-MM-DD');
        data[i].DividendDate = moment(data[i].DividendDate).format('YYYY-MM-DD');
        data[i]['realized'] ='true';
        data[i]['realized_date'] = moment(date).format('YYYY-MM-DD');
        data[i]['InstructionDate'] = moment(date).format('YYYY-MM-DD');
      }
     
    //  let jsonarray:any = [];
     if(data){
      // jsonarray = data;
      // jsonarray.forEach((element) => {});
      setfileData(data);
      setFileDataCount(data.length)
     }
    
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
    } else {
      toast.error('Please Upload Correct Format of File');
    }
  }else{
    toast.error("File size should be less than 2MB")
  }
  };

  const [errorBoolean, setErrorBoolean] = useState(false);

  const renderErrorPopup = () => {
    switch (errorBoolean) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal60w"
            show={true}
          > 
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setErrorBoolean(false);
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">Kindly fill following fields data Missing Fields </h6>
             </div>   

            <div className="modal-body">
              <div className="">
                <Row>
                  {/* <div className="mx-auto">
                    <h1 className="pb-3">Kindly fill following fields data</h1>
                    Missing Fields
                  </div> */}
                  <div className={'d-flex border w-100'}>
                    <div className={'w-50'}>Row Number</div>
                    <div className={'w-50'}>Field Name</div>
                  </div>
                  {errorData.map((item, index) => {
                    return (
                      <div className={'d-flex w-100'}>
                        <div className={'w-50'}>{item.row_number}</div>
                        <div className={'w-50'}>{item.field_name}</div>
                      </div>
                    );
                  })}
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              <Link to="/admin/transaction-types"> <i className="fa fa-angle-left"></i> </Link>
               Fund Dividend Payout
               <Dropdown className="float-right">
                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                  Download Samples
                </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="./../../Uploader-Sample/Fund-Dividend-Payout.xlsx" download>Fund Dividend Payout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            </h4>
            </div>
            <div className="card-body">
            <Row>
              <Col md="2">
                <div className="form-group">
                  <label>Select Fund</label>
                  <select
                    className={"form-control " +
                    ErrorBorder(fund)}
                    onChange={(e) => {
                        // setFund(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text)
                        setFund(e.target.value);
                        getFundAccounts(e.target.value);
  
                        // clear fields
                        setFundAccount('');
                        setAccountTitle('');
                        setAccountNo('');
                        setBank('');
                        setBranch('');
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Fund
                    </option>
                      {renderFundsDropDown()}
                  </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group">
                  <label>Select Fund Account</label>
                  <select
                    className={"form-control "+
                    ErrorBorder(fundAccount)}
                    onChange={(e) => {
                        setFundAccount(e.target.value);
                        getFundAccountData(e.target.value);
                        // var AccountTitle=e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text;
                        // setAccountTitle(AccountTitle.split('(')[1].replace(')',''));
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Fund Account
                    </option>
                    {renderFundAccountsDropDown()}
                  </select>
                </div>
              </Col>
              </Row>
              <Row>
                  <Col>
                  <label>Fund : {fund}</label>
                    </Col>
                    <Col>
                    <label>Fund Acount : {fundAccount}</label>
                    </Col>
                    <Col>
                    <label>Title : {accountTitle}</label>
                    </Col>
                    <Col>
                    <label>Account No. : {accountno}</label>
                    </Col>
                    <Col>
                    <label>Bank : {bank}</label>
                    </Col>
                    <Col>
                    <label>Branch : {branch}</label>
                    </Col>
              </Row>
            <div className="">
              <div className="row">
              <div className="col-md-2">
                <label>Choose File</label>
              <div
                className="multi-input pointer"
                onClick={() => myRef1?.current?.click()}
              >
                <div className="form-group">
                  <div className="form-control">
                    {fileName === '' ? 'Upload File' : fileName}
                  </div>
                  <input
                    type="file"
                    ref={myRef1}
                    style={{ display: 'none' }}
                    multiple={false}
                    onChange={(e) => {
                      upload(e);
                    }}
                  />
                  {fileError ? (
                    <p className="error-labels error-message2">{fileError}</p>
                  ) : (
                    ''
                  )}

                </div>
              </div>
              {fileUploadingFlag && <img src="assets/loader.svg" width="50" />}
            </div>
            </div>
            </div>
            {fileDataCount<=0 ?(
              ''
            ):(
              <div className="table-responsive">
              <JsonToTable json={fileData} />
              </div>
            )}
            Total Rows: <span className="text-primary mt-3">{fileDataCount} </span>
               
            <div className="mt-4">
              <button
                className="btn btn-primary "
                onClick={() => {
                  UploadTxn();
                }}
                disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Upload</span>
                )}
              </button>
            </div>
          </div>
        </div>
        {renderErrorPopup()}
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default FundDividendPayout;