import React from 'react';

var accountTitle_AccountNumber_Regex = /^[0-9a-zA-Z -]+$/;
function numberWithCommas(amount: any) {
  if (amount === 'NaN' || amount === NaN || amount === '' || amount === undefined) {
    return ''
  } else {
    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }
}
function sortAMCS(data) {
  let sortedData = data.sort(function (a, b) {
    let x = a.name.toLowerCase();
    let y = b.name.toLowerCase();
    if (x > y) { return 1; }
    if (x < y) { return -1; }
    return 0;
  });
  return sortedData;
}
function toProperCase(str) {
  return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
}

function isNumber(value) {
  if (value == NaN || value == 'NaN' || value == undefined || value == 'undefined' || value == '') {
    value = '0';
  }
  return value;
}

 function isDateValid(date:any){
  if(date=='Invalid date'){
    date='';
  }
 return date;
 }

export { numberWithCommas, toProperCase, isNumber,sortAMCS,isDateValid ,accountTitle_AccountNumber_Regex };