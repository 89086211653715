import React, { useEffect } from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { BsTable } from 'react-icons/bs';
import { getUnitHoldersByAmcAndFolioNo } from '../../stores/services/unit-holder.service';
import { getUnitHolderStatement } from '../../stores/services/report.service';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';
import { isNumber, numberWithCommas } from '../../utils/customFunction';
import { getFundByAmc } from '../../stores/services/funds.service';

const UnitHolderStatement = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const amc_name = sessionStorage.getItem('amc_name') || '';
  let date = new Date();
  const [unitHolderStatement, setUnitHolderStatement] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [unitHolderData, setUnitHolderData] = useState<any>([]);
  const [folioNo, setFolioNo] = useState('');
  const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [resFolioNo, setResFolioNo] = useState('');
  const [resAmc, setResAmc] = useState('');
  const [resFromDate, setResFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [resToDate, setResToDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [folioNoErr, setFolioNoErr] = useState('');

  useEffect(() => {
    const getFundsByAmc = async () =>{
      try {
        allFunds.length = 0;
        setAllFunds(allFunds);
        const response = await getFundByAmc(email, amc_code);
        setAllFunds(response.data.data);
      } catch (error) {
  
      }
    }
    getFundsByAmc();
  }, [])
  
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };


  //code for search data for Reports

  const searchData = async () => {
    
    try {
      unitHolderStatement.length = 0;
      setUnitHolderStatement(unitHolderStatement);
      setLoading(true);
      if (folioNo === '') {
        setFolioNoErr('required-border');
      } else if (folioNo === '') {
        setFolioNoErr('required-border');
      } else {
        const searchResponse = await getUnitHolderStatement(email, amc_code + '-' + folioNo, fromDate, toDate);
        // const response = await searchUnitHolderAPI(email, AmcCode+'-'+folioNo);
        const response = await getUnitHoldersByAmcAndFolioNo(email, amc_code, amc_code + '-' + folioNo);
        //   if(searchResponse.data.status===200 && searchResponse.data.data.length>0){
        //   setUnitHolderData(response.data.data)
        //   let array = Object.entries(searchResponse.data.data);
        //   setResAmc(amc_name);
        //   setResFolioNo(amc_code+'-'+folioNo);
        //   setResToDate(toDate);
        //   setResFromDate(fromDate);
        //   let data:any=[];
        //   // let balance=0.0;
        //   let temp=[] as any;
        //   temp=array[0][1];
        //   setOpeningBalance(temp.Record.current_holding  || '0');
        //   let balance=parseFloat(temp.Record.current_holding || '0'); 
        //   let totalunits=0, totalgrossamount=0, totalnetamount=0, totalcgt=0, totalsaleload=0, totalredemptionload=0, totalrefundCapital=0;
        //   array.forEach(([key, value]) => {       
        //     data=value;
        //     let record=[];
        //     record['Record']= data.Record; 

        //     // if(key=='0'){
        //     //   setOpeningBalance(data.Record.current_holding);
        //     //   balance=parseFloat(data.Record.current_holding);
        //     //  }
        //     if(data.Record.units=== 'NaN'){
        //       data.Record.units = 0;
        //     }
        //     if(data.Record.sale_load=== 'NaN'){
        //       data.Record.sale_load = 0;
        //     }

        //     totalunits+=parseFloat(data.Record.units || 0);
        //     totalgrossamount+=parseFloat(data.Record.gross_amount || 0);
        //     totalnetamount+=parseFloat(data.Record.net_amount || 0);
        //     totalcgt+=parseFloat(data.Record.tax_cgt === undefined ? '0' : data.Record.tax_cgt || 0);
        //     totalredemptionload+=parseFloat(data.Record.redemption_load === undefined ? '0' : data.Record.redemption_load || 0);
        //     totalsaleload+=parseFloat(data.Record.sale_load === undefined ? '0' : data.Record.sale_load || 0);
        //     totalrefundCapital += parseFloat(data.Record.refund_of_capital === undefined ? '0' : data.Record.refund_of_capital || 0);
        //     if(data.Record.txnr_type=='saleofunit' || data.Record.txnr_type=='conversionin'){
        //         balance += parseFloat(data.Record.units || '0');
        //     }else if(data.Record.txnr_type==='funddividendpayout'){
        //       balance += parseFloat(data.Record.units || '0');
        //       balance += parseFloat(data.Record.refund_of_capital || '0');
        //     }else if(data.Record.txnr_type=='transferofunits'){
        //       let transfereesData : any = JSON.parse(data.Record.transfrees);
        //       let total_Balance_for_transacferees=0;
        //       transfereesData.map((itm:any)=>{
        //         total_Balance_for_transacferees += +itm.transferees_unit_transfer;
        //       });
        //       balance -= total_Balance_for_transacferees;
        //       data.Record.units = total_Balance_for_transacferees.toString();
        //     }else{
        //         balance -= parseFloat(data.Record.units || '0');
        //     } 
        //       record['Record']['balance']=balance.toFixed(4);
        //       unitHolderStatement.push(record);
        //   })

        //   setClosingBalance(balance.toFixed(4));
        //   setTotalUnits(totalunits.toFixed(4));
        //   setTotalGrossAmount(totalgrossamount.toFixed(2));
        //   setTotalNetAmount(totalnetamount.toFixed(2));
        //   setTotalCGT(totalcgt.toFixed(2));
        //   setTotalRedemptionLoad(totalredemptionload.toFixed(2));
        //   setTotalSaleLoad(totalsaleload.toFixed(2));
        //   setTotalRefundCapital(totalrefundCapital.toFixed(2));

        //   setUnitHolderCount(unitHolderStatement.length);
        // }else{
        if (searchResponse.data.status === 200 && searchResponse.data.data.length > 0) {
          setUnitHolderData(response.data.data);

          let array = Object.entries(searchResponse.data.data);
          setResAmc(amc_name);
          setResFolioNo(amc_code + '-' + folioNo);
          setResToDate(toDate);
          setResFromDate(fromDate);
          let data: any = [];
          // let balance=0.0;
          let temp = [] as any;
          temp = array[0][1];
          let temp1: any = [];
          searchResponse.data.data.map((item) => {
            temp1.push(item['Record'].fund_code);
          })
          let unique_heading = temp1.filter((value, index) => temp1.indexOf(value) === index);

          let allData = unique_heading.map((head, index) => {
            let fundName;
            allFunds.map((item)=>{
              if(item.symbol_code === head){
                fundName = item.fund_name;
              }
            })
            let count: any = 0, count_balance: any = 0, open_balance: any = 0, totalunits = 0, totalgrossamount = 0, totalnetamount = 0, totalcgt = 0, totalsaleload = 0, totalredemptionload = 0, totalrefundCapital = 0;
            return {
              heading: `${fundName}(${head})`,
              data1: searchResponse.data.data.filter((data, i) => {
                if (data['Record'].fund_code === head) {
                  count += 1;
                  data.Record.units = isNumber(data.Record.units);
                  data.Record.sale_load = isNumber(data.Record.sale_load);

                  if (count === 1) {
                    open_balance = parseFloat(data.Record.current_holding || 0);
                    count_balance += parseFloat(open_balance || 0);
                  }
                  
                  totalgrossamount += parseFloat(data.Record.gross_amount || 0);
                  totalnetamount += parseFloat(data.Record.net_amount || 0);
                  totalcgt += parseFloat(data.Record.tax_cgt === undefined ? 0 : data.Record.tax_cgt || 0);
                  totalredemptionload += parseFloat(data.Record.redemption_load === undefined ? 0 : data.Record.redemption_load || 0);
                  totalsaleload += parseFloat(data.Record.sale_load === undefined ? 0 : data.Record.sale_load || 0);
                  totalrefundCapital += parseFloat(data.Record.refund_of_capital === undefined ? 0 : data.Record.refund_of_capital || 0);
                  if (data.Record.txnr_type == 'saleofunit' || data.Record.txnr_type == 'conversionin') {
                    count_balance += parseFloat(data.Record.units || 0);
                  } else if (data.Record.txnr_type === 'funddividendpayout') {
                    count_balance += parseFloat(data.Record.units || 0);
                    count_balance += parseFloat(data.Record.refund_of_capital || 0);
                  } else if (data.Record.txnr_type == 'transferofunits') {
                    if (data.Record.broker === 'receiver') {
                      let transfereesData: any = JSON.parse(data.Record.transfrees);
                      let total_Balance_for_transacferees: any = 0;
                      transfereesData.filter((itm: any) => {
                        if (itm.folio_no === amc_code + '-' + folioNo) {
                          total_Balance_for_transacferees += parseFloat(itm.transferees_unit_transfer || 0);
                        }
                      });
                      count_balance += parseFloat(total_Balance_for_transacferees || 0);
                      data.Record.units = total_Balance_for_transacferees.toString();
                    } else {
                      let transfereesData: any = JSON.parse(data.Record.transfrees);
                      let total_Balance_for_transacferees: any = 0;
                      transfereesData.map((itm: any) => {
                        // if(itm.folio_no === AmcCode +'-'+ folioNo){
                        total_Balance_for_transacferees += parseFloat(itm.transferees_unit_transfer || 0);
                        // }
                      });
                      count_balance -= parseFloat(total_Balance_for_transacferees || 0);
                      data.Record.units = total_Balance_for_transacferees.toString();
                    }
                  } else {
                    count_balance -= parseFloat(data.Record.units || 0);
                  }
                  totalunits += parseFloat(data.Record.units || 0);
                  data['Record']['count_balance'] = count_balance;
                  return data['Record'];
                }
              }),
              opening_balance: open_balance,
              closing_balance: count_balance,
              total_units: totalunits.toFixed(4),
              total_grossAmount: totalgrossamount.toFixed(2),
              total_netAmount: totalnetamount.toFixed(2),
              total_CGT: totalcgt.toFixed(2),
              total_redemptionLoad: totalredemptionload.toFixed(2),
              total_saleLoad: totalsaleload.toFixed(2),
              total_refundCapital: totalrefundCapital.toFixed(2),
            }
          });

          setUnitHolderStatement(allData);

        } else {
          toast.error('Data not found.')
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // const numberWithCommas = (amount:any) => {
  //     return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

  // }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Unit Holder Statement</h4>
                  </div>
                  <div className="card-body">
                    <Row>

                      <Col md="2">
                        <div className="form-group">
                          {/* <label>Select Unit Holder</label>
                  <select
                    className={"form-control "+ folioNoErr}
                    onChange={(e) => {
                        setFolioNo(e.target.value);
                        setFolioNoErr('');
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Unit Holder
                    </option>
                      {renderUnitHoldersDropDown()}
                  </select> */}
                          <label>Enter Folio No.</label>
                          <input type='text'
                            className={"form-control " + folioNoErr}
                            value={folioNo}
                            onChange={async (e) => {
                              setFolioNo(e.target.value);
                              setFolioNoErr('');
                            }} />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date From</label>
                          <input
                            type="date"
                            defaultValue={fromDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format('YYYY-MM-DD');
                              setFromDate(date);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date To</label>
                          <input
                            type="date"
                            defaultValue={toDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format('YYYY-MM-DD');
                              setToDate(date);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className=" ">
                          {/* here Search Button */}
                          <button
                            className="btn btn-primary mt-4"
                            onClick={() => {
                              searchData();
                            }}
                            disabled={Boolean(loading)}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Search</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: 'white', color: 'black' }}
                    >
                      <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} landscape={true} repeatHeaders={true} fileName={"Unit-Holder-Statement-" + date + ".pdf"} ref={pdfExportComponent}>
                        {unitHolderStatement.length > 0 ? (
                          <>
                            <ReportHeader title="Unit Holder Statement" />
                            {/* <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">Unit Holder Statement</h3>
                  </div> */}

                            <Row>

                              {unitHolderData.length > 0 && unitHolderData[0].name !== undefined && (
                                <Col md="4" style={{ marginLeft: '70px' }}>
                                  <Row>
                                    <Col md="5">
                                      <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                        Name:
                                      </span>
                                    </Col>
                                    <Col md="6">
                                      <span className="text-nowrap">{unitHolderData[0].name}</span>
                                    </Col>
                                  </Row>
                                </Col>
                              )}

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      AMC:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{resAmc}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      Unit Holder:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{resFolioNo}</span>
                                  </Col>
                                </Row>
                              </Col>


                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      From Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>To Date:</span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
                                  </Col>
                                </Row>
                              </Col>

                            </Row>

                            {unitHolderData.length > 0 && (
                              <>
                                <hr />
                                <Row>

                                  {unitHolderData[0].account_title !== undefined && (
                                    <Col md="4" style={{ marginLeft: '70px' }}>

                                      <Row>
                                        <Col md="5">
                                          <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Account Title:</span>
                                        </Col>
                                        <Col md="6">
                                          <span className="text-nowrap">{unitHolderData[0].account_title}</span>
                                        </Col>
                                      </Row>
                                    </Col>
                                  )}

                                  {unitHolderData[0].account_no !== undefined && (
                                    <Col md="4" style={{ marginLeft: '70px' }}>
                                      <Row>
                                        <Col md="5">
                                          <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Account No:</span>
                                        </Col>
                                        <Col md="6">
                                          <span className="text-nowrap">{unitHolderData[0].account_no}</span>
                                        </Col>
                                      </Row>
                                    </Col>
                                  )}

                                  {unitHolderData[0].bank_name !== undefined && (
                                    <Col md="4" style={{ marginLeft: '70px' }}>
                                      <Row>
                                        <Col md="5">
                                          <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Bank:</span>
                                        </Col>
                                        <Col md="6">
                                          <span className="text-nowrap">{unitHolderData[0].bank_name}</span>
                                        </Col>
                                      </Row>
                                    </Col>
                                  )}

                                  {unitHolderData[0].branch_name !== undefined && (
                                    <Col md="4" style={{ marginLeft: '70px' }}>
                                      <Row>
                                        <Col md="5">
                                          <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Branch:</span>
                                        </Col>
                                        <Col md="6">
                                          <span className="text-nowrap">{unitHolderData[0].branch_name}</span>
                                        </Col>
                                      </Row>
                                    </Col>
                                  )}

                                </Row>
                              </>
                            )}

                            {/* <div
                              style={{
                                overflowX: 'auto',
                                overflowY: 'hidden',
                              }}
                            >
                              <div className="float-right"><b>Opening Balance</b> : {numberWithCommas(openingBalance)}</div>
                              <table className="report" style={{ color: 'black' }}>
                                <thead style={{ alignSelf: 'center' }}>
                                  <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Fund</th>
                                    <th className="text-right">Refund Capital</th>
                                    <th className="text-right">Fund Units</th>
                                    <th className="text-right">Gross Amount</th>
                                    <th className="text-right">Net Amount</th>
                                    <th className="text-right">Sale Load</th>
                                    <th className="text-right">Redemption Load</th>
                                    <th className="text-right">CGT</th>
                                    <th className="text-right">Balance</th>
                                  </tr>
                                </thead>
                                <tbody className="report-table-body">
                                  {unitHolderStatement.map((item: any, index: number) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>{moment(item.Record.execution_date).format('DD-MM-YYYY')}</td>
                                          <td>{CapTxnType(item.Record.txnr_type)}</td>
                                          <td>{item.Record.fund_code}</td>
                                          <td className="text-right">{numberWithCommas(item.Record.refund_of_capital)}</td>
                                          <td className="text-right">{numberWithCommas(item.Record.units)}</td>
                                          <td className="text-right">{numberWithCommas(item.Record.gross_amount)}</td>
                                          <td className="text-right">{numberWithCommas(item.Record.net_amount)}</td>
                                          <td className="text-right">{numberWithCommas(item.Record.sale_load === undefined ? '0' : item.Record.sale_load)}</td>
                                          <td className="text-right">{numberWithCommas(item.Record.redemption_load === undefined ? '0' : item.Record.redemption_load)}</td>
                                          <td className="text-right">{numberWithCommas(item.Record.tax_cgt === undefined ? '0' : item.Record.tax_cgt)}</td>
                                          <td className="text-right">{numberWithCommas(item.Record.balance === undefined ? '0' : item.Record.balance)}</td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                  <tr>
                                    <th>Total</th>
                                    <th></th>
                                    <th></th>
                                    <th className="text-right">{numberWithCommas(totalRefundCapital)}</th>
                                    <th className="text-right">{numberWithCommas(totalUnits)}</th>
                                    <th className="text-right">{numberWithCommas(totalGrossAmount)}</th>
                                    <th className="text-right">{numberWithCommas(totalNetAmount)}</th>
                                    <th className="text-right">{numberWithCommas(totalSaleLoad)}</th>
                                    <th className="text-right">{numberWithCommas(totalRedemptionLoad)}</th>
                                    <th className="text-right">{numberWithCommas(totalCGT)}</th>
                                    <th></th>
                                  </tr>
                                </tbody>
                              </table>
                            </div> */}
                            <table className="report" style={{ color: 'black' }}>
                              <thead style={{ alignSelf: 'center' }}>
                                <tr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }}>
                                  <th style={{ width: '5%' }}>Date</th>
                                  <th style={{ width: '10%' }}>Description</th>
                                  <th className="text-right" style={{ width: '10%' }}>Refund Capital</th>
                                  <th className="text-right" style={{ width: '10%' }} >Fund Units</th>
                                  <th className="text-right" style={{ width: '10%' }}>Gross Amount</th>
                                  <th className="text-right" style={{ width: '10%' }}>Net Amount</th>
                                  <th className="text-right" style={{ width: '10%' }}>Sale Load</th>
                                  <th className="text-right" style={{ width: '10%' }}>Redemption Load</th>
                                  <th className="text-right" style={{ width: '10%' }}>CGT</th>
                                  <th className="text-right" style={{ width: '15%' }}>Balance</th>
                                </tr>
                              </thead>
                            </table>

                            {unitHolderStatement.map((unitHolder: any, index: number) => {
                              return (
                                <>
                                  <div className="float-left" style={{ fontSize: "12px", fontWeight: "bold" }}>{unitHolder.heading}</div>
                                  <div className="float-right" style={{ fontSize: "12px" }}><b>Opening Balance</b> : {numberWithCommas(parseFloat(unitHolder.opening_balance).toFixed(2))}<br></br></div>
                                  <table className="report" style={{ color: 'black' }}>
                                    <tbody className="report-table-body">
                                      {unitHolder.data1.map((item: any, index: number) => {
                                        return (
                                          <>
                                            <tr style={{ alignSelf: 'center' }} key={index}>
                                              <td style={{ width: '5%' }}>{moment(item.Record.execution_date).format('DD-MM-YYYY')}</td>
                                              <td style={{ width: '10%' }}>{CapTxnType(item.Record.txnr_type) !== 'Transfer of Units' ? CapTxnType(item.Record.txnr_type) : item.Record.broker === 'receiver' ? CapTxnType(item.Record.txnr_type) + `(receiver)` : CapTxnType(item.Record.txnr_type) + `(sender)`}</td>
                                              <td className="text-right" style={{ width: '10%' }}>{numberWithCommas(parseFloat(item.Record.refund_of_capital).toFixed(2)) || '0.00'}</td>
                                              <td className="text-right" style={{ width: '10%' }}>{numberWithCommas(parseFloat(item.Record.units).toFixed(4))}</td>
                                              <td className="text-right" style={{ width: '10%' }}>{numberWithCommas(parseFloat(item.Record.gross_amount).toFixed(2))}</td>
                                              <td className="text-right" style={{ width: '10%' }}>{numberWithCommas(parseFloat(item.Record.net_amount).toFixed(2))}</td>
                                              <td className="text-right" style={{ width: '10%' }}>{numberWithCommas(parseFloat(item.Record.sale_load).toFixed(2) === undefined ? '0.00' : parseFloat(item.Record.sale_load).toFixed(2)) || '0.00'}</td>
                                              <td className="text-right" style={{ width: '10%' }}>{numberWithCommas(parseFloat(item.Record.redemption_load).toFixed(2) === undefined ? '0.00' : parseFloat(item.Record.redemption_load).toFixed(2)) || '0.00'}</td>
                                              <td className="text-right" style={{ width: '10%' }}>{numberWithCommas(parseFloat(item.Record.tax_cgt).toFixed(2) === undefined ? '0.00' : parseFloat(item.Record.tax_cgt).toFixed(2)) || '0.00'}</td>
                                              <td className="text-right" style={{ width: '15%' }}>{numberWithCommas(parseFloat(item.Record.count_balance).toFixed(2) === undefined ? '0.00' : parseFloat(item.Record.count_balance).toFixed(2))}</td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                    <tfoot >
                                      <tr key={index}>
                                        <th style={{ width: '5%' }}>Total</th>
                                        <th style={{ width: '10%' }}></th>
                                        <th className="text-right" style={{ fontSize: "11px", width: '10%' }}>{numberWithCommas(parseFloat(unitHolder.total_refundCapital).toFixed(2))}</th>
                                        <th className="text-right" style={{ fontSize: "11px", width: '10%' }}>{numberWithCommas(parseFloat(unitHolder.total_units).toFixed(4))}</th>
                                        <th className="text-right" style={{ fontSize: "11px", width: '10%' }}>{numberWithCommas(parseFloat(unitHolder.total_grossAmount).toFixed(2))}</th>
                                        <th className="text-right" style={{ fontSize: "11px", width: '10%' }}>{numberWithCommas(parseFloat(unitHolder.total_netAmount).toFixed(2))}</th>
                                        <th className="text-right" style={{ fontSize: "11px", width: '10%' }}>{numberWithCommas(parseFloat(unitHolder.total_saleLoad).toFixed(2))}</th>
                                        <th className="text-right" style={{ fontSize: "11px", width: '10%' }}>{numberWithCommas(parseFloat(unitHolder.total_redemptionLoad).toFixed(2))}</th>
                                        <th className="text-right" style={{ fontSize: "11px", width: '10%' }}>{numberWithCommas(parseFloat(unitHolder.total_CGT).toFixed(2))}</th>
                                        <th></th>
                                      </tr>
                                    </tfoot>
                                  </table>
                                  <div className="float-right" style={{ marginTop: "-20px", fontSize: "12px" }}><b>Closing Balance</b> : {numberWithCommas(parseFloat(unitHolder.closing_balance).toFixed(2))}</div>
                                  <br />
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text">No record found</p>
                          </div>
                        )}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        // printReport();
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UnitHolderStatement;
