import React, { useState, useEffect } from 'react';	
import {	
  Container,	
  Nav,	
  Navbar,	
  UncontrolledDropdown,	
  DropdownToggle,	
  DropdownItem,	
  DropdownMenu,	
  NavItem,	
  NavbarBrand,	
  NavbarToggler,	
  Collapse,	
  NavLink,	
  ButtonDropdown,	
  Button,	
  UncontrolledTooltip	
} from 'reactstrap';
import copy from 'copy-to-clipboard';
import { AiOutlineCopy } from 'react-icons/ai';	
// nodejs library that concatenates classes	
import classNames from "classnames";	
// import routes from "./routes";	
// import Sidebar from "./sidebar";	
import FixedPlugin from "./FixedPlugin";
import Headerlogo from "../assets/MACS-FO-Logo-w.png";		
import { getAllPendingTransactions } from './../stores/services/transactions.service';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import useNetwork from './network';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IoIosNotifications } from 'react-icons/io';
import {
  AllReadCall,
  getNotifications,
  updateNotification,
} from './../stores/services/notification.service';
import Config from './../config/index';
import { GoPrimitiveDot } from 'react-icons/go';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {env} from "./../env";
import { logoutCentral, logoutMain } from '../stores/services/auth.service';
// const io = require('socket.io-client');	
var ps;	
const Header = (props) => {	
  const history = useHistory();	
  if(sessionStorage.getItem('email')== null){
     history.replace('/login');
  }	
  const email = sessionStorage.getItem('email') || '';	
  const role = sessionStorage.getItem('role') || '';
  // useEffect(() => {	
  //   const socket = io(Config.socketBaseUrl);	
  //   // socket;
  //   socket.on('connect', function () {	
  //     socket.emit('test', 'Hello From dccl');	
  //   });	
  //   socket.on('error', function (error) {	
  //     socket.emit('error', 'Hello From dccl');	
  //   });	
  //   socket.on(email.replace('@', '%40'), function (data) {
  //     setTimeout(() => {
  //       GetNotificationData();
  //     }, 5000);
  //   });
  //   document.title=`${env.REACT_APP_TITLE}`
  // }, [])	
  // const socket = io(`${Config.socketBaseUrl}?user_id=${email}`);

  React.useEffect(() => {
    document.title = `${env.REACT_APP_TITLE}`
  }, []);

  let [showToolbar, setShowToolbar] = useState(true);	
  let [showTooltip, setShowTooltip] = useState(false);	
  const [collapsed, setCollapsed] = useState(true);	
  const [dropdownOpen, setOpen] = useState(false);	
  const isConnected = useNetwork();	
  const [anchorEl, setAnchorEl] = React.useState(null);	
  const handleClick = (event: any) => {	
    setAnchorEl(event.currentTarget);	
    setAnchorEl(event.currentTarget);	
  };	
  const handleClose = () => {	
    setAnchorEl(null);	
  };	
  const [readNotfication, setReadNotification] = useState<any>([]);
  const [unreadNotfication, setUnreadNotification] = useState<any>([]);
  const [loadingNotification, setloadingNotification] = useState<boolean>(
    false
  );
  const [notiDot, setNotiDot] = useState(false);
  
  useEffect(() => {
    GetNotificationData();
  }, []);

  const [totalUnreadNotifications,settotalUnreadNotifications]=useState(0);

  // const countUnreadNotifications = async () => {
  //   setloadingNotification(true);
  //   try {
  //     const response = await getNotifications(email);
  //     toast.success(parseFloat(response.data.unread.length));
  //     settotalUnreadNotifications(parseFloat(response.data.unread.length));
  //     if (response.data.unread.length > 0) {
  //       setNotiDot(true);
        
  //     } else {
  //       setNotiDot(false);
  //     }
  //   } catch (error) {}
  //   setloadingNotification(false);
  // };

  const GetNotificationData = async () => {
    setloadingNotification(true);
    try {
      const response = await getNotifications(email);
      settotalUnreadNotifications(parseFloat(response.data.unread.length));
      // total count unread notifications
      setReadNotification(response.data.read);
      setUnreadNotification(response.data.unread);
      if (response.data.unread.length > 0) {
        setNotiDot(true); 
      } else {
        setNotiDot(false);
      }
    } catch (error) {}
    setloadingNotification(false);
  };
  const updateStatusOfNotification = async (notification_id: string) => {
    try {
      const response = await updateNotification(email, notification_id);
      try {
        const response = await getNotifications(email);
        setReadNotification(response.data.read);
        setUnreadNotification(response.data.unread);
        if (response.data.unread.length > 0) {
          setNotiDot(true);
        } else {
          setNotiDot(false);
        }
      } catch (error) {}
    } catch (error) {}
  };	
  const onCopy = (value) => {
    copy(value);
    toast.info('Copied To Clipboard');
  };
  const renderUnreadNotification = () => {	
    return unreadNotfication.map((element: any, index:number) => {	
      return (	
        <MenuItem className="p-2 unread-noti pl-4 border flex" key={index} onClick={() => {	
          searchFilters(element.txn_id.replace('TXN_','')).then(() => { });	
          updateStatusOfNotification(element.notification_id)	
        }} >	
          <IoIosNotifications className='mr-3 tick-noti' />	
          <div className="line-height">	
            <span className="d-block text-noti" style={{color:"white"}}>Assigned Txn #{element.txn_id.replace('TXN_','')}</span>	
            <span className="date-noti" style={{color:"white"}}>{moment(element.created_at).format(	
              'DD-MM-YYYY hh:mm A'	
            )}</span>	
          </div>
          <AiOutlineCopy
            className={'ml-4'}
            style={{color:"white"}}
            onClick={() => {
              onCopy(element.txn_id.replace('TXN_',''));
            }}
          />	
        </MenuItem>	
      );	
    })	
  }	
  
  const MarkAllReadCall = async () => {
    setloadingNotification(true);
    let data : Array<any> = [];
    unreadNotfication.map((item, index) => {
      data.push(item.notification_id);
    });
    try {
      const response = await AllReadCall(email, data);
      try {
        const response = await getNotifications(email);
        setReadNotification(response.data.read);
        setUnreadNotification(response.data.unread);
        if (response.data.unread.length > 0) {
          setNotiDot(true);
        } else {
          setNotiDot(false);
        }
      } catch (error) {
        toast.error('Something wrong while fetching data');
      }
    } catch (error) {
      toast.error('Something wrong while fetching data');
    }
    setloadingNotification(false);
  };

  const renderReadNotification = () => {	
    return readNotfication.map((element: any, index:number) => {	
      return (	
        <MenuItem className="p-2 pl-4 border flex" key={index} onClick={() => {	
          searchFilters(element.txn_id.replace('TXN_','')).then(() => { });	
        }}>	
          <IoIosNotifications className='mr-3 tick-noti' />	
          <div className="line-height">	
            <span className="d-block text-noti">Assigned Txn #{element.txn_id.replace('TXN_','')}</span>	
            <span className="date-noti">{moment(element.created_at).format(	'DD-MM-YYYY hh:mm A')}</span>	
          </div>
          <AiOutlineCopy
            className={'ml-4'}
            onClick={() => {
              onCopy(element.txn_id.replace('TXN_',''));
            }}
          />		
        </MenuItem>	
      );	
    })	
  }	

  const searchFilters = (searchVar: string) => {
    return new Promise((resolve, reject) => {
      getAllPendingTransactions('', sessionStorage.getItem('email') || '',	'',	searchVar,'')
        .then((response) => {
          if (response.data.data[0].txn_status === 'REJECTED-AMC') {
            sessionStorage.setItem('rejectedTxObj',JSON.stringify(response.data.data[0]));
            sessionStorage.setItem('rejectedTxName',response.data.data[0].txnr_type);
            sessionStorage.setItem('rejectedFlag', 'true');
            history.replace(
              `/admin/beta-transaction/${response.data.data[0].txnr_type}`
            );
          } else {
            sessionStorage.setItem(
              'csab-txn',
              JSON.stringify(response.data.data[0])
            );
            history.replace(`/admin/signatory-a/${response.data.data[0].txn_status.toLowerCase()}`);
          }
          resolve('Success');
        })
        .catch((err) => {
          toast.error("Action already performed.");
          // toast.error(err.response.data.message);
          reject('Error');
        });
    });
  };
  
  // const searchFilters = (searchVar: string) => {
  //   return new Promise((resolve, reject) => {
  //     getAllPendingTransactions(
  //       sessionStorage.getItem('email') || '',
  //       '',
  //       searchVar,
  //       '',
  //       ''
  //     )
  //       .then((response) => {
  //         if (response.data.data[0].txn_status === 'REJECTED-AMC') {
  //           // sessionStorage.setItem(
  //           //   'rejectedTxObj',
  //           //   JSON.stringify(response.data.data[0])
  //           // );
  //           // sessionStorage.setItem(
  //           //   'rejectedTxName',
  //           //   response.data.data[0].txnr_type
  //           // );
  //           // sessionStorage.setItem('rejectedFlag', 'true');
  //           // history.replace(
  //           //   `/beta-transaction/${response.data.data[0].txnr_type}`
  //           // );
  //         } else {
  //           sessionStorage.setItem('csab-txn',JSON.stringify(response.data.data[0])
  //           );
  //           // history.replace(
  //           //   `/signatory-a/${response.data.data[0].txn_status.toLowerCase()}`
  //           // );
  //           history.replace(`/signatory-a/${role}`
  //           );
  //         }
  //         resolve('Success');
  //       })
  //       .catch((err) => {
  //         toast.error('The transaction is already approved.');
  //         reject('Error');
  //       });
  //   });
  // };
  const renderMenuPopup = () => {	
    return (	
      <Menu	
        className="shadow"	
        id="simple-menu"	
        anchorEl={anchorEl}	
        PaperProps={{	
          style: {	
            marginTop: "30px",	
            width: '400px',	
            // maxHeight: '70vh',	
            outline: 'none',	
            // background: '#5ad2d2'	
          }	
        }}	
        anchorOrigin={{	
          vertical: 'top',	
          horizontal: 'right',	
        }}	
        transformOrigin={{	
          vertical: 'top',	
          horizontal: 'right',	
        }}	
        open={Boolean(anchorEl)}	
        onClose={handleClose}	
      >	
        <div className="  ">	

          <div className="p-2 d-flex  heading-noti border-bottom">
            <div
              className={'' + (unreadNotfication.length > 0 && !loadingNotification ? '' : 'mx-auto')}
            >
          <h4 className="pl-3 card-title text-black">Notifications</h4>	

            </div>
            {unreadNotfication.length > 0 && !loadingNotification && (
              <div
                className={'as-read ml-auto'}
                onClick={() => {
                  MarkAllReadCall();
                }}
              >
              
                <h4 className="pl-3 card-title text-black">  Mark all as read</h4>	

              </div>
            )}
          </div>

          {loadingNotification ?	
            <div className="p-4  ">	
             <i className="fa fa-spinner fa-spin fa-2x " aria-hidden="true"></i> 	
            </div>	
            :	
            <div>	
              {(readNotfication.length === 0 && unreadNotfication.length === 0) ?	
                <div className="p-4  ">	
                  No Notification	
              </div>	
                :	
                <>	
                  {renderUnreadNotification()}	
                  {renderReadNotification()}	
                </>	
              }	
            </div>}	
          <div className="  p-2 border-top pointer" onClick={() => {	
            history.replace('/admin/notification-view')	
          }}>	
            See All	
          </div>	
        </div>	
      </Menu>);	
  };	
  const toggle = () => setOpen(!dropdownOpen);	
  // React.useEffect(() => {	
  //   ipcRenderer.on('navigate', (data: any, obj: any) => {	
  //     history.replace(obj);	
  //     renderMenuItemsRightClick();	
  //   });	
  //   const role = sessionStorage.getItem('role') || '';	
  //   const features = sessionStorage.getItem('features') || '';	
  //   ipcRenderer.send('menu-item-show', { role, features });	
  //   renderMenuItemsRightClick();	
  // }, []);	
  // const renderMenuItemsRightClick = () => {	
  //   let rightClickPosition: any = null	
  //   const menu = new remote.Menu()	
  //   const menuItem = new remote.MenuItem({	
  //     label: 'Cut (Ctrl + X)',	
  //     click: () => {	
  //       remote.getCurrentWindow().webContents.cut();	
  //     }	
  //   })	
  //   const menuItem1 = new remote.MenuItem({	
  //     label: 'Copy (Ctrl + C)',	
  //     click: () => {	
  //       remote.getCurrentWindow().webContents.copy();	
  //     }	
  //   })	
  //   const menuItem2 = new remote.MenuItem({	
  //     label: 'Paste (Ctrl + V)',	
  //     click: () => {	
  //       remote.getCurrentWindow().webContents.paste();	
  //     }	
  //   })	
  //   const menuItem3 = new remote.MenuItem({	
  //     label: 'Reload',	
  //     click: () => {	
  //       remote.getCurrentWindow().webContents.reload()	
  //     }	
  //   })	
  //   const menuItem4 = new remote.MenuItem({
  //     label: 'Inspect Element',
  //     click: () => {
  //       remote
  //         .getCurrentWindow()
  //         .webContents.inspectElement(
  //           rightClickPosition.x,
  //           rightClickPosition.y
  //         );
  //     },
  //   });	
  //   menu.append(menuItem)	
  //   menu.append(menuItem1)	
  //   menu.append(menuItem2)	
  //   menu.append(menuItem3)	
  //   menu.append(menuItem4)	
  //   window.addEventListener('contextmenu', (e) => {	
  //     e.preventDefault()	
  //     rightClickPosition = { x: e.x, y: e.y }	
  //     // @ts-ignore	
  //     menu.popup(remote.getCurrentWindow())	
  //   }, false)	
  // }	
  // for sidebar	
  const [activeColor, setActiveColor] = React.useState("blue");	
  const [sidebarMini, setSidebarMini] = React.useState(true);	
  const [opacity, setOpacity] = React.useState(0);	
  const [sidebarOpened, setSidebarOpened] = React.useState(false);	
  const mainPanelRef = React.useRef(null);	
  const notificationAlertRef = React.useRef(null);	
  	
  const handleActiveClick = (color) => {	
    setActiveColor(color);	
  };	
  const handleMiniClick = () => {	
    let notifyMessage = "Sidebar mini ";	
    if (document.body.classList.contains("sidebar-mini")) {	
      setSidebarMini(false);	
      notifyMessage += "deactivated...";	
    } else {	
      setSidebarMini(true);	
      notifyMessage += "activated...";	
    }	
    let options = {};	
    options = {	
      place: "tr",	
      message: notifyMessage,	
      type: "primary",	
      icon: "tim-icons icon-bell-55",	
      autoDismiss: 7,	
    };	
    // notificationAlertRef.current.notificationAlert(options);	
    document.body.classList.toggle("sidebar-mini");	
  };	
  const toggleSidebar = () => {	
    setSidebarOpened(!sidebarOpened);	
    document.documentElement.classList.toggle("nav-open");	
  };	
  const closeSidebar = () => {	
    setSidebarOpened(false);	
    document.documentElement.classList.remove("nav-open");	
  };	
 // test nav	
 const [collapseOpen, setCollapseOpen] = React.useState(false);	
   const [color, setColor] = React.useState("navbar-transparent");	
  React.useEffect(() => {	
    window.addEventListener("resize", updateColor);	
    return function cleanup() {	
      window.removeEventListener("resize", updateColor);	
    };	
  });	
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)	
  const updateColor = () => {	
    if (window.innerWidth < 993 && collapseOpen) {	
      setColor("bg-white");	
    } else {	
      setColor("navbar-transparent");	
    }	
  };	
  // this function opens and closes the collapse on small devices	
  const toggleCollapse = () => {	
    if (collapseOpen) {	
      setColor("navbar-transparent");	
    } else {	
      setColor("bg-white");	
    }	
    setCollapseOpen(!collapseOpen);	
  };	
  	
  //  end of test nav	
// Logout Function
  const logoutFunction = async () => {
    const responseCentral = await logoutCentral(email);
    if (responseCentral.data.status === 200) {
      const responseMain = await logoutMain(email);
      if (responseMain.data.status === 200) {
        sessionStorage.clear();
        history.replace('/login');
      }
    }
  }
  return (	
    <> 	
 	
 	
      {/* <FixedPlugin	
        activeColor={activeColor}	
        sidebarMini={sidebarMini}	
        handleActiveClick={handleActiveClick}	
        handleMiniClick={handleMiniClick}	
      />	 */}
  {/* Test nav */}	
  <Navbar	
        className={classNames("navbar-absolute-height", {	
          // eslint-disable-next-line no-restricted-globals
          [color]: location.pathname.indexOf("full-screen-map") === -1,	
        })}	
        expand="lg"	
      >	
  <Container fluid onLoad={()=>{
    GetNotificationData();
    
  }}>	
        <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
            {/* <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              DashBoard
            </NavbarBrand> */}

          <NavbarBrand>	 
              <img src={Headerlogo} width={180} alt="" />	
          </NavbarBrand>	
          </div>	
          	
          <Collapse navbar isOpen={collapseOpen}>	
            <Nav className="ml-auto" navbar>	
               	
              <UncontrolledDropdown nav>	

               <div className="pointer" onClick={(e) => {	
                  GetNotificationData();	
                  handleClick(e);	
                }}>
               {notiDot && <div className="notification d-none d-lg-block d-xl-block">{totalUnreadNotifications} </div>}
                <i className="tim-icons icon-bell-55 pt-7" > </i>	
              </div>

              </UncontrolledDropdown>	
              <UncontrolledDropdown nav >	
                <DropdownToggle 	
                  caret	
                  color="default"	
                  data-toggle="dropdown"	
                  nav	
                  onClick={(e) => e.preventDefault()}	
                >	
                  <div className="photo">	
                    <i className="tim-icons icon-single-02">	
                      </i>	
                  </div>	
          	
                </DropdownToggle>	
                 	
                <DropdownMenu className="dropdown-navbar" right tag="ul">	

                <NavLink tag="li">
                  <Dropdown.Item> 
                    <div>
                      <span>{role}</span>
                      <span className="d-block">{email}</span>
                    </div>
                  </Dropdown.Item> 
                </NavLink>

                
              <NavLink tag="li">	
                  <DropdownItem  onClick={() => {	
                        history.replace('/admin/user-setting');	
                      }}>	
                    {/* <a	
                       onClick={() => {	
                        history.replace('/admin/user-setting');	
                      }}	
                    >	
                      Settings	
                    </a>	 */}
                    Settings
                  </DropdownItem>	
                </NavLink>	
                <NavLink tag="li">	
                  <DropdownItem  onClick={() => {		
                        logoutFunction();
                      }}	>	
                    Logout	
                  </DropdownItem>	
              </NavLink>	
              	
            </DropdownMenu>	
              </UncontrolledDropdown>	
              <li className="separator d-lg-none" />	
            </Nav>	
            {!isConnected && <div className='network-box'>You're Offline now.<br/>Please check your connection. </div>}	
          </Collapse>	
        </Container>	
        </Navbar>	
  {/* // end Test nav */}	

      {renderMenuPopup()}	
    </>	
  );	
};	
export default Header;	
