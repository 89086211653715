import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param email
 * @param amc_name
 * @param type_of_fund
 * @param date_of_incorporation
 * @param fund_name
 * @param symbol_code
 * @param date_of_revocation
 * @param psx_listing
 */
const addFund = async (
  email: string,
  amc_name: string,
  type_of_fund: string,
  date_of_incorporation: string,
  fund_name: string,
  symbol_code: string,
  date_of_revocation: string,
  psx_listing: string,
  nav: string
) => {
  const url = `${Config.baseUrl}/fund/`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        amc_name,
        type_of_fund,
        date_of_incorporation,
        fund_name,
        symbol_code,
        date_of_revocation,
        psx_listing,
        nav,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await addFund(email,amc_name,type_of_fund,date_of_incorporation,fund_name,symbol_code,date_of_revocation,psx_listing,nav)
      } else {
        return result
      }
    }else {
      throw err
    }
  }
};


// const addDailyNav = async (
//   email: string,
//   amc_code: string, 
//   fund: string,
//   nav: string,
//   nav_date: string,  
//   total_fund_units: string,
//   total_net_asset: string,
//   // offer_rate: string,
// ) => {
//   const url = `${Config.baseUrl}/fund/daily-nav`; 
//   const result: any = await axios.post(
//     url,
//     {
//       email,
//       amc_code,
//       fund,
//       nav,
//       nav_date,
//       total_fund_units,
//       total_net_asset,
//       offer_rate: 'sale',
//     },
//     {
//       headers: {
//         Authorization: sessionStorage.getItem('token') || '',
//       },
//     }
//   );
//   return result;
// };

const addDailyNav = async (
  email: string,
  data: string,
  // offer_rate: string,
) => {
  const url = `${Config.baseUrl}/fund/bulk-daily-nav`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        data,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await addDailyNav(email, data)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/*** 
 * @param email
 */
const getDailyNavByDate = async (email: string, fund: string, nav_date: string) => {
  const url = `${Config.baseUrl}/fund/get-daily-nav?email=${email}&fund=${fund}&nav_date=${nav_date}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getDailyNavByDate(email, fund, nav_date)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/*** 
 * @param email
 */
const getDailyNavOfAllFunds = async (email: string, amc_code: string, nav_date: string) => {
  const url = `${Config.baseUrl}/fund/get-daily-nav-by-amc-code?email=${email}&amc_code=${amc_code}&nav_date=${nav_date}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getDailyNavOfAllFunds(email, amc_code, nav_date)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 * @param email
 */
const getFunds = async (
  email: string,
  page_number: string = '',
  page_size: string = ''
) => {
  const url = `${Config.baseUrl}/fund?email=${email}&page_number=${page_number}&page_size=${page_size}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getFunds(email, page_number, page_size)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};
/**
 * @param email
 */
const getFundByAmc = async (email: string, amcCode: string) => {
  const url = `${Config.baseUrl}/fund/by-amc-code?email=${email}&amc_code=${amcCode}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getFundByAmc(email, amcCode)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};
const EditFund = async (
  email: string,
  amc_name: string,
  type_of_fund: string,
  date_of_incorporation: string,
  fund_name: string,
  symbol_code: string,
  date_of_revocation: string,
  psx_listing: string,
  nav: string
) => {
  const url = `${Config.baseUrl}/fund/update`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        amc_name,
        type_of_fund,
        date_of_incorporation,
        fund_name,
        symbol_code,
        date_of_revocation,
        psx_listing,
        nav,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await EditFund(email,amc_name,type_of_fund,date_of_incorporation,fund_name,symbol_code,date_of_revocation,psx_listing,nav)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};
const updateFundStatus = async (
  email: string,
  symbol_code: string,
  status: string
) => {
  const url = `${Config.baseUrl}/fund/update`;
  try {
    const result: any = await axios.post(
      url,
      { email, symbol_code, status },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await updateFundStatus(email, symbol_code, status)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param symbol_code
 */
const deleteFund = async (email: string, symbol_code: string) => {
  const url = `${Config.baseUrl}/fund/delete-fund/`;
  try {
    const result: any = await axios.put(
      url,
      { email, symbol_code },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await deleteFund(email, symbol_code)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const EditNavFund = async (obj: string) => {
  // const email = sessionStorage.getItem('email') || '';
  const url = `${Config.baseUrl}/fund/update`;
  try {
    const result: any = await axios.post(url, obj, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await EditNavFund(obj)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 */
const getFundType = async (email: string) => {
  const url = `${Config.baseUrl}/mastertxn/mop/?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getFundType(email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param name
 * @param code
 */
const addModeOfPayment = async (email: string, name: string, code: string) => {
  const url = `${Config.baseUrl}/mastertxn/mop/`;
  try {
    const result: any = await axios.post(
      url,
      { email, name, code },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await addModeOfPayment(email, name, code)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param name
 * @param code
 */
const addNatureOfTransaction = async (
  email: string,
  name: string,
  code: string
) => {
  const url = `${Config.baseUrl}/mastertxn/not/`;
  try {
    const result: any = await axios.post(
      url,
      { email, name, code },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await addNatureOfTransaction(email, name, code)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param name
 * @param code
 */
const addModeOfTransaction = async (
  email: string,
  name: string,
  code: string
) => {
  const url = `${Config.baseUrl}/mastertxn/tot/`;
  try {
    const result: any = await axios.post(
      url,
      { email, name, code },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await addModeOfTransaction(email, name, code)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param name
 * @param code
 */
const addInstrumentType = async (email: string, name: string, code: string) => {
  const url = `${Config.baseUrl}/mastertxn/it/`;
  try {
    const result: any = await axios.post(
      url,
      { email, name, code },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await addInstrumentType(email, name, code)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};
/**
 *
 * @param email
 */
const getInstrumentType = async (email: string) => {
  const url = `${Config.baseUrl}/mastertxn/it?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getInstrumentType(email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const UpdateNavOfAllFunds = async (email: string, funds: string) => {
  const url = `${Config.baseUrl}/fund/update-multiple`;
  try {
    const result: any = await axios.post(
      url,
      { email, funds },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await UpdateNavOfAllFunds(email, funds)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

export {
  UpdateNavOfAllFunds,
  addFund,
  EditNavFund,
  EditFund,
  updateFundStatus,
  getFundByAmc,
  getFunds,
  getInstrumentType,
  deleteFund,
  getFundType,
  addModeOfPayment,
  addNatureOfTransaction,
  addModeOfTransaction,
  addInstrumentType,
  addDailyNav,
  getDailyNavByDate,
  getDailyNavOfAllFunds
};
