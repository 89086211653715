declare global {
  interface Window {
    env: any
  }
}

// change with your own variables
type EnvType = {
  // for PTCL
  REACT_APP_TITLE: "MACS Front-Office Alpha",
  REACT_APP_VERSION: "v3.0.3",
  REACT_APP_BASE_URL: "http://203.128.8.2:5520/api",
  REACT_APP_SOCKET_URL: "http://203.128.8.2:5520/",
  REACT_APP_IMAGE_URL: "http://203.128.8.2:5520/",
  CAPTCHA_ENABLED: "false", // true or false
  REACT_LOGIN_REGISTER: "http://203.128.8.2:5521",

  // for NayaTel
  // REACT_APP_TITLE: "MACS Front-Office Alpha UAT QA",
  // REACT_APP_VERSION: "v2.2.3",
  // REACT_APP_BASE_URL: "http://124.109.39.158:3000/api",
  // REACT_APP_SOCKET_URL: "http://124.109.39.158:3000/",
  // REACT_APP_IMAGE_URL: "http://124.109.39.158:3000/",
  // CAPTCHA_ENABLED: "true", // true or false

  // for Production
  // REACT_APP_TITLE: "MACS Front-Office Beta",
  // REACT_APP_VERSION: "v2.2.3",
  // REACT_APP_BASE_URL: "https://beta.macs-core.com:3000/api",
  // REACT_APP_SOCKET_URL: "https://beta.macs-core.com:3000/",
  // REACT_APP_IMAGE_URL: "https://beta.macs-core.com:3000/",
  // CAPTCHA_ENABLED: "true", // true or false
}
export const env: EnvType = { ...process.env, ...window.env }