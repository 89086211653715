
import React, { useEffect } from 'react';
import { useState } from 'react';
// import Header from './../components/Header';
import { getAmc, searchAPI } from './../stores/services/amc.service';
import { Modal } from 'react-bootstrap';
import { Container, Row, Col, Label, Input, FormGroup } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
import Select from 'react-select';
import {
  getFundByAmc,
  getInstrumentType,
  getDailyNavByDate,
  getFunds
} from './../stores/services/funds.service';
import {
  getModeOfPayments,
  getModeOfTx,
} from './../stores/services/transactions.service';
import {
  getAccountByAmc,
  getAccountByFund,
  getActiveAccountByFund
} from './../stores/services/account.service';
import { getUnitHoldersByAmcAndFolioNo } from './../stores/services/unit-holder.service';
import { getAllBanks } from './../stores/services/bank.service';
import { getBranchByBankName } from './../stores/services/branch.service';
import { RiArrowGoForwardFill } from 'react-icons/ri';
import {
  fieldData,
  transactionObj,
  fieldValue,
  chechFieldDisplay,
  getCounterAccount,
  getTaxObj,
  checkTaxBoolean,
} from './field';
import {
  addMegaTransaction,
  EditTransaction,
  getFlow,
  addConversionOfUnitsTransaction
} from './../stores/services/beta-transaction.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsPlusCircleFill } from 'react-icons/bs';
import {
  addFundSecurity,
  getAllActiveFundSecurity,
  getAllFundSecurity,
  getAllPsx,
  getAllSecurities,
} from './../stores/services/security.service';
import {
  inWords
} from './../stores/services/template.service';
import { IoIosClose, IoMdClose } from 'react-icons/io';
import { getBrokerByType } from './../stores/services/broker.service';
import { getTaxAmount, getTaxType } from './../stores/services/tax.service';
import { constant } from 'lodash';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { numberWithCommas, accountTitle_AccountNumber_Regex, isNumber } from './../utils/customFunction';
import { Link } from 'react-router-dom';

import AddUnitHolder from './add-new-unitholder-txn';
import NumberFormat from "react-number-format";

import fs from 'fs';
const readline = require('readline');

interface FieldValues {
  email: string;
  txnr_type: string;
  amc_code: string;
  fund_code: string;
  instruction_date: string;
  execution_date: string;
  executed_date: string;
  fund_account: string;
  reinvest: string;
  account_title: string;
  period: string;
  account_no: string;
  bank: string;
  branch: string;
  counter_account_type: string;
  counter_type: string;
  counter_account_title: string;
  counter_account_number: string;
  counter_bank: string;
  counter_branch: string;
  mode_of_payment: string;
  payment_type: string;
  instrument_no: string;
  instrument_type: string;
  instrument_date: string;
  realized_date: string;
  realized: string;
  gross_amount: string;
  net_amount: string;
  dr_amount: string;
  cr_amount: string;
  balance: string;
  folio_no: string;
  unitholder_name: string;
  units: string;
  nav: string;
  dividend_date: string;
  dividend_rate: string;
  sale_date: string;
  current_holding: string;
  total_holding: string;
  symbol: string;
  dividend_percentage: string;
  credit_date: string;
  maturity_type: string;
  security_type: string;
  issue_date: string;
  conversion_date: string;
  associated_transaction: string;
  maturity_date: string;
  coupon_rate: string;
  price: string;
  face_value: string;
  money_market_face_value: string;
  detail: string;
  type: string;
  redemption_date: string;
  remain_holding: string;
  settlement_by: string;
  tax_type: string;
  settlement_date: string;
  total_charges: string;
  txn_charges: string;
  sale_txn_no: string;
  return_date: string;
  txn_id: string;
  tenor: string;
  yield: string;
  last_coupon_date: string;
  next_coupon_date: string;
  fund_ips_account: string;
  counter_party_ips_account: string;
  system_tax: string;
  primary_dealer: string;
  broker_commission: string;
  tax_sst: string;
  tax_wht_it: string;
  tax_wht_sst: string;
  tax_cgt: string;
  sale_load: string;
  redemption_load: string;
  low_balance_agree: string;
  offer_price: string;
  load_per_unit: string;
  percentage_of_discount: string;
  applicable_offer_price: string;
  sales_tax_collector: string;
  volume: string;
  par_value: string;
  announcement_date: string;
  bonus_percentage: string;
  bonus_credit_date: string;
  bonus_gross_volume: string;
  tax_it_bonus_amount: string;
  net_bonus_volume: string;
  right_share_percentage: string;
  right_share_credit_date: string;
  right_share_gross_volume: string;
  tax_it_right_share_amount: string;
  net_right_share_volume: string;
  tax_on_dvidend: string;
  tax_on_bonus: string;
  tax_on_right: string;
  zakat_amount: string;
}

const TransactionTxnFields = (props: any) => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const [headLabel, setHeadLabel] = React.useState('');
  const tx = sessionStorage.getItem('rejectedTxName') || '';
  const amcEdit = sessionStorage.getItem('rejectedAmc') || false;
  const [amcdata, setAmcdata] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [accountNoData, setAccountNoData] = useState<any>([]);
  const [MOPData, setMOPData] = useState<any>([]);
  const [MOTData, setMOTData] = useState<any>([]);
  const [iTypeData, setITypeData] = useState<any>([]);
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
  const [check_list, setCheckListData] = useState<any>([]);
  const [checklistSetPopup, setCheckListSetPopup] = useState(false);
  const [status, setStatus] = useState('');
  const [reasonText, setReasonText] = useState('');
  const [Loading, setLoading] = useState(false);
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  const requiredFields = transactionObj(headLabel);
  let counterAccounter = getCounterAccount(headLabel);
  let [counterType, setCounterType] = useState('');
  const [accLoading, setAccLoading] = useState(false);
  const [unitHolderData, setUnitHolderData] = useState<any>([]);
  const paymentType = sessionStorage.getItem('payment_type') || '';

  const [psxList, setPsxList] = useState<any>([]);
  const [allFundSecurities, setAllFundSecurities] = useState<any>([]); // Fund as securities Hook
  const [stockMarketDataFund, setStockMarketDataFund] = useState<any>([]); // PSX data of fund
  const [investmentInFundData, setInvestmentInFundData] = useState<any>([]); // stock market data of fund
  const [moneyMarketDataFund, setMoneyMarketDataFund] = useState<any>([]); // money market data of fund
  const [borkerByTypeData, setBorkerByTypeData] = useState<any>([]);
  const [securityTypeData, setSecurityTypeData] = useState<any>([]);
  const [taxData, setTaxData] = useState<any>([]);
  const [counterFundAccount, setCounterFundAccount] = useState<any>([]);
  const amcName = sessionStorage.getItem('amc_name') || '';
  let [sstPercentage, setSSTPercentage] = useState('');
  let [whtITPercentage, setWHTITPercentage] = useState('');
  let [sstWHTPercentage, setSSTWHTPercentage] = useState('');
  // type
  const [inflowType, setInFlowType] = useState<any>([]); // Types For Inflow Transaction Data
  const [outflowType, setOutFlowType] = useState<any>([]); // Types For Outflow Transaction Data
  const [flowLoading, setFlowLoading] = useState<boolean>(false); // will Be true When all Apis give Response
  const [primaryDealerData, setPrimaryDealerData] = useState<any>([]); // Money Maket Securities Hook - primaryDealerData

  //for data checks
  const [fundCheck, setFundCheck] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [accountCheck, setAccountCheck] = useState(false);
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  const [disableAddTaxBtn, setDisableAddTaxBtn] = useState<boolean>(true);
  const [brokerCountFlag, setBrokerCountFlag] = useState(false);
  const [toAccountData, setToAccountData] = useState<any>([]); // all Account Numbers  against a Fund
  const [allCounterFunds, setAllCounterFunds] = useState<any>([]); // all Funds Data related to an Amc
  const [allCounterFundsAccounts, setAllCounterFundsAccounts] = useState<any>(
    []
  ); // all Funds Data related to an Amc
  const [stockMarketData, setStockMarketData] = useState<any>([]); // Types For Equity Investment Transaction Data
  const [InvestmentInFundRecord, setInvestmentInFundRecord] = useState<any>([]); // Types For Investment In Fund Transaction Data
  const [unitHolderCheck, setUnitHolderCheck] = useState(false);
  const [reinest, setReinvest] = useState<boolean>(false);

  const [fundCDCParticipantID, setCDCParticipantID] = useState('');
  const [pledged_holding, setPledgedHolding] = useState('');

  const [unitHolderFieldReadOnly, setUnitHolderFieldReadOnly] = useState(false);
  const [loadBtnEnable, setLoadBtnEnable] = useState(false);

  const [viewModalAddNewHolder, setViewModalAddNewUnitHolder] = useState(false);

  const [mmSecurityLoading, setMMSecurityLoading] = useState(false);

  const [propsState, setPropsState] = useState({
    amc_code: '',
    folioNo: '',
    unitholder: '',
    account_title: '',
    account_no: '',
    bank: '',
    branch: ''
  })

  const [color, setColor] = useState("#db6cfa");
  const styles = {
    color: color
  }

  const loadDailyNav = async (fund: string, nav_date: string) => {
    try {
      const amcResponse = await getDailyNavByDate(email, fund, nav_date);
      return amcResponse.data;
    } catch (error) {
    }
  }

  const decimalValueNav = (value, len) => {
    if (value === 0 || value === parseInt(value, 10)) return value;
    value = value.toString();
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  const getFundByAMcCode = async (code: string) => {
    if (counterAccounter === 'Fund') {
      clearCounterAccountFields();
    }
    setAccountCheck(false);
    setMMSecurityLoading(true);
    setAccFundLoading(true);
    allFunds.length = 0;
    setAllFunds(allFunds);
    // accountNoData.length = 0;
    // setAccountNoData(accountNoData);
    // setToAccountData(accountNoData);
    if (!flag) {
      clearFundAccountFields();
    }
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(email, code);
      // sort data
      let sortedData: any = [];
      sortedData = response.data.data.sort(function (a, b) {
        let x = a.nature.toLowerCase();
        let y = b.nature.toLowerCase();
        if (x > y) { return 1; }
        if (x < y) { return -1; }
        return 0;
      });
      // setAllFunds(response.data.data);
      setAllFunds(sortedData);
      let temp = sortedData.map((val) => {
        if (val.nature === 'Special Managed Account') {
          return {
            ...val,
            label: `${val.fund_name} (${val.symbol_code}) - ${'SMA'} `,
            value: val.symbol_code,
          };
        } else {
          return {
            ...val,
            label: `${val.fund_name} (${val.symbol_code})`,
            value: val.symbol_code,
          };
        }
      });
      setAllCounterFunds(temp);
      setStockMarketData([]);
      setInvestmentInFundRecord([]);
      if (response.data.data.length < 1) {
        setFundCheck(true);
      } else {
        setFundCheck(false);
      }
      setAccFundLoading(false);
    } catch (error) { }

    // // Get acconts by amc Code
    // try {
    //   const accResponse = await getAccountByAmc(email, code);
    //   setCounterFundAccount(accResponse.data.data);
    // } catch (error) {}

    //get Unit Holder data
    // try {
    //   // const unitHolderResponse = await getAllUnitHoldersByAmc(email, code);
    //   const unitHolderResponse = await getUnitHoldersByAmcAndFolioNo(email, code,'');
    //   let temp = unitHolderResponse.data.data.map((val) => {
    //     return {
    //       ...val,
    //       label: `${val.name} (${val.folio_no})`,
    //       value: val.folio_no,
    //     };
    //   });
    //   // temp.unshift({ value: 'Other', label: 'Other' });
    //   setUnitHolderData(temp);
    //   if (unitHolderResponse.data.data.length < 1) {
    //     setUnitHolderCheck(true);
    //   } else {
    //     setUnitHolderCheck(false);
    //   }
    // } catch (error) {}
    // setAccFundLoading(false);
  };

  const getUnitHoldersByAmcFolioNo = async (email, amc_code, foliono) => {
    try {
      setUnitHolderFieldReadOnly(true);
      const unitHolderResponse = await getUnitHoldersByAmcAndFolioNo(email, amc_code, foliono);
      let dataunit = unitHolderResponse.data.data;
      if (dataunit.length > 0) {
        setUnitHolderData(dataunit);

        if (headLabel === 'saleofunit' || headLabel === 'redemptionofunits') {
          await setCounterFieldsData(foliono, unitHolderResponse.data.data);
        } else if (headLabel === 'conversionin' || headLabel === 'conversionout' || headLabel === 'unitconversion') {
          await setFolioNoData(foliono, unitHolderResponse.data.data);
        }
      } else {
        // clear the fields
        if (headLabel === 'saleofunit' || headLabel === 'redemptionofunints') {
          setFields({
            ...fields,
            counter_account_title: '',
            counter_account_number: '',
            counter_bank: '',
            counter_branch: '',
            folio_no: '',
            unitholder_name: '',
            current_holding: ''
          })
        } else if (headLabel === 'conversionin' || headLabel === 'conversionout' || headLabel === 'unitconversion') {
          setFields({
            ...fields,
            counter_account_title: '',
            counter_account_number: '',
            counter_bank: '',
            counter_branch: '',
            unitholder_name: '',
            current_holding: ''
          })
        }
        toast.error('Invalid folio no. (' + foliono.replaceAll(amc_code.toString() + '-', '') + ')')
      }
    } catch (error: any) {
      if (error.response !== undefined) {
        toast.error(error.response.data.message)
      }
    }
    setUnitHolderFieldReadOnly(false);
  }

  const setFolioNoDataTranferor = (selected, dataUnitHolder) => {
    count = count + 1;
    setAccTiltleName(`${selected}-${count}`);
    if (selected !== 'Other') {
      // if user select any account from dropdown then data fillout
      dataUnitHolder.map((item, index) => {
        if (item.folio_no) {
          if (item.folio_no === selected) {
            fields['unitholder_name'] = item.name;
            if (item.balance_unit !== '' && item.balance_unit !== '[]' && item.balance_unit !== undefined) {
              let unitBlance = JSON.parse(item.balance_unit);
              if (unitBlance && typeof unitBlance !== 'number') {
                const temp = unitBlance.filter((element) => {
                  if (element.fund_code.trim() === fields['fund_code']) {
                    return element;
                  }
                });

                if (temp.length > 0) {
                  setAccTiltleName(temp[0].fund_unit.toString());
                  fields['current_holding'] = parseFloat(temp[0].fund_unit.toString().trim()).toFixed(4);
                  fields['remain_holding'] = fields['current_holding']
                  setFields(fields);
                } else {
                  toast.error('Zero Units of selected Fund', {
                    position: 'top-right',
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                  });
                  setAccTiltleName(`${temp.length + index}`);
                  fields['current_holding'] = '';
                  setFields(fields);
                }
              }
            } else {
              //empty
              toast.error('Zero Units of selected Fund', {
                position: 'top-right',
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
              });
              fields['current_holding'] = '';
              count = count + 1;
              setAccTiltleName(`${selected}-${count}-${selected}`);
            }
          }
        }
      });
    } else {
      fields['current_holding'] = '0.00';
      setAccTiltleName(`${count}-${selected}`);
    }
  };

  const getUnitHoldersByAmcFolioNoTranferor = async (email, amc_code, foliono) => {
    fields['unitholder_name'] = '';
    fields['current_holding'] = '';
    fields['remain_holding'] = '';
    setFields(fields);
    try {
      setUnitHolderFieldReadOnly(true);
      const unitHolderResponse = await getUnitHoldersByAmcAndFolioNo(email, amc_code, foliono);
      let dataunit = unitHolderResponse.data.data;
      if (dataunit.length > 0) {
        setUnitHolderData(dataunit);
        if (headLabel === 'transferofunits') {
          await setFolioNoDataTranferor(foliono, unitHolderResponse.data.data);
          dataunit.map((item: any) => {
            let replaceFolio = item.folio_no.replace('UNITHOLDER_', '')
            setTransfereeFolioNumber(replaceFolio.replaceAll(amc_code.toString() + '-', ''))
            setTransfereeName(item.name)
          })
        }
      } else {
        toast.error('Invalid folio no. (' + foliono.replaceAll(amc_code.toString() + '-', '') + ')')
      }
    } catch (error: any) {
      if (error.response !== undefined) {
        toast.error(error.response.data.message)
      }
    }
    setUnitHolderFieldReadOnly(false);
  }

  const [transfereeFolioNumber, setTransfereeFolioNumber] = useState('');
  const [transfereeName, setTransfereeName] = useState('');
  const [TransfreeUnitHolderLength, setTransfreeUnitHolderLength] = useState([]);

  const getUnitHoldersByAmcFolioNoTransfree = async (email, amc_code, foliono) => {
    if (transfereesFolioNo.replace(`${fields['amc_code']}-`, '') === fields['folio_no'].replace(`${fields['amc_code']}-`, '')) {
      toast.error('Transferor and Transferees folio number cannot be same');
      setUnitHolderFieldReadOnly(false);
    } else {
      try {
        setUnitHolderFieldReadOnly(true);
        const unitHolderResponse = await getUnitHoldersByAmcAndFolioNo(email, amc_code, foliono);
        setTransfreeUnitHolderLength(unitHolderResponse.data.data)
        let dataunit = unitHolderResponse.data.data;
        if (dataunit.length > 0) {
          setUnitHolderData(dataunit);

          if (headLabel === 'transferofunits') {
            dataunit.map((item: any) => {
              JSON.parse(item.balance_unit).filter((item) => {
                if (item.fund_code === fields['fund_code']) {
                  transfereesEntity['current_holding'] = item.fund_unit;
                  setTransfereesEntity(transfereesEntity);
                }
              })

              let replaceFolio = item.folio_no.replace('UNITHOLDER_', '')
              setTransfereeFolioNumber(replaceFolio.replaceAll(amc_code.toString() + '-', ''))
              setTransfereeName(item.name)
            })
          }
        } else {
          toast.error('Invalid folio no. (' + foliono.replaceAll(amc_code.toString() + '-', '') + ')')
        }
      } catch (error: any) {
        if (error.response !== undefined) {
          toast.error(error.response.data.message)
        }
      }
    }
    setUnitHolderFieldReadOnly(false);
  }





  const getAccountByFundName = async (fund_code: string) => {
    allFunds.map((item: any) => {
      if (item.symbol_code === fund_code) {
        fields['nav'] = item.nav;
        fields['face_value'] = item.face_value;
        setFields(fields);
        if (headLabel !== 'investmentinfund') {
          let stock_market_data = [] as any;
          if (item.stock_market !== '' && item.stock_market !== '[]' && item.stock_market !== undefined) {
            let stock_arr = JSON.parse(item.stock_market);
            stock_arr.map((item, ind) => {
              stock_market_data.push(item);
            });

            setStockMarketData(stock_market_data);
          } else {
            setStockMarketData([]);
          }
        } else {
          let fund_market_data = [] as any;
          if (item.fund_market !== '' && item.fund_market !== '[]' && item.fund_market !== undefined) {
            let fundMarket_arr = JSON.parse(item.fund_market);
            fundMarket_arr.map((item, ind) => {
              fund_market_data.push(item);
            });

            setInvestmentInFundRecord(fund_market_data);
          } else {
            setInvestmentInFundRecord([]);
          }
        }
      }
    });
    // setStockMarketData([]);

    setAccLoading(true);
    //get account by fund name for dropdown
    try {
      const accResponse = await getActiveAccountByFund(email, fund_code);
      let temp = accResponse.data.data.map((val) => {
        return {
          ...val,
          // add bank name and branch name
          // label: ` ${val.account_no} (${val.account_code})`,
          label: ` ${val.bank_name} ${val.branch_name} (${val.account_title})`,
          value: val.account_code,
        };
      });
      // temp.unshift({ value: 'Other', label: 'Other' });
      setToAccountData(temp);
      setAccountNoData(temp);
      if (accResponse.data.data.length < 1) {
        setAccountCheck(true);
      } else {
        setAccountCheck(false);
      }
    } catch (error) { }
    setAccLoading(false);
  };

  // Function to get aLL Accounts of selected Fund from Instruction Section
  const [counterFundLoading, setCounterFundLoading] = useState(false);
  const [fundtemp, setFundtemp] = useState('');

  const getCounterFundAccountByFundName = async (fund_code: string) => {
    setCounterFundLoading(true);
    //get account by fund name for dropdown
    try {
      const accResponse = await getActiveAccountByFund(email, fund_code);
      let temp = accResponse.data.data.map((val) => {
        return {
          ...val,
          // label: `${val.account_no} (${val.account_code})`,
          label: `${val.bank_name}-${val.branch_name} (${val.account_title})`,
          value: val.account_code,
        };
      });
      temp.unshift({ value: 'Other', label: 'Other' });
      setAllCounterFundsAccounts(temp);
    } catch (error) { }
    setCounterFundLoading(false);
  };

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const flag = sessionStorage.getItem('rejectedFlag') || false;
  const [grossAmountReadOnly, setGrossAmountReadOnly] = useState(false);
  const [netAmountReadOnly, setNetAmountReadOnly] = useState(false);
  const [unitsReadOnly, setUnitsReadOnly] = useState(false);
  const [discount_applicable_ReadOnly, setDiscount_Applicable_ReadOnly] = useState(false);
  const [grossAmountReadOnlyIF, setGrossAmountReadOnlyIF] = useState(false);
  const [noOfUnitsReadOnlyIF, setNoOfUnitsReadOnlyIF] = useState(false);

  React.useEffect(() => {
    setHeadLabel(props.match.params.type);
    fields['txnr_type'] = props.match.params.type;

    // setFields(fields);
    counterAccounter = getCounterAccount(props.match.params.type);
    if (counterAccounter === 'Other') {
      fields['counter_account_type'] = 'Other';
      setFields(fields);
    }
    if (props.match.params.type === 'debtmarketinvestment' || props.match.params.type === 'equityinvestment' || props.match.params.type === 'investmentinfund') {
      setGrossAmountReadOnly(true);
      setNetAmountReadOnly(true);
    }
  }, [props.match.params.type]);

  const loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser') || '');

  let [realize, setRealize] = useState(false);

  // filed Object Hook For geting data from fields / object for transaction creation
  let date = new Date();
  let disableDates = moment(date).format('YYYY-MM-DD');
  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: '',
    amc_code: loggedInUser.amc_code,
    dividend_date: '',
    dividend_rate: '',
    fund_code: '',
    reinvest: 'false',
    instruction_date: moment(date).format('YYYY-MM-DD'),
    execution_date: moment(date).format('YYYY-MM-DD'),
    executed_date: moment(date).format('YYYY-MM-DD'),
    fund_account: '',
    account_title: '',
    account_no: '',
    bank: '',
    branch: '',
    counter_account_type: '',
    counter_type: '',
    counter_account_title: '',
    counter_account_number: '',
    counter_bank: '',
    counter_branch: '',
    mode_of_payment: '',
    payment_type: '',
    instrument_no: '',
    instrument_type: '',
    instrument_date: moment(date).format('YYYY-MM-DD'),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format('YYYY-MM-DD'),
    realized: 'true',
    gross_amount: '',
    net_amount: '',
    dr_amount: '',
    period: '',
    cr_amount: '',
    balance: '',
    folio_no: '',
    unitholder_name: '',
    units: '',
    nav: '',
    sale_date: moment(date).format('YYYY-MM-DD'),
    current_holding: '0',
    total_holding: '',
    symbol: '',
    dividend_percentage: '',
    credit_date: '',
    maturity_type: '',
    security_type: '',
    issue_date: moment(date).format('YYYY-MM-DD'),
    conversion_date: moment(date).format('YYYY-MM-DD'),
    associated_transaction: '',
    maturity_date: moment(date).format('YYYY-MM-DD'),
    coupon_rate: '',
    price: '',
    face_value: '',
    money_market_face_value: '',
    detail: '',
    type: '',
    redemption_date: moment(date).format('YYYY-MM-DD'),
    remain_holding: '0',
    settlement_by: '',
    tax_type: '',
    settlement_date: moment(date).format('YYYY-MM-DD'),
    total_charges: '0',
    txn_charges: '',
    sale_txn_no: '',
    return_date: '',
    txn_id: '',
    last_coupon_date: moment(date).format('YYYY-MM-DD'),
    next_coupon_date: moment(date).format('YYYY-MM-DD'),
    yield: '',
    tenor: '',
    fund_ips_account: '',
    counter_party_ips_account: '',
    system_tax: '',
    primary_dealer: '',
    broker_commission: '',
    tax_sst: '',
    tax_wht_it: '',
    tax_wht_sst: '',
    tax_cgt: '0',
    sale_load: '',
    redemption_load: '0',
    low_balance_agree: '',
    offer_price: '',
    load_per_unit: '',
    percentage_of_discount: '0',
    applicable_offer_price: '',
    sales_tax_collector: 'SRB', // default value SRB
    volume: '',
    par_value: '',
    announcement_date: '',     // Dividend on Investment
    bonus_percentage: '',
    bonus_credit_date: '',
    bonus_gross_volume: '',
    tax_it_bonus_amount: '',
    net_bonus_volume: '',
    right_share_percentage: '',
    right_share_credit_date: '',
    right_share_gross_volume: '',
    tax_it_right_share_amount: '',
    net_right_share_volume: '',
    tax_on_dvidend: '',
    tax_on_bonus: '',
    tax_on_right: '',
    zakat_amount: '0',
  });

  //code for edit transaction
  let [count, setCount] = useState(0);
  const [PIB_fields, setPIBFields] = useState(false);
  const [securityCheck, setSecurityCheck] = useState('');

  React.useEffect(() => {
    if (flag) {
      const obj = JSON.parse(sessionStorage.getItem('rejectedTxObj') || ' ');
      getFundByAMcCode(obj.amc_code);
      fields['payment_type'] = obj.payment_type || '';
      fields['txnr_type'] = obj.txnr_type || '';
      fields['amc_code'] = obj.amc_code || '';
      getAccountByFundName(obj.fund_code);
      fields['fund_code'] = obj.fund_code || '';
      getBranchByName(obj.bank);
      getCounterBranchByName(obj.counter_bank);
      fields['instruction_date'] = obj.instruction_date || '';
      fields['execution_date'] = obj.execution_date || '';
      fields['executed_date'] = obj.executed_date || '';
      fields['fund_account'] = obj.fund_account || '';
      fields['dividend_date'] =
        obj.dividend_date || '';
      fields['dividend_rate'] = obj.dividend_rate || '';
      fields['reinvest'] = obj.reinvest || '';
      fields['period'] = obj.period || '';
      fields['account_title'] = obj.account_title || '';
      fields['account_no'] = obj.account_number || '';
      fields['bank'] = obj.bank || '';
      fields['branch'] = `${obj.branch} – ${obj.bank_city}` || '';
      fields['bank_city'] = obj.bank_city;
      fields['counter_account_type'] = obj.counter_account_type || '';
      fields['counter_type'] = obj.counter_type || '';
      fields['counter_account_title'] = obj.counter_account_title || '';
      fields['counter_account_number'] = obj.counter_account_number || '';
      fields['counter_bank'] = obj.counter_bank || '';
      fields['counter_branch'] = obj.counter_branch || '';
      fields['mode_of_payment'] = obj.mode_of_payment || '';
      fields['instrument_no'] = obj.instrument_no || '';
      fields['instrument_type'] = obj.instrument_type || '';
      fields['instrument_date'] = obj.instrument_date || '';
      fields['realized_date'] = obj.realized_date || '';
      fields['realized'] = obj.realized || '';
      fields['gross_amount'] = obj.gross_amount.toString() || '';
      fields['net_amount'] = obj.net_amount.toString() || '';
      fields['dr_amount'] = '0';
      fields['cr_amount'] = '0';
      fields['balance'] = obj.balance.toString() || '';
      fields['folio_no'] = obj.folio_no || '';
      fields['unitholder_name'] = obj.unitholder_name || '';
      fields['units'] = obj.units.toString() || '';
      fields['nav'] = obj.nav.toString() || '';
      fields['sale_date'] = obj.sale_date || '';
      fields['current_holding'] = obj.current_holding.toString() || '';
      fields['total_holding'] = obj.total_holding.toString() || '';
      fields['symbol'] = obj.symbol || '';
      fields['dividend_percentage'] = obj.dividend_percentage.toString() || '';
      fields['credit_date'] = obj.credit_date || '';
      fields['maturity_type'] = obj.maturity_type || '';
      fields['security_type'] = obj.security_type || '';
      fields['issue_date'] = obj.issue_date || '';
      fields['conversion_date'] = obj.conversion_date || '';
      fields['associated_transaction'] = obj.associated_transaction || '';
      fields['maturity_date'] = obj.maturity_date || '';
      fields['coupon_rate'] = obj.coupon_rate || '';
      fields['price'] = obj.price.toString() || '';
      fields['face_value'] = obj.face_value.toString() || '';
      // fields['money_market_face_value'] = obj.money_market_face_value.toString() || '';
      fields['detail'] = obj.detail || '';
      fields['type'] = obj.type || '';
      if (obj.reinvest === 'true') {
        setReinvest(true);
      } else {
        setReinvest(false);
      }
      fields['redemption_date'] = obj.redemption_date || '';
      fields['remain_holding'] = obj.remain_holding.toString() || '';
      fields['settlement_by'] = obj.settlement_by || '';
      fields['tax_type'] = obj.tax_type || '';
      fields['settlement_date'] = obj.settlement_date || '';
      fields['total_charges'] = obj.total_charges.toString() || '';
      fields['sale_txn_no'] = obj.sale_txn_no || '';
      fields['return_date'] = obj.return_date || '';
      fields['trx_id'] = obj.txn_id || '';
      fields['primary_dealer'] = obj.primary_dealer;
      fields['yield'] = obj.yield || '';
      if (headLabel === 'advisoryfee' || headLabel === 'auditorsfee' || headLabel === 'managementfee' || headLabel === 'trusteefee' || headLabel === 'custodyfee'
        || headLabel === 'shariahadvisoryfee' || headLabel === 'cdcfee' || headLabel === 'listingfee' || headLabel === "listingfee" || headLabel === "brokagefee" ||
        headLabel === 'printingfee' || headLabel === 'professionalfee' || headLabel === 'ratingfee' || headLabel === 'taxpayment' || headLabel === 'secpfee' || headLabel === 'otherpayment') {
        fields['tax_sst'] = obj.tax_sst || '';
        fields['tax_wht_it'] = obj.tax_sst || '';
        fields['tax_wht_sst'] = obj.tax_sst || '';
        fields['sales_tax_collector'] = obj.sales_tax_collector || '';
      }

      if (headLabel === '' || headLabel === '') {
        fields['sale_load'] = obj.sale_load || '';
      }

      if (headLabel === 'equityinvestment') {
        fields['broker_commission'] = obj.broker_commission || '';
        fields['tax_sst'] = obj.tax_sst || '';
        fields['sales_tax_collector'] = obj.sales_tax_collector || '';
      }
      setSecurites(JSON.parse(obj.securities));
      if (headLabel === '' || headLabel === '') {
        fields['redemption_load'] = obj.redemption_load || '';
        fields['tax_cgt'] = obj.tax_cgt || '';
      }
      if (headLabel === 'moneymarketpurchase' || headLabel === 'saleofsecurities') {
        if (obj.security_type === 'PIB') {
          setPIBFields(true);
          fields['last_coupon_date'] = obj.last_coupon_date || '';
          fields['next_coupon_date'] = obj.next_coupon_date || '';
          fields['tenor'] = obj.tenor || '';
        }
      }
      fields['zakat_amount'] = obj.zakat_amount;
      fields['txn_status'] = 'CREATED';

      // setSecurites(obj.securities);
      // Dividend On Investment
      fields['volume'] = obj.volume;
      fields['par_value'] = obj.par_value;
      fields['announcement_date '] = obj.announcement_date;
      fields['bonus_percentage'] = obj.bonus_percentage;
      fields['bonus_credit_date'] = obj.bonus_credit_date;
      fields['bonus_gross_volume'] = obj.bonus_gross_volume;
      fields['tax_it_bonus_amount'] = obj.tax_it_bonus_amount;
      fields['net_bonus_volume'] = obj.net_bonus_volume;
      fields['right_share_percentage'] = obj.right_share_percentage;
      fields['right_share_credit_date'] = obj.right_share_credit_date;
      fields['right_share_gross_volume'] = obj.right_share_gross_volume;
      fields['tax_it_right_share_amount'] = obj.tax_it_right_share_amount;
      fields['net_right_share_volume'] = obj.net_right_share_volume;
      fields['tax_on_dvidend'] = obj.tax_on_dvidend;
      fields['tax_on_bonus'] = obj.tax_on_bonus;
      fields['tax_on_right'] = obj.tax_on_right;
      // setTotalTax(obj.txn_charges);
      count = count + 1;
      setCount(count);
      fields['fund_ips_account'] = obj.fund_ips_account || '';
      fields['counter_party_ips_account'] = obj.counter_party_ips_account || '';
      fields['low_balance_agree'] = ''
    }
  }, []);

  const [bankNameData, setBankNameData] = useState<any>([]);
  const [amcdataCounter, setAmcdataCounter] = useState<any>([]); // all Amc Data

  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };
  //


  const getTaxRate = () => {
    let taxFields = getTaxObj(props.match.params.type);
    setSSTPercentage(taxFields.tax_rate);
    setWHTITPercentage(taxFields.wht_it_rate);
    setSSTWHTPercentage(taxFields.wht_sst_rate);
  };

  //end
  React.useEffect(() => {
    getTaxRate();
    if (!flag) {
      fields['payment_type'] = paymentType;
      fields['email'] = email;
      fields['amc_code'] = loggedInUser.amc_code;
    }

    setFlowLoading(true);
    const fetchAmc = async () => {
      MOPData.length = 0;
      setMOPData(MOPData);

      // get funds by amc_code
      try {
        const loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser') || '');
        await getFundByAMcCode(loggedInUser.amc_code);
        //get all broker data
        // const brokerResponse = await getBrokersByAmc(email, loggedInUser.amc_code);
        // setAllBrokers(brokerResponse.data.data);
      } catch (error) {

      }

      try {
        const response = await searchAPI(email, loggedInUser.amc_code);
        let temp = response.data.data.map((val) => {
          return {
            ...val,
            label: `${val.account_no}`,
            value: val.account_no,
          };
        });
        temp.unshift({
          value: 'Other',
          label: 'Other',
        });
        setAmcdataCounter(temp);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        }
      }

      //api call to fetch data for bank dropdown
      try {
        const bankResponse = await getAllBanks(email);
        let temp = bankResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.bank_code.replace('BANK_', '')} (${val.bank_name})`,
            value: val.bank_code.replace('BANK_', ''),
          };
        });
        temp.unshift({ value: 'Other', label: 'Other' });
        setBankNameData(temp);
      } catch (error) { }
      //get all getModeOfPayments list for dropdown
      try {
        const response = await getModeOfPayments(email);
        setMOPData(response.data.mode_of_payment);
      } catch (error) { }
      //get InstrumentType data
      try {
        const inTypeResponse = await getInstrumentType(email);
        setITypeData(inTypeResponse.data.instrument_types);
      } catch (error) { }

      if (props.match.params.type === 'equityinvestment') {
        //get Broker data
        try {
          const response = await getAllPsx(email);
          let sortedData = response.data.data.sort(function (a, b) {
            let x = a.company_name.toLowerCase();
            let y = b.company_name.toLowerCase();
            if (x > y) { return 1; }
            if (x < y) { return -1; }
            return 0;
          });
          // setPsxList(response.data.data);
          setPsxList(sortedData);
        } catch (error) { }
      }

      if (props.match.params.type === 'investmentinfund') {
        try {
          const response = await getAllActiveFundSecurity(email);
          let sortedData = response.data.data.sort(function (a, b) {
            let x = a.name.toLowerCase();
            let y = b.name.toLowerCase();
            if (x > y) { return 1; }
            if (x < y) { return -1; }
            return 0;
          });
          setAllFundSecurities(sortedData);
        } catch (err: any) {
          if (err.response !== undefined) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message)
          }
        }
      }

      try {
        const brokerResponse = await getBrokerByType(email, counterAccounter);
        let temp = brokerResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.company_name} (${val.company_code})`,
            value: val.company_code,
          };
        });
        temp.unshift({ value: 'Other', label: 'Other' });

        setBorkerByTypeData(temp);
        brokerResponse.data.data.length > 0
          ? setBrokerCountFlag(false)
          : setBrokerCountFlag(true);
      } catch (error) { }


      if (props.match.params.type === 'taxpayment') {
        //get tax type data
        try {
          const taxTypeRes = await getTaxType(email);
          setTaxData(taxTypeRes.data.tax_names);
        } catch (error) { }
      }

      if (props.match.params.type === 'inflow' || props.match.params.type === 'outflow') {
        try {
          const flowTypeRes = await getFlow(email);
          setInFlowType(flowTypeRes.data.inflow);
          setOutFlowType(flowTypeRes.data.outflow);
        } catch (error) { }
      }

      if (props.match.params.type === 'debtmarketinvestment') {
        //get security data
        try {
          setMMSecurityLoading(true);
          const res = await getAllSecurities(email);
          setSecurityTypeData(res.data.data);
          setMMSecurityLoading(false);
        } catch (error) { }
        //API all to get Broker Primary Dealer
        try {
          const res = await getBrokerByType(email, 'Primary Dealer');
          setPrimaryDealerData(res.data.data);
        } catch (error) { }
      }

      setFlowLoading(false);
    };
    fetchAmc();
  }, []);

  //render dropdown for flow data
  const renderinFlowTypeDropdown = () => {
    return (
      inflowType &&
      inflowType.map((item: any, index: number) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      })
    );
  };
  const renderOutFlowTypeDropdown = () => {
    return (
      outflowType &&
      outflowType.map((item: any, index: number) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      })
    );
  };

  //render dropdown for tax data
  const renderTaxTypeDropdown = () => {
    return taxData.map((item: any, index: number) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  //render dropdown for mop data
  const renderModeOfPayments = () => {
    return MOPData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === 'Special Managed Account') {
        return (
          <>
            <option key={index} value={item.symbol_code}>
              {item.fund_name} ({item.symbol_code}) - {'SMA'}
            </option>
          </>
        );
      } else {
        return (
          <option key={index} value={item.symbol_code}>
            {item.fund_name} ({item.symbol_code})
          </option>
        );
      }
    });
  };

  const getFundMoneyMarketPortfolioData = (fund_code, security_type) => {
    const s = allFunds.filter((item: any, index: number) => {
      return (fund_code === item.symbol_code)
    });
    let data = JSON.parse(s[0].money_market);
    let securitiesData = data.filter((item: any, index: number) => {
      return security_type === item.security_type;
    });
    setMoneyMarketDataFund(securitiesData);
  };

  const getFundStockMarketData = (code) => {
    const s = allFunds.filter((item: any, index: number) => {
      return (code === item.symbol_code)
    });
    setStockMarketDataFund(JSON.parse(s[0].stock_market))
  };

  // Data Show In case of Sell Security in investment in fund.
  const getInvestmentInFundData = (code) => {
    const s = allFunds.filter((item: any, index: number) => {
      return (code === item.symbol_code)
    });
    setInvestmentInFundData(JSON.parse(s[0]?.fund_market))
  };


  //render dropdown for iTypeData data
  const renderiTypeDataDropdown = () => {
    return iTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  //render dropdown for primary dealer data
  const renderPrimaryDealerDropdown = () => {
    return primaryDealerData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.company_name}>
          {/* {item.name} */}
          {`${item.company_name} (${item.company_code}) `}
        </option>
      );
    });
  };

  //render dropdown for psx data
  const renderPsxDataDropdown = () => {
    return psxList.map((item: any, index: string) => {
      return (
        <option key={index} value={item.symbol}>
          {item.company_name} ({item.symbol})
        </option>
      );
    });
  };

  //render dropdown for money market securities data of fund
  const renderMoneyMarketSecurityData = () => {
    return moneyMarketDataFund.map((item: any, index: any) => {
      return (
        <option
          key={index}
          value={item.code.toString() + '&&' + index.toString()}>{item.name}</option>
      );
    });
  };

  //render dropdown for Fund Securities
  const renderFundSecuritiesDropdown = () => {
    return allFundSecurities.map((item: any, index: string) => {
      if (fields['fund_code'] !== item.code) {
        return (
          <option
            key={index}
            value={item.code}
          >{`${item.code} - ${item.name}`}</option>
        );
      }
    });
  };

  //render dropdown for Sell Fund Securities
  const renderSellFundSecuritiesData = () => {
    return investmentInFundData.map((item: any, index: string) => {
      return (
        <option
          key={index}
          value={item.code}
        >{item.code}</option>
      );
    });
  };

  //render dropdown for psx data of fund
  const renderStockMarketData = () => {
    return stockMarketDataFund.map((item: any, index: string) => {
      return (
        <option
          key={index}
          value={item.code}
        >{item.code}</option>
      );
    });
  };

  //render dropdown for security data
  const renderSecurityDataDropdown = () => {
    return securityTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.code.replace('SECURITY_', '')}>
          {item.name.replace('SECURITY_', '')}
        </option>
      );
    });
  };

  const [securityData, setSecurityData] = useState([]);
  // filter securities data based on security_type selection
  const renderFilterSecurityDataDropdown = (security_type: string) => {
    const check = securityTypeData.filter((item: any, index: string) => {
      return (item.security_type === security_type);
    });
    setSecurityData(check);
  };

  const renderSecuritiesDropDown = () => {
    return securityData.map((item: any, index: number) => {
      return (
        <option key={index} value={item.code.replace('SECURITY_', '')}>
          {item.name}
        </option>
      );
    });
  }

  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  const [allCounterbranchesData, setAllCounterBranchesData] = useState<any>([]);

  const getBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: 'Other', label: 'Other' });
      setAllBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) { }
  };


  const getCounterBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name} – ${val.city}`,
          // value: val.branch_code,
          value: `${val.branch_name} – ${val.city}`,
        };
      });
      temp.unshift({ value: 'Other', label: 'Other' });
      setAllCounterBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) { }
  };

  const [inputType, setInputType] = useState(false);
  const onOtherSelection = (val) => {
    if (val === 'Other') {
      setFields({
        ...fields,
        counter_branch: '',
      });
      setInputType(true);
    }
  };

  // handle data for Fund Account Dropdown selection
  const setFundDataCounter = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      // if user select any account from dropdown then data fillout
      accountNoData.map((item, index) => {
        if (item.account_code === selected) {
          getCounterBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          fields['counter_account_type'] = item.account_code;
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.account_no;
          fields['counter_bank'] = item.bank_name;
          fields['counter_branch'] = `${item.branch_name} – ${item.city}`;
          setFields(fields);

          //////
          termDepositEntity['bank'] = item.bank_name;
          termDepositEntity['branch'] = `${item.branch_name} – ${item.city}`;
          setTermDepositEntity(termDepositEntity);
        }
      });
    }
  };

  const [branchInputType, setBranchInputType] = useState(false);
  const onOtherBranchSelection = (val) => {
    if (val === 'Other') {
      setFields({
        ...fields,
        branch: '',
      });
      setBranchInputType(true);
    }
  };

  const [viewAddBranchCity, setViewAddBranchCity] = useState(false);
  const [counterBranch, setCounterBranch] = useState('')
  const [counterCity, setCounterCity] = useState('')
  // Add Counter Account Branch and City
  const renderModalAddBranchCity = () => {
    switch (viewAddBranchCity) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              // setViewMoneyMarketModal(false);
              // setPIBFields(false);
            }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewAddBranchCity(false);
                  setCounterBranch('');
                  setCounterCity('');
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <div>
                <h6 className="title title-up">Add Branch and City</h6>
              </div>
            </div>

            <div className="modal-body">
              <div className="  ml-3 mt-3 mb-3 mr-4">

                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                <div className="form-group">
                  <div>Branch*</div>
                  <input
                    type="text"
                    value={counterBranch}
                    className={
                      'form-control w-100 '
                    }
                    onChange={(e) => {
                      setCounterBranch(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  <div>City*</div>
                  <input
                    type="text"
                    value={counterCity}
                    className={
                      'form-control w-100 '
                    }
                    onChange={(e) => {
                      setCounterCity(e.target.value);
                    }}
                  />
                </div>

                <div className=" ">

                  <div className=" mt-3">
                    <button type="button" className="btn btn-primary btn-block"
                      onClick={() => {
                        let cBranch = `${counterBranch} – ${counterCity}`;
                        if (counterBranch === '' || counterCity === '') {
                          toast.error('Kindly fill all fields.')
                        } else {
                          allCounterbranchesData.push({ value: cBranch, label: cBranch });
                          setFields({
                            ...fields,
                            counter_branch: cBranch,
                          });
                          setViewAddBranchCity(false);
                          // setInputType(true);
                          setCounterBranch('');
                          setCounterCity('');
                        }
                      }}>Add</button>
                  </div>
                </div>

              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  //for Validation
  const validate = async () => {
    let count = 0;
    requiredFields.map((item, index) => {
      if (flag) {
        const value = fields[item];
        if (value === '') {
          count = count + 1;
        }
      } else {
        const value = fields[item];
        if (value === '') {
          count = count + 1;
        }
      }
    });
    if (count > 0) {
      return false;
    } else {
      return true;
    }
  };
  const [unitNav, setUnitNav] = useState('');

  const calculateTaxIntoTax = async () => {
    let taxBoolean = await checkTaxBoolean(headLabel);
    if (taxBoolean) {
      let taxFields = await getTaxObj(headLabel);
      let tempAmount = 0;
      let tempWht_it_amount = 0;
      let tempWht_sst_amount = 0;
      let tempNetAmount = 0;
      if (taxFields.tax_rate !== 0) {
        tempAmount = (+fields.gross_amount * taxFields.tax_rate) / 100;
      }
      let tempTotalAmount = +fields.gross_amount + tempAmount;
      if (taxFields.wht_it_rate !== 0) {
        tempWht_it_amount = (tempTotalAmount * taxFields.wht_it_rate) / 100;
      }
      if (taxFields.wht_sst_rate !== 0) {
        tempWht_sst_amount = (tempAmount * taxFields.wht_sst_rate) / 100;
      }
      if (headLabel === 'saleload') {
        tempNetAmount = +fields.gross_amount - tempWht_it_amount;
      } else {
        tempNetAmount =
          tempTotalAmount - tempWht_it_amount - tempWht_sst_amount;
      }
      let taxObj = {
        type: headLabel,
        amount: parseFloat(fields.gross_amount).toFixed(2),
        sst_rate: taxFields.tax_rate,
        sst_amount: tempAmount.toFixed(2),
        total_amount: tempTotalAmount.toFixed(2),
        wht_it_rate: taxFields.wht_it_rate,
        wht_it_amount: tempWht_it_amount.toFixed(2),
        wht_sst_rate: taxFields.wht_sst_rate,
        wht_sst_amount: tempWht_sst_amount.toFixed(2),
        net_amount: tempNetAmount.toFixed(2),
      };
      fields['system_tax'] = JSON.stringify(taxObj);
    }
  };

  //Create Transaction Api Call
  const createTransaction = async () => {

    if (!chechFieldDisplay(headLabel, 'nav')) {
      fields['nav'] = '';
    }
    if (headLabel === 'transferofunits') {
      fields['fund_account'] = 'None';
      fields['account_title'] = 'None';
      fields['account_no'] = 'None';
      fields['bank'] = 'None';
      fields['branch'] = 'None';
      // fields['bank_city'] = 'None';
      fields['mode_of_payment'] = 'None';
      fields['counter_type'] = '';
      fields['gross_amount'] = '0';
      fields['net_amount'] = '0';
      fields['transfrees'] = JSON.stringify(transferees);
      setFields(fields);
    }
    if (headLabel == 'saleofunit' || headLabel == 'conversionin') {
      if (fields['total_holding'] === 'NaN') {
        fields['total_holding'] = '';
      }
      if (fields['units'] === 'NaN') {
        fields['units'] = '';
      }
    }
    if (headLabel == 'redemptionofunits' || headLabel == 'conversionout') {
      if (fields['remain_holding'] === 'NaN') {
        fields['remain_holding'] = '';
      }
      if (fields['units'] === 'NaN') {
        fields['units'] = '';
      }
    }

    if (headLabel === 'unitconversion') {
      let conversionAmount = +fields['net_amount'] / +unitNav;
      fields['total_holding'] = conversionAmount.toFixed(2);
    } else {
      fields['counter_type'] = counterType !== '' ? counterType : counterAccounter;
    }
    if (headLabel === 'debtmarketinvestment') {
      fields['securities'] = JSON.stringify(moneyMarketSecurities);
    }

    else {
      fields['securities'] = JSON.stringify(securites);
    }

    fields['txn_charges'] = JSON.stringify(totalTax);

    const isValid = await validate(); // check validation for Fields Data
    if (isValid) {
      if (headLabel === 'transferofunits' && (fields['current_holding'] === fields['remain_holding'])) {
        toast.error('Current holding and remaining holding should not be same')
      } else if (
        headLabel === 'fundtransfer' &&
        fields['counter_account_type'] === fields['fund_account']
      ) {
        toast.error('Both account should be not same');
      } else {
        setLoading(true);
        await calculateTaxIntoTax();
        if (flag) {
          if (headLabel === 'saleofunit' || headLabel === 'redemptionofunits') {
            fields['counter_account_type'] = fields['counter_account_type'].replaceAll(fields['amc_code'] + '-', '')
            fields['counter_account_type'] = fields['amc_code'] + '-' + fields['counter_account_type'];
          }
          if (headLabel === 'conversionin' || headLabel === 'conversionout' || headLabel === 'unitconversion') {
            fields['folio_no'] = fields['folio_no'].replaceAll(fields['amc_code'] + '-', '');
            fields['folio_no'] = fields['amc_code'] + '-' + fields['folio_no'];
          }
          if (parseFloat(fields['net_amount']) < 0 || parseFloat(fields['gross_amount']) < 0 || parseFloat(fields['units']) < 0 || parseFloat(fields['remain_holding']) < 0) {
            setLoading(false)
            toast.error("Amounts or units or remaining holding are negative");
          } else if ((headLabel === 'redemptionofunits' && +fields['units'] === 0) || (headLabel === 'conversionout' && +fields['units'] === 0) || (headLabel === 'saleofunit' && +fields['units'] === 0)
            || (headLabel === 'conversionin' && +fields['units'] === 0)) {
            setLoading(false)
            toast.error(" Units cannot be zero.");
          }
          else {
            if (headLabel === 'pledgedin' || headLabel === 'pledgedout') {
              fields['fund_account'] = 'None';
              fields['account_title'] = 'None';
              fields['account_no'] = 'None';
              fields['bank'] = 'None';
              fields['branch'] = 'None';
              fields['bank_city'] = 'None'
              fields['mode_of_payment'] = 'None';
              fields['gross_amount'] = '0';
              fields['net_amount'] = '0';
              setFields(fields);
            }
            try {
              const response = await EditTransaction(fields); // for Edit existed transaction
              if (response.data.status === 400) {
                setViewBankBalanceModal(true);
                toast.error(response.data.message);
              } else if (response.data.status === 417) {
                toast.warning(response.data.message);
                setLoading(false);
              } else {
                setUnderStand(false);
                setViewBankBalanceModal(false);
                toast.success(response.data.message);
                setTimeout(() => {
                  setLoading(false)
                  history.replace(`/admin/create-transaction`);
                }, 3000);
              }
            } catch (error: any) {
              setLoading(false);
              if (error.response !== undefined) {
                toast.error(error.response.data.message);
              } else {
                toast.error('Something went wrong!');
              }
            }
          }
        } else {
          if (parseFloat(fields['net_amount']) < 0 || parseFloat(fields['gross_amount']) < 0 || parseFloat(fields['units']) < 0 || parseFloat(fields['remain_holding']) < 0) {
            setLoading(false)
            toast.error("Amounts or units or remaining holding are negative");
          } else if ((headLabel === 'redemptionofunits' && fields['units'] === '0') || (headLabel === 'conversionout' && fields['units'] === '0') || (headLabel === 'saleofunit' && (fields['units'] === '0' || fields['units'] === '0.0000'))
            || (headLabel === 'conversionin' && (fields['units'] === '0' || fields['units'] === '0.0000'))) {
            setLoading(false)
            toast.error(" Units cannot be zero.");
          }
          else {
            if (headLabel === 'pledgedin' || headLabel === 'pledgedout') {
              fields['fund_account'] = 'None';
              fields['account_title'] = 'None';
              fields['account_no'] = 'None';
              fields['bank'] = 'None';
              fields['branch'] = 'None';
              fields['bank_city'] = 'None'
              fields['mode_of_payment'] = 'None';
              fields['gross_amount'] = '0';
              fields['net_amount'] = '0';
              setFields(fields);
            }
            if (headLabel === 'saleofunit' || headLabel === 'redemptionofunits') {
              fields['counter_account_type'] = fields['counter_account_type'].replaceAll(fields['amc_code'] + '-', '');
              fields['counter_account_type'] = fields['amc_code'] + '-' + fields['counter_account_type'];
            }
            if (headLabel === 'conversionin' || headLabel === 'conversionout' || headLabel === 'unitconversion') {
              fields['folio_no'] = fields['folio_no'].replaceAll(fields['amc_code'] + '-', '');
              fields['folio_no'] = fields['amc_code'] + '-' + fields['folio_no'];
            }
            if (headLabel === 'transferofunits') {
              fields['folio_no'] = fields['amc_code'] + '-' + fields['folio_no'];
            }
            fields['net_amount'] = isNumber(fields['net_amount']);
            setFields(fields);
            try {
              const response = await addMegaTransaction(fields); // for add new transaction
              if (response.data.status === 400) {
                setViewBankBalanceModal(true);
                toast.warning(response.data.message);
              } else if (response.data.status === 417) {
                toast.warning(response.data.message);
                setLoading(false);
              } else if (response.data.status === 401) {
                toast.error(response.data.message);
                setLoading(false);
              } else if (response.data.status === 402) {
                toast.error(response.data.message);
                setLoading(false);
              } else {
                setUnderStand(false);
                setViewBankBalanceModal(false);
                sessionStorage.setItem('last_id', response.data.txn_id);
                toast.success(`${response.data.message} - ${response.data.txn_id}`)
                // toast.success(response.data.message);
                setTimeout(() => {
                  setLoading(false)
                  history.replace(`/admin/create-transaction`);
                }, 3000);
              }
            } catch (error: any) {
              setLoading(false);
              if (error.response !== undefined) {
                toast.error(error.response.data.message);
              } else {
                toast.error('Something went wrong!');
              }
            }
          }
        }
      }
    } else {
      setLoading(false);
      setErrorShow(true);
      toast.error('Kindly Fill All the Fields');
    }
  };

  const [accTilteName, setAccTiltleName] = useState('');
  const [bankBalance, setBankBalance] = useState('');
  // handle data for Fund Account Dropdown selection
  const setFundAccountFieldsData = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['account_title'] = '';
      fields['account_no'] = '';
      fields['bank'] = '';
      fields['branch'] = '';
      fields['bank_city'] = '';
      fields['fund_account'] = 'other';
      setBankBalance("");
      setFields(fields);
    } else {
      accountNoData.map((item, index) => {
        if (item.account_code === selected) {
          getBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          fields['fund_account'] = item.account_code.replace('ACCOUNT_', '');
          fields['account_title'] = item.account_title;
          fields['account_no'] = item.account_no;
          fields['bank'] = item.bank_name;
          fields['branch'] = `${item.branch_name} – ${item.bank_city}`;
          fields['bank_city'] = item.bank_city;
          setBankBalance(item.balance_amount);
          setFields(fields);
        }
      });
    }
  };

  // handle data for Fund Account Dropdown selection for unit conversion
  const setFundAccountDataCounter = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      // if user select any account from dropdown then data fillout
      allCounterFundsAccounts.map((item, index) => {
        if (item.account_code === selected) {
          getCounterBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          fields['counter_account_type'] = item.account_code;
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.account_no;
          fields['counter_bank'] = item.bank_name;
          fields['counter_branch'] = `${item.branch_name} – ${item.city}`;
          setFields(fields);
        }
      });
    }
  };


  const setCounterFieldsData = (selected, unitHolderdata) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['unitholder_name'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      // if user select any account from dropdown then data fillout
      unitHolderdata.map((item, index) => {
        //map from unitHolder data
        if (item.folio_no === selected) {
          let code = selected.split('-')[0];
          setAccTiltleName(item.folio_no);
          getCounterBranchByName(item.bank_name);
          // fields['counter_account_type'] = item.folio_no.replace('UNITHOLDER_','');
          fields['counter_account_type'] = item.folio_no.replace(code + '-'.toString(), '');
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.account_no;
          fields['counter_bank'] = item.bank_name;
          fields['counter_branch'] = `${item.branch_name} – ${item.city}`;
          fields['unitholder_name'] = item.name;
          // fields['current_holding'] = item.balance_unit;
          fields['folio_no'] = item.folio_no.replace('UNITHOLDER_', '');
          setFields(fields);
          if (item.balance_unit !== '' && item.balance_unit !== '[]' && item.balance_unit !== undefined) {
            let unitBlance = JSON.parse(item.balance_unit);
            if (unitBlance && typeof unitBlance !== 'number') {
              const temp = unitBlance.filter((element) => {
                if (element.fund_code.trim() === fields['fund_code']) {
                  return element;
                }
              });

              if (temp.length > 0) {
                // if (headLabel == 'gainrealization') {
                //   let grossAmount =
                //     parseInt(fields['nav']) * parseInt(temp[0].fund_unit);
                //   let netAmount =
                //     grossAmount - parseInt(fields['total_charges']);
                //   let remainHolding = netAmount / parseInt(fields['nav']);
                //   let cValue = parseFloat(temp[0].fund_unit).toFixed(4);
                //   fields['current_holding'] = cValue;
                //   // fields['gross_amount'] = grossAmount.toFixed(2);
                //   // fields['net_amount'] = netAmount.toFixed(2);
                //   fields['remain_holding'] = remainHolding.toFixed(2);
                //   return;
                // }
                fields['current_holding'] = temp[0].fund_unit === 'NaN' ? '0' : parseFloat(temp[0].fund_unit.toString().trim()).toFixed(4);
              } else {
                if (headLabel !== 'funddividendpayout') {
                  // toast.error('No Units of selected Fund')
                }
                fields['current_holding'] = '0.00';
                setAccTiltleName(selected);
              }
            }
          } else {
            //empty
            fields['current_holding'] = '0.00';
            if (headLabel !== 'funddividendpayout') {
              // toast.error('No Units of selected Fund');
            }
            setAccTiltleName(selected);
          }
        }
      });
      setFields(fields);
    }
  };

  // const setCounterFieldsData = (selected) => {
  //   'akram';
  //   if (selected === 'Other') {
  //     //incase if other option is selected then user entrer data manually
  //     setAccTiltleName(selected);
  //     fields['counter_account_type'] = 'Other';
  //     fields['counter_account_title'] = '';
  //     fields['counter_account_number'] = '';
  //     fields['counter_bank'] = '';
  //     fields['counter_branch'] = '';
  //     fields['current_holding'] = '';
  //     // add new
  //     fields['unitholder_name'] = '';
  //     fields['folio_no'] = '';
  //     setFields(fields);
  //   } else {
  //     // if user select any account from dropdown then data fillout
  //     unitHolderData.map((item, index) => {
  //       //map from unitHolder data
  //       if (item.folio_no === selected) {
  //         setAccTiltleName(item.folio_no);
  //         getCounterBranchByName(item.bank_name);
  //         fields['counter_account_type'] = item.folio_no;
  //         fields['counter_account_title'] = item.account_title;
  //         fields['counter_account_number'] = item.account_no;
  //         fields['counter_bank'] = item.bank_name;
  //         fields['counter_branch'] = item.branch_name;
  //         // add new line
  //         fields['unitholder_name'] = item.name;
  //         // fields['current_holding'] = item.balance_unit;
  //         fields['folio_no'] = item.folio_no;
  //         setFields(fields);

  //         if (item.balance_unit !== '') {
  //           let unitBlance = JSON.parse(item.balance_unit);
  //           if (unitBlance && typeof unitBlance !== 'number') {
  //             const temp = unitBlance.filter((element) => {
  //               if (element.fund_code === fields['fund_code']) {
  //                 return element;
  //               }
  //             });
  //             if (temp.length > 0) {
  //               if (headLabel == 'gainrealization') {
  //                 let grossAmount =
  //                   parseInt(fields['nav']) * parseInt(temp[0].fund_unit);
  //                 let netAmount =
  //                   grossAmount - parseInt(fields['total_charges']);
  //                 let remainHolding = netAmount / parseInt(fields['nav']);
  //                 let cValue = parseFloat(temp[0].fund_unit).toFixed(4);
  //                 fields['current_holding'] = cValue;
  //                 // fields['gross_amount'] = grossAmount.toFixed(2);
  //                 // fields['net_amount'] = netAmount.toFixed(2);
  //                 fields['remain_holding'] = remainHolding.toFixed(2);
  //                 return;
  //               }
  //               fields['current_holding'] = temp[0].fund_unit==='NaN' ? '0' : parseFloat(temp[0].fund_unit).toFixed(4);
  //             } else {
  //               if (headLabel !== 'funddividendpayout') {
  //                 // toast.error('No Units of selected Fund')
  //               }
  //               fields['current_holding'] = '0.00';
  //             }
  //           }
  //         } else {
  //           //empty
  //           fields['current_holding'] = '0.00';
  //           if (headLabel !== 'funddividendpayout') {
  //             toast.error('No Units of selected Fund');
  //           }
  //         }
  //       }
  //     });
  //     setFields(fields);
  //   }
  // };

  // const setFolioNoData = (selected) => {
  //   count = count + 1;
  //   setAccTiltleName(`${selected}-${count}`);
  //   if (selected !== 'Other') {
  //     // if user select any account from dropdown then data fillout
  //     unitHolderData.map((item, index) => {
  //       if (item.folio_no) {
  //         if (item.folio_no === selected) {
  //           if (
  //             item.balance_unit !== '' &&
  //             item.balance_unit !== '[]' &&
  //             item.balance_unit !== undefined
  //           ) {
  //             let unitBlance = JSON.parse(item.balance_unit);
  //             if (unitBlance && typeof unitBlance !== 'number') {
  //               const temp = unitBlance.filter((element) => {
  //                 if (element.fund_code === fields['fund_code']) {
  //                   return element;
  //                 }
  //               });

  //               if (temp.length > 0) {
  //                 setAccTiltleName(temp[0].fund_unit.toString());
  //                 fields['current_holding'] = parseFloat(
  //                   temp[0].fund_unit
  //                 ).toFixed(4);
  //                 setFields(fields);
  //               } else {
  //                 toast.error('Zero Units of selected Fund', {
  //                   position: 'top-right',
  //                   autoClose: 2500,
  //                   hideProgressBar: false,
  //                   closeOnClick: true,
  //                   pauseOnHover: false,
  //                   draggable: false,
  //                 });
  //                 setAccTiltleName(`${temp.length + index}`);
  //                 fields['current_holding'] = '0.00';
  //                 setFields(fields);
  //               }
  //             }
  //           } else {
  //             //empty
  //             toast.error('Zero Units of selected Fund', {
  //               position: 'top-right',
  //               autoClose: 2500,
  //               hideProgressBar: false,
  //               closeOnClick: true,
  //               pauseOnHover: false,
  //               draggable: false,
  //             });
  //             fields['current_holding'] = '0.00';
  //             count = count + 1;
  //             setAccTiltleName(`${selected}-${count}-${selected}`);
  //           }
  //         }
  //       }
  //     });
  //   } else {
  //     fields['current_holding'] = '0.00';
  //     setAccTiltleName(`${count}-${selected}`);
  //   }
  // };
  //clear fund account fileds if fund or amc is changed beacause these properties are depend on Funds

  // for Folio Number Data
  const setFolioNoData = (selected, dataUnitHolder) => {
    count = count + 1;
    setAccTiltleName(`${selected}-${count}`);
    if (selected !== 'Other') {
      // if user select any account from dropdown then data fillout
      dataUnitHolder.map((item, index) => {
        if (item.folio_no) {
          if (item.folio_no === selected) {
            fields['unitholder_name'] = item.name;
            if (
              item.balance_unit !== '' &&
              item.balance_unit !== '[]' &&
              item.balance_unit !== undefined
            ) {
              let unitBlance = JSON.parse(item.balance_unit);
              if (unitBlance && typeof unitBlance !== 'number') {
                const temp = unitBlance.filter((element) => {
                  if (element.fund_code.trim() === fields['fund_code']) {
                    return element;
                  }
                });

                if (temp.length > 0) {
                  setAccTiltleName(temp[0].fund_unit.toString());
                  fields['current_holding'] = parseFloat(temp[0].fund_unit.toString().trim()).toFixed(4);
                  setFields(fields);
                  // setFields({
                  //   ...fields,
                  //   current_holding: parseFloat(
                  //     temp[0].fund_unit
                  //   ).toFixed(4)
                  // })
                } else {
                  toast.error('Zero Units of selected Fund', {
                    position: 'top-right',
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                  });
                  setAccTiltleName(`${temp.length + index}`);
                  fields['current_holding'] = '0.00';
                  setFields(fields);
                }
              }
            } else {
              //empty
              toast.error('Zero Units of selected Fund', {
                position: 'top-right',
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
              });
              fields['current_holding'] = '0.00';
              count = count + 1;
              setAccTiltleName(`${selected}-${count}-${selected}`);
            }
          }
        }
      });
    } else {
      fields['current_holding'] = '0.00';
      setAccTiltleName(`${count}-${selected}`);
    }
  };


  const clearFundAccountFields = () => {
    setAccTiltleName('Unset');
    fields['fund_account'] = '';
    fields['account_title'] = '';
    fields['account_no'] = '';
    fields['bank'] = '';
    fields['branch'] = '';
    fields['bank_city'] = '';
    fields['nav'] = '';
    setFields(fields);
  };

  // Set Data in Fields from selected Broker
  const setBrokerData = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      borkerByTypeData.map((item, index) => {
        if (item.company_code === selected) {
          setAccTiltleName(item.bank_name + 'changes');
          fields['counter_account_type'] = item.company_code;
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.iban;
          fields['counter_bank'] = item.bank_name;
          fields['counter_branch'] = item.branch;
          fields['sales_tax_collector'] = item.tax_collected;
          setFields(fields);

        }
      });
    }
  };

  // Set Data in Fields from selected Broker
  const setAmcAccountData = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      amcdataCounter.map((item, index) => {
        if (item.account_no === selected) {
          setAccTiltleName(item.account_no + 'changes');
          getCounterBranchByName(item.bank);
          fields['counter_account_type'] = item.account_no;
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.account_no;
          fields['counter_bank'] = item.bank;
          fields['counter_branch'] = item.branch;
          setFields(fields);
        }
      });
    }
  };

  //clear fund account fileds if fund or amc is changed beacause these properties are depend on Funds
  const clearCounterAccountFields = () => {
    setAccTiltleName(fields['current_holding'] + 'change');
    fields['counter_account_type'] = '';
    fields['counter_account_title'] = '';
    fields['counter_account_number'] = '';
    fields['counter_bank'] = '';
    fields['counter_branch'] = '';
    fields['current_holding'] = '';
    fields['folio_no'] = '';
    setFields(fields);
  };

  //Select data if Counter Account is Fund account
  const setFundData = (selected) => {
    counterFundAccount.map((item, index) => {
      if (item.account_no === selected) {
        setAccTiltleName(item.bank_name + 'changes');
        fields['counter_account_type'] = item.account_no;
        fields['counter_account_title'] = item.account_title;
        fields['counter_account_number'] = item.account_no;
        fields['counter_bank'] = item.bank_name;
        fields['counter_branch'] = item.branch_name;
        fields['current_holding'] = parseFloat(item.balance_amount).toFixed(4);
        setFields(fields);
      }
    });
  };

  const [grossAmountInWords, setGrossAmountInWords] = useState('');
  const [totalAmountInWords, setTotalAmountInWords] = useState('');
  const [netAmountInWords, setNetAmountInWords] = useState('');
  const [netAmountSeparator, setNetAmountSeparator] = useState('');
  const [grossAmountSeparator, setGrossAmountSeparator] = useState('');
  const [unitsSeparator, setUnitsSeparator] = useState('');
  const [unitsInWords, setUnitsInWord] = useState('');

  const setTotalRemainHolding = (amount, type) => {
    if (type === "NetAmount") {
      // let unit=parseFloat(netamount)/parseFloat(fields['nav']);
      // let a=unit.toFixed(4).toString();
      // let netamounts = amount;
      // let units=parseFloat(netamounts)/parseFloat(fields['nav']);
      // let sunit=units.toFixed(4).toString();
      if (headLabel === "saleofunit" || headLabel === "conversionin") {
        // let netamount = (parseFloat(amount) - parseFloat(fields['sale_load'].replace('','0'))).toFixed(2);

        // let remainingholding=parseFloat(fields['current_holding']) + parseFloat(sunit)
        // let remainholding=remainingholding.toFixed(4).toString();                         
        if (amount.length === 0) {
          setFields({
            ...fields,
            net_amount: amount,
            // units:'0.0000',
            // total_holding:fields['current_holding']
          });
        } else {
          setFields({
            ...fields,
            net_amount: amount,
            total_charges: '0',
            // units:sunit,
            // total_holding:remainholding
          });
        }
      } else {
        if (amount.length === 0) {
          setFields({
            ...fields,
            // gross_amount:amount,
            net_amount: amount,
            // units:'0.0000'
          });
          setDisableAddTaxBtn(true);
        } else {
          setFields({
            ...fields,
            // gross_amount:amount,
            net_amount: amount,
            total_charges: '0',
            // units: sunit
          });
        }
      }
      //   else if(headLabel==="unitconversion"){
      //     let remainingholding=parseFloat(fields['current_holding']) - parseFloat(a)
      //     let remainholding=remainingholding.toFixed(4).toString();                         
      // if(amount.length===0){
      //   setFields({
      //     ...fields,
      //     net_amount: amount,
      //     units:'0.0000',
      //     remain_holding:fields['current_holding']
      //   });
      // }else{
      //   setFields({
      //     ...fields,
      //     net_amount: amount,
      //     total_charges: '0',
      //     units:a,
      //     remain_holding:remainholding
      //   });
      // }
      // }else if(headLabel==="conversionout" || headLabel==="redemptionofunits"){
      //   let remainingholding=parseFloat(fields['current_holding']) - parseFloat(a)
      //   let remainholding=remainingholding.toFixed(4).toString();                         
      // if(amount.length===0){
      // setFields({
      //   ...fields,
      //   net_amount: amount,
      //   units:'0.0000',
      //   remain_holding:fields['current_holding']
      // });
      // }else{
      // setFields({
      //   ...fields,
      //   net_amount: amount,
      //   total_charges: '0',
      //   units:a,
      //   remain_holding:remainholding
      // });
      // }
      // }else{
      // if(amount.length===0){
      // setFields({
      //   ...fields,
      //   net_amount: amount,
      //   units:'0.0000'
      // });
      // }else{
      // setFields({
      //   ...fields,
      //   net_amount: amount,
      //   total_charges: '0',
      //   units:a
      // });
      // }
      // }
    } else if (type === "GrossAmount") {
      let unit = parseFloat(fields['applicable_offer_price']) <= 0 ? 0 : parseFloat(amount) / parseFloat(fields['applicable_offer_price']);
      let a = unit.toFixed(4).toString();
      if (headLabel === "saleofunit" || headLabel === "conversionin") {
        let saleload = (parseFloat(fields['load_per_unit']) * parseFloat(a)).toFixed(2);
        let netamount = (parseFloat(amount) - parseFloat(saleload.replace('', '0'))).toFixed(2);
        //let netamount = amount;
        // let units=parseFloat(netamount)/parseFloat(fields['nav']);
        // let sunit=units.toFixed(4).toString();
        let remainingholding = parseFloat(fields['current_holding']) + parseFloat(a)
        let remainholding = remainingholding.toFixed(4).toString();
        if (amount.length === 0) {
          setFields({
            ...fields,
            gross_amount: amount,
            net_amount: amount,
            sale_load: '0',
            units: '0.0000',
            total_holding: fields['current_holding']
          });
          setNetAmountSeparator('');
          setNetAmountInWords('');
          setGrossAmountSeparator('');
          setGrossAmountInWords('');
          setUnitsSeparator('');
          setUnitsInWord('');
        } else {
          setFields({
            ...fields,
            gross_amount: amount,
            net_amount: netamount,
            sale_load: saleload,
            total_charges: '0',
            units: a,
            total_holding: remainholding
          });
          let inwordUnits = inWords(a);
          setUnitsInWord(inwordUnits);
          let inwordNetAmount = inWords(netamount);
          setNetAmountInWords(inwordNetAmount);
          let inwordGrossAmount = inWords(amount);
          let unitSeparator = numberWithCommas(a);
          setUnitsSeparator(unitSeparator);
          setGrossAmountInWords(inwordGrossAmount);
          let grossAmountSeparator = numberWithCommas(amount);
          setGrossAmountSeparator(grossAmountSeparator);
          let netAmountSeparator = numberWithCommas(netamount);
          setNetAmountSeparator(netAmountSeparator);
        }
      }
      //   else if(headLabel==="unitconversion"){
      //     let remainingholding=parseFloat(fields['current_holding']) - parseFloat(a)
      //     let remainholding=remainingholding.toFixed(4).toString();                         
      // if(amount.length===0){
      //   setFields({
      //     ...fields,
      //     gross_amount:amount,
      //     net_amount: amount,
      //     units:'0.0000',
      //     remain_holding:fields['current_holding']
      //   });
      //   setNetAmountSeparator('');
      //   setNetAmountInWords('');
      //   setGrossAmountSeparator('');
      //   setGrossAmountInWords('');
      //   setUnitsSeparator('');
      // }else{
      //   setFields({
      //     ...fields,
      //     gross_amount:amount,
      //     net_amount: amount,
      //     total_charges: '0',
      //     units:a,
      //     remain_holding:remainholding
      //   });
      //   let inwordNetAmount = inWords(amount);
      //     setNetAmountInWords(inwordNetAmount);
      //     let inwordGrossAmount = inWords(amount);

      //     let unitSeparator=numberWithCommas(a);
      //     setUnitsSeparator(unitSeparator);
      //     setGrossAmountInWords(inwordGrossAmount);
      //     let grossAmountSeparator= numberWithCommas(amount);
      //     setGrossAmountSeparator(grossAmountSeparator);
      //     let netAmountSeparator= numberWithCommas(amount);
      //     setNetAmountSeparator(netAmountSeparator);
      // }
      // }
      else if (headLabel === "conversionout" || headLabel === "redemptionofunits" || headLabel === "unitconversion") {
        let redemptionload = (parseFloat(fields['load_per_unit']) * parseFloat(a)).toFixed(2);
        let remainingholding = parseFloat(fields['current_holding']) - parseFloat(a)
        let remainholding = remainingholding.toFixed(4).toString();
        let net_amount = (amount - parseFloat(redemptionload.replace('', '0')) - parseFloat(fields['tax_cgt'].replace('', '0')) - parseFloat(fields['zakat_amount'] || '0')).toFixed(2);
        if (amount.length === 0) {
          setFields({
            ...fields,
            gross_amount: amount,
            net_amount: amount,
            redemption_load: '0',
            units: '0.0000',
            remain_holding: fields['current_holding']
          });
          setNetAmountSeparator('');
          setNetAmountInWords('');
          setGrossAmountSeparator('');
          setGrossAmountInWords('');
          setUnitsSeparator('');
        } else {
          setFields({
            ...fields,
            gross_amount: amount,
            net_amount: net_amount,
            redemption_load: redemptionload,
            total_charges: '0',
            units: a,
            remain_holding: remainholding
          });
          let inwordNetAmount = inWords(net_amount);
          setNetAmountInWords(inwordNetAmount);
          let inwordGrossAmount = inWords(amount);
          let unitSeparator = numberWithCommas(a);
          setUnitsSeparator(unitSeparator);
          setGrossAmountInWords(inwordGrossAmount);
          let grossAmountSeparator = numberWithCommas(amount);
          setGrossAmountSeparator(grossAmountSeparator);
          let netAmountSeparator = numberWithCommas(net_amount);
          setNetAmountSeparator(netAmountSeparator);
        }
      } else if (headLabel === "advisoryfee" || headLabel === "auditorsfee" || headLabel === "managementfee" || headLabel === "trusteefee" || headLabel === "custodyfee"
        || headLabel === "shariahadvisoryfee" || headLabel === "cdcfee" || headLabel === "listingfee" || headLabel === "brokagefee" ||
        headLabel === "printingfee" || headLabel === "professionalfee" || headLabel === "ratingfee" || headLabel === "taxpayment" || headLabel === "secpfee" || headLabel === "otherpayment") {
        let grossamount = amount;
        if (grossamount != '') {
          let sstAmount = ((parseFloat(sstPercentage) / 100) * (parseFloat(grossamount))).toFixed(2);
          let totalamount = ((parseFloat(grossamount) + parseFloat(sstAmount))).toFixed(2);
          let whtITAmount = ((parseFloat(whtITPercentage) / 100) * parseFloat(totalamount)).toFixed(2);
          let whtsstAmount = ((parseFloat(sstWHTPercentage) / 100) * parseFloat(sstAmount)).toFixed(2);
          let netAmount = (parseFloat(totalamount) - parseFloat(whtITAmount) - parseFloat(whtsstAmount)).toFixed(2);
          setFields({
            ...fields,
            gross_amount: grossamount,
            net_amount: netAmount.toString(),
            tax_sst: sstAmount.toString(),
            total_charges: totalamount.toString(),
            tax_wht_it: whtITAmount.toString(),
            tax_wht_sst: whtsstAmount.toString()
          });
          let inwordNetAmount = inWords(netAmount);
          netAmount === 'NaN' ? setNetAmountInWords('') : setNetAmountInWords(inwordNetAmount);
          let inwordGrossAmount = inWords(amount);
          setGrossAmountInWords(inwordGrossAmount);
          let grossAmountSeparator = numberWithCommas(amount);
          setGrossAmountSeparator(grossAmountSeparator);
          let netAmountSeparator = numberWithCommas(netAmount);
          netAmount == 'NaN' ? setNetAmountSeparator('') : setNetAmountSeparator(netAmountSeparator);
        } else {
          setFields({
            ...fields,
            gross_amount: '',
            net_amount: ''
          });
          setGrossAmountInWords('');
          setGrossAmountSeparator('');
          setNetAmountInWords('');
          setNetAmountSeparator('');
        }
      } else {
        if (amount.length === 0) {
          setFields({
            ...fields,
            gross_amount: amount,
            net_amount: amount,
            // units:'0.0000'
          });
          setNetAmountSeparator('');
          setNetAmountInWords('');
          setGrossAmountSeparator('');
          setGrossAmountInWords('');
        } else {
          setFields({
            ...fields,
            gross_amount: amount,
            net_amount: amount,
            total_charges: '0',
            // units:a
          });
          let inwordNetAmount = inWords(amount);
          setNetAmountInWords(inwordNetAmount);
          let inwordGrossAmount = inWords(amount);
          setGrossAmountInWords(inwordGrossAmount);
          let grossAmountSeparator = numberWithCommas(amount);
          setGrossAmountSeparator(grossAmountSeparator);
          let netAmountSeparator = numberWithCommas(amount);
          setNetAmountSeparator(netAmountSeparator);
        }
      }
    }
  };

  //set Current holding

  const setCurentHolding = (unit_value) => {
    //case to handle
    let total_Hold,
      gross_am = 0;
    let netamount = 0;
    if (fields.payment_type === 'Receipt') {
      total_Hold = +fields['current_holding'] + +unit_value;
      gross_am = +unit_value * +fields['nav'];
      if (chechFieldDisplay(headLabel, 'total_holding')) {
        fields['total_holding'] = parseFloat(total_Hold).toFixed(4);
      }
      if (chechFieldDisplay(headLabel, 'nav')) {
        fields['gross_amount'] = gross_am.toFixed(2);
        fields['net_amount'] = gross_am.toFixed(2);
      }
      setAccTiltleName(
        (
          +fields['gross_amount'] +
          +fields['total_holding'] * +unit_value
        ).toString()
      );
    } else if (
      fields.payment_type === 'Payment' ||
      headLabel === 'unitconversion'
    ) {
      total_Hold = +fields['current_holding'] - +unit_value;
      gross_am = +unit_value * +fields['nav'];
      let red_load = unit_value * (+fields['nav'] - +fields['offer_price']) * (1 - (+fields['percentage_of_discount'] / 100));
      netamount = gross_am - red_load || 0 - parseFloat(fields['tax_cgt'].replace('', '0'));

      if (chechFieldDisplay(headLabel, 'remain_holding')) {
        fields['remain_holding'] = parseFloat(total_Hold).toFixed(4);
      }
      if (chechFieldDisplay(headLabel, 'nav')) {
        fields['gross_amount'] = gross_am.toFixed(2);
        fields['redemption_load'] = red_load.toFixed(2);
        fields['net_amount'] = gross_am.toFixed(2);

        if (unit_value !== '') {
          let a = inWords(fields['gross_amount']);
          let b = inWords(fields['net_amount']);
          // let c = inWords(unit_value);
          setGrossAmountInWords(a);
          setNetAmountInWords(b);
          // setUnitsInWord(c);
          //// separator
          let unitSepa = numberWithCommas(unit_value);
          let grossAmountSepa = numberWithCommas(fields['gross_amount']);
          let netAmountSepa = numberWithCommas(fields['net_amount']);
          setUnitsSeparator(unitSepa);
          setGrossAmountSeparator(grossAmountSepa);
          setNetAmountSeparator(netAmountSepa);
        } else {
          setGrossAmountInWords('');
          setNetAmountInWords('');
          setGrossAmountSeparator('');
          setNetAmountSeparator('');
          setUnitsSeparator('');
          //setUnitsInWord('');
        }

      }
      setAccTiltleName(
        (
          +fields['total_holding'] / +fields['gross_amount'] +
          +unit_value
        ).toString()
      );
    } else {
    }
  };

  const [termDeposit, setTermDeposit] = useState<any>([]);
  const [termDepositEntity, setTermDepositEntity] = useState<any>({
    code: '',
    name: '',
    principal_amount: '',
    interest_rate: '',
    from_date: moment(date).format('YYYY-MM-DD'),
    to_date: moment(date).format('YYYY-MM-DD'),
    bank: '',
    branch: '',
  });

  const [viewMoneyMarketModal, setViewMoneyMarketModal] = useState(false);
  const [moneyMarketSecurities, setMoneyMarketSecurities] = useState<any>([]);
  const [moneyMarketSecuritiesEntity, setMoneyMarketSecuritiesEntity] = useState<any>({
    security_type: '',
    investment_type: '',
    type: '',
    symbol: '',
    name: '',
    issue_date: '',
    maturity_date: '',
    money_market_face_value: '',
    price: '',
    units: '',
    yield: '',
    coupon_rate: '',
    next_coupon_date: '',
    last_coupon_date: '',
    primary_dealer: '',
    fund_ips_account: '',
    counter_party_ips_account: '',
    broker_name: '',
    settlement_amount: '',
    total_face_value: '',
    accrued_amount: '',
    brokerage: '',
    premium_discount: '',
    accrued_days: '',
    counter_party_name: '',
    detail: '',
    principal_amount: '',
    unredeem_value: '',
    coupon_payment: '',
    days_of_maturity: ''
  });

  const [viewAddModal, setViewAddModal] = useState(false);
  const [securites, setSecurites] = useState<any>([]);
  const [securitesEntity, setSecuritesEntity] = useState<any>({
    market: '',
    investment_type: '',
    sst_on_commission: '',
    commission_rate: '',
    // capital_value_tax:'',
    net_rate: '',
    type: '',
    symbol: '',
    quantity: '',
    avg_rate: '',
    commission_charges: '0',
    gross_amount: '',
    net_amount: '',
    // broker:''
  });


  // add money market security in array.
  const addMoneyMrketSecurityInArray = () => {
    // let count = 0;
    // for (let i in moneyMarketSecuritiesEntity) {
    //   if (moneyMarketSecuritiesEntity[i] === '') {
    //     if(PIB_fields===true){
    //     count = count + 1;
    //   }
    //   }
    // }
    // if (count > 0) {
    //   toast.error('Kindly Fill All Fields');
    // } else {

    if (moneyMarketSecuritiesEntity['security_type'] === '') {
      toast.error("Security type is empty");
    }
    else if (moneyMarketSecuritiesEntity['type'] === '') {
      toast.error("Type is empty");
    } else if (moneyMarketSecuritiesEntity['symbol'] === '') {
      toast.error("Symbol field is empty");
    } else if ((moneyMarketSecuritiesEntity['security_type'] === 'PIB' || moneyMarketSecuritiesEntity['security_type'] === 'TBILL' || moneyMarketSecuritiesEntity['security_type'] === 'SUKUK' || moneyMarketSecuritiesEntity['security_type'] === 'TFC') && (moneyMarketSecuritiesEntity['units'] <= 0)) {
      toast.error("Units must be greater than zero");
    } else if (moneyMarketSecuritiesEntity['settlement_amount'] === '') {
      toast.error("Settlement Amount field is empty");
    }
    else {
      moneyMarketSecurities.push(moneyMarketSecuritiesEntity);
      setMoneyMarketSecurities(moneyMarketSecurities);
      setViewMoneyMarketModal(false);
      setMoneyMarketSecuritiesEntity((prevState) => ({
        ...moneyMarketSecuritiesEntity,
        security_type: '',
        investment_type: '',
        type: '',
        symbol: '',
        name: '',
        issue_date: '',
        maturity_date: '',
        money_market_face_value: '',
        price: '',
        units: '',
        yield: '',
        coupon_rate: '',
        next_coupon_date: '',
        last_coupon_date: '',
        primary_dealer: '',
        fund_ips_account: '',
        counter_party_ips_account: '',
        broker_name: '',
        settlement_amount: '',
        total_face_value: '',
        accrued_amount: '',
        brokerage: '',
        premium_discount: '',
        accrued_days: '',
        counter_party_name: '',
        detail: '',
        principal_amount: '',
        unredeem_value: '',
        coupon_payment: '',
        days_of_maturity: ''
      }));
      let gross = 0;
      moneyMarketSecurities.map((item, index) => {
        // let amount=parseFloat(item.price)* parseFloat(item.money_market_face_value)*parseFloat(item.units);
        // item.settlement_amount=amount;
        if (item.type === 'Sell' || item.type === 'Maturity' || item.type === 'Coupon') {
          gross = gross - parseFloat(item.settlement_amount);
        } else {
          gross = gross + parseFloat(item.settlement_amount);
        }
      });
      if (gross < 0) {
        // let status: any = gross * -1 < 0 ? 'Credit' : 'Debit';
        // setEquityInvestmentStatus(status);
        setFields((prevState) => ({
          ...fields,
          payment_type: 'Receipt',
          gross_amount: (gross * -1).toFixed(2),
          net_amount: (gross * -1).toFixed(2),
        }));
      } else {
        // let status: any = gross < 0 ? 'Credit' : 'Debit';
        // setEquityInvestmentStatus(status);
        setFields((prevState) => ({
          ...fields,
          payment_type: 'Payment',
          gross_amount: gross.toFixed(2),
          net_amount: gross.toFixed(2),
        }));
      }
      setPIBFields(false);
    }
    // }
  };

  // add secuirty Entity

  const addSecurityInArray = () => {
    let count = 0;
    for (let i in securitesEntity) {
      if (securitesEntity[i] === '') {
        count = count + 1;
      }
    }
    if (count > 0) {
      toast.error('Kindly Fill All Fields');
    } else {
      // const check = securites.filter((item) => {
      //   return (
      //     item.symbol === securitesEntity['symbol'] &&
      //     item.type === securitesEntity['type']
      //   );
      // });
      // if (check.length > 0) {
      //   securites.map((item, index) => {
      //     if (
      //       item.symbol === securitesEntity['symbol'] &&
      //       item.type === securitesEntity['type']
      //     ) {
      //       item.quantity = (+item.quantity + +securitesEntity['quantity']).toFixed(4);
      //       item.charges = (+item.commission_charges + +securitesEntity['commission_charges']).toFixed(2);
      //       item.gross_amount = (+item.gross_amount + +securitesEntity['gross_amount']).toFixed(2);
      //       item.net_amount = (+item.net_amount + +securitesEntity['net_amount']).toFixed(2);
      //       item.sst_on_commission = (+item.sst_on_commission + +securitesEntity['sst_on_commission']).toFixed(2);
      //       item.commission_charges = (+item.commission_charges + +securitesEntity['commission_charges']).toFixed(2);
      //     }
      //   });
      // } else {
      if (securitesEntity['type'] === 'Sell') {
        securitesEntity['quantity'] = '-' + securitesEntity['quantity'];
        securitesEntity['gross_amount'] = '-' + securitesEntity['gross_amount'];
        securitesEntity['net_amount'] = securitesEntity['net_amount'].startsWith('-') ? securitesEntity['net_amount'] : '-' + securitesEntity['net_amount']

      }
      securites.push(securitesEntity);
      setSecurites(securites);
      // }
      setViewAddModal(false);
      setSecuritesEntity((prevState) => ({
        ...securitesEntity,
        gross_amount: '',
        net_amount: '',
        market: '',
        investment_type: '',
        sst_on_commission: '',
        commission_rate: '',
        // capital_value_tax:'',
        net_rate: '',
        type: '',
        symbol: '',
        quantity: '',
        avg_rate: '',
        commission_charges: '0',
        // broker:'',
      }));
      let gross = 0, net = 0, sst = 0, brokercomission = 0;
      securites.map((item, index) => {
        // if (item.type === 'Sell') {
        net = net + parseFloat(item.net_amount);
        gross = gross + parseFloat(item.gross_amount);
        // } else {
        //   net = net + parseFloat(item.net_amount);
        //   gross = gross + parseFloat(item.gross_amount);
        // }
        sst = sst + parseFloat(item.sst_on_commission);
        brokercomission = brokercomission + parseFloat(item.commission_charges);
      });
      if (net < 0) {
        let status: any = net * -1 < 0 ? 'Credit' : 'Debit';
        setEquityInvestmentStatus(status);
        setFields((prevState) => ({
          ...fields,
          payment_type: 'Receipt',
          gross_amount: (gross * -1).toFixed(2),
          net_amount: (net * -1).toFixed(2),
          tax_sst: (sst).toFixed(2),
          broker_commission: (brokercomission).toFixed(2),
        }));
      } else {
        let status: any = net < 0 ? 'Credit' : 'Debit';
        setEquityInvestmentStatus(status);
        setFields((prevState) => ({
          ...fields,
          payment_type: 'Payment',
          gross_amount: gross.toFixed(2),
          net_amount: net.toFixed(2),
          tax_sst: sst.toFixed(2),
          broker_commission: brokercomission.toFixed(2),
        }));
      }
    }
  };

  // const addSecurityInArray = () => {
  //   let count = 0;
  //   for (let i in securitesEntity) {
  //     if (securitesEntity[i] === '') {
  //       count = count + 1;
  //     }
  //   }
  //   if (count > 0) {
  //     toast.error('Kindly Fill All Fields');
  //   } else {
  //     securites.push(securitesEntity);
  //     setSecurites(securites);
  //     setViewAddModal(false);
  //     setSecuritesEntity((prevState) => ({
  //       ...securitesEntity,
  //       gross_amount: '',
  //       net_amount: '',
  //       market: '',
  //       investment_type:'',
  //       sst_on_commission:'',
  //       commission_rate:'',
  //       // capital_value_tax:'',
  //       net_rate:'',
  //       type: '',
  //       symbol: '',
  //       quantity: '',
  //       avg_rate: '',
  //       commission_charges: '0',
  //     }));
  //     let gross = 0;
  //     securites.map((item, index) => {
  //       if (item.type === 'Sale') {
  //         gross = gross - parseFloat(item.net_amount);
  //       } else {
  //         gross = gross + parseFloat(item.net_amount);
  //       }
  //     });
  //     if (gross < 0) {
  //       let status: any = gross * -1 < 0 ? 'Credit' : 'Debit';
  //       setEquityInvestmentStatus(status);
  //       setFields((prevState) => ({
  //         ...fields,
  //         payment_type: 'Receipt',
  //         gross_amount: (gross * -1).toFixed(2),
  //         net_amount: (gross * -1).toFixed(2),
  //       }));
  //     } else {
  //       let status: any = gross < 0 ? 'Credit' : 'Debit';
  //       setEquityInvestmentStatus(status);
  //       setFields((prevState) => ({
  //         ...fields,
  //         payment_type: 'Payment',
  //         gross_amount: gross.toFixed(2),
  //         net_amount: gross.toFixed(2),
  //       }));
  //     }
  //     // setFields(prevState => ({
  //     //     ...fields,
  //     //     gross_amount: gross.toString(),
  //     // }));
  //   }
  // };

  //onchange Quantity in security Modal  the value of Gross amount calculated here
  const onChangeSecurityValue = (value) => {
    let grossAmount = +closeRate * parseFloat(value);
    let netAmount =
      +closeRate * parseFloat(value) - parseFloat(securitesEntity.charges || '0');
    setSecuritesEntity((prevState) => ({
      ...securitesEntity,
      gross_amount: grossAmount.toFixed(2),
      net_amount: netAmount.toFixed(2),
    }));
    setAccTiltleName(value);
  };


  //onchange Gross ammount in security Modal  the value of Gross amount calculated here
  const onChangeGrossValue = (value) => {
    let netAmount = 0;
    if (securitesEntity['type'] === 'Buy') {
      setAccTiltleName(value);
      netAmount = parseFloat(value) + parseFloat(securitesEntity.commission_charges || '0') + parseFloat(securitesEntity.sst_on_commission || '0');
    } else {
      setAccTiltleName(value);
      netAmount =
        parseFloat(value) - parseFloat(securitesEntity.commission_charges || '0') - parseFloat(securitesEntity.sst_on_commission || '0');
    }
    setSecuritesEntity((prevState) => ({
      ...securitesEntity,
      gross_amount: parseFloat(value).toFixed(2),
      net_amount: netAmount,
    }));
    setAccTiltleName(value);
  };

  //onchange Quantity in Fund security Modal  the value of Gross amount calculated here
  const onChangeSecurityFundSecurityUnitsValue = (value) => {
    let grossAmount = +closeRate * parseFloat(value);
    let netAmount = 0;
    if (fundInvestmentSecurityEtity['type'] === 'Buy') {
      netAmount =
        +closeRate * parseFloat(value) + parseFloat(fundInvestmentSecurityEtity['sale_load'] || '0');
    } else {
      netAmount =
        +closeRate * parseFloat(value) - parseFloat(fundInvestmentSecurityEtity['redemption_load'] || '0') - parseFloat(fundInvestmentSecurityEtity['tax_cgt'] || '0') - parseFloat(fundInvestmentSecurityEtity['zakat_amount'] || '0');
    }
    setFundInvestmentSecurityEtity((prevState) => ({
      ...fundInvestmentSecurityEtity,
      gross_amount: grossAmount.toFixed(2),
      net_amount: netAmount.toFixed(2),
    }));
    setAccTiltleName(value);
  };
  //onchange Gross ammount in Fund Security Modal  the value of Gross amount calculated here
  const onChangeGrossValueFundSecurity = (value) => {
    let netAmount = 0;
    if (fundInvestmentSecurityEtity['type'] === 'Buy') {
      // setAccTiltleName(value);
      netAmount = parseFloat(value) + parseFloat(fundInvestmentSecurityEtity['sale_load'] || '0');
    } else {
      // setAccTiltleName(value);
      netAmount =
        parseFloat(value) - parseFloat(fundInvestmentSecurityEtity['zakat_amount'] || '0') - parseFloat(fundInvestmentSecurityEtity['tax_cgt'] || '0') - parseFloat(fundInvestmentSecurityEtity['redemption_load'] || '0');
    }
    let qunt = ((parseFloat(value) / parseFloat(closeRate)));
    setFundInvestmentSecurityEtity((prevState) => ({
      ...fundInvestmentSecurityEtity,
      gross_amount: value,
      net_amount: netAmount.toFixed(2),
      quantity: qunt,
    }));
    setAccTiltleName(value);
  };


  //onchange Quantity in security Modal  the value of Gross amount calculated here
  const onChangeRate = (value) => {
    let grossAmount =
      parseFloat(securitesEntity['quantity']) * parseFloat(value);
    let netAmount = grossAmount - parseFloat(securitesEntity.charges || '0');
    setSecuritesEntity((prevState) => ({
      ...securitesEntity,
      gross_amount: grossAmount.toFixed(2),
      net_amount: netAmount.toFixed(2),
    }));
    setAccTiltleName(value);
  };

  //On add Charges the value of Net amount calculated here
  const onAddChargeValue = (value, fieldType) => {
    if (fieldType == 'commission_charges') {
      if (securitesEntity['type'] === 'Buy') {
        let netAmount =
          parseFloat(securitesEntity.gross_amount) + parseFloat(value) + parseFloat(securitesEntity.sst_on_commission);
        setSecuritesEntity((prevState) => ({
          ...securitesEntity,
          net_amount: netAmount.toFixed(2),
        }))
      } else {
        setAccTiltleName(value);
        let netAmount =
          parseFloat(securitesEntity.gross_amount) - parseFloat(value) - parseFloat(securitesEntity.sst_on_commission);
        setSecuritesEntity((prevState) => ({
          ...securitesEntity,
          net_amount: netAmount.toFixed(2),
        }));
        setAccTiltleName('changed-values');
      }
    } else if (fieldType == 'sst_commission') {
      if (securitesEntity['type'] === 'Buy') {
        let netAmount =
          parseFloat(securitesEntity.gross_amount) + parseFloat(securitesEntity.commission_charges) + parseFloat(value);
        setSecuritesEntity((prevState) => ({
          ...securitesEntity,
          net_amount: netAmount.toFixed(2),
        }))
      } else {
        setAccTiltleName(value);
        let netAmount =
          parseFloat(securitesEntity.gross_amount) - parseFloat(securitesEntity.commission_charges) - parseFloat(value);
        setSecuritesEntity((prevState) => ({
          ...securitesEntity,
          net_amount: netAmount.toFixed(2),
        }));
        setAccTiltleName('changed-values');
      }
    }
  };

  const [closeRate, setCloseRate] = useState('');
  const [bsType, setBSType] = useState('');

  const [fund, setFund] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');

  const [fundError, setFundError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [nameError, setNameError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [allInvestmentInFunds, setAllInvestmentInFunds] = useState<any>([]);

  const [investmentInLoading, setInvestmentInLoading] = useState(false);


  useEffect(() => {
    const getAllfunds = async () => {
      try {
        const response = await getFunds(email);
        let temp: any = '';
        temp = { fund_name: 'Other' };
        response.data.data.unshift(temp);
        setAllInvestmentInFunds(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    }
    getAllfunds();
  }, [])
  const [investmentFlag, setInvestmentFlag] = useState(false);
  const renderAllInvestmentInFundsDropdown = () => {
    return allInvestmentInFunds.map((item: any, index: number) => {
      return (
        <>
          {
            index === 0 ? (
              <option key={index} value={item.fund_name}>
                {item.fund_name}
              </option>
            ) : (
              <option key={index} value={item.symbol_code}>
                {item.symbol_code}-{item.fund_name}
              </option>
            )
          }

        </>
      );
    });
  };
  const validateInvevestmentInFund = () => {
    let fundErr,
      codeErr,
      priceErr,
      nameErr = '';
    fund.trim() === '' ? (fundErr = 'Required') : (fundErr = '');
    name.trim() === '' ? (nameErr = 'Required') : (nameErr = '');
    code.trim() === '' ? (codeErr = 'Required') : (codeErr = '');
    price.trim() === ('') ? (priceErr = 'Required') : (priceErr = '');

    if (fundErr ||
      nameErr ||
      codeErr ||
      priceErr
    ) {
      setFundError(fundErr);
      setNameError(nameErr);
      setCodeError(codeErr);
      setPriceError(priceErr);
      return false;
    } else {
      return true;
    }
  };

  const createFundSecurity = async () => {
    const isValidateInvevestmentInFund = validateInvevestmentInFund();
    if (isValidateInvevestmentInFund) {
      if (parseFloat(price) <= 0) {
        toast.error('Price must be greater than zero');
      } else {

        setInvestmentInLoading(true)
        await addFundSecurity(email, name, code, price)
          .then((response) => {
            if (response.data.status == 200) {
              toast.success(response.data.message);
              allFundSecurities.push({ email, name, code, price })
              setViewAddFundInvestmentModal(true);
              setViewAddInvestmentInFund(false);
              setFund('');
              setCode('');
              setPrice('');
              setName('');
            } else if (response.data.status == 500) {
              toast.error(response.data.message);
            } else {
              toast.error(response.data.message);
            }

          })
          .catch((err: any) => {
            if (err.response !== undefined) {
              toast.error(err.response.data.message);
            } else {
              toast.error(err.message)
            }
          })
        setInvestmentInLoading(false);
      }
    }
  }
  const [viewAddInvestmentInFund, setViewAddInvestmentInFund] = useState(false);

  const renderModalAddInvestmentInFund = () => {
    switch (viewAddInvestmentInFund) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              // setViewAddModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewAddFundInvestmentModal(true);
                  setViewAddInvestmentInFund(false);
                  setFund('');
                  setCode('');
                  setPrice('');
                  setName('');
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> Add Investment In Fund</h6>
            </div>


            <div className="modal-body">
              <div className="">

                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <label>Select Fund</label>
                      <div>
                        <div className="">
                          <select
                            className={`form-control  w-100 ${fundError ? ' required-border' : ''
                              }`}
                            onChange={(e) => {
                              allInvestmentInFunds.filter((elem) => {
                                if (elem.fund_name === 'Other') {
                                  setCode('');
                                  setName('');
                                  setPrice('');
                                  setInvestmentFlag(false);
                                } else {
                                  if (elem.symbol_code === e.target.value) {
                                    setCode(elem.symbol_code);
                                    setName(elem.fund_name);
                                    setInvestmentFlag(true);
                                  }
                                }
                              })
                              setFund(e.target.value);
                              setPrice('')
                              setFundError('');

                            }}>
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderAllInvestmentInFundsDropdown()}
                          </select>


                        </div>

                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label>Code: <span className="color-amber">*</span></label>
                      <div>
                        <div className="">
                          <input
                            type="text"
                            className={`form-control  w-100 ${codeError ? ' required-border' : ''
                              }`}
                            disabled={investmentFlag}
                            value={code}
                            onChange={(e) => {
                              setCode(e.target.value);
                              setCodeError('');
                            }}
                            readOnly={investmentFlag}
                          />

                        </div>

                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>

                  <Col md="6">
                    <div className="form-group">
                      <label>Name: <span className="color-amber">*</span></label>
                      <div className="">
                        <input
                          className={`form-control  w-100 ${nameError ? ' required-border' : ''}`}
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            setNameError('');
                          }} />
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label>Price</label>
                      <div>
                        <div className="">
                          <NumberFormat
                            thousandSeparator={true}
                            inputMode="numeric"
                            className={`form-control text-right  w-100 ${priceError ? ' required-border' : ''}`}
                            // className="form-control w-100 text-right"
                            value={decimalValue(price, 4)}
                            onValueChange={(e) => {
                              // let value = decimalValue(e.value, 4);
                              setPrice(e.value);
                              setPriceError('');
                            }} />


                        </div>

                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>


                </Row>


                <div className=" mt-3">
                  <div className="">
                    <button className="btn btn-primary" onClick={createFundSecurity} disabled={Boolean(investmentInLoading)}>
                      {investmentInLoading ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span className="login-txt"> Loading...</span></> : <span>Create</span>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [viewAddFundInvestmentModal, setViewAddFundInvestmentModal] = useState(false);

  // Investment In Fund
  const [fundInvestmentSecurityEtity, setFundInvestmentSecurityEtity] = useState<any>({
    type: '',
    symbol: '',
    quantity: '',
    avg_rate: '',
    gross_amount: '',
    net_amount: '',
  });

  // add security Entity
  const addFundInvestmentSecurityInArray = () => {
    let count = 0;
    for (let i in fundInvestmentSecurityEtity) {
      if (fundInvestmentSecurityEtity[i] === '') {
        if (fundInvestmentSecurityEtity['type'] === 'Buy') {
          if (i !== 'redemption_load') {
            count = count + 1;
          }
        } else {
          if (i !== 'sale_load') {
            count = count + 1;
          }
        }

      }
    }
    if (count > 0) {
      toast.error('Kindly Fill All Fields');
    } else {
      if (fundInvestmentSecurityEtity['type'] === 'Sell') {
        fundInvestmentSecurityEtity['quantity'] = '-' + fundInvestmentSecurityEtity['quantity'];
        fundInvestmentSecurityEtity['gross_amount'] = '-' + fundInvestmentSecurityEtity['gross_amount'];
        fundInvestmentSecurityEtity['net_amount'] = fundInvestmentSecurityEtity['net_amount'].startsWith('-') ? fundInvestmentSecurityEtity['net_amount'] : '-' + fundInvestmentSecurityEtity['net_amount']
      }

      securites.push(fundInvestmentSecurityEtity);
      setSecurites(securites);
      // }
      setNoOfUnitsReadOnlyIF(false);
      setGrossAmountReadOnlyIF(false);
      setViewAddFundInvestmentModal(false);
      setFundInvestmentSecurityEtity((prevState) => ({
        ...fundInvestmentSecurityEtity,
        type: '',
        symbol: '',
        quantity: '',
        avg_rate: '',
        sale_load: '',
        redemption_load: '',
        zakat_amount: '0',
        tax_cgt: '0',
        net_amount: '',
      }));
      let gross = 0, net = 0;
      securites.map((item, index) => {
        net = net + parseFloat(item.net_amount);
        gross = gross + parseFloat(item.gross_amount);
      });
      if (net < 0) {
        let status: any = net * -1 < 0 ? 'Credit' : 'Debit';
        setEquityInvestmentStatus(status);
        setFields((prevState) => ({
          ...fields,
          payment_type: 'Receipt',
          gross_amount: (gross * -1).toFixed(2),
          net_amount: (net * -1).toFixed(2),
        }));
      } else {
        let status: any = net < 0 ? 'Credit' : 'Debit';
        setEquityInvestmentStatus(status);
        setFields((prevState) => ({
          ...fields,
          payment_type: 'Payment',
          gross_amount: gross.toFixed(2),
          net_amount: net.toFixed(2),
        }));
      }
    }
  };

  // ADD  Investment In Fund
  const renderModalInvestmentInFundPopup = () => {
    switch (viewAddFundInvestmentModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              // setViewAddModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setNoOfUnitsReadOnlyIF(false);
                  setGrossAmountReadOnlyIF(false);
                  setViewAddFundInvestmentModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> Add Fund Security </h6>
            </div>


            <div className="modal-body">
              <div className="">

                <Row>
                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Fund</h4>
                      </div>
                      <div className="card-body">
                        <Row>
                          <Col>
                            <div className="form-group">
                              Type*
                              <select
                                className="form-control w-100"
                                onChange={(e) => {
                                  if (fields['fund_code'] === '') {
                                    toast.error('Please Select First Fund');
                                  } else {
                                    if (e.target.value === 'Sell') {
                                      if (fields['fund_code'] === '') {
                                        toast.error('Select Fund First')
                                      } else {
                                        fundInvestmentSecurityEtity['redemption_load'] = '';
                                        fundInvestmentSecurityEtity['zakat_amount'] = '0';
                                        fundInvestmentSecurityEtity['tax_cgt'] = '0';
                                        setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                                        getInvestmentInFundData(fields['fund_code']);
                                      }
                                    } else {
                                      fundInvestmentSecurityEtity['sale_load'] = '';
                                      setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                                    }
                                    // else if(e.target.value==='Buy' || e.target.value===''){
                                    //   renderStockMarketData();
                                    // }
                                    setBSType(e.target.value);
                                    fundInvestmentSecurityEtity['type'] = e.target.value;
                                    setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                                  }
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Type
                                </option>
                                <option value="Buy">Invest</option>
                                <option value="Sell">Redeem</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md='8'>
                            <div className="form-group">
                              Funds*
                              <select
                                className="form-control w-100"
                                onChange={(e) => {

                                  if (bsType === 'Buy') {
                                    fundInvestmentSecurityEtity['symbol'] = e.target.value;
                                    fundInvestmentSecurityEtity['redemption_load'] = '';
                                    fundInvestmentSecurityEtity['zakat_amount'] = '0';
                                    fundInvestmentSecurityEtity['tax_cgt'] = '0';
                                    setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);

                                    let value = allFundSecurities.filter((item: any) => {
                                      return item.code === e.target.value;
                                    });

                                    fundInvestmentSecurityEtity['avg_rate'] = value[0]?.price || '0';
                                    setCloseRate(value[0]?.price || '0');
                                    setSecuritesEntity(fundInvestmentSecurityEtity);
                                    setAccTiltleName(e.target.value);
                                  } else if (bsType === 'Sell') {
                                    fundInvestmentSecurityEtity['symbol'] = e.target.value;
                                    fundInvestmentSecurityEtity['redemption_load'] = '';
                                    setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                                    let value = investmentInFundData.filter((item: any) => {
                                      return item.code === e.target.value;
                                    });
                                    fundInvestmentSecurityEtity['avg_rate'] = value[0]?.price || '0';
                                    setFundSecurityUnitPortfolio(value[0]?.units);
                                    setCloseRate(value[0]?.price || '0');
                                    setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                                    setAccTiltleName(e.target.value);
                                  }
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select
                                </option>
                                {fields['fund_code'] !== '' ? (bsType === 'Buy' || bsType === '' ? renderFundSecuritiesDropdown() : renderSellFundSecuritiesData()) : ''}
                              </select>

                            </div>
                          </Col>
                          {
                            (fundInvestmentSecurityEtity['type'] === '' || fundInvestmentSecurityEtity['type'] === 'Buy') && (
                              <Col md='4'>
                                <br />
                                <button className='btn btn-default btn-sm float-right mr-4'
                                  title="Add New Unit-Holder"
                                  onClick={() => {
                                    setViewAddInvestmentInFund(true);
                                    setViewAddFundInvestmentModal(false);
                                  }}>
                                  <i className="fa fa-plus"></i>
                                </button>
                              </Col>
                            )
                          }

                        </Row>

                        <Row>
                          <Col>
                            <div className="form-group">
                              NAV Price*
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className="form-control w-100 text-right"
                                value={decimalValue(closeRate, 4) || ''}
                                onValueChange={(e) => {
                                  let value = decimalValue(e.value, 4);
                                  let grossAmount = parseFloat(fundInvestmentSecurityEtity['quantity']) * parseFloat(value);
                                  fundInvestmentSecurityEtity['gross_amount'] = grossAmount;
                                  fundInvestmentSecurityEtity['avg_rate'] = value
                                  setCloseRate(value);
                                  setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                                  // onChangeRate(value);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>

                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Transaction</h4>

                      </div>
                      <div className="card-body">
                        <Row>
                        </Row>
                        <Row>
                          <Col>
                            <div className="form-group">
                              No of Units*
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className="form-control w-100 text-right"
                                readOnly={noOfUnitsReadOnlyIF}
                                disabled={noOfUnitsReadOnlyIF}
                                value={decimalValue(fundInvestmentSecurityEtity['quantity'].toString(), 4)}
                                onValueChange={(e) => {
                                  if (noOfUnitsReadOnlyIF === false) {
                                    let value = decimalValue(e.value, 4);
                                    // value === '' ?
                                    //   setGrossAmountReadOnlyIF(false)
                                    //   : setGrossAmountReadOnlyIF(true)
                                    // let grossAmount = parseFloat(value) * parseFloat(closeRate);
                                    // fundInvestmentSecurityEtity['gross_amount'] = grossAmount;
                                    fundInvestmentSecurityEtity['quantity'] = value;
                                    setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                                    onChangeSecurityFundSecurityUnitsValue(value);
                                    value === '' ?
                                      setGrossAmountReadOnlyIF(false)
                                      : setGrossAmountReadOnlyIF(true)
                                  }
                                }}
                              />

                            </div>
                          </Col>
                        </Row>

                      </div>
                    </div>
                  </Col>

                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Amount</h4>

                      </div>
                      <div className="card-body">
                        <Row>
                          <Col>
                            <div className="form-group">
                              Gross Amount
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className="form-control w-100 text-right"
                                readOnly={grossAmountReadOnlyIF}
                                disabled={grossAmountReadOnlyIF}
                                value={fundInvestmentSecurityEtity['gross_amount']}
                                onValueChange={(e) => {
                                  if (grossAmountReadOnlyIF === false) {
                                    let value = decimalValue(e.value, 2);
                                    fundInvestmentSecurityEtity['gross_amount'] = value;
                                    setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                                    onChangeGrossValueFundSecurity(value);
                                    value === '' ?
                                      setNoOfUnitsReadOnlyIF(false)
                                      : setNoOfUnitsReadOnlyIF(true)
                                  }
                                }}

                              // readOnly={grossAmountReadOnlyIF}
                              />

                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {
                            (headLabel === 'investmentinfund' && fundInvestmentSecurityEtity['type'] === 'Sell') ? (
                              <Col>
                                <div className="form-group">
                                  Redemption Load
                                  <NumberFormat
                                    thousandSeparator={true}
                                    inputMode="numeric"
                                    className="form-control text-right"
                                    value={fundInvestmentSecurityEtity['redemption_load']}
                                    onValueChange={(e) => {
                                      let value = decimalValue(e.value, 2);

                                      let netamount = (parseFloat(fundInvestmentSecurityEtity['gross_amount'] || '0') - (parseFloat(fundInvestmentSecurityEtity['tax_cgt'] || '0')) - (parseFloat(value || '0')) - (parseFloat(fundInvestmentSecurityEtity['zakat_amount'] || '0'))).toFixed(2);

                                      fundInvestmentSecurityEtity['redemption_load'] = value;
                                      fundInvestmentSecurityEtity['net_amount'] = netamount;
                                      setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);

                                      let netamountWords = inWords(netamount.toString());
                                      setNetAmountInWords(netamountWords);

                                      let netAmountSeparator = numberWithCommas(netamount.toString());
                                      setNetAmountSeparator(netAmountSeparator);

                                      let redemptionLoad: any = parseFloat(fields['redemption_load'] || '0') + (parseFloat(value || '0'));
                                      setFields({
                                        ...fields,
                                        redemption_load: redemptionLoad.toFixed(2),
                                      })
                                    }}
                                  />
                                </div>
                              </Col>
                            ) : (
                              <Col>
                                <div className="form-group">
                                  Sale Load
                                  <NumberFormat
                                    thousandSeparator={true}
                                    inputMode="numeric"
                                    className="form-control text-right"
                                    value={fundInvestmentSecurityEtity['sale_load']}
                                    onValueChange={(e) => {
                                      let value = decimalValue(e.value, 2);
                                      let netamount = parseFloat(fundInvestmentSecurityEtity['gross_amount'] || '0') + (parseFloat(value || '0'));

                                      fundInvestmentSecurityEtity['net_amount'] = netamount;
                                      fundInvestmentSecurityEtity['sale_load'] = value;

                                      setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);

                                      let netamountWords = inWords(netamount.toString());
                                      setNetAmountInWords(netamountWords);

                                      let netAmountSeparator = numberWithCommas(netamount.toString());
                                      setNetAmountSeparator(netAmountSeparator);

                                      let saleLoad: any = parseFloat(fields['sale_load'] || '0') + (parseFloat(value || '0'));
                                      setFields({
                                        ...fields,
                                        sale_load: saleLoad.toFixed(2),
                                      })
                                    }}
                                  />
                                </div>
                              </Col>
                            )}

                        </Row>

                        {
                          (headLabel === 'investmentinfund' && fundInvestmentSecurityEtity['type'] === 'Sell') && (
                            <Row>
                              <Col>
                                <div className="form-group">
                                  CGT
                                  <NumberFormat
                                    thousandSeparator={true}
                                    inputMode="numeric"
                                    className='form-control w-100 text-right'
                                    value={fundInvestmentSecurityEtity['tax_cgt'] || '0'}
                                    onValueChange={(e) => {
                                      let value = decimalValue(e.value, 2);

                                      let netamount = (parseFloat(fundInvestmentSecurityEtity['gross_amount'].replace('', '0')) - (parseFloat(fundInvestmentSecurityEtity['redemption_load'].replace('', '0'))) - (parseFloat(value || '0')) - (parseFloat(fundInvestmentSecurityEtity['zakat_amount'] || '0'))).toFixed(2);
                                      fundInvestmentSecurityEtity['net_amount'] = netamount;
                                      fundInvestmentSecurityEtity['tax_cgt'] = value;

                                      setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);

                                      let netamountWords = inWords(netamount.toString());
                                      setNetAmountInWords(netamountWords);

                                      let netAmountSeparator = numberWithCommas(netamount.toString());
                                      setNetAmountSeparator(netAmountSeparator);

                                      let taxCgt: any = parseFloat(fields['tax_cgt'] || '0') + (parseFloat(value || '0'));
                                      setFields({
                                        ...fields,
                                        tax_cgt: taxCgt.toFixed(2),
                                      })
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          )
                        }

                        {
                          (headLabel === 'investmentinfund' && fundInvestmentSecurityEtity['type'] === 'Sell') && (
                            <Row>
                              <Col>
                                <div className="form-group">
                                  Zakat
                                  <NumberFormat
                                    thousandSeparator={true}
                                    inputMode="numeric"
                                    className={
                                      'form-control w-100 text-right'
                                    }
                                    value={fundInvestmentSecurityEtity['zakat_amount'] || '0'}
                                    onValueChange={(e) => {
                                      let value = decimalValue(e.value, 2);

                                      let netamount = (parseFloat(fundInvestmentSecurityEtity['gross_amount'] || '0') - parseFloat(fundInvestmentSecurityEtity['redemption_load'] || '0') - parseFloat(fundInvestmentSecurityEtity['tax_cgt'] || '0') - parseFloat(value || '0')).toFixed(2);

                                      fundInvestmentSecurityEtity['net_amount'] = netamount;
                                      fundInvestmentSecurityEtity['zakat_amount'] = value;

                                      setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);

                                      let netamountWords = inWords(netamount.toString());
                                      setNetAmountInWords(netamountWords);

                                      let netAmountSeparator = numberWithCommas(netamount.toString());
                                      setNetAmountSeparator(netAmountSeparator);

                                      let zakatAmount: any = parseFloat(fields['zakat_amount'] || '0') + (parseFloat(value || '0'));
                                      setFields({
                                        ...fields,
                                        zakat_amount: zakatAmount.toFixed(2),
                                      })
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          )
                        }

                        <Row>
                          <Col>
                            <div className="form-group">
                              Net Amount
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className="form-control w-100 disable-input text-right"
                                value={parseFloat(
                                  fundInvestmentSecurityEtity['net_amount']
                                ).toFixed(2)}
                                readOnly
                              />

                            </div>
                          </Col>
                        </Row>

                      </div>
                    </div>
                  </Col>

                </Row>
                <div className=" mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      if ((fundInvestmentSecurityEtity['type'] === 'Sell') && (parseFloat(fundInvestmentSecurityEtity['quantity']) > parseFloat(fundSecurityUnitPortfolio) || parseFloat(fundInvestmentSecurityEtity['quantity']) <= 0)) {
                        if (parseFloat(fundInvestmentSecurityEtity['quantity']) <= 0) {
                          toast.error("Units must be greater than zero");
                          return;
                        } else {
                          if (parseFloat(fundSecurityUnitPortfolio) <= 0) {
                            toast.error(`Portfolio's units are ${fundSecurityUnitPortfolio}, you can't enter ${fundInvestmentSecurityEtity['quantity']} `);
                          } else {
                            toast.error('Units should be less than {' + fundSecurityUnitPortfolio + '} and greater than {' + 0 + '}');
                          }
                          return;
                        }
                      } else {
                        fundInvestmentSecurityEtity['avg_rate'] = closeRate;
                        setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                        addFundInvestmentSecurityInArray();
                      }
                    }}
                  >
                    Add
                  </button>
                </div>
              </div >
            </div >
          </Modal >
        );
      default:
        return '';
    }
  };

  const renderInvestmentInFundData = () => {
    // let mmSecurities:any=[];
    // if(headLabel === 'equityinvestment'){
    //    mmSecurities= JSON.parse(securites);
    // }
    //  let mmSecurities:any = JSON.parse(securites);
    return securites.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.type}</td>
          <td>{items.symbol}</td>
          <td>{parseFloat(items.quantity).toFixed(2)}</td>
          <td>{parseFloat(items.avg_rate).toFixed(4)}</td>
          <td>{parseFloat(isNumber(items.gross_amount)).toFixed(2)}</td>
          {
            items.type === 'Buy' ? (
              <>
                <td>{parseFloat(isNumber(items.sale_load)).toFixed(4)}</td>
              </>
            ) : (
              <>
                <td>{parseFloat(isNumber(items.redemption_load)).toFixed(4)}</td>
                <td>{parseFloat(isNumber(items.tax_cgt)).toFixed(4)}</td>
                <td>{parseFloat(isNumber(items.zakat_amount)).toFixed(4)}</td>
              </>
            )
          }
          <td>{parseFloat(isNumber(items.net_amount)).toFixed(2)}</td>
          <td>
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...securites];
                array.splice(index, 1);
                setSecurites(array);

                setFields((prevState) => ({
                  ...fields,
                  payment_type: 'Payment or Receipt',
                  gross_amount: '',
                  net_amount: '0',
                }));

                // let gross = 0, net = 0, sst = 0, brokercomission = 0;

                // array.map((item, index) => {
                //   if (item.type === 'Sell') {
                //     net = net - parseFloat(item.net_amount);
                //     gross = gross - parseFloat(item.gross_amount);
                //   } else {
                //     net = net + parseFloat(item.net_amount);
                //     gross = gross + parseFloat(item.gross_amount);
                //   }
                //   sst = sst + parseFloat(item.sst_on_commission);
                //   brokercomission = brokercomission + parseFloat(item.commission_charges);
                // });

                // if (net < 0) {
                //   let status: any = net * -1 < 0 ? 'Credit' : 'Debit';
                //   setEquityInvestmentStatus(status);
                //   setFields((prevState) => ({
                //     ...fields,
                //     payment_type: 'Receipt',
                //     gross_amount: (gross * -1).toFixed(2),
                //     net_amount: (net * -1).toFixed(2),
                //     tax_sst: (sst).toFixed(2),
                //     broker_commission: (brokercomission).toFixed(2),
                //   }));
                // } else {
                //   let status: any = gross < 0 ? 'Credit' : 'Debit';
                //   setEquityInvestmentStatus(status);
                //   setFields((prevState) => ({
                //     ...fields,
                //     payment_type: 'Payment',
                //     gross_amount: gross.toFixed(2),
                //     net_amount: net.toFixed(2),
                //     tax_sst: sst.toFixed(2),
                //     broker_commission: brokercomission.toFixed(2),
                //   }));
                // }
                setAccTiltleName(items.symbol);
              }}
            />
          </td>
        </tr>
      );
    });
  };


  const renderModalForSecuirtyPopup = () => {
    switch (viewAddModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              // setViewAddModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewAddModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Add Security </h6>
            </div>


            <div className="modal-body">
              <div className="">

                <Row>
                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Security</h4>

                      </div>
                      <div className="card-body">
                        <Row>
                          <Col>
                            <div className="form-group">
                              Type*
                              <select
                                className="form-control w-100"
                                onChange={(e) => {
                                  if (e.target.value === 'Sell') {
                                    if (fields['fund_code'] === '') {
                                      toast.error('Select Fund First')
                                    } else {
                                      getFundStockMarketData(fields['fund_code']);
                                    }
                                  }
                                  setBSType(e.target.value);
                                  securitesEntity['type'] = e.target.value;
                                  setSecuritesEntity(securitesEntity);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Type
                                </option>
                                <option value="Buy">Buy</option>
                                <option value="Sell">Sell</option>
                                {/* <option value="Sale">Sale</option> */}
                              </select>
                            </div>
                          </Col>
                        </Row>


                        <Row>
                          <Col>
                            <div className="form-group">
                              Market*
                              <select
                                className="form-control w-100"
                                value={securitesEntity.market}
                                onChange={(e) => {
                                  setSecuritesEntity({
                                    ...securitesEntity,
                                    market: e.target.value,
                                  });
                                }}
                              >
                                <option value="" defaultChecked hidden>Select Market</option>
                                <option value="REG">Regular</option>
                                <option value="ODL">Odd Lot</option>
                                <option value="FUT">Future</option>
                                <option value="BNB">Bills and Bonds</option>
                                <option value="IPO">Initial Public Offering</option>
                                <option value="BKB">Book Building</option>
                                <option value="CSF">Cash Settled Futures</option>
                                <option value="DSF">Direct Settled Futures</option>
                                <option value="SIF">Stock Index Futures</option>
                                <option value="NDM">Negotiable Deal Market</option>
                                <option value="FRO">Future Rollover Contract</option>
                                <option value="MTS">Margin Trading System</option>
                                <option value="SQR">Square Up</option>
                              </select>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="form-group">
                              Symbol*
                              <select
                                className="form-control w-100"
                                onChange={(e) => {
                                  if (bsType === 'Buy') {
                                    securitesEntity['symbol'] = e.target.value;
                                    setSecuritesEntity(securitesEntity);
                                    let value = psxList.filter((item: any) => {
                                      return item.symbol === e.target.value;
                                    });
                                    securitesEntity['avg_rate'] = value[0].close_rate;
                                    setCloseRate(value[0].close_rate);
                                    setSecuritesEntity(securitesEntity);
                                    setAccTiltleName(e.target.value);
                                  } else if (bsType === 'Sell') {
                                    securitesEntity['symbol'] = e.target.value;
                                    setSecuritesEntity(securitesEntity);
                                    let value = stockMarketDataFund.filter((item: any) => {
                                      return item.code === e.target.value;
                                    });
                                    securitesEntity['avg_rate'] = value[0].close_rate;
                                    setCloseRate(value[0].close_rate);
                                    setEquityUnitPortfolio(value[0]?.unit);
                                    setSecuritesEntity(securitesEntity);
                                    setAccTiltleName(e.target.value);
                                  }
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select
                                </option>
                                {bsType === 'Buy' || bsType === '' ? renderPsxDataDropdown() : renderStockMarketData()}
                              </select>
                            </div>
                          </Col>
                        </Row>

                        {/* <Row>
                     <Col>
                    <div className="form-group">
                      Symbol*
                      <select
                        className="form-control w-100"
                        onChange={(e) => {
                          securitesEntity['symbol'] = e.target.value;
                          setSecuritesEntity(securitesEntity);
                          let value = psxList.filter((item: any) => {
                            return item.symbol === e.target.value;
                          });
                          securitesEntity['avg_rate'] = value[0].close_rate;
                          setCloseRate(value[0].close_rate);
                          setSecuritesEntity(securitesEntity);
                          setAccTiltleName(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {' '}
                          Select
                        </option>
                        {renderPsxDataDropdown()}
                      </select>
                    </div>
                  </Col>
                       </Row> */}

                        <Row>
                          <Col>
                            <div className="form-group">
                              Avg Rate*
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className="form-control w-100 text-right"
                                value={closeRate}
                                onValueChange={(e) => {
                                  let value = decimalValue(e.value, 4);
                                  let net_rate = (parseFloat(value) - parseFloat(securitesEntity['commission_rate'] || '0')).toFixed(2);
                                  securitesEntity['avg_rate'] = value;
                                  securitesEntity['net_rate'] = net_rate;
                                  setCloseRate(value);
                                  setSecuritesEntity(securitesEntity);
                                  onChangeRate(value);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="form-group">
                              Investment Type
                              <select
                                className="form-control w-100"
                                value={securitesEntity.investment_type}
                                onChange={(e) => {

                                  setSecuritesEntity({
                                    ...securitesEntity,
                                    investment_type: e.target.value
                                  });
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Investment Type
                                </option>
                                <option value="HFT">Held To Trading</option>
                                <option value="HTM">Held To Maturity</option>
                                <option value="AFS">Available For Sale</option>
                              </select>
                            </div>
                          </Col>
                        </Row>

                      </div>
                    </div>
                  </Col>

                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Transaction</h4>

                      </div>
                      <div className="card-body">

                        <Row>
                          <Col>
                            <div className="form-group">
                              Quantity*
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className="form-control w-100 text-right"
                                value={securitesEntity['quantity']}
                                onValueChange={(e) => {
                                  let value = e.value;
                                  let commissionRate = (parseFloat(securitesEntity['commission_charges'] || '0') / (parseFloat(value))).toFixed(2);
                                  securitesEntity['quantity'] = value;
                                  securitesEntity['commission_rate'] = commissionRate;
                                  setSecuritesEntity(securitesEntity);
                                  onChangeSecurityValue(value);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="form-group">
                              Commission Charges
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className="form-control text-right"
                                value={securitesEntity['commission_charges']}
                                onValueChange={(e) => {
                                  let value = decimalValue(e.value, 2);
                                  let sst_on_commission = (parseFloat(value) * (13 / 100)).toFixed(2);
                                  let commissionRate = (parseFloat(value) / (parseFloat(securitesEntity['quantity'] || '0'))).toFixed(2);
                                  let netrate = (parseFloat(securitesEntity['avg_rate'] || '0') - parseFloat(commissionRate)).toFixed(2);
                                  securitesEntity['commission_charges'] = value;
                                  securitesEntity['commission_rate'] = commissionRate;
                                  securitesEntity['sst_on_commission'] = sst_on_commission;
                                  securitesEntity['net_rate'] = netrate;
                                  setSecuritesEntity(securitesEntity);

                                  onAddChargeValue(value, 'commission_charges');
                                  setAccTiltleName(value);
                                }}
                              />
                            </div>
                          </Col>
                          <Col>
                            <div className="form-group">
                              Commission Rate
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className="form-control text-right"
                                value={securitesEntity.commission_rate}
                                onValueChange={(e) => {
                                  let value = decimalValue(e.value, 2);
                                  let net_rate = (parseFloat(securitesEntity['avg_rate'] || '0') - parseFloat(value)).toFixed(2);
                                  securitesEntity['commission_rate'] = value;
                                  securitesEntity['net_rate'] = net_rate;
                                  setSecuritesEntity(securitesEntity);
                                  setAccTiltleName(value);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="form-group">
                              Net Rate
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className="form-control text-right"
                                value={securitesEntity.net_rate}
                                onValueChange={(e) => {
                                  let value = decimalValue(e.value, 2);
                                  setSecuritesEntity(securitesEntity);
                                  setAccTiltleName(value);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>

                      </div>
                    </div>
                  </Col>

                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Amount</h4>

                      </div>
                      <div className="card-body">
                        <Row>
                          <Col>
                            <div className="form-group">
                              Gross Amount
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className="form-control w-100 text-right"
                                value={parseFloat(
                                  securitesEntity['gross_amount']
                                ).toFixed(2)}
                                onValueChange={(e) => {
                                  let value = decimalValue(e.value, 2);
                                  // securitesEntity['quantity'] = parseFloat(value) / (+closeRate);
                                  // securitesEntity['gross_amount'] = value;
                                  // setSecuritesEntity(securitesEntity);
                                  onChangeGrossValue(value);
                                }}
                              // readOnly
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="form-group">
                              SST On Commission
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className="form-control text-right"
                                value={securitesEntity.sst_on_commission}
                                onValueChange={(e) => {
                                  let value = decimalValue(e.value, 2);
                                  securitesEntity['sst_on_commission'] = value;
                                  setSecuritesEntity(securitesEntity);
                                  setAccTiltleName(value);
                                  onAddChargeValue(value, 'sst_commission');
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                       <Col>
                    <div className="form-group">
                      Capital Value Tax
                      <input
                        type="number"
                        className="form-control"
                        value={securitesEntity.capital_value_tax}
                        onChange={(e) => {
                          securitesEntity['capital_value_tax'] = e.target.value;
                          setSecuritesEntity(securitesEntity);
                          setAccTiltleName(e.target.value);
                        }}
                      />
                    </div>
                    </Col>
                       </Row> */}
                        <Row>
                          <Col>
                            <div className="form-group">
                              Net Amount
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className="form-control w-100 disable-input text-right"
                                value={parseFloat(
                                  securitesEntity['net_amount']
                                ).toFixed(2)}
                                readOnly
                              />
                            </div>
                          </Col>
                        </Row>

                      </div>
                    </div>
                  </Col>

                </Row>
                <div className=" mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      if ((securitesEntity['type'] === 'Sell') && (parseFloat(securitesEntity['quantity']) > parseFloat(equityUnitPortfolio) || parseFloat(securitesEntity['quantity']) <= 0)) {
                        if (parseFloat(securitesEntity['quantity']) <= 0) {
                          toast.error("Units must be greater than zero");
                          return;
                        } else {
                          if (parseFloat(equityUnitPortfolio) <= 0) {
                            toast.error(`Portfolio's units are ${equityUnitPortfolio}, you can't enter ${securitesEntity['quantity']} `);
                          } else {
                            toast.error('Units should be less than {' + equityUnitPortfolio + '} and greater than {' + 0 + '}');
                          }
                          return;
                        }
                      } else {
                        securitesEntity['avg_rate'] = closeRate;
                        setSecuritesEntity(securitesEntity);
                        addSecurityInArray();
                      }
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const clearMoneyMarketEntityFields = () => {
    moneyMarketSecuritiesEntity['issue_date'] = '';
    moneyMarketSecuritiesEntity['maturity_date'] = '';
    moneyMarketSecuritiesEntity['money_market_face_value'] = '';
    moneyMarketSecuritiesEntity['units'] = '';
    moneyMarketSecuritiesEntity['price'] = '';
    moneyMarketSecuritiesEntity['yield'] = '';
    moneyMarketSecuritiesEntity['investment_type'] = '';
    moneyMarketSecuritiesEntity['coupon_rate'] = '';
    moneyMarketSecuritiesEntity['next_coupon_date'] = '';
    moneyMarketSecuritiesEntity['last_coupon_date'] = '';
    moneyMarketSecuritiesEntity['primary_dealer'] = '';
    moneyMarketSecuritiesEntity['fund_ips_account'] = '';
    moneyMarketSecuritiesEntity['counter_party_ips_account'] = '';
    moneyMarketSecuritiesEntity['broker_name'] = '';
    moneyMarketSecuritiesEntity['settlement_amount'] = '';
    moneyMarketSecuritiesEntity['total_face_value'] = '';
    moneyMarketSecuritiesEntity['accrued_amount'] = '';
    moneyMarketSecuritiesEntity['brokerage'] = '';
    moneyMarketSecuritiesEntity['premium_discount'] = '';
    moneyMarketSecuritiesEntity['accrued_days'] = '';
    moneyMarketSecuritiesEntity['counter_party_name'] = '';
    moneyMarketSecuritiesEntity['detail'] = '';
    moneyMarketSecuritiesEntity['principal_amount'] = '';
    moneyMarketSecuritiesEntity['unredeem_value'] = '';
    moneyMarketSecuritiesEntity['coupon_payment'] = '';
    moneyMarketSecuritiesEntity['days_of_maturity'] = '';
    setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
  }

  const [txnType, setTxnType] = useState('');
  const [fieldsReadOnly, setFieldsReadOnly] = useState(true);
  const [mmFieldReadOnly, setMMFieldReadOnly] = useState(true);
  const [otherType, setOtherType] = useState('');
  const [portfolioAmount, setPortfolioAmount] = useState('');
  const [portfolioUnits, setPortfolioUnits] = useState('');
  const [fundSecurityUnitPortfolio, setFundSecurityUnitPortfolio] = useState('');
  const [equityUnitPortfolio, setEquityUnitPortfolio] = useState('');

  const [mmFaceValue, setMMFaceValue] = useState('');
  const [totalFaceValueReadOnly, setTotalFaceValueReadOnly] = useState(false);

  const renderModalForMoneyMarketSecurities = () => {
    switch (viewMoneyMarketModal) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => {
              // setViewMoneyMarketModal(false);
              // setPIBFields(false);
            }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setMoneyMarketSecuritiesEntity({});
                  setViewMoneyMarketModal(false);
                  setPIBFields(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Add Money Market Security</h6>
            </div>

            <div className="modal-body">
              <div className="  m-3">

                <Row>
                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Security</h4>
                      </div>
                      <div className="card-body">

                        <Row>
                          <Col>
                            <div className="form-group">
                              Securities / Placement
                              {mmSecurityLoading ? (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x" ></i>
                                </div>
                              ) : (
                                <select
                                  className='form-control'
                                  onChange={(e) => {
                                    if (headLabel === 'debtmarketinvestment') {
                                      if (e.target.value === 'PIB' || e.target.value === 'TFC' || e.target.value === 'SUKUK') {
                                        setPIBFields(true);
                                      } else {
                                        setPIBFields(false);
                                        moneyMarketSecuritiesEntity['coupon_rate'] = '0';
                                        moneyMarketSecuritiesEntity['next_coupon_date'] = '';
                                        moneyMarketSecuritiesEntity['last_coupon_date'] = '';
                                      }
                                    }
                                    if (moneyMarketSecuritiesEntity['type'] === 'Sell' || moneyMarketSecuritiesEntity['type'] === 'Maturity' || moneyMarketSecuritiesEntity['type'] === 'Coupon') {
                                      if (fields['fund_code'] === '') {
                                        toast.error('Select Fund First');
                                      } else if (moneyMarketSecuritiesEntity['type'] === '') {
                                        toast.error('Select Type First');
                                      } else {
                                        getFundMoneyMarketPortfolioData(fields['fund_code'], e.target.value);
                                      }
                                      // set MM FaceValue readonly false
                                      setMMFieldReadOnly(false);

                                      setTotalFaceValueReadOnly(true);
                                    } else {
                                      renderFilterSecurityDataDropdown(e.target.value);
                                      setMMFieldReadOnly(true);

                                      setTotalFaceValueReadOnly(false);
                                    }
                                    setSecurityCheck(e.target.value);
                                    // renderFilterSecurityDataDropdown(e.target.value)
                                    moneyMarketSecuritiesEntity['security_type'] = e.target.value;
                                    setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                  }}>
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Security</option>
                                  <optgroup>Money Market</optgroup>
                                  <option value='PIB'>Pakistan Investment Bonds</option>
                                  <option value='TBILL'>Treasuary bill</option>

                                  <optgroup>Debt Market</optgroup>
                                  <option value='SUKUK'>Sukuk Certificates</option>
                                  <option value='TFC'>Term Finance Certificates</option>
                                  <option value='CP'>Commercial Papers</option>

                                  <optgroup>Term Deposits</optgroup>
                                  <option value='TDR'>Term Deposit Receipts</option>
                                  <option value='CDR'>Call Deposit Receipts</option>
                                  <option value='LOP'>Letter of Placement</option>
                                </select>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="form-group">
                              Type
                              <select
                                className="form-control w-100"
                                onChange={(e) => {
                                  clearMoneyMarketEntityFields();
                                  if (e.target.value === 'Sell' || e.target.value === 'Maturity' || e.target.value === 'Coupon') {
                                    if (fields['fund_code'] === '') {
                                      toast.error('Select Fund First');
                                    } else if (moneyMarketSecuritiesEntity['security_type'] === '') {
                                      toast.error('Select Fund First');
                                    } else {
                                      getFundMoneyMarketPortfolioData(fields['fund_code'], moneyMarketSecuritiesEntity['security_type']);
                                    }
                                    // set MM FaceValue readonly false
                                    setMMFieldReadOnly(false);

                                    setTotalFaceValueReadOnly(true);
                                  } else {
                                    // set MM FaceValue readonly true
                                    renderFilterSecurityDataDropdown(moneyMarketSecuritiesEntity['security_type']);
                                    setMMFieldReadOnly(true);

                                    setTotalFaceValueReadOnly(false);
                                  }
                                  setTxnType(e.target.value);
                                  moneyMarketSecuritiesEntity['type'] = e.target.value;
                                  setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Type
                                </option>
                                <option value="Buy">Buy</option>
                                <option value="Sell">Sell</option>
                                {/* <option value="Sale">Sale</option> */}
                                <option value="Maturity">Maturity</option>
                                <option value="Coupon">Coupon</option>
                                {/* <option value="RollOver">Roll-Over</option> */}
                              </select>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="form-group">
                              Security
                              {mmSecurityLoading ? (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x" ></i>
                                </div>
                              ) : (
                                <div className="form-group">
                                  <select
                                    className={
                                      'form-control '
                                    }
                                    //  value={fields['symbol']}
                                    onChange={(e) => {
                                      if (e.target.value === 'Other') {
                                        setFieldsReadOnly(false);
                                        setMMFieldReadOnly(false);
                                        setTotalFaceValueReadOnly(false);
                                        // clear fields in case of other
                                        clearMoneyMarketEntityFields();
                                        // end of clear fields

                                        setOtherType(e.target.value);
                                        let date = moneyMarketSecuritiesEntity['issue_date'].replace('-', '');
                                        let date2 = moneyMarketSecuritiesEntity['maturity_date'].replace('-', '');
                                        let symbol = moneyMarketSecuritiesEntity['security_type'] + '-' + date + '-' + date2;
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          symbol: symbol,
                                          // name: e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text
                                          name: symbol
                                        });
                                        return;
                                      } else if (txnType === 'Buy' || txnType === '') {
                                        setFieldsReadOnly(true);
                                        setMMFieldReadOnly(true);
                                        setTotalFaceValueReadOnly(false);
                                        // clear fields in case of other
                                        clearMoneyMarketEntityFields();
                                        // end of clear fields
                                        let data: any = securityData.filter((item: any, index: number) => {
                                          return e.target.value == item.code.replace('SECURITY_', '');
                                        });
                                        if (securityCheck === 'PIB' || securityCheck === 'TBILL' || securityCheck === 'TFC' || securityCheck === 'SUKUK') {
                                          moneyMarketSecuritiesEntity['issue_date'] = moment(data[0].issue_date).format('YYYY-MM-DD') || '';
                                          moneyMarketSecuritiesEntity['maturity_date'] = moment(data[0].maturity_date).format('YYYY-MM-DD') || '';
                                          moneyMarketSecuritiesEntity['money_market_face_value'] = data[0].face_value || '';
                                          moneyMarketSecuritiesEntity['price'] = data[0].price || '';
                                          moneyMarketSecuritiesEntity['symbol'] = e.target.value;
                                          moneyMarketSecuritiesEntity['name'] = e.nativeEvent.target === null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                                          setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                        } else {
                                          setMoneyMarketSecuritiesEntity({
                                            ...moneyMarketSecuritiesEntity,
                                            issue_date: moment(data[0].issue_date).format('YYYY-MM-DD') || '',
                                            maturity_date: moment(data[0].maturity_date).format('YYYY-MM-DD') || '',
                                            symbol: e.target.value,
                                            name: e.nativeEvent.target === null ? '' : e.nativeEvent.target[e.target.selectedIndex].text
                                          })
                                        }

                                      } else {
                                        setFieldsReadOnly(true);
                                        setTotalFaceValueReadOnly(true);
                                        // clear fields in case of other
                                        clearMoneyMarketEntityFields();
                                        // end of clear fields
                                        let value = (e.target.value).toString();
                                        let securityCode = value.split('&&')[0];
                                        let selectedIndex = value.split('&&')[1];
                                        let data: any = moneyMarketDataFund.filter((item: any, index: any) => {
                                          // return e.target.value==item.code;
                                          return securityCode == item.code.toString() && selectedIndex == index.toString();
                                        });

                                        if (securityCheck === 'PIB' || securityCheck === 'TBILL' || securityCheck === 'TFC' || securityCheck === 'SUKUK') {
                                          if (data[0].mmface_value == undefined || data[0].mmface_value == NaN || data[0].mmface_value == 'NaN' || data[0].mmface_value == '') {
                                            data[0].mmface_value = '0';
                                          }
                                          setPortfolioUnits(data[0].units);
                                          setMMFaceValue(data[0].mmface_value.toString());
                                          moneyMarketSecuritiesEntity['issue_date'] = moment(data[0].issue_date).format('YYYY-MM-DD') || '';
                                          moneyMarketSecuritiesEntity['maturity_date'] = moment(data[0].maturity_date).format('YYYY-MM-DD') || '';
                                          moneyMarketSecuritiesEntity['money_market_face_value'] = data[0].mmface_value || '';
                                          moneyMarketSecuritiesEntity['price'] = data[0].price || '';
                                          moneyMarketSecuritiesEntity['symbol'] = securityCode;
                                          moneyMarketSecuritiesEntity['name'] = e.nativeEvent.target === null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                                          setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                        } else {
                                          setPortfolioAmount(data[0].total_amount)
                                          setMoneyMarketSecuritiesEntity({
                                            ...moneyMarketSecuritiesEntity,
                                            issue_date: moment(data[0].issue_date).format('YYYY-MM-DD') || '',
                                            maturity_date: moment(data[0].maturity_date).format('YYYY-MM-DD') || '',
                                            symbol: securityCode,
                                            name: e.nativeEvent.target === null ? '' : e.nativeEvent.target[e.target.selectedIndex].text
                                          })
                                        }
                                      }

                                      if (securityCheck === 'PIB') {
                                        let totalfacevalue = parseFloat(moneyMarketSecuritiesEntity['money_market_face_value'] || '0') * parseFloat(moneyMarketSecuritiesEntity['units'] || '0');
                                        let prediscount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || '0') * parseFloat(moneyMarketSecuritiesEntity['price'] || '0')) - totalfacevalue).toFixed(2);
                                        let samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || '0') * parseFloat(moneyMarketSecuritiesEntity['price'] || '0')) + parseFloat(moneyMarketSecuritiesEntity['accrued_amount'] || '0')).toFixed(2);
                                        let nextcoupondate = new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                        let lastcoupondate = new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                        var oneDay = 24 * 60 * 60 * 1000;
                                        let coupondays: any;
                                        coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime()) / oneDay;
                                        let accruedamount = (((totalfacevalue * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0) / 100) / parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          total_face_value: totalfacevalue.toString(),
                                          premium_discount: prediscount,
                                          settlement_amount: samount,
                                          accrued_amount: accruedamount
                                        });
                                      }
                                      if (securityCheck === "TFC" || securityCheck === "SUKUK") {
                                        let totalfacevalue = parseFloat(moneyMarketSecuritiesEntity['money_market_face_value'] || '0') * parseFloat(moneyMarketSecuritiesEntity['units'] || '0');
                                        let prediscount = (((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || '0')) * 1000) - parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0)).toFixed(2);
                                        let nextcoupondate = new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                        let lastcoupondate = new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                        var oneDay = 24 * 60 * 60 * 1000;
                                        let coupondays: any;
                                        coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime()) / oneDay;
                                        let accruedamount = (((parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || '0') * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || '0') / 100) / parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                        let samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || '0') + parseFloat(accruedamount || '0')).toFixed(2);
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          total_face_value: totalfacevalue.toString(),
                                          premium_discount: prediscount,
                                          settlement_amount: samount,
                                          accrued_amount: accruedamount
                                        });
                                      }
                                      if (securityCheck === "TBILL") {
                                        let ndate = new Date(fields['execution_date']);
                                        let days: any;
                                        let mdate = new Date(moment(moneyMarketSecuritiesEntity['maturity_date']).format('YYYY-MM-DD'));
                                        var oneDay = 24 * 60 * 60 * 1000;
                                        days = (mdate.getTime() - ndate.getTime()) / oneDay;

                                        let totalfacevalue = parseFloat(moneyMarketSecuritiesEntity['money_market_face_value'] || '0') * parseFloat(moneyMarketSecuritiesEntity['units'] || 0);
                                        let samount = ((totalfacevalue * parseFloat(moneyMarketSecuritiesEntity['price'] || '0')) / 100).toFixed(2);
                                        //////// let prediscount =((parseFloat(moneyMarketSecuritiesEntity['units'] || '0') * parseFloat(moneyMarketSecuritiesEntity['price'] || '0')) - parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0)).toFixed(2);
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          days_of_maturity: days,
                                          total_face_value: totalfacevalue,
                                          settlement_amount: samount,
                                          //////// premium_discount: prediscount
                                        });
                                      }

                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      {' '}
                                      Select Type
                                    </option>
                                    {txnType === 'Buy' || txnType === '' ? renderSecuritiesDropDown() : renderMoneyMarketSecurityData()}
                                    {/* {renderSecuritiesDropDown()} */}
                                    {txnType === 'Buy' || txnType === '' ? <option value="Other">Other</option> : ''}
                                  </select>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="form-group">
                              Investment Type
                              <select
                                className="form-control w-100"
                                value={moneyMarketSecuritiesEntity['investment_type']}
                                onChange={(e) => {
                                  // moneyMarketSecuritiesEntity['investment_type'] = e.target.value;
                                  // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                  setMoneyMarketSecuritiesEntity({
                                    ...moneyMarketSecuritiesEntity,
                                    investment_type: e.target.value
                                  })
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Investment Type
                                </option>
                                <option value="HFT">Held To Trading</option>
                                <option value="HTM">Held To Maturity</option>
                                <option value="AFS">Available For Sale</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="form-group">
                              <div>Issue Date</div>
                              <div className="form-group">
                                <input
                                  type="Date"
                                  value={moneyMarketSecuritiesEntity['issue_date']}
                                  readOnly={fieldsReadOnly}
                                  className={
                                    'form-control w-100 '
                                  }
                                  onChange={(e) => {
                                    if (otherType === 'Other') {
                                      let date = e.target.value.replaceAll('-', '');
                                      let symbol = moneyMarketSecuritiesEntity['security_type'] + '-' + date + '-' + moneyMarketSecuritiesEntity['maturity_date'].replaceAll('-', '');
                                      moneyMarketSecuritiesEntity['symbol'] = symbol;
                                      moneyMarketSecuritiesEntity['name'] = symbol;
                                      setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                    }
                                    setMoneyMarketSecuritiesEntity({
                                      ...moneyMarketSecuritiesEntity,
                                      issue_date: e.target.value
                                    })

                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="form-group">
                              <div>Maturity Date</div>
                              <div className="form-group">
                                <input
                                  type="Date"
                                  value={moneyMarketSecuritiesEntity['maturity_date']}
                                  readOnly={fieldsReadOnly}
                                  className={
                                    'form-control w-100 '
                                  }
                                  onChange={(e) => {
                                    if (otherType === 'Other') {
                                      let date = e.target.value.replaceAll('-', '');
                                      let symbol = moneyMarketSecuritiesEntity['security_type'] + '-' + moneyMarketSecuritiesEntity['issue_date'].replaceAll('-', '') + '-' + date;
                                      moneyMarketSecuritiesEntity['symbol'] = symbol;
                                      moneyMarketSecuritiesEntity['name'] = symbol;
                                      moneyMarketSecuritiesEntity['maturity_date'] = e.target.value;
                                      setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                    }
                                    if (securityCheck === 'TBILL') {
                                      let ndate = new Date(fields['execution_date']);
                                      let days: any;
                                      let mdate = new Date(moment(e.target.value).format('YYYY-MM-DD'));
                                      var oneDay = 24 * 60 * 60 * 1000;

                                      days = (mdate.getTime() - ndate.getTime()) / oneDay;
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        maturity_date: e.target.value,
                                        days_of_maturity: days
                                      });
                                    } else {
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        maturity_date: e.target.value
                                      });
                                      // moneyMarketSecuritiesEntity['maturity_date'] = e.target.value;
                                      // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity); 
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        {(securityCheck !== 'TDR' && securityCheck !== 'CDR' && securityCheck !== 'LOP' && securityCheck !== 'CP') && (
                          <>
                            <Row>
                              <Col>
                                <div className="form-group">
                                  Money Market Face Value
                                  <div className="form-group">
                                    <NumberFormat
                                      thousandSeparator={true}
                                      inputMode="numeric"
                                      value={moneyMarketSecuritiesEntity['money_market_face_value']}
                                      readOnly={fieldsReadOnly}
                                      className={
                                        'form-control w-100 text-right'
                                      }
                                      // readOnly
                                      onValueChange={(e) => {
                                        let value = decimalValue(e.value, 2);
                                        if (securityCheck === 'PIB' || securityCheck === 'TFC' || securityCheck === 'SUKUK' || securityCheck === 'TBILL') {
                                          let samount: any;
                                          let totalfacevalue = parseFloat(value) * parseFloat(moneyMarketSecuritiesEntity['units'] || 0);
                                          if (securityCheck === 'TBILL') {
                                            samount = ((totalfacevalue * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) / 100).toFixed(2);
                                            setMoneyMarketSecuritiesEntity({
                                              ...moneyMarketSecuritiesEntity,
                                              money_market_face_value: value,
                                              total_face_value: totalfacevalue,
                                              settlement_amount: samount
                                            });
                                          } else {
                                            setMoneyMarketSecuritiesEntity({
                                              ...moneyMarketSecuritiesEntity,
                                              money_market_face_value: value,
                                              total_face_value: totalfacevalue
                                            });
                                          }
                                        } else {
                                          setMoneyMarketSecuritiesEntity({
                                            ...moneyMarketSecuritiesEntity,
                                            money_market_face_value: value
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}

                        <Row>
                          <Col>
                            <div className="form-group ">
                              <div>Primary Dealer</div>
                              <select className="form-control w-100"
                                value={moneyMarketSecuritiesEntity['primary_dealer']}
                                onChange={(e) => {
                                  // moneyMarketSecuritiesEntity['primary_dealer'] = e.target.value;
                                  // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity)

                                  setMoneyMarketSecuritiesEntity({
                                    ...moneyMarketSecuritiesEntity,
                                    primary_dealer: e.target.value
                                  })
                                }}>
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Type
                                </option>
                                <option value="None">None</option>
                                {renderPrimaryDealerDropdown()}
                              </select>
                            </div>
                          </Col>
                        </Row>

                      </div>
                    </div>
                  </Col>

                  {PIB_fields === true && (
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Coupon</h4>
                        </div>
                        <div className="card-body">

                          <Row>
                            <Col>
                              <div className="form-group">
                                <div>Last Coupon Date</div>
                                <div className="form-group">
                                  <input
                                    type="Date"
                                    value={moneyMarketSecuritiesEntity['last_coupon_date']}
                                    className={
                                      'form-control w-100 '
                                    }
                                    onChange={(e) => {
                                      if (securityCheck === 'PIB' || securityCheck === 'TFC' || securityCheck === 'SUKUK') {
                                        let ndate = new Date(fields['execution_date']);
                                        let days: any;
                                        let ldate = new Date(moment(e.target.value).format('YYYY-MM-DD'));
                                        var oneDay = 24 * 60 * 60 * 1000;

                                        let accruedamount: any;
                                        let nextcoupondate = new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                        // let lastcoupondate= new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                        var oneDay = 24 * 60 * 60 * 1000;
                                        let coupondays: any;
                                        let samount: any;
                                        coupondays = (nextcoupondate.getTime() - ldate.getTime()) / oneDay;
                                        if (securityCheck === "PIB") {
                                          accruedamount = (((parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0) / 100) / parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                          samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                                        } else if (securityCheck === "TFC" || securityCheck === "SUKUK") {
                                          accruedamount = (((parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0) / 100) / parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                          samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0)).toFixed(2);
                                        }
                                        days = (ndate.getTime() - ldate.getTime()) / oneDay;
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          settlement_amount: samount,
                                          accrued_amount: accruedamount,
                                          last_coupon_date: e.target.value,
                                          accrued_days: days
                                        });
                                      } else {
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          last_coupon_date: e.target.value
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <div className="form-group">
                                <div>Next Coupon Date</div>
                                <div className="form-group">
                                  <input
                                    type="Date"
                                    value={moneyMarketSecuritiesEntity['next_coupon_date']}
                                    className={
                                      'form-control w-100 '
                                    }
                                    onChange={(e) => {
                                      if (securityCheck === 'PIB' || securityCheck === 'TFC' || securityCheck === 'SUKUK') {
                                        let accruedamount: any;
                                        let nextcoupondate = new Date(moment(e.target.value).format('YYYY-MM-DD'));
                                        let lastcoupondate = new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                        var oneDay = 24 * 60 * 60 * 1000;
                                        let coupondays: any;
                                        let samount: any;
                                        coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime()) / oneDay;
                                        if (securityCheck === "PIB") {
                                          accruedamount = (((parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0) / 100) / parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                          samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                                        } else if (securityCheck === "TFC" || securityCheck === "SUKUK") {
                                          accruedamount = (((parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0) / 100) / parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                          samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0)).toFixed(2);
                                        }
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          settlement_amount: samount,
                                          accrued_amount: accruedamount,
                                          next_coupon_date: e.target.value
                                        });
                                      } else {
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          next_coupon_date: e.target.value
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <div className="form-group">
                                <div>Coupon Rate</div>
                                <div className="form-group">
                                  <input
                                    type="number"
                                    value={moneyMarketSecuritiesEntity['coupon_rate']}
                                    className={
                                      'form-control w-100 '
                                    }
                                    onChange={(e) => {
                                      let value = decimalValue(e.target.value, 4);
                                      if (securityCheck === 'PIB' || securityCheck === 'TFC' || securityCheck === 'SUKUK') {
                                        let accruedamount: any;
                                        let nextcoupondate = new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                        let lastcoupondate = new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                        var oneDay = 24 * 60 * 60 * 1000;
                                        let coupondays: any;
                                        let samount: any;
                                        coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime()) / oneDay;
                                        if (securityCheck === "PIB") {
                                          accruedamount = (((parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0) * ((parseFloat(value) / 100) / parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                          samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                                        } else if (securityCheck === "TFC" || securityCheck === "SUKUK") {
                                          accruedamount = (((parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) * ((parseFloat(value) / 100) / parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                          samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0)).toFixed(2);
                                        }
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          coupon_rate: value,
                                          accrued_amount: accruedamount,
                                          settlement_amount: samount
                                        });
                                      } else {
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          coupon_rate: value
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          {(securityCheck === "PIB" || securityCheck === "TFC" || securityCheck === "SUKUK") && (
                            <Row>
                              <Col>
                                <div className="form-group">
                                  <div>Coupon Payment/Year</div>
                                  <div className="form-group">
                                    <input
                                      type="number"
                                      value={moneyMarketSecuritiesEntity['coupon_payment']}
                                      className={
                                        'form-control w-100 '
                                      }
                                      onChange={(e) => {
                                        let value = decimalValue(e.target.value, 0);
                                        if (securityCheck === 'PIB' || securityCheck === 'TFC' || securityCheck === 'SUKUK') {
                                          let accruedamount: any;
                                          let nextcoupondate = new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                          let lastcoupondate = new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                          var oneDay = 24 * 60 * 60 * 1000;
                                          let coupondays: any;
                                          let samount: any;
                                          coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime()) / oneDay;
                                          if (securityCheck === "PIB") {
                                            accruedamount = (((parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0) / 100) / parseFloat(value))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                            samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                                          } else if (securityCheck === "TFC" || securityCheck === "SUKUK") {
                                            accruedamount = (((parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0) / 100) / parseFloat(value))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                            samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0)).toFixed(2);
                                          }

                                          setMoneyMarketSecuritiesEntity({
                                            ...moneyMarketSecuritiesEntity,
                                            coupon_payment: value,
                                            accrued_amount: accruedamount,
                                            settlement_amount: samount
                                          });
                                        } else {
                                          setMoneyMarketSecuritiesEntity({
                                            ...moneyMarketSecuritiesEntity,
                                            coupon_payment: value
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          )}

                          <Row>
                            <Col>
                              <div className="form-group">
                                <div>Accrued Days</div>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    value={moneyMarketSecuritiesEntity['accrued_days']}
                                    className={
                                      'form-control w-100 '
                                    }
                                    onChange={(e) => {
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        accrued_days: e.target.value
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </div>
                      </div>
                    </Col>
                  )}

                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Transaction</h4>
                      </div>
                      <div className="card-body">

                        <Row>
                          <Col>
                            <div className="form-group">
                              <div>Detail</div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  value={moneyMarketSecuritiesEntity['detail']}
                                  className={
                                    'form-control w-100 '
                                  }
                                  onChange={(e) => {
                                    // moneyMarketSecuritiesEntity['detail'] = e.target.value;
                                    // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                    setMoneyMarketSecuritiesEntity({
                                      ...moneyMarketSecuritiesEntity,
                                      detail: e.target.value
                                    })
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        {(securityCheck !== 'TDR' && securityCheck !== 'CDR' && securityCheck !== 'LOP' && securityCheck !== 'CP') && (
                          <>
                            <Row>
                              <Col>
                                <div className="form-group">
                                  <div>Price</div>
                                  <div className="form-group">
                                    <NumberFormat
                                      thousandSeparator={true}
                                      inputMode="numeric"
                                      value={moneyMarketSecuritiesEntity['price']}
                                      className={
                                        'form-control w-100 '
                                      }
                                      onValueChange={(e) => {
                                        let value = decimalValue(e.value, 4);
                                        let samount: any;
                                        let prediscount: any;
                                        if (securityCheck === 'TFC' || securityCheck === 'SUKUK') {
                                          prediscount = (((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(value)) * 1000) - parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0)).toFixed(2);
                                        } else {
                                          prediscount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(value)) - parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0)).toFixed(2);
                                        }
                                        if (securityCheck === 'PIB') {
                                          samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(value)) + parseFloat(moneyMarketSecuritiesEntity['accrued_amount'] || 0)).toFixed(2);
                                          setMoneyMarketSecuritiesEntity({
                                            ...moneyMarketSecuritiesEntity,
                                            price: value,
                                            settlement_amount: samount,
                                            premium_discount: prediscount
                                          })
                                        } else if (securityCheck === 'TFC' || securityCheck === 'SUKUK') {
                                          setMoneyMarketSecuritiesEntity({
                                            ...moneyMarketSecuritiesEntity,
                                            price: value,
                                            // settlement_amount:samount,
                                            premium_discount: prediscount
                                          });
                                        } else if (securityCheck === 'TBILL') {
                                          samount = ((parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0) * parseFloat(value)) / 100).toFixed(2);
                                          setMoneyMarketSecuritiesEntity({
                                            ...moneyMarketSecuritiesEntity,
                                            price: value,
                                            settlement_amount: samount,
                                            premium_discount: prediscount
                                          });
                                        } else {
                                          setMoneyMarketSecuritiesEntity({
                                            ...moneyMarketSecuritiesEntity,
                                            price: value
                                          })
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>


                            <Row>
                              <Col>
                                <div className="form-group">
                                  <div>Units</div>
                                  <div className="form-group">
                                    <NumberFormat
                                      thousandSeparator={true}
                                      inputMode="numeric"
                                      className="form-control w-100 text-right"
                                      value={moneyMarketSecuritiesEntity['units']}
                                      onValueChange={(e) => {
                                        let value = decimalValue(e.value, 4);
                                        let totalfacevalue: any;
                                        let prediscount: any;
                                        if (securityCheck === 'TBILL') {
                                          totalfacevalue = (parseFloat(moneyMarketSecuritiesEntity['money_market_face_value']) * parseFloat(value)).toFixed(4);
                                          let settlementamount = ((parseFloat(totalfacevalue) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) / 100).toFixed(2);

                                          moneyMarketSecuritiesEntity['total_face_value'] = totalfacevalue;
                                          moneyMarketSecuritiesEntity['settlement_amount'] = settlementamount;
                                          moneyMarketSecuritiesEntity['units'] = value;
                                          setMoneyMarketSecuritiesEntity({
                                            ...moneyMarketSecuritiesEntity,
                                            units: value,
                                            total_face_value: totalfacevalue,
                                            settlement_amount: settlementamount,
                                          });
                                        }
                                        if (securityCheck === 'PIB' || securityCheck === 'TFC' || securityCheck === 'SUKUK') {
                                          totalfacevalue = (parseFloat(moneyMarketSecuritiesEntity['money_market_face_value']) * parseFloat(value)).toFixed(4);
                                          if (securityCheck === 'TFC' || securityCheck === 'SUKUK') {
                                            prediscount = (((parseFloat(value) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) * 1000) - parseFloat(totalfacevalue || 0)).toFixed(2);
                                          } else {
                                            prediscount = ((parseFloat(value) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) - parseFloat(totalfacevalue || 0)).toFixed(2);
                                          }
                                          //calculate accrued amount
                                          let accruedamount: any;
                                          let nextcoupondate = new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                          let lastcoupondate = new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                          var oneDay = 24 * 60 * 60 * 1000;
                                          let coupondays: any;
                                          let samount: any;
                                          coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime()) / oneDay;
                                          if (securityCheck === "PIB") {
                                            accruedamount = (((parseFloat(totalfacevalue) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0) / 100) / parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                            // samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                                            samount = ((parseFloat(value) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                                          } else if (securityCheck === "TFC" || securityCheck === "SUKUK") {
                                            accruedamount = (((parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0) / 100) / parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                            samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0)).toFixed(2);
                                          }
                                          setMoneyMarketSecuritiesEntity({
                                            ...moneyMarketSecuritiesEntity,
                                            units: value,
                                            total_face_value: totalfacevalue,
                                            settlement_amount: samount,
                                            premium_discount: prediscount,
                                            accrued_amount: accruedamount
                                          });
                                        } else {
                                          setMoneyMarketSecuritiesEntity({
                                            ...moneyMarketSecuritiesEntity,
                                            units: value
                                          });
                                        }

                                      }} />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </>

                        )}
                        <Row>
                          <Col>
                            <div className="form-group">
                              <div>Yield/Interest Rate</div>
                              <div className="form-group">
                                <NumberFormat
                                  thousandSeparator={true}
                                  inputMode="numeric"
                                  value={moneyMarketSecuritiesEntity['yield']}
                                  className={
                                    'form-control w-100 text-right'
                                  }
                                  onValueChange={(e) => {
                                    let value = decimalValue(e.value, 4);
                                    // moneyMarketSecuritiesEntity['yield'] = value;
                                    // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                    setMoneyMarketSecuritiesEntity({
                                      ...moneyMarketSecuritiesEntity,
                                      yield: value
                                    })
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        {(securityCheck !== 'TDR' && securityCheck !== 'CDR' && securityCheck !== 'LOP' && securityCheck !== 'CP') && (
                          <>
                            <Row>
                              <Col>
                                <div className="form-group">
                                  <div>Total Face Value</div>
                                  <div className="form-group">
                                    <NumberFormat thousandSeparator={true}
                                      inputMode="numeric"
                                      value={moneyMarketSecuritiesEntity['total_face_value']} className="form-control w-100 text-right"
                                      readOnly={totalFaceValueReadOnly}
                                      onValueChange={(e) => {
                                        let value = decimalValue(e.value, 4);
                                        if (securityCheck === 'PIB' || securityCheck === 'TFC' || securityCheck === 'SUKUK' || securityCheck === 'TBILL') {
                                          let prediscount: any;
                                          if (securityCheck === 'TFC' || securityCheck === 'SUKUK') {
                                            prediscount = (((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) * 1000) - parseFloat(value)).toFixed(2);
                                          } else {
                                            prediscount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) - parseFloat(value)).toFixed(2);
                                          }
                                          let accruedamount: any;
                                          let nextcoupondate = new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                          let lastcoupondate = new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                          var oneDay = 24 * 60 * 60 * 1000;
                                          let coupondays: any;
                                          let samount: any;
                                          coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime()) / oneDay;
                                          if (securityCheck === 'PIB') {
                                            accruedamount = (((parseFloat(value) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0) / 100) / parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                            samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                                          } else if (securityCheck === 'TFC' || securityCheck === 'SUKUK') {
                                            accruedamount = (((parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0) / 100) / parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                            samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0)).toFixed(2);
                                          } else if (securityCheck === 'TBILL') {
                                            samount = ((parseFloat(value) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) / 100).toFixed(2);
                                          }
                                          setMoneyMarketSecuritiesEntity({
                                            ...moneyMarketSecuritiesEntity,
                                            total_face_value: value,
                                            accrued_amount: accruedamount,
                                            premium_discount: prediscount,
                                            settlement_amount: samount
                                          });
                                        } else {
                                          setMoneyMarketSecuritiesEntity({
                                            ...moneyMarketSecuritiesEntity,
                                            total_face_value: value
                                          });
                                        }

                                      }} />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}

                        {(securityCheck === "TFC" || securityCheck === "SUKUK") && (
                          <Row>
                            <Col>
                              <div className="form-group">
                                <div>UnRedeem Value</div>
                                <div className="form-group">
                                  <NumberFormat
                                    thousandSeparator={true}
                                    inputMode="numeric"
                                    // value={moneyMarketSecuritiesEntity['yield']}
                                    className={
                                      'form-control w-100 text-right'
                                    }
                                    onValueChange={(e) => {
                                      let value = decimalValue(e.value, 4);
                                      if (securityCheck === 'TFC' || securityCheck === 'SUKUK') {
                                        let accruedamount: any;
                                        let nextcoupondate = new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                        let lastcoupondate = new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                        var oneDay = 24 * 60 * 60 * 1000;
                                        let coupondays: any;
                                        let samount: any;
                                        coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime()) / oneDay;
                                        // if(securityCheck==="PIB"){
                                        //   accruedamount= (((parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                        //   samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                                        // }else 
                                        if (securityCheck === "TFC" || securityCheck === "SUKUK") {
                                          accruedamount = (((parseFloat(value) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0) / 100) / parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                          samount = (parseFloat(value) + parseFloat(accruedamount || 0)).toFixed(2);
                                        }
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          settlement_amount: samount,
                                          accrued_amount: accruedamount,
                                          unredeem_value: value
                                        });
                                      } else {
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          unredeem_value: value
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                        )}

                      </div>
                    </div>
                  </Col>

                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Amount</h4>
                      </div>
                      <div className="card-body">

                        {(securityCheck === 'PIB' || securityCheck === 'TFC' || securityCheck === 'SUKUK') && (
                          <Row>
                            <Col>
                              <div className="form-group">
                                <div>Accrued Amount</div>
                                <div className="form-group">

                                  <NumberFormat thousandSeparator={true}
                                    inputMode="numeric" value={moneyMarketSecuritiesEntity['accrued_amount']} className="form-control w-100 text-right"
                                    onValueChange={(e) => {
                                      let value = decimalValue(e.value, 2);
                                      if (securityCheck === 'PIB') {
                                        let Settlement_Amount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(value)).toFixed(2);
                                        // let prediscount = (parseFloat(Settlement_Amount) - parseFloat(moneyMarketSecuritiesEntity['total_face_vale'] || 0)).toFixed(2);
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          accrued_amount: value,
                                          settlement_amount: Settlement_Amount,
                                          // premium_discount: prediscount
                                        });
                                      } else if (securityCheck === 'TFC' || securityCheck === 'SUKUK') {

                                        let samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(value)).toFixed(2);
                                        // let prediscount = (parseFloat(samount) - parseFloat(moneyMarketSecuritiesEntity['total_face_vale'] || 0)).toFixed(2);
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          accrued_amount: value,
                                          settlement_amount: samount,
                                          // premium_discount: prediscount
                                        });
                                      } else {
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          accrued_amount: value
                                        });
                                      }

                                    }} />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )}

                        <Row>
                          <Col>
                            <div className="form-group">
                              <div>Brokerage</div>
                              <div className="form-group">
                                <NumberFormat thousandSeparator={true}
                                  inputMode="numeric"
                                  value={moneyMarketSecuritiesEntity['brokerage']} className="form-control w-100 text-right" onValueChange={(e) => {
                                    let value = decimalValue(e.value, 2);
                                    // if(securityCheck === 'TDR' || securityCheck ==='CDR' || securityCheck==='LOP' || securityCheck==='CP'){
                                    //     //  let samount = (parseFloat(moneyMarketSecuritiesEntity['principal_amount'] || 0) - parseFloat(value)).toFixed(2);
                                    //   setMoneyMarketSecuritiesEntity({
                                    //     ...moneyMarketSecuritiesEntity,
                                    //     settlement_amount: value,
                                    //     brokerage:value,
                                    //   });
                                    // }else{
                                    setMoneyMarketSecuritiesEntity({
                                      ...moneyMarketSecuritiesEntity,
                                      brokerage: value,
                                    });
                                    // }
                                  }} />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="form-group">
                              <div>Principal Amount</div>
                              <div className="form-group">
                                <NumberFormat
                                  thousandSeparator={true}
                                  inputMode="numeric"
                                  value={moneyMarketSecuritiesEntity['principal_amount']}
                                  className={
                                    'form-control w-100 text-right'
                                  }
                                  onValueChange={(e) => {
                                    let value = decimalValue(e.value, 2);
                                    if (securityCheck === 'TDR' || securityCheck === 'CDR' || securityCheck === 'LOP' || securityCheck === 'CP') {
                                      // let samount = (parseFloat(value) - parseFloat(moneyMarketSecuritiesEntity['brokerage'] || 0)).toFixed(2);
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        settlement_amount: value,
                                        principal_amount: value,
                                      });
                                    } else {
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        principal_amount: value,
                                      });
                                      // moneyMarketSecuritiesEntity['principal_amount'] = value;
                                      // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="form-group">
                              <div>{txnType === 'Coupon' ? 'Coupon Maturity Amount' : txnType === 'Maturity' ? 'Maturity Amount' : 'Settlement Amount'}</div>
                              <div className="form-group">
                                <NumberFormat
                                  thousandSeparator={true}
                                  inputMode="numeric"
                                  value={moneyMarketSecuritiesEntity['settlement_amount']}
                                  className={
                                    'form-control w-100 text-right'
                                  }
                                  onValueChange={(e) => {
                                    let value = decimalValue(e.value, 2);
                                    //  let premiumdiscount= parseFloat(value)-parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0);
                                    setMoneyMarketSecuritiesEntity({
                                      ...moneyMarketSecuritiesEntity,
                                      settlement_amount: value,
                                      // premium_discount:premiumdiscount
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        {securityCheck === 'TBILL' && (
                          <Row>
                            <Col>
                              <div className="form-group">
                                <div>Days to Maturity</div>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    value={moneyMarketSecuritiesEntity['days_of_maturity']}
                                    className={
                                      'form-control w-100 '
                                    }
                                    onChange={(e) => {
                                      let value = decimalValue(e.target.value, 0);
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        days_of_maturity: value
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )}



                        {(securityCheck === 'PIB' || securityCheck === 'TFC' || securityCheck === 'SUKUK') && (
                          <Row>
                            <Col>
                              <div className="form-group">
                                <div>Premium / Discount</div>
                                <div className="form-group">
                                  <NumberFormat thousandSeparator={true}
                                    inputMode="numeric" value={moneyMarketSecuritiesEntity['premium_discount']} className="form-control w-100 text-right"
                                    onValueChange={(e) => {

                                      moneyMarketSecuritiesEntity['premium_discount'] = e.value;
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        premium_discount: e.value
                                      });

                                    }} />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )}

                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Party</h4>
                      </div>
                      <div className="card-body">

                        <Row>
                          <Col>
                            <div className="form-group">
                              <div>Fund IPS Account</div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  value={moneyMarketSecuritiesEntity['fund_ips_account']}
                                  className={'form-control w-100 '}
                                  onChange={(e) => {
                                    // moneyMarketSecuritiesEntity['fund_ips_account'] = e.target.value;
                                    // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                    setMoneyMarketSecuritiesEntity({
                                      ...moneyMarketSecuritiesEntity,
                                      fund_ips_account: e.target.value
                                    })
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="form-group">
                              Counter Party IPS Account
                              <div className="form-group">
                                <input
                                  type="text"
                                  value={moneyMarketSecuritiesEntity['counter_party_ips_account']}
                                  className={'form-control w-100 '}
                                  onChange={(e) => {
                                    // moneyMarketSecuritiesEntity['counter_party_ips_account'] = e.target.value;
                                    // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                    setMoneyMarketSecuritiesEntity({
                                      ...moneyMarketSecuritiesEntity,
                                      counter_party_ips_account: e.target.value
                                    })
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="form-group">
                              <div>Broker Name</div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  value={moneyMarketSecuritiesEntity['broker_name']}
                                  className={'form-control w-100 '}
                                  onChange={(e) => {
                                    // moneyMarketSecuritiesEntity['broker_name'] = e.target.value;
                                    // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                    setMoneyMarketSecuritiesEntity({
                                      ...moneyMarketSecuritiesEntity,
                                      broker_name: e.target.value
                                    })
                                  }}

                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="form-group">
                              <div>Counter Party Name</div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  value={moneyMarketSecuritiesEntity['counter_party_name']}
                                  className={'form-control w-100 '}
                                  onChange={(e) => {
                                    // moneyMarketSecuritiesEntity['counter_party_name'] = e.target.value;
                                    // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                    setMoneyMarketSecuritiesEntity({
                                      ...moneyMarketSecuritiesEntity,
                                      counter_party_name: e.target.value
                                    })
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                      </div>
                    </div>
                  </Col>

                </Row>

                <div className=" ">

                  <div className=" mt-3">
                    <button type="button" className="btn btn-primary btn-block" onClick={() => {
                      if ((txnType === 'Sell' || txnType === 'Maturity') && (securityCheck === 'PIB' || securityCheck === 'TBILL' || securityCheck === 'TFC' || securityCheck === 'SUKUK')
                        && (+moneyMarketSecuritiesEntity['units'] > +portfolioUnits || +moneyMarketSecuritiesEntity['units'] < 0)) {
                        // toast.error('Units should be less than {' + portfolioUnits + '} and greater than {' + 0 + '}');
                        if (+portfolioUnits <= 0) {
                          toast.error(`Portfolio's units are ${portfolioUnits}, you can't enter ${moneyMarketSecuritiesEntity['units']} `);
                        } else {
                          toast.error('Units should be less than {' + portfolioUnits + '} and greater than {' + 0 + '}');
                        }
                        return;
                      } else if ((txnType === 'Sell' || txnType === 'Maturity') && (securityCheck === 'CP' || securityCheck === 'TDR' || securityCheck === 'CDR' || securityCheck === 'LOP')
                        && (+moneyMarketSecuritiesEntity['principal_amount'] > +portfolioAmount)) {
                        toast.error('Principal amount should not be greater than {' + portfolioAmount + '}')
                        return;
                      } else if ((txnType === 'Sell' || txnType === 'Maturity') && (securityCheck === 'PIB' || securityCheck === 'TBILL' || securityCheck === 'TFC' || securityCheck === 'SUKUK')
                        && (+moneyMarketSecuritiesEntity['money_market_face_value'] > +mmFaceValue || +moneyMarketSecuritiesEntity['money_market_face_value'] < 0)) {
                        toast.error('Money Market Face Value should be less than {' + mmFaceValue + '} and greater than {' + 0 + '}');
                        return;
                      } else {
                        addMoneyMrketSecurityInArray();
                      }

                    }}  >Add</button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const renderMoneyMarketData = () => {
    return moneyMarketSecurities.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.security_type}</td>
          <td>{items.investment_type}</td>
          <td>{items.type}</td>
          <td>{items.symbol}</td>
          <td>{items.name}</td>
          <td>{items.issue_date}</td>
          <td>{items.maturity_date}</td>
          <td>{items.money_market_face_value}</td>
          <td>{items.price}</td>
          <td>{items.units}</td>
          <td>{items.yield}</td>
          <td>{items.coupon_rate}</td>
          <td>{items.next_coupon_date}</td>
          <td>{items.last_coupon_date}</td>
          <td>{items.primary_dealer}</td>
          <td>{items.fund_ips_account}</td>
          <td>{items.counter_party_ips_account}</td>
          <td>{items.broker_name}</td>
          <td>{items.settlement_amount}</td>
          <td>{items.total_face_value}</td>
          <td>{items.accrued_amount}</td>
          <td>{items.brokerage}</td>
          <td>{items.premium_discount}</td>
          <td>{items.accrued_days}</td>
          <td>{items.counter_party_name}</td>
          <td>{items.detail}</td>
          <td>{items.principal_amount}</td>
          <td>{items.unredeem_value}</td>
          <td>{items.coupon_payment}</td>
          <td>{items.days_of_maturity}</td>
          <td>
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...moneyMarketSecurities];
                array.splice(index, 1);
                setMoneyMarketSecurities(array);
                let gross = 0;
                array.map((item, index) => {
                  if (item.type === 'Sell') {
                    gross = gross - parseFloat(item.settlement_amount);
                  } else {
                    gross = gross + parseFloat(item.settlement_amount);
                  }
                });
                if (gross < 0) {
                  setFields((prevState) => ({
                    ...fields,
                    payment_type: 'Receipt',
                    gross_amount: (gross * -1).toFixed(2),
                    net_amount: (gross * -1).toFixed(2),
                  }));
                } else {
                  setFields((prevState) => ({
                    ...fields,
                    payment_type: 'Payment',
                    gross_amount: gross.toFixed(2),
                    net_amount: gross.toFixed(2),
                  }));
                }
                // setAccTiltleName(items.symbol);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const renderPsxData = () => {
    // let mmSecurities:any = JSON.parse(securites);
    return securites.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.type}</td>
          <td>{items.investment_type}</td>
          <td>{items.market}</td>
          <td>{items.symbol}</td>
          <td>{parseFloat(items.quantity).toFixed(2)}</td>
          <td>{parseFloat(items.avg_rate).toFixed(4)}</td>
          {/* <td>{items.broker}</td> */}
          <td>{items.commission_rate}</td>
          <td>{items.sst_on_commission}</td>
          {/* <td>{items.capital_value_tax}</td> */}
          <td>{items.net_rate}</td>
          <td>{parseFloat(items.gross_amount).toFixed(2)}</td>
          <td>{parseFloat(items.commission_charges).toFixed(2)}</td>
          <td>{parseFloat(items.net_amount).toFixed(2)}</td>
          <td>
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...securites];
                array.splice(index, 1);
                setSecurites(array);
                let gross = 0, net = 0, sst = 0, brokercomission = 0;

                array.map((item, index) => {
                  if (item.type === 'Sell') {
                    net = net - parseFloat(item.net_amount);
                    gross = gross - parseFloat(item.gross_amount);
                  } else {
                    net = net + parseFloat(item.net_amount);
                    gross = gross + parseFloat(item.gross_amount);
                  }
                  sst = sst + parseFloat(item.sst_on_commission);
                  brokercomission = brokercomission + parseFloat(item.commission_charges);
                });

                if (net < 0) {
                  let status: any = net * -1 < 0 ? 'Credit' : 'Debit';
                  setEquityInvestmentStatus(status);
                  setFields((prevState) => ({
                    ...fields,
                    payment_type: 'Receipt',
                    gross_amount: (gross * -1).toFixed(2),
                    net_amount: (net * -1).toFixed(2),
                    tax_sst: (sst).toFixed(2),
                    broker_commission: (brokercomission).toFixed(2),
                  }));
                } else {
                  let status: any = gross < 0 ? 'Credit' : 'Debit';
                  setEquityInvestmentStatus(status);
                  setFields((prevState) => ({
                    ...fields,
                    payment_type: 'Payment',
                    gross_amount: gross.toFixed(2),
                    net_amount: net.toFixed(2),
                    tax_sst: sst.toFixed(2),
                    broker_commission: brokercomission.toFixed(2),
                  }));
                }
                setAccTiltleName(items.symbol);
              }}
            />
            {/* <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...securites];
                array.splice(index, 1);
                setSecurites(array);
                let gross = 0;
                array.map((item, index) => {
                  if (item.type === 'Sale') {
                    gross = gross - parseFloat(item.net_amount);
                  } else {
                    gross = gross + parseFloat(item.net_amount);
                  }
                });
                if (gross < 0) {
                  setFields((prevState) => ({
                    ...fields,
                    payment_type: 'Receipt',
                    gross_amount: (gross * -1).toFixed(2),
                    net_amount: (gross * -1).toFixed(2),
                  }));
                } else {
                  setFields((prevState) => ({
                    ...fields,
                    payment_type: 'Payment',
                    gross_amount: gross.toFixed(2),
                    net_amount: gross.toFixed(2),
                  }));
                }
                setAccTiltleName(items.symbol);
              }}
            /> */}
          </td>
        </tr>
      );
    });
  };

  const [viewAddTaxModal, setViewAddTaxModal] = useState(false);
  const [totalTax, setTotalTax] = useState<any>([]);
  const [taxEntity, setTaxEntity] = useState<any>({
    name: '',
    amount: '',
    calculatedAmount: '',
  });
  //render Tax table data
  const renderTaxData = () => {
    return totalTax.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.name}</td>
          <td>{parseFloat(items.amount).toFixed(2)}</td>
          <td>
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...totalTax];
                let diff = 0;
                diff =
                  parseFloat(fields['total_charges']) -
                  parseFloat(items.amount);
                fields['total_charges'] = diff.toFixed(2);
                array.splice(index, 1);
                setTotalTax(array);
                setAccTiltleName(items.symbol);
                let net =
                  parseFloat(fields['gross_amount']) -
                  parseFloat(fields['total_charges']);

                let currentHolding = net / parseInt(fields['nav']);
                fields['remain_holding'] = currentHolding.toFixed(2);
                // setFields((prevState) => ({
                //   ...fields,
                //   net_amount: net.toFixed(2),
                // }));

                /////// self
                let na = net.toString();
                let unit = net / parseFloat(fields['nav']);
                let a = unit.toFixed(4).toString();

                if (headLabel === "saleofunit" || headLabel === "conversionin") {
                  let remainingholding = parseFloat(fields['current_holding']) + parseFloat(a);
                  let remainholding = remainingholding.toFixed(4).toString();
                  if (na.length === 0) {
                    setFields({
                      ...fields,
                      net_amount: na,
                      units: '0.0000',
                      total_holding: fields['current_holding']
                    });
                  } else {
                    setFields((prevState) => ({
                      ...fields,
                      net_amount: na,
                      remain_holding: remainholding,
                      units: a,
                      total_holding: remainholding
                    }))
                  }
                } else if (headLabel === "unitconversion") {
                  let remainingholding = parseFloat(fields['current_holding']) - parseFloat(a)
                  let remainholding = remainingholding.toFixed(4).toString();
                  if (na.length === 0) {
                    setFields({
                      ...fields,
                      net_amount: na,
                      units: '0.0000',
                      remain_holding: fields['current_holding']
                    });
                  } else {
                    setFields((prevState) => ({
                      ...fields,
                      net_amount: na,
                      remain_holding: remainholding,
                      units: a,
                      total_holding: remainholding
                    }))
                  }
                } else if (headLabel === "conversionout" || headLabel === "redemptionofunits") {
                  let remainingholding = parseFloat(fields['current_holding']) - parseFloat(a)
                  let remainholding = remainingholding.toFixed(4).toString();
                  if (na.length === 0) {
                    setFields({
                      ...fields,
                      net_amount: na,
                      units: '0.0000',
                      remain_holding: fields['current_holding']
                    });
                  } else {
                    setFields((prevState) => ({
                      ...fields,
                      net_amount: na,
                      remain_holding: remainholding,
                      units: a,
                      total_holding: remainholding
                    }))
                  }
                } else {
                  if (na.length === 0) {
                    setFields({
                      ...fields,
                      net_amount: na,
                      units: '0.0000'
                    });
                  } else {
                    setFields({
                      ...fields,
                      net_amount: na,
                      total_charges: '0',
                    });
                  }
                }

              }}
            />
          </td>
        </tr>
      );
    });
  };

  // add Transferees in array.
  const addTransfereesInArray = () => {
    // transfereesEntity.filter((item=>{
    //   if(item.folio_no === transfereesEntity['f'])
    // }))
    transferees.push(transfereesEntity);
    setTransferees(transferees);
    setViewTransfereesModall(false);
    setTransfereesEntity((prevState) => ({
      ...transfereesEntity,
      folio_no: '',
      name: '',
      transferees_unit_transfer: '',
    }));
  };

  const [transfereesFolioNo, setTransfereesFolioNo] = useState('')
  const [viewTransfereesModal, setViewTransfereesModall] = useState(false);
  const [transferees, setTransferees] = useState<any>([]);
  const [transfereesEntity, setTransfereesEntity] = useState<any>({
    folio_no: '',
    name: '',
    transferees_unit_transfer: '',
  })
  // unit transferees model
  const renderModalUnitsTransferees = () => {
    switch (viewTransfereesModal) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              // setViewMoneyMarketModal(false);
              // setPIBFields(false);
            }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  // setMoneyMarketSecuritiesEntity({});
                  setViewTransfereesModall(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <div>
                <h6 className="title title-up">Add Transferees</h6>
                <button className='btn btn-default btn-sm float-right mr-4'
                  title="Add New Unit-Holder"
                  onClick={() => {
                    if (fields['amc_code'] !== '') {
                      setViewModalAddNewUnitHolder(true);
                    } else {
                      toast.error('First select AMC');
                    }
                  }}>
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </div>

            <div className="modal-body">
              <div className="  ml-3 mt-3 mb-3 mr-4">

                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                <Row>
                  <Col md='10'>
                    <div className="form-group">
                      <div>Folio No</div>
                      <input
                        type="text"
                        value={transfereesFolioNo}
                        placeholder='Enter Folio No.'
                        data-tip="Press Enter key to load data."
                        className={
                          'form-control w-100 '
                        }
                        onChange={async (e) => {
                          TransfreeUnitHolderLength.length = 0;

                          setTransfereeName('')
                          transfereesEntity['transferees_unit_transfer'] = ''
                          let value = e.target.value;
                          setTransfereesFolioNo(value)
                          transfereesEntity['folio_no'] = e.target.value;
                          setTransfereesEntity(transfereesEntity);

                        }}
                        onKeyPress={async (e) => {
                          transfereesEntity['transferees_unit_transfer'] = '';
                          if (e.key === 'Enter' && unitHolderFieldReadOnly === false) {
                            await getUnitHoldersByAmcFolioNoTransfree(email, fields['amc_code'], fields['amc_code'] + '-' + transfereesEntity['folio_no']);
                          }
                        }}
                      />
                    </div>
                  </Col>
                  <Col md='2'>
                    <br />
                    <button className="btn btn-default btn-sm"
                      disabled={Boolean(unitHolderFieldReadOnly)}
                      onClick={async () => {
                        await getUnitHoldersByAmcFolioNoTransfree(email, fields['amc_code'], fields['amc_code'] + '-' + transfereesEntity['folio_no']);
                      }}>
                      {unitHolderFieldReadOnly ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {/* <span className="login-txt"> Search</span> */}
                          <i className="fa fa-search"></i>
                        </>
                      ) : (
                        // <span>Search</span>
                        <i className="fa fa-search"></i>
                      )}
                    </button>
                  </Col>
                </Row>
                <div className="form-group">
                  <div>Name</div>
                  <input
                    type="text"
                    value={transfereesEntity['name'] = transfereeName}
                    className={
                      'form-control w-100 '
                    }
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <div>Transfer Units</div>
                  <NumberFormat thousandSeparator={true}
                    inputMode="numeric"
                    value={transfereesEntity['transferees_unit_transfer']}
                    placeholder='Enter Units.'
                    className={
                      'form-control w-100 text-righ'
                    }
                    onValueChange={(e) => {
                      let value: any = decimalValue(e.value, 4)
                      transfereesEntity['transferees_unit_transfer'] = value;
                      setTransfereesEntity(transfereesEntity);
                      // let remainingHolding: any = ((parseFloat(fields['current_holding']) - parseFloat(transfereesEntity['transferees_unit_transfer'])) || '0');
                      setFields({
                        ...fields,
                        remain_holding: fields['remain_holding'],
                      });

                      // setCounterFieldsData(e.target.value);
                    }}
                  />
                </div>

                <div className=" ">

                  <div className=" mt-3">
                    <button type="button" className="btn btn-primary btn-block"
                      onClick={() => {
                        if (TransfreeUnitHolderLength.length > 0 && transfereesFolioNo.length > 0) {
                          if (transfereesEntity['transferees_unit_transfer'] <= 0) {
                            toast.error("Transfer units must be greater than zero.")
                          } else if (parseFloat(transfereesEntity['transferees_unit_transfer']) > parseFloat(fields['remain_holding'])) {
                            toast.error("Transfer units not be greater than remaining holding.")
                          } else {
                            // if(fields['folio_no'] === )
                            let remainingHolding: any = ((parseFloat(fields['remain_holding']) - parseFloat(transfereesEntity['transferees_unit_transfer'])) || '0');
                            transfereesEntity['folio_no'] = fields['amc_code'] + '-' + transfereesEntity['folio_no'];
                            setTransfereesEntity(transfereesEntity['folio_no']);
                            setFields({
                              ...fields,
                              remain_holding: remainingHolding.toString(),
                            });
                            setTransfereesFolioNo('')
                            setTransfereeName('');
                            addTransfereesInArray();
                          }
                        } else {
                          toast.error('Please load valid folio number');
                        }
                      }}>Add</button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const renderTransfereesData = () => {
    return transferees.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.folio_no}</td>
          <td>{items.name}</td>
          <td className='text-right'>{items.transferees_unit_transfer}</td>
          <td>
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let remaining = 0;
                let array = [...transferees];
                remaining = parseFloat(fields['remain_holding']) + parseFloat(items.transferees_unit_transfer);
                fields['remain_holding'] = remaining.toFixed(4)
                setFields(fields);
                array.splice(index, 1);
                setTransferees(array);
              }}
            />
          </td>
        </tr>
      );
    });
  }

  //calculate total tax
  const calculateTaxForDisplay = () => {
    let total = 0;
    totalTax.map((item, index) => {
      total = total + parseFloat(item.amount);
    });
    let net = 0;
    if (headLabel === 'equityinvestment' && fields.payment_type === 'Receipt') {
      net = parseFloat(fields['gross_amount']) + total;
    } else {
      net = parseFloat(fields['gross_amount']) - total;
    }

    let remainHolding = fields['remain_holding'];

    if (headLabel == 'gainrealization') {
      remainHolding = (net / parseInt(fields['nav'])).toFixed(4);
    }

    if (net < 0) {
      net = net * -1;
    } else if (net > 0) {
    }
    //  let unit=net/parseFloat(fields['nav']);
    // let a=unit.toFixed(4).toString();
    // let remainingholding=parseFloat(fields['current_holding'])+parseFloat(a)
    // let remainholding=remainingholding.toFixed(4).toString();
    // setFields((prevState) => ({
    //   ...fields,
    //   total_charges: total.toFixed(2),
    //   net_amount: net.toFixed(2),
    //   remain_holding: remainHolding,
    //   units:a,
    //   total_holding:remainholding
    // }));

    let na = net.toString();
    let unit = net / parseFloat(fields['nav']);
    let a = unit.toFixed(4).toString();

    if (headLabel === "saleofunit" || headLabel === "conversionin") {
      let remainingholding = parseFloat(fields['current_holding']) + parseFloat(a)
      let remainholding = remainingholding.toFixed(4).toString();
      if (na.length === 0) {
        setFields({
          ...fields,
          net_amount: na,
          units: '0.0000',
          total_holding: fields['current_holding']
        });
      } else {
        setFields((prevState) => ({
          ...fields,
          total_charges: total.toFixed(2),
          net_amount: na,
          remain_holding: remainholding,
          units: a,
          total_holding: remainholding
        }))
      }
    } else if (headLabel === "unitconversion") {
      let remainingholding = parseFloat(fields['current_holding']) - parseFloat(a)
      let remainholding = remainingholding.toFixed(4).toString();
      if (na.length === 0) {
        setFields({
          ...fields,
          net_amount: na,
          units: '0.0000',
          remain_holding: fields['current_holding']
        });
      } else {
        setFields((prevState) => ({
          ...fields,
          total_charges: total.toFixed(2),
          net_amount: na,
          remain_holding: remainholding,
          units: a,
          total_holding: remainholding
        }))
      }
    } else if (headLabel === "conversionout" || headLabel === "redemptionofunits") {
      let remainingholding = parseFloat(fields['current_holding']) - parseFloat(a)
      let remainholding = remainingholding.toFixed(4).toString();
      if (na.length === 0) {
        setFields({
          ...fields,
          net_amount: na,
          units: '0.0000',
          remain_holding: fields['current_holding']
        });
      } else {
        setFields((prevState) => ({
          ...fields,
          total_charges: total.toFixed(2),
          net_amount: na,
          remain_holding: remainholding,
          units: a,
          total_holding: remainholding
        }))
      }
    } else {
      if (na.length === 0) {
        setFields({
          ...fields,
          net_amount: na,
          units: '0.0000'
        });
      } else {
        setFields((prevState) => ({
          ...fields,
          total_charges: total.toFixed(2),
          net_amount: net.toFixed(2),
          remain_holding: remainHolding
          // units:a,
          // total_holding:remainholding
        }));
      }
    }

  };

  // ADD TAX Entity inside tax Array
  const addTaxInArray = async () => {
    setDisableAddBtn(true);

    if (fields['gross_amount'] === '') {
      toast.error('First Enter Gross Amount');
      setDisableAddBtn(false);
    } else {
      let count = 0;
      //here we need to fetch system calculated tax
      try {
        const response = await getTaxAmount(
          email,
          headLabel,
          fields['gross_amount'],
          taxEntity['name']
        );
        if (response.data.data === 'empty') {
          taxEntity['calculatedAmount'] = '0';
        } else {
          taxEntity['calculatedAmount'] = response.data.data.toString();
        }
      } catch (error) { }
      //push data inside array
      for (let i in taxEntity) {
        if (taxEntity[i] === '') {
          count += 1;
        }
      }
      if (count > 0) {
        toast.error('Kindly Fill All Fields');
        setDisableAddBtn(false);
      } else {
        debugger;
        //calculate tax to compare it with Gross amount
        let total = 0;
        totalTax.map((tax, index) => {
          total = total + +tax.amount;
        });
        total = total + +taxEntity.amount;
        //
        if (+fields.gross_amount >= total) {
          setViewAddTaxModal(false);
          const check = totalTax.filter((item) => {
            return item.name === taxEntity['name'];
          });
          if (check.length > 0) {
            totalTax.map((item, index) => {
              if (item.name === taxEntity['name']) {
                item.amount = (+item.amount + +taxEntity['amount']).toString();
              }
            });
          } else {
            totalTax.push(taxEntity);
            setTotalTax(totalTax);
          }
          const filedEntirty = {
            name: '',
            amount: '',
            calculatedAmount: '',
          };
          setTaxEntity(filedEntirty);
          calculateTaxForDisplay();
          setDisableAddBtn(false);
        } else {
          toast.error('Tax Should Not Be greater than Gross Amount');
          setDisableAddBtn(false);
        }
      }
    }
  };

  const renderModalForTaxPopup = () => {
    switch (viewAddTaxModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => { setViewAddTaxModal(false) }}
          >

            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewAddTaxModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Add Tax</h6>
            </div>

            <div className="modal-body">
              <div className="">
                <div className="row">
                  <div className="col-sm-12">
                    Name*
                    <div className="form-group">
                      <select className="form-control w-100" onChange={(e) => {
                        taxEntity['name'] = e.target.value;
                        setTaxEntity(taxEntity)
                      }}>
                        <option value="" defaultChecked hidden > Select Tax</option>
                        {renderTaxTypeDropdown()}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    Amount*
                    <div className="form-group">
                      <input type="number" defaultValue={taxEntity['amount']} className="form-control w-100" onChange={(e) => {
                        taxEntity['amount'] = e.target.value;
                        setTaxEntity(taxEntity)
                      }} />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group mt-4">
                      <button type="button" className="btn btn-primary w-100 px-3 rounded" onClick={() => {
                        addTaxInArray();

                      }}  >Add</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  //retrun error border class
  const ErrorBorder = (value) => {
    if ((errorShow && value === '') || (value.substring(0, 1) === '-')) {
      return 'required-border';
    } else {
      return '';
    }
  };
  //

  const renderStockData = () => {
    return stockMarketData.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td className="text-right">{parseFloat(items.unit)}</td>
          <td className="text-right">{parseFloat(isNumber(items.close_rate)).toFixed(2)}</td>
        </tr>
      );
    });
  };

  const renderInvestmentInFundRecord = () => {
    return InvestmentInFundRecord.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td className="text-right">{parseFloat(isNumber(items.units)).toFixed(2)}</td>
          <td className="text-right">{parseFloat(isNumber(items.price)).toFixed(4)}</td>
        </tr>
      );
    });
  };

  const myRef1 = React.useRef<HTMLInputElement>(null);
  const [filename, setFileName] = useState('');
  const [file, setFile] = useState('');
  const [datafetch, setDataFetch] = useState<any>([]);
  const [equityInvestmentStatus, setEquityInvestmentStatus] = useState('');
  // add secuirty Entity
  // const addUploadedSecurityInArray = (tempData) => {
  //   const check = securites.filter((item) => {
  //     return (
  //       item.symbol === tempData['symbol'] &&
  //       item.type === tempData['type']
  //     );
  //   });
  //   if (check.length > 0) {
  //     securites.map((item, index) => {
  //       if (
  //         item.symbol === tempData['symbol'] &&
  //         item.type === tempData['type']
  //       ) {
  //         item.quantity = (
  //           +item.quantity + +tempData['quantity']
  //         ).toFixed(4);
  //         item.charges = (+item.charges + +tempData['charges']).toFixed(
  //           2
  //         );
  //         item.gross_amount = (
  //           +item.gross_amount + +tempData['gross_amount']
  //         ).toFixed(2);
  //         item.net_amount = (
  //           +item.net_amount + +tempData['net_amount']
  //         ).toFixed(2);
  //       }
  //     });

  //   } else {
  //     securites.push(tempData);
  //     setSecurites(securites);
  //   }
  //   let gross = 0;
  //   securites.map((item, index) => {
  //     if (item.type === 'Sale') {
  //       gross = gross - parseFloat(item.net_amount);
  //     } else {
  //       gross = gross + parseFloat(item.net_amount);
  //     }
  //   });
  //   if (gross < 0) {
  //     let status: any = gross * -1 < 0 ? 'Credit' : 'Debit';
  //     setEquityInvestmentStatus(status);
  //     setFields((prevState) => ({
  //       ...fields,
  //       payment_type: 'Receipt',
  //       gross_amount: (gross * -1).toFixed(2),
  //       net_amount: (gross * -1).toFixed(2),
  //     }));
  //   } else {
  //     let status: any = gross < 0 ? 'Credit' : 'Debit';
  //     setEquityInvestmentStatus(status);
  //     setFields((prevState) => ({
  //       ...fields,
  //       payment_type: 'Payment',
  //       gross_amount: gross.toFixed(2),
  //       net_amount: gross.toFixed(2),
  //     }));
  //   }
  // };

  const addUploadedSecurityInArray = (tempData) => {
    securites.push(tempData);
    setSecurites(securites);
    let gross = 0, net = 0, sst = 0, brokercomission = 0;
    securites.map((item, index) => {
      if (item.type === 'Sell') {
        net = net - parseFloat(item.net_amount);
        gross = gross - parseFloat(item.gross_amount);
      } else {
        net = net + parseFloat(item.net_amount);
        gross = gross + parseFloat(item.gross_amount);
      }
      sst = sst + parseFloat(item.sst_on_commission);
      brokercomission = brokercomission + parseFloat(item.commission_charges);
    });
    if (net < 0) {
      let status: any = net * -1 < 0 ? 'Credit' : 'Debit';
      setEquityInvestmentStatus(status);
      setFields((prevState) => ({
        ...fields,
        payment_type: 'Receipt',
        gross_amount: (gross * -1).toFixed(2),
        net_amount: (net * -1).toFixed(2),
        tax_sst: (sst).toFixed(2),
        broker_commission: (brokercomission).toFixed(2),
      }));
    } else {
      let status: any = net < 0 ? 'Credit' : 'Debit';
      setEquityInvestmentStatus(status);
      setFields((prevState) => ({
        ...fields,
        payment_type: 'Payment',
        gross_amount: gross.toFixed(2),
        net_amount: net.toFixed(2),
        tax_sst: sst.toFixed(2),
        broker_commission: brokercomission.toFixed(2),
      }));
    }
  };

  const setDataFunction = (temp: any) => {
    temp.map((item, index) => {
      let obj = {
        gross_amount: item.Value,
        net_amount: item.Value,
        market: item.MarketType,
        type: item.BuySell === 'B' ? 'Buy' : 'Sell',
        symbol: item.Symbol,
        quantity: item.Volume,
        avg_rate: item.AveragePrice,
        commission_charges: '0',
        commission_rate: (parseFloat('0') / parseFloat(item.Volume === '-' ? item.Volume.substring(1) : item.Volume)).toFixed(2),
        sst_on_commission: (0 * 13 / 100).toFixed(2),
        net_rate: (parseFloat(item.AveragePrice) - (parseFloat('0') / parseFloat(item.Volume === '-' ? item.Volume.substring(1) : item.Volume))).toFixed(2)
      };
      addUploadedSecurityInArray(obj);
    });
  };
  // async function processLineByLine(fileObj) {
  //   const fileStream = fs.createReadStream(fileObj?.path);
  //   const rl = readline.createInterface({
  //     input: fileStream,
  //     crlfDelay: Infinity,
  //   });
  //   var data = [] as any;
  //   var i = 0;
  //   let code = '';
  //   let date = '';
  //   let column : Array<any> = [];
  //   for await (var line of rl) {
  //     line = line.replace(/"/g, '');
  //     line = line.trim();
  //     let str = line.split(':');
  //     if (i == 0) {
  //       date = str[1];
  //       code = str[2];
  //     }
  //     if (i == 1) {
  //       column = str.map(function (x) {
  //         return x.replace(/ /g, '');
  //       });
  //       column = column.map(function (x) {
  //         return x.replace('/', '');
  //       });
  //     }
  //     if (i >= 2) {
  //       let obj = {
  //         [column[0]]: str[0],
  //         [column[1]]: str[1],
  //         [column[2]]: str[2],
  //         [column[3]]: str[3],
  //         [column[4]]: str[4],
  //         [column[5]]: str[5],
  //         [column[6]]: str[6],
  //         [column[7]]: str[7],
  //         [column[8]]: str[8],
  //         [column[9]]: str[9],
  //         [column[10]]: str[10],
  //         [column[11]]: str[11],
  //       };
  //       data.push(obj);
  //     }
  //     i++;
  //   }
  //   setDataFetch(data);
  //   setDataFunction(data);
  // }

  async function processLineByLine(fileObj) {
    var reader = new FileReader();
    reader.readAsText(fileObj);
    let content: any = [];
    reader.onload = function (fileObj) {
      content = reader.result;
      content = content.split('\n');
      let headings = content[0].split(':')
      let code = headings[2].replace(/"/g, '');
      let date = headings[1].replace(/"/g, '');
      moment.defaultFormat = "DD/MM/YYYY";
      //  if(code===fundCDCParticipantID && moment(date,moment.defaultFormat).format('YYYY-MM-DD')===fields['execution_date']){
      if (code === fundCDCParticipantID && moment(date).format('YYYY-MM-DD') === fields['execution_date']) {
        let column = [] as any;
        var data = [] as any;
        var i = 0;
        let TotalLine = content.length - 1;
        for (i = 0; i < TotalLine; i++) {
          content[i] = content[i].replaceAll('"', '').trim();
          let str = content[i].split(':');
          if (i == 1) {
            column = str.map(function (x) {
              return x.replace(/ /g, '');
            });
            column = column.map(function (x) {
              return x.replace('/', '');
            });
          }
          if (i >= 2) {
            let obj = {
              [column[0]]: str[0],
              [column[1]]: str[1],
              [column[2]]: str[2],
              [column[3]]: str[3],
              [column[4]]: str[4],
              [column[5]]: str[5],
              [column[6]]: str[6],
              [column[7]]: str[7],
              [column[8]]: str[8],
              [column[9]]: str[9],
              [column[10]]: str[10],
              [column[11]]: str[11],
            };
            data.push(obj);
          }
        }
        setDataFetch(data);
        setDataFunction(data);
      } else {
        setFileName('');
        setFile('');
        toast.error("Invalid file for the selected fund or you are uploading previous file.")
        // toast.error("Invalid file for the selected fund.")
      }
    }
  }

  const uploadCDCFile = (e: any) => {
    securites.length = 0;
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (type === 'txt') {
      setFileName(file.name);
      setFile(file);
      processLineByLine(file);
    } else {
      toast.error('Please Upload Correct Format of File');
    }
  };


  const [viewBankBalanceModal, setViewBankBalanceModal] = useState(false);
  const [underStand, setUnderStand] = useState(false)
  // popup for bank balance
  const renderModalForBankBalance = () => {
    switch (viewBankBalanceModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setUnderStand(false);
              setViewBankBalanceModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setLoading(false)
                  // setUnderStand(false);
                  setViewBankBalanceModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Bank Balance</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div></div> Balance
                    <input type="number"
                      value={bankBalance || '0'}
                      readOnly
                      className="form-control w-100" />

                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    Detail*
                    <input
                      type="text"
                      defaultValue={fields['detail']}
                      className={
                        'form-control w-100 '
                      }
                      onChange={(e) => {
                        setFields({
                          ...fields,
                          detail: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-inline">
                    <div className="form-group mr-2">
                      <input
                        type="checkbox"
                        // defaultValue={underStand}
                        // className={
                        //   'form-control w-100 '
                        // }
                        onChange={(e) => {
                          setLoading(false)
                          setUnderStand(!underStand)
                        }}
                      />
                    </div>
                    <span>I understand</span>
                  </div>
                </div>
              </div>
              {underStand === true ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <button className="btn btn-primary btn-block"
                        disabled={Boolean(Loading)}
                        onClick={() => {
                          //   setFields({
                          //     ...fields,
                          //     low_balance_agree: 'Yes',
                          //   });
                          fields.low_balance_agree = 'Yes';
                          setFields(fields);
                          createTransaction();

                        }}>
                        {Loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Proceed</span>
                        )}
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button className="btn btn-info btn-block" onClick={() => {
                        setUnderStand(false);
                        setViewBankBalanceModal(false);
                      }}>
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              {/* </div> */}
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  let [currentHolding, setCurrentHolding] = useState('');

  const setFolioNoDataForStep2 = (selected, funCode) => {
    count = count + 1;
    setAccTiltleName(`${selected}-${count}`);
    if (selected !== 'Other') {
      // if user select any account from dropdown then data fillout
      unitHolderData.map((item, index) => {
        if (item.folio_no) {
          if (item.folio_no === selected) {
            // fields['unitholder_name'] = item.name;
            setFields({
              ...fields,
              unitholder_name: item.name
            })
            if (
              item.balance_unit !== '' &&
              item.balance_unit !== '[]' &&
              item.balance_unit !== undefined
            ) {
              let unitBlance = JSON.parse(item.balance_unit);
              if (unitBlance && typeof unitBlance !== 'number') {
                const temp = unitBlance.filter((element) => {
                  if (element.fund_code.trim() === funCode) {
                    return element;
                  }
                });

                if (temp.length > 0) {
                  setAccTiltleName(temp[0].fund_unit.toString().trim().toString());
                  // fields['current_holding'] = parseFloat(
                  //   temp[0].fund_unit
                  // ).toFixed(4);
                  // setFields(fields);
                  setFields({
                    ...fields,
                    current_holding: parseFloat(temp[0].fund_unit.toString().trim()).toFixed(4)
                  })
                  currentHolding = parseFloat(temp[0].fund_unit.toString().trim()).toFixed(4);
                  setCurrentHolding(currentHolding);
                } else {
                  toast.error('Zero Units of selected Fund', {
                    position: 'top-right',
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                  });
                  setAccTiltleName(`${temp.length + index}`);
                  // fields['current_holding'] = '0.00';
                  // setFields(fields);
                  setFields({
                    ...fields,
                    current_holding: '0.00'
                  })
                  // setCurrentHolding('0.00');
                  currentHolding = '0.00';
                  setCurrentHolding(currentHolding);
                }
              }
            } else {
              //empty
              toast.error('Zero Units of selected Fund', {
                position: 'top-right',
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
              });
              // fields['current_holding'] = '0.00';
              setFields({
                ...fields,
                current_holding: '0.00'
              })
              currentHolding = '0.00';
              setCurrentHolding(currentHolding);
              count = count + 1;
              setAccTiltleName(`${selected}-${count}-${selected}`);
            }
          }
        }
      });
    } else {
      // fields['current_holding'] = '0.00';
      setFields({
        ...fields,
        current_holding: '0.00'
      })
      currentHolding = '0.00';
      setCurrentHolding(currentHolding);
      setAccTiltleName(`${count}-${selected}`);
    }
  };

  const [finishFlag, setFinishFlag] = useState(false);

  const Next = async () => {
    const isValid = await validate(); // check validation for Fields Data
    if (isValid) {
      setFields({
        ...fields,
        txnr_type: 'conversionout'
      })
      fields['txnr_type'] = 'conversionout';
      setFields(fields);
      if (parseFloat(fields['net_amount']) < 0 || parseFloat(fields['gross_amount']) < 0 || parseFloat(fields['units']) < 0 || parseFloat(fields['remain_holding']) < 0) {
        toast.error("Amounts or units or remain holding are negative");
      } else if (fields['txnr_type'] === 'conversionout' && +fields['units'] === 0) {
        toast.error(" Units cannot be zero.")
      } else {
        sessionStorage.setItem('Txn1', JSON.stringify(fields));
        setFinishFlag(true);
        history.replace(`/admin/beta-transaction/conversionin`);
      }
    } else {
      setErrorShow(true);
      toast.error('Kindly Fill All the Fields');
    }
  }

  React.useEffect(() => {
    if (finishFlag) {
      let data: string = sessionStorage.getItem('Txn1') || '';
      let prevTxnData = JSON.parse(data);
      // for conversion of units 2nd step
      setFolioNoDataForStep2(fields['folio_no'], prevTxnData.counter_type);
      setFields({
        ...fields,
        fund_code: prevTxnData.counter_type,
        fund_account: prevTxnData.counter_account_type,
        account_title: prevTxnData.counter_account_title,
        account_no: prevTxnData.counter_account_number,
        bank: prevTxnData.counter_bank,
        branch: prevTxnData.counter_branch,
        counter_type: prevTxnData.fund_code,
        counter_account_type: prevTxnData.fund_account,
        counter_account_title: prevTxnData.account_title,
        counter_account_number: prevTxnData.account_no,
        counter_bank: prevTxnData.bank,
        counter_branch: prevTxnData.branch,
        conversion_date: prevTxnData.redemption_date,
        redemption_date: '',
        units: '',
        nav: '',
        percentage_of_discount: '',
        load_per_unit: '',
        offer_price: '',
        applicable_offer_price: '',
        current_holding: currentHolding,
        total_holding: '',
        redemption_load: '',
        tax_cgt: '',
        zakat_amount: '',
        gross_amount: prevTxnData.net_amount,
        net_amount: '',
        txnr_type: 'conversionin',
        payment_type: 'Receipt'
      })
      setUnitsSeparator('');
      setGrossAmountSeparator('');
      setGrossAmountInWords('');
      setNetAmountSeparator('');
      setNetAmountInWords('');
    }
  }, [finishFlag])

  const Finish = async () => {
    setLoading(true);
    const isValid = await validate(); // check validation for Fields Data
    if (isValid) {
      if (parseFloat(fields['net_amount']) < 0 || parseFloat(fields['gross_amount']) < 0 || parseFloat(fields['units']) < 0 || parseFloat(fields['remain_holding']) < 0) {
        toast.error("Amounts or units or remain holding are negative");
      } else if (fields['txnr_type'] === 'conversionin' && +fields['units'] === 0) {
        toast.error("Units cannot be zero.")
      } else {
        let data: string = sessionStorage.getItem('Txn1') || '';
        let txnsData: any = [];
        txnsData.push(JSON.parse(data));

        txnsData[0].folio_no = txnsData[0].folio_no.replaceAll(txnsData[0].amc_code + '-', '')
        txnsData[0].folio_no = txnsData[0].amc_code + '-' + txnsData[0].folio_no;

        fields['folio_no'] = fields['folio_no'].replaceAll(fields['amc_code'] + '-', '')
        fields['folio_no'] = fields['amc_code'] + '-' + fields['folio_no'];
        txnsData.push(fields);
        const response = await addConversionOfUnitsTransaction(email, txnsData)
        toast.success(response.data.message)
        sessionStorage.removeItem('Txn1');
        setTimeout(() => {
          history.replace(`/admin/transaction-types`);
        }, 3000);
      }
    } else {
      setErrorShow(true);
      toast.error('Kindly Fill All the Fields');
    }
    setLoading(false);
  }

  React.useEffect(() => {
    if (headLabel === 'saleofunit') {
      setFields({
        ...fields,
        counter_account_type: propsState.folioNo.replaceAll(propsState.amc_code + '-', ''),
        folio_no: propsState.folioNo,
        unitholder_name: propsState.unitholder,
        counter_account_title: propsState.account_title,
        counter_account_number: propsState.account_no,
        counter_bank: propsState.bank,
        counter_branch: propsState.branch
      })
    } else if (headLabel === 'conversionin') {
      setFields({
        ...fields,
        // counter_account_type:'Other',
        folio_no: propsState.folioNo.replaceAll(propsState.amc_code + '-', ''),
        unitholder_name: propsState.unitholder,
        // counter_account_title: propsState.account_title,
        // counter_account_number:propsState.account_no,
        // counter_bank:propsState.bank,
        // counter_branch:propsState.branch
      })
    }
    setViewModalAddNewUnitHolder(false);
  }, [propsState])

  const renderModalAddNewUnitHolder = () => {
    switch (viewModalAddNewHolder) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => {
              setViewModalAddNewUnitHolder(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewModalAddNewUnitHolder(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Add UnitHolder</h6>
            </div>
            <div className="modal-body">
              <>
                <AddUnitHolder setPropState={setPropsState} />
              </>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  }


  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer />
              {/* <Header /> */}
              <div className="">
                <div className="input-holder">
                  <div className="row">
                    <h4 className=" card-title">
                      {/* {cardType} */}
                      <i className="fa fa-angle-left mr-2 ml-3 mt-1 pointer" onClick={() => {
                        history.replace('/admin/create-transaction')
                      }}></i>
                      {CapTxnType(headLabel)} Transaction</h4>
                  </div>

                  {/* <Link to="/admin/bulk-upload" className="btn btn-primary btn-sm mr-3" replace>
                     <i className="fa fa-upload mr-2"></i> Bulk Upload
               </Link>  */}

                  {headLabel === 'unitconversion' && (
                    <span>Step<span className='text-primary'> 1</span> of 2</span>
                  )}
                  {finishFlag === true && (
                    <span>Step<span className='text-primary'> 2</span> of 2</span>
                  )}
                  <span>
                    <Link to="/admin/bulk-upload" className="btn btn-primary btn-sm mr-3" replace>
                      <i className="fa fa-upload mr-2"></i> Bulk Upload
                    </Link>
                    <Link to='/admin/transaction-types'>
                      <IoMdClose size={35}
                        style={styles}
                        onMouseEnter={() => setColor("white")}
                        onMouseLeave={() => setColor("#db6cfa")}
                      // onClick={() => {
                      //   history.replace('/admin/transaction-types')
                      // }}
                      />
                    </Link>
                  </span>

                </div>
                <div className=" ">

                  <Row >
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Instruction</h4>
                        </div>
                        <div className="card-body">
                          <div>
                            {/* fund dropdown , itx value depend on amc value  */}
                            <div
                              className="form-group "
                              data-tip="Select Fund"
                            >
                              Fund Name*
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />
                              {accFundLoading ? (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x" ></i>
                                </div>
                              ) : (
                                <select
                                  className={
                                    'form-control w-100 ' +
                                    ErrorBorder(fields['fund_code'])
                                  }
                                  value={fields['fund_code']}
                                  disabled={finishFlag}
                                  onChange={(e) => {
                                    fields['fund_code'] = e.target.value;
                                    setFields(fields);

                                    clearFundAccountFields();
                                    getAccountByFundName(e.target.value);
                                    if (headLabel === 'unitconversion') {
                                      let filterToAcc = allFunds.filter(
                                        (acc) => acc.symbol_code !== e.target.value
                                      );
                                      let temp = filterToAcc.map((val) => {
                                        if (val.nature === 'Special Managed Account') {
                                          return {
                                            ...val,
                                            label: `${val.fund_name} (${val.symbol_code}) - ${'SMA'} `,
                                            value: val.symbol_code,
                                          };
                                        } else {
                                          return {
                                            ...val,
                                            label: `${val.fund_name} (${val.symbol_code})`,
                                            value: val.symbol_code,
                                          };
                                        }
                                      });
                                      setAllCounterFunds(temp);
                                    }

                                    if (headLabel === 'equityinvestment') {
                                      let filter_CDC_Participant_ID = allFunds.filter(
                                        (acc) => acc.symbol_code == e.target.value
                                      );
                                      setCDCParticipantID(filter_CDC_Participant_ID[0].cdc_participant_id)
                                    }

                                    if (headLabel === 'cashdividend' || headLabel === 'pledgedin' || headLabel === 'pledgedout') {
                                      {
                                        getFundStockMarketData(e.target.value)
                                      }
                                    }
                                    if (headLabel === 'transferofunits') {
                                      fields['folio_no'] = '';
                                      fields['unitholder_name'] = '';
                                      fields['current_holding'] = '';
                                      fields['remain_holding'] = '';
                                      setFields(fields);
                                      setTransferees([]);
                                    }
                                    if (headLabel === 'investmentinfund') {
                                      securites.length = 0;
                                      setSecurites(securites);
                                    }
                                    // // clear counter account
                                    // if(headLabel)
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {fundCheck ? 'No Fund Found' : 'Select Fund'}
                                  </option>
                                  {renderFundsDropdown()}
                                </select>
                              )}
                            </div>
                            <div className="form-group">
                              <div>Instruction Date</div>
                              <input
                                type="date"
                                value={fields['instruction_date']}
                                readOnly
                                className={
                                  'form-control w-100 ' +
                                  ErrorBorder(fields['instruction_date'])
                                }
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    instruction_date: e.target.value,
                                    //realized_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="form-group">
                              {/* <div>Execution Date</div> */}
                              <div>Settlement Date</div>
                              <input
                                type="date"
                                value={fields['execution_date']}
                                min={disableDates}
                                readOnly={finishFlag}
                                className={'form-control w-100' + ErrorBorder(fields['execution_date'])
                                }
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    execution_date: e.target.value,
                                    settlement_date: e.target.value
                                  });
                                }}
                              />
                            </div>
                            {headLabel !== 'pledgedin' && headLabel !== 'pledgedout' && headLabel !== 'transferofunits' && (
                              <div className="form-group">
                                <div>Mode of Payment*</div>
                                <select
                                  value={fields['mode_of_payment']}
                                  className={
                                    'form-control w-100 ' +
                                    ErrorBorder(fields['mode_of_payment'])
                                  }
                                  disabled={finishFlag}
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      mode_of_payment: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Payment
                                  </option>
                                  {renderModeOfPayments()}
                                </select>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>

                    {(headLabel === 'pledgedin' || headLabel === 'pledgedout') && (
                      <Col>
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">{CapTxnType(headLabel)}</h4>
                          </div>
                          <div className="card-body">
                            <div>

                              {chechFieldDisplay(headLabel, 'symbol') && (
                                <div className="form-group">
                                  <div>Symbol*</div>
                                  <select
                                    className={
                                      'form-control w-100 ' + ErrorBorder(fields['symbol'])
                                    }
                                    defaultValue={fields['symbol']}
                                    onChange={(e) => {
                                      if (headLabel === 'pledgedin') {
                                        let vol = stockMarketDataFund.filter((item, index) => {
                                          return (item.code === e.target.value);
                                        });
                                        let plegged = +vol[0].unit - +vol[0].pledged
                                        setPledgedHolding(plegged.toString());
                                        setFields({
                                          ...fields,
                                          symbol: e.target.value,
                                        });

                                      } else if (headLabel === 'pledgedout') {
                                        let vol = stockMarketDataFund.filter((item, index) => {
                                          return (item.code === e.target.value);
                                        });
                                        setPledgedHolding(vol[0].pledged);
                                        setFields({
                                          ...fields,
                                          symbol: e.target.value,
                                        });
                                      } else {
                                        let vol = stockMarketDataFund.filter((item, index) => {
                                          return (item.code === e.target.value);
                                        })
                                        setFields({
                                          ...fields,
                                          volume: vol[0].unit,
                                          symbol: e.target.value,
                                        });
                                      }
                                      // let vol= stockMarketDataFund.filter((item, index)=>{
                                      // return (item.code===e.target.value);
                                      // })
                                      // setVolume(vol[0].unit);
                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      {' '}
                                      Select Type
                                    </option>
                                    {renderStockMarketData()}
                                  </select>
                                </div>
                              )}
                              {(headLabel === 'pledgedin' || headLabel === 'pledgedout') && (
                                <div className="form-group">
                                  <div>Available for {CapTxnType(headLabel)}</div>
                                  <input
                                    type="number"
                                    defaultValue={pledged_holding}
                                    className={
                                      'form-control w-100 '
                                    }
                                    readOnly
                                  />
                                </div>
                              )}

                              {chechFieldDisplay(headLabel, 'volume') && (
                                <div className="form-group">
                                  <div>Shares to {CapTxnType(headLabel)}</div>
                                  <input
                                    type="number"
                                    value={fields['volume']}
                                    className={
                                      'form-control w-100 ' + ErrorBorder(fields['volume'])
                                    }
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      if (headLabel === 'pledgedin') {
                                        let pledged = parseInt(pledged_holding) - parseInt(value);
                                        if (pledged > 0) {
                                          setFields({
                                            ...fields,
                                            volume: value,
                                          });
                                        }
                                      } else {
                                        if (parseInt(value) <= parseFloat(pledged_holding) || value === '') {
                                          setFields({
                                            ...fields,
                                            volume: value,
                                          });
                                        }
                                      }
                                    }}
                                  />
                                </div>
                              )}


                            </div>
                          </div>
                        </div>
                      </Col>
                    )}

                    {(headLabel !== 'pledgedin' && headLabel !== 'pledgedout' && headLabel !== 'transferofunits') && (
                      <Col>
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              {headLabel === 'fundtransfer'
                                ? 'From Account'
                                : 'Fund Account'}
                            </h4>
                          </div>
                          <div className="card-body">
                            <div>
                              {/* hide field in conversion of units next button */}
                              {finishFlag === false && (
                                <div className="form-group"
                                // data-tip="First Select Fund Name"
                                >
                                  <div>Fund Account*</div>
                                  {accLoading ? (
                                    <div className="form-control w-100">
                                      <i className="fa fa-spinner fa-spin fa-1x" ></i>
                                    </div>
                                  ) : fields['fund_code'].trim() === '' ? (
                                    <div className="form-control    w-100">
                                      Kindly First Select Fund
                                    </div>
                                  ) : (
                                    <Select
                                      className="react-select info"
                                      classNamePrefix="react-select"
                                      label={'Select'}
                                      isDisabled={false}
                                      onChange={(e) => {
                                        // fields['fund_account'];
                                        setFields(fields);
                                        setFundAccountFieldsData(e.value);
                                        if (headLabel !== 'inflow') {
                                          let filterToAcc = accountNoData.filter(
                                            (acc) => acc.account_code !== e.value
                                          );
                                          setToAccountData(filterToAcc);
                                        }
                                      }}
                                      value={accountNoData.filter((option) =>
                                        option.value.replace('ACCOUNT_', '') === fields['fund_account']
                                      )}
                                      isClearable={false}
                                      isSearchable={true}
                                      name="color"
                                      options={accountNoData}
                                    />
                                  )}
                                </div>
                              )}

                              {/* in conversion of units, show fields on next button */}
                              {finishFlag === true && (
                                <div className="form-group">
                                  <div>Fund Account*</div>
                                  <input
                                    type="text"
                                    // min={fields['execution_date_duplicate']}
                                    min={disableDates}
                                    value={fields['fund_code']}
                                    className={
                                      'form-control w-100 ' +
                                      ErrorBorder(fields['execution_date'])
                                    }
                                    readOnly={finishFlag}
                                  />
                                </div>
                              )}

                              <div className="form-group">
                                <div>Account Title*</div>
                                <input
                                  type="text"
                                  value={fields.account_title}
                                  className={
                                    'form-control w-100 ' +
                                    ErrorBorder(fields['account_title'])
                                  }
                                  readOnly
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      account_title: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <div>Account Number*</div>
                                <input
                                  type="text"
                                  value={fields['account_no']}
                                  className={
                                    'form-control w-100 ' + ErrorBorder(fields['account_no'])
                                  }
                                  readOnly
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      account_no: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                              <div className="form-group w-100 d-flex">
                                <div className="w-50 flex mr-2">
                                  <div>Bank</div>
                                  <div className="form-group">
                                    <input className="form-control"
                                      value={fields['bank']}
                                      readOnly
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          bank: e.target.value,
                                        });
                                      }} />
                                  </div>
                                </div>
                                <div className="w-50 flex">
                                  <div>Branch</div>
                                  <div className="form-group">
                                    <input className="form-control"
                                      value={fields['branch']}
                                      readOnly
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          branch: e.target.value,
                                        });
                                      }} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}

                    {(headLabel === 'transferofunits') && (
                      <Col>
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              Transferor
                            </h4>
                          </div>
                          <div className="card-body">
                            <ReactTooltip
                              textColor="white"
                              backgroundColor="black"
                              effect="float"
                            />
                            <Row>
                              <Col md='9'>
                                <div className="form-group">
                                  <div>Folio Number</div>
                                  <input
                                    type="text"
                                    value={fields['folio_no'].replaceAll(`${fields['amc_code']}-`, '')}
                                    placeholder='Enter Folio No.'
                                    data-tip="Press Enter key to load data."
                                    className={
                                      'form-control w-100 ' +
                                      ErrorBorder(fields['folio_no'])
                                    }
                                    onChange={(e) => {
                                      fields['unitholder_name'] = '';
                                      fields['current_holding'] = '';
                                      fields['remain_holding'] = '';
                                      setFields(fields);
                                      setTransferees([]);
                                      setFields({
                                        ...fields,
                                        folio_no: e.target.value.replaceAll(`${fields['amc_code']}-`, ''),
                                      });
                                      // setCounterFieldsData(e.target.value);
                                    }}
                                    onKeyPress={async (e) => {
                                      if (e.key === 'Enter' && unitHolderFieldReadOnly === false) {
                                        await getUnitHoldersByAmcFolioNoTranferor(email, fields['amc_code'], fields['amc_code'] + '-' + fields['folio_no']);
                                      }
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="1">
                                <br />
                                <div>
                                  <button className="btn btn-default btn-sm"
                                    disabled={Boolean(unitHolderFieldReadOnly)}
                                    onClick={async () => {
                                      await getUnitHoldersByAmcFolioNoTranferor(email, fields['amc_code'], fields['amc_code'] + '-' + fields['folio_no']);
                                    }}>
                                    {unitHolderFieldReadOnly ? (
                                      <>
                                        <span
                                          className="spinner-border login-txt spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        {/* <span className="login-txt"> Search</span> */}
                                        <i className="fa fa-search"></i>
                                      </>
                                    ) : (
                                      // <span>Search</span>
                                      <i className="fa fa-search"></i>
                                    )}
                                  </button>
                                </div>
                              </Col>
                              <Col md='2'></Col>
                            </Row>

                            <div className="form-group">
                              <div>Name</div>
                              <input
                                type="text"
                                value={fields['unitholder_name']}
                                className={
                                  'form-control w-100 ' +
                                  ErrorBorder(fields['unitholder_name'])
                                }
                                readOnly
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    unitholder_name: e.target.value,
                                  });
                                  // setCounterFieldsData(e.target.value);
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <div>Current Holding</div>
                              <input
                                type="text"
                                value={fields['current_holding']}
                                data-tip="Press Enter key to load data."
                                className={
                                  'form-control w-100 ' +
                                  ErrorBorder(fields['current_holding'])
                                }
                                readOnly
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    current_holding: e.target.value,
                                  });
                                  // setCounterFieldsData(e.target.value);
                                }}
                              />
                            </div>
                            <div className="form-group">
                              <div>Remaining Holding</div>
                              <input
                                type="text"
                                value={fields['remain_holding']}
                                data-tip="Press Enter key to load data."
                                className={
                                  'form-control w-100 ' +
                                  ErrorBorder(fields['remain_holding'])
                                }
                                readOnly
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    remain_holding: e.target.value,
                                  });
                                  // setCounterFieldsData(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}

                    {(headLabel === 'transferofunits') && (
                      <Col md='4'>
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              Transferees
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <div>Add Transferees</div>
                                  <button className=" btn-round btn-icon btn btn-success" onClick={() => {
                                    setTransfereeName('');
                                    setTransfereesFolioNo('');
                                    setTransfereesEntity((prevState) => ({
                                      ...transfereesEntity,
                                      folio_no: '',
                                      name: '',
                                      transferees_unit_transfer: '',
                                    }));
                                    setViewTransfereesModall(!viewTransfereesModal);
                                  }}> <i className="fa fa-plus"></i> </button>
                                  {transferees.length > 0 && (
                                    <>
                                      <div className="table-responsive">
                                        <div className="form-group">
                                          <table className="table  ">
                                            <thead>
                                              <tr>
                                                <th>Folio Number</th>
                                                <th>Name</th>
                                                <th className='text-right'>Transfer Unit</th>
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>{renderTransfereesData()}</tbody>
                                          </table>
                                          <div className="ml-3" >
                                            <button className='btn btn-primary' onClick={() => {
                                              transferees.length = 0;
                                              setTransfereesEntity(transferees);
                                              fields['remain_holding'] = fields['current_holding'];
                                            }}>Clear All</button>
                                          </div>
                                        </div></div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}

                    {(headLabel !== 'pledgedin' && headLabel !== 'pledgedout' && headLabel !== 'transferofunits') && (
                      <Col>
                        <div className="card">
                          <div className="card-header">
                            {/* condition to for label selection */}
                            <h4 className="card-title">
                              {headLabel === 'fundtransfer'
                                ? 'To Account'
                                : 'Counter Account'}
                              {(headLabel === 'saleofunit' || headLabel === 'conversionin') && (
                                // <>
                                <button className='btn btn-default btn-sm float-right'
                                  title="Add New Unit-Holder"
                                  onClick={() => {
                                    if (fields['amc_code'] !== '') {
                                      setViewModalAddNewUnitHolder(true);
                                    } else {
                                      toast.error('First select AMC');
                                    }
                                  }}>
                                  <i className="fa fa-plus"></i>
                                </button>
                                // </>

                              )}
                            </h4>
                          </div>
                          <div className="card-body">
                            <div>
                              {/* start of fund selection in the case of Unit Conversion Transaction */}
                              {headLabel === 'unitconversion' && finishFlag === false && (
                                <div className="form-group w-100 d-flex">
                                  <div
                                    className="w-50 flex"
                                    data-tip="First Select Amc"
                                  >
                                    <div>Fund Name*</div>
                                    <ReactTooltip
                                      textColor="white"
                                      backgroundColor="black"
                                      effect="float"
                                    />
                                    {accFundLoading ? (
                                      <div className="form-control w-100">
                                        <i className="fa fa-spinner fa-spin fa-1x" ></i>
                                      </div>
                                    ) : fields['amc_code'].trim() === '' ? (
                                      <div className="form-control    w-100">
                                        First Select AMC
                                      </div>
                                    ) : (
                                      <Select
                                        className="react-select info"
                                        classNamePrefix="react-select"
                                        label={'Select'}
                                        isDisabled={false}
                                        onChange={(e) => {
                                          getCounterFundAccountByFundName(e.value);
                                          allCounterFunds.map((item) => {
                                            if (item.symbol_code === e.value) {
                                              setUnitNav(item.nav);
                                            }
                                          });
                                          fields['counter_type'] = e.value;
                                        }}
                                        isClearable={false}
                                        isSearchable={true}
                                        name="color"
                                        options={allCounterFunds}
                                        menuPortalTarget={document.body}
                                        menuShouldBlockScroll={true}
                                      />
                                    )}
                                  </div>
                                  <div className="w-50 flex">
                                    <div>Fund Account</div>
                                    {counterFundLoading ? (
                                      <div className="form-control w-100">
                                        <i className="fa fa-spinner fa-spin fa-1x" ></i>
                                      </div>
                                    ) : (
                                      <Select
                                        className="react-select info"
                                        classNamePrefix="react-select"
                                        label={'Select'}
                                        isDisabled={false}
                                        onChange={(e) => {
                                          setFields({
                                            ...fields,
                                            counter_account_type: e.value,
                                          });
                                          setFundAccountDataCounter(e.value);
                                        }}
                                        value={allCounterFundsAccounts.filter(
                                          (option) =>
                                            option.value ===
                                            fields['counter_account_type']
                                        )}
                                        isClearable={false}
                                        isSearchable={true}
                                        name="color"
                                        options={allCounterFundsAccounts}
                                        menuPortalTarget={document.body}
                                        menuShouldBlockScroll={true}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}

                              {finishFlag === true && (
                                <div className="form-group w-100 d-flex">

                                  <div
                                    className="w-50 flex"
                                  >
                                    <div>Fund Name</div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={fields['counter_type']}
                                      readOnly={finishFlag}
                                    />
                                  </div>
                                  <div className="w-50 flex">
                                    <div>Fund Account</div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={fields['counter_account_type']}
                                      readOnly={finishFlag}
                                    />
                                  </div>
                                </div>
                              )}

                              {headLabel !== 'unitconversion' && (
                                <div className="form-group">
                                  <ReactTooltip
                                    textColor="black"
                                    backgroundColor="white"
                                    effect="float"
                                  />

                                  {finishFlag === false && ( // conversion of unit is selected and click on next button then is title will not show.
                                    <div className="text-capitalize">
                                      {counterAccounter === 'Fund'
                                        ? 'Fund Account'
                                        : counterAccounter}
                                    </div>
                                  )}

                                  {counterAccounter === 'Unit Holder' ? (
                                    //For Unit Holder Counter Account
                                    fields['fund_code'].trim() === '' ? (
                                      <div className="form-control    w-100">
                                        Kindly First Select Fund
                                      </div>
                                    ) : (
                                      <>
                                        <Row>
                                          <Col md="9">
                                            <ReactTooltip
                                              textColor="white"
                                              backgroundColor="black"
                                              effect="float"
                                            />
                                            <div className="form-group">
                                              <div></div>
                                              <input
                                                type="text"
                                                value={fields['counter_account_type']}
                                                placeholder='Enter Folio No.'
                                                data-tip="Press Enter key to load data."
                                                className={
                                                  'form-control w-100 ' +
                                                  ErrorBorder(fields['counter_account_type'])
                                                }
                                                readOnly={unitHolderFieldReadOnly}
                                                onChange={(e) => {
                                                  setFields({
                                                    ...fields,
                                                    counter_account_type: e.target.value,
                                                  });
                                                }}
                                                onKeyPress={(e) => {
                                                  if (e.key === 'Enter' && unitHolderFieldReadOnly === false) {
                                                    getUnitHoldersByAmcFolioNo(email, fields['amc_code'], fields['amc_code'] + '-' + fields['counter_account_type']);
                                                  }
                                                }}
                                              />
                                            </div>
                                          </Col>
                                          <Col md="3">
                                            <div></div>
                                            <button className="btn btn-default btn-sm mt-a"
                                              disabled={Boolean(unitHolderFieldReadOnly)}
                                              onClick={() => {
                                                getUnitHoldersByAmcFolioNo(email, fields['amc_code'], fields['amc_code'] + '-' + fields['counter_account_type']);
                                              }}>
                                              {unitHolderFieldReadOnly ? (
                                                <>
                                                  <span
                                                    className="spinner-border login-txt spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                  ></span>
                                                  {/* <span className="login-txt"> Search</span> */}
                                                  <i className="fa fa-search"></i>
                                                </>
                                              ) : (
                                                // <span>Search</span>
                                                <i className="fa fa-search"></i>
                                              )}
                                            </button>
                                          </Col>
                                        </Row>
                                      </>
                                    )
                                  ) : counterAccounter === 'Fund' ? (
                                    //For fund
                                    <div data-tip="First Select Fund Name">
                                      <ReactTooltip
                                        textColor="white"
                                        backgroundColor="black"
                                        effect="float"
                                      />
                                      {accLoading ? (
                                        <div className="form-control w-100">
                                          <i className="fa fa-spinner fa-spin fa-1x" ></i>
                                        </div>
                                      ) : fields['fund_code'].trim() === '' ? (
                                        <div className="form-control    w-100">
                                          First Select Fund
                                        </div>
                                      ) : (
                                        <Select
                                          className="react-select info"
                                          classNamePrefix={"react-select " + ErrorBorder(fields['counter_account_type'])}
                                          label={'Select'}
                                          isDisabled={false}
                                          onChange={(e) => {
                                            setFields({
                                              ...fields,
                                              counter_account_type: e.value,
                                            });
                                            setFundDataCounter(e.value);
                                          }}
                                          value={toAccountData.filter(
                                            (option) =>
                                              option.value ===
                                              fields['counter_account_type']
                                          )}
                                          isClearable={false}
                                          isSearchable={true}
                                          name="color"
                                          options={toAccountData}
                                        />
                                      )}
                                    </div>
                                  ) : //For Other Counter Amc
                                    counterAccounter === 'amc' ? (
                                      accFundLoading ? (
                                        <div className="form-control w-100">
                                          <i className="fa fa-spinner fa-spin fa-1x" ></i>
                                        </div>
                                      ) : (
                                        <Select
                                          className="react-select info"
                                          classNamePrefix="react-select"
                                          label={'Select'}
                                          isDisabled={false}
                                          onChange={async (e) => {
                                            await setAmcAccountData(e.value);
                                            let taxCollector = '';
                                            if (e.value === 'Other') {
                                              taxCollector = 'Other'
                                            } else {
                                              let amcTaxCollector = amcdataCounter.filter((item) =>
                                                item.value === e.value)

                                              taxCollector = amcTaxCollector[0].tax_collector;
                                            }

                                            fields['counter_account_type'] = e.value;
                                            fields['sales_tax_collector'] = taxCollector;
                                            setFields(fields);

                                          }}
                                          value={amcdataCounter.filter(
                                            (option) =>
                                              option.value ===
                                              fields['counter_account_type']
                                          )}
                                          isClearable={false}
                                          isSearchable={true}
                                          name="color"
                                          options={amcdataCounter}
                                        />
                                      )
                                    ) : //For Other Counter Accounts

                                      counterAccounter === 'Other' ? (
                                        <input
                                          type="text"
                                          value={fields['counter_account_type']}
                                          className="form-control w-100"
                                          readOnly
                                        />
                                      ) : (
                                        //For Other Brokers
                                        finishFlag === false && ( // check the conversion of unit next button 
                                          <Select
                                            className="react-select info"
                                            classNamePrefix="react-select"
                                            label={'Select'}
                                            isDisabled={false}
                                            onChange={(e) => {
                                              setFields({
                                                ...fields,
                                                counter_account_type: e.value,
                                              });
                                              setBrokerData(e.value);
                                            }}
                                            value={borkerByTypeData.filter(
                                              (option) =>
                                                option.value ===
                                                fields['counter_account_type']
                                            )}
                                            isClearable={false}
                                            isSearchable={true}
                                            name="color"
                                            options={borkerByTypeData}
                                          />
                                        )
                                      )}
                                </div>
                              )}
                              <div className="form-group">
                                <div>Account Title</div>
                                <input
                                  type="text"
                                  value={fields['counter_account_title']}
                                  className={'form-control w-100 ' + ErrorBorder(fields['counter_account_title'])}
                                  readOnly={finishFlag || (headLabel == 'fundtransfer' || headLabel == 'unitconversion')}
                                  onChange={(e) => {
                                    if (e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value == '') {
                                      counterType = 'Other';
                                      setCounterType(counterType);
                                      setFields({
                                        ...fields,
                                        counter_account_title: e.target.value || '',
                                      });
                                    }
                                    // setFields({
                                    //   ...fields,
                                    //   counter_account_title: e.target.value,
                                    // });
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <div>Account Number</div>
                                <input
                                  type="text"
                                  value={fields['counter_account_number']}
                                  className={'form-control w-100 '}
                                  readOnly={finishFlag || (headLabel == 'fundtransfer' || headLabel == 'unitconversion')}
                                  onChange={(e) => {
                                    if (e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value == '') {
                                      counterType = 'Other';
                                      setCounterType(counterType);
                                      setFields({
                                        ...fields,
                                        counter_account_number: e.target.value,
                                      });
                                    }
                                    // setFields({
                                    //   ...fields,
                                    //   counter_account_number: e.target.value,
                                    // });
                                  }}
                                />
                              </div>
                              <div className="form-group w-100 d-flex">
                                <div className="w-50 flex mr-2">
                                  <div>Bank</div>
                                  {/* <div className="form-group">
                          <input className="form-control"
                              value={fields['counter_bank']}
                              onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    counter_bank: e.target.value,
                                  });
                                }} />
                                </div> */}
                                  <Select
                                    className="react-select info"
                                    classNamePrefix="react-select"
                                    label={'Select Bank'}
                                    // isDisabled={false}
                                    isDisabled={finishFlag}
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        counter_bank: e.value,
                                      });
                                      getCounterBranchByName(e.value);
                                      termDepositEntity['bank'] = e.value;
                                      setTermDepositEntity(termDepositEntity);
                                    }}
                                    value={bankNameData.filter(
                                      (option) =>
                                        option.value === fields['counter_bank']
                                    )}
                                    isClearable={false}
                                    isSearchable={true}
                                    name="color"
                                    options={bankNameData}
                                    menuPortalTarget={document.body}
                                  />
                                </div>

                                {finishFlag === true ? (
                                  <div className="w-50 flex">
                                    <div>Branch</div>
                                    <div className="d-flex">
                                      <input
                                        type="text"
                                        value={fields['counter_branch']}
                                        className="form-control w-100 "
                                        readOnly={finishFlag}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="w-50 flex mr-2">
                                    <div>Branch</div>
                                    <div className="form-group">
                                      {/* <input className="form-control"
                              value={fields['counter_branch']}
                              onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    counter_branch: e.target.value,
                                  });
                                  termDepositEntity['branch']=e.target.value;
                                  setTermDepositEntity(termDepositEntity);
                                }} /> */}

                                      {inputType ? (
                                        <div className="d-flex">
                                          <input
                                            type="text"
                                            value={fields['counter_branch']}
                                            className="form-control w-100 "
                                            readOnly
                                          />
                                        </div>
                                      ) : (
                                        <Select
                                          className="react-select info"
                                          classNamePrefix="react-select"
                                          label={'Select Branch'}
                                          // isDisabled={false}
                                          isDisabled={finishFlag}
                                          onChange={(e) => {
                                            if (e.value === 'Other') {
                                              setViewAddBranchCity(true)
                                            } else {
                                              setFields({
                                                ...fields,
                                                counter_branch: e.value,
                                              });
                                              onOtherSelection(e.value);
                                              termDepositEntity['branch'] = e.value;
                                              setTermDepositEntity(termDepositEntity);
                                            }
                                          }}
                                          value={allCounterbranchesData.filter(
                                            (option) =>
                                              option.value === fields['counter_branch']
                                          )}
                                          isClearable={false}
                                          isSearchable={true}
                                          name="color"
                                          options={allCounterbranchesData}
                                          menuPortalTarget={document.body}
                                          menuShouldBlockScroll={true}
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>


                  <Row>
                    {(headLabel !== 'pledgedin' && headLabel !== 'pledgedout' && headLabel !== 'transferofunits') && (
                      <Col>
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">Instrument</h4>
                          </div>
                          <div className="card-body">
                            <div>
                              <div className="form-group">
                                <div>Instrument Type</div>
                                <select
                                  className={'form-control w-100 '}
                                  value={fields['instrument_type']}
                                  disabled={finishFlag}
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      instrument_type: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Type
                                  </option>
                                  {renderiTypeDataDropdown()}
                                </select>
                              </div>
                              {fields.payment_type === 'Receipt' && (
                                <>
                                  <div className="form-group">
                                    <div>Instrument No./Reference No</div>
                                    <input
                                      defaultValue={fields['instrument_no']}
                                      type="text"
                                      className={'form-control w-100 '}
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          instrument_no: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <div>Instrument Date / Deposit Date</div>
                                    <input
                                      type="date"
                                      defaultValue={fields['instrument_date']}
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['instrument_date'])
                                      }
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          instrument_date: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </>
                              )}




                            </div>
                          </div>
                        </div>
                      </Col>
                    )}
                    {(headLabel !== 'pledgedmark' && headLabel !== 'pledgedrelease') && (
                      <Col md="4">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">Transaction Details</h4>
                          </div>
                          <div className="card-body">
                            {(headLabel !== 'transferofunits') && (
                              <div>
                                {(headLabel === 'unitconversion' ||
                                  // headLabel === 'redemptionofunits' ||
                                  headLabel === 'conversionin' ||
                                  headLabel === 'conversionout'
                                ) && (
                                    <>
                                      <ReactTooltip
                                        textColor="white"
                                        backgroundColor="black"
                                        effect="float"
                                      />
                                      {/* <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            label={'Select'}
                            // isDisabled={false}
                            isDisabled={finishFlag}
                            onChange={(e) => {
                              if (fields['fund_code']) {
                                fields['folio_no'] = e.value;
                                setFolioNoData(e.value);
                              } else {
                                toast.error('Kindly First select Fund');
                              }
                            }}
                            value={unitHolderData.filter(
                              (option) => option.value === fields['folio_no']
                            )}
                            isClearable={false}
                            isSearchable={true}
                            name="color"
                            options={unitHolderData}
                          /> */}
                                      <Row>
                                        <Col md="9">
                                          <div className="form-group">
                                            <div>Folio Number</div>
                                            <input
                                              type="text"
                                              placeholder='Enter Folio No.'
                                              data-tip="Press Enter key to load current holding."
                                              className={
                                                'form-control w-100 ' +
                                                ErrorBorder(fields['folio_no'])
                                              }

                                              // value={fields['folio_no']}
                                              value={fields['folio_no']}
                                              readOnly={unitHolderFieldReadOnly}
                                              onChange={(e) => {
                                                setFields({
                                                  ...fields,
                                                  folio_no: e.target.value,
                                                });
                                              }}
                                              onKeyPress={(e) => {
                                                if (e.key === 'Enter' && unitHolderFieldReadOnly === false) {
                                                  if (fields['fund_code']) {
                                                    getUnitHoldersByAmcFolioNo(email, fields['amc_code'], fields['amc_code'] + '-' + fields['folio_no']);
                                                  } else {
                                                    toast.error('Kindly First select Fund');
                                                  }
                                                }
                                              }}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="3">
                                          <div className="form-group">
                                            <div></div>
                                            <button className="btn btn-default btn-sm mt-4"
                                              disabled={Boolean(unitHolderFieldReadOnly)}
                                              onClick={() => {
                                                if (fields['fund_code']) {
                                                  getUnitHoldersByAmcFolioNo(email, fields['amc_code'], fields['amc_code'] + '-' + fields['folio_no']);
                                                } else {
                                                  toast.error('Kindly First select Fund');
                                                }
                                              }}>
                                              {unitHolderFieldReadOnly ? (
                                                <>
                                                  <span
                                                    className="spinner-border login-txt spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                  ></span>
                                                  {/* <span className="login-txt"> Search</span> */}
                                                  <i className="fa fa-search"> </i>
                                                </>
                                              ) : (
                                                // <span>Search</span>
                                                <i className="fa fa-search"> </i>
                                              )}
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>

                                      <div className="form-group">
                                        <div>UnitHolder Name</div>
                                        <input
                                          type="text"
                                          value={fields['unitholder_name']}
                                          placeholder='UnitHolder Name'
                                          className={
                                            'form-control w-100 ' +
                                            ErrorBorder(fields['unitholder_name'])
                                          }
                                          readOnly
                                          onChange={(e) => {
                                            setFields({
                                              ...fields,
                                              unitholder_name: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}

                                {chechFieldDisplay(headLabel, 'folio_no') && (

                                  <Row>
                                    <Col md="6">
                                      <div className="form-group">
                                        <div>Folio Number*</div>
                                        <input
                                          type="text"
                                          value={fields['folio_no']}
                                          className={
                                            'form-control w-100' + ErrorBorder(fields['folio_no'])
                                          }
                                          readOnly
                                          onChange={(e) => {
                                            setFields({
                                              ...fields,
                                              folio_no: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>

                                    </Col>

                                    <Col md="6">
                                      <div className="form-group">
                                        <div>UnitHolder Name*</div>
                                        <input
                                          type="text"
                                          value={fields['unitholder_name']}
                                          className={
                                            'form-control w-100 ' +
                                            ErrorBorder(fields['unitholder_name'])
                                          }
                                          readOnly
                                          onChange={(e) => {
                                            setFields({
                                              ...fields,
                                              unitholder_name: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                                {chechFieldDisplay(headLabel, 'sale_date') && (
                                  <Row>
                                    <Col md="6">
                                      <div className="form-group">
                                        <div>Sale Date</div>
                                        <input
                                          type="date"
                                          defaultValue={fields['sale_date']}
                                          max={disableDates}
                                          className={
                                            'form-control w-100 ' +
                                            ErrorBorder(fields['sale_date'])
                                          }
                                          onChange={(e) => {
                                            setFields({
                                              ...fields,
                                              sale_date: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col md="4">
                                      <button className="btn btn-default mt-3"
                                        disabled={Boolean(loadBtnEnable)}
                                        onClick={async () => {
                                          try {
                                            setLoadBtnEnable(true);
                                            await loadDailyNav(fields['fund_code'], fields['sale_date'])
                                              .then(res => {
                                                if (res.status == 200) {
                                                  let newNav = 0;
                                                  res = res.data
                                                  if (res.length > 0) {
                                                    newNav = decimalValueNav(res[0].Record.nav, 4);
                                                    let offerprice = res[0].Record.offer_price;
                                                    let loadperunit = decimalValueNav(offerprice - newNav, 4);

                                                    setFields({
                                                      ...fields,
                                                      nav: newNav.toString(),
                                                      offer_price: offerprice.toString(),
                                                      load_per_unit: loadperunit.toString(),
                                                      applicable_offer_price: offerprice,
                                                      percentage_of_discount: '0'
                                                    });
                                                  } else {
                                                    setFields({
                                                      ...fields,
                                                      nav: '0',
                                                      offer_price: '0',
                                                      load_per_unit: '0',
                                                      applicable_offer_price: '0'
                                                    });
                                                  }
                                                  setLoadBtnEnable(false);
                                                } else if (res.status === 404) {
                                                  setFields({
                                                    ...fields,
                                                    nav: '0',
                                                    offer_price: '0',
                                                    load_per_unit: '0',
                                                    applicable_offer_price: '0'
                                                  });
                                                } else {

                                                  setLoadBtnEnable(false);

                                                  toast.error(res.message);

                                                }
                                              });
                                          } catch (error: any) {
                                            setLoadBtnEnable(false);
                                            if (error.response !== undefined) {
                                              toast.error(error.response.data.message);
                                            } else {
                                              toast.error('Request Failed!');
                                            }
                                          }
                                          setLoadBtnEnable(false);
                                        }}
                                      >Load</button>
                                    </Col>
                                  </Row>
                                )}
                                {chechFieldDisplay(headLabel, 'redemption_date') && (
                                  <Row>
                                    <Col md="6">
                                      <div className="form-group">
                                        <div>Redemption Date</div>
                                        <input
                                          type="date"
                                          defaultValue={fields['redemption_date']}
                                          max={disableDates}
                                          className={
                                            'form-control w-100 ' +
                                            ErrorBorder(fields['redemption_date'])
                                          }
                                          onChange={(e) => {
                                            setFields({
                                              ...fields,
                                              redemption_date: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col md="4">
                                      <button className="btn btn-default mt-3"
                                        disabled={Boolean(loadBtnEnable)}
                                        onClick={async () => {
                                          try {
                                            setLoadBtnEnable(true);
                                            await loadDailyNav(fields['fund_code'], fields['redemption_date']).then(res => {
                                              let newNav = 0, loadperunit = 0;
                                              if (res.status === 200) {
                                                res = res.data;
                                                if (res.length > 0) {
                                                  newNav = decimalValueNav(res[0].Record.nav, 4);
                                                  let redemptionprice = res[0].Record.redemption_price;
                                                  loadperunit = decimalValueNav(newNav - redemptionprice, 4);
                                                  setFields({
                                                    ...fields,
                                                    nav: newNav.toString(),
                                                    offer_price: redemptionprice.toString(),
                                                    load_per_unit: loadperunit.toString(),
                                                    applicable_offer_price: redemptionprice,
                                                    percentage_of_discount: '0'
                                                  });
                                                } else {
                                                  setFields({
                                                    ...fields,
                                                    nav: '0',
                                                    offer_price: '0',
                                                    load_per_unit: '0',
                                                    applicable_offer_price: '0'
                                                  });
                                                }
                                                setLoadBtnEnable(false);
                                              } else if (res.status === 404) {
                                                setFields({
                                                  ...fields,
                                                  nav: '0',
                                                  offer_price: '0',
                                                  load_per_unit: '0',
                                                  applicable_offer_price: '0'
                                                });
                                              } else {

                                                setLoadBtnEnable(false);

                                                toast.error(res.message);

                                              }
                                            }
                                            );
                                          } catch (error: any) {
                                            setLoadBtnEnable(false);
                                            if (error.response !== undefined) {
                                              toast.error(error.response.data.message);
                                            } else {
                                              toast.error('Request Failed!');
                                            }
                                          }
                                          setLoadBtnEnable(false);
                                          //loadDailyNav(fields['fund_name'], fields['sale_date'])
                                        }}
                                      >Load
                                      </button>
                                    </Col>
                                  </Row>
                                )}

                                {chechFieldDisplay(headLabel, 'conversion_date') && (
                                  <Row>
                                    <Col md="6">
                                      <div className="form-group">
                                        <div>Conversion Date</div>
                                        <input
                                          type="Date"
                                          defaultValue={fields['conversion_date']}
                                          className={
                                            'form-control w-100 ' +
                                            ErrorBorder(fields['conversion_date'])
                                          }
                                          onChange={(e) => {
                                            setFields({
                                              ...fields,
                                              conversion_date: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col md="4">
                                      <button className="btn btn-default mt-3"
                                        disabled={Boolean(loadBtnEnable)}
                                        onClick={async () => {
                                          try {
                                            setLoadBtnEnable(true);
                                            if (finishFlag === true) {
                                              await loadDailyNav(fields['fund_code'], fields['conversion_date']).then(res => {
                                                let newNav = 0;
                                                if (res.status === 200) {
                                                  res = res.data;
                                                  if (res.length > 0) {
                                                    newNav = decimalValueNav(res[0].Record.nav, 4);
                                                    let offerprice = res[0].Record.offer_price;
                                                    let loadperunit = decimalValueNav(offerprice - newNav, 4);

                                                    let unit = (parseFloat(fields['gross_amount']) / parseFloat(offerprice)).toFixed(4);
                                                    let saleload = (parseFloat(loadperunit) * parseFloat(unit)).toFixed(2);
                                                    let netamount = (parseFloat(fields['gross_amount']) - parseFloat(saleload.replace('', '0'))).toFixed(2);
                                                    let remainingholding = (parseFloat(fields['current_holding']) + parseFloat(unit)).toFixed(4);
                                                    // let applicable_offer_price= +newNav + parseFloat(loadperunit);

                                                    setFields({
                                                      ...fields,
                                                      nav: newNav.toString(),
                                                      offer_price: offerprice.toString(),
                                                      load_per_unit: loadperunit.toString(),
                                                      applicable_offer_price: offerprice.toString(),
                                                      units: unit,
                                                      sale_load: saleload || '0',
                                                      net_amount: netamount,
                                                      total_holding: remainingholding,
                                                      percentage_of_discount: '0'
                                                    });

                                                    let inwordUnits = inWords(unit);
                                                    setUnitsInWord(inwordUnits);
                                                    let inwordNetAmount = inWords(netamount);
                                                    setNetAmountInWords(inwordNetAmount);
                                                    let inwordGrossAmount = inWords(fields['gross_amount']);
                                                    let unitSeparator = numberWithCommas(unit);
                                                    setUnitsSeparator(unitSeparator);
                                                    setGrossAmountInWords(inwordGrossAmount);
                                                    let grossAmountSeparator = numberWithCommas(fields['gross_amount']);
                                                    setGrossAmountSeparator(grossAmountSeparator);
                                                    let netAmountSeparator = numberWithCommas(netamount);
                                                    setNetAmountSeparator(netAmountSeparator);

                                                  } else {
                                                    setFields({
                                                      ...fields,
                                                      nav: '0',
                                                      offer_price: '0',
                                                      load_per_unit: '0',
                                                      applicable_offer_price: '0'
                                                    });
                                                  }
                                                  setLoadBtnEnable(false);
                                                } else if (res.status === 404) {
                                                  setFields({
                                                    ...fields,
                                                    nav: '0',
                                                    offer_price: '0',
                                                    load_per_unit: '0',
                                                    applicable_offer_price: '0'
                                                  });
                                                } else {

                                                  setLoadBtnEnable(false);

                                                  toast.error(res.message);

                                                }
                                              });
                                            } else {
                                              await loadDailyNav(fields['fund_code'], fields['conversion_date']).then(res => {
                                                let newNav = 0;
                                                if (res.status === 200) {
                                                  res = res.data;
                                                  if (res.length > 0) {
                                                    newNav = decimalValueNav(res[0].Record.nav, 4);
                                                    let offerprice = res[0].Record.offer_price;
                                                    let loadperunit = decimalValueNav(offerprice - newNav, 4);

                                                    setFields({
                                                      ...fields,
                                                      nav: newNav.toString(),
                                                      offer_price: offerprice.toString(),
                                                      load_per_unit: loadperunit.toString(),
                                                      applicable_offer_price: offerprice.toString(),
                                                      percentage_of_discount: '0'
                                                    });

                                                  } else {
                                                    setFields({
                                                      ...fields,
                                                      nav: '0',
                                                      offer_price: '0',
                                                      load_per_unit: '0',
                                                      applicable_offer_price: '0'
                                                    });
                                                  }
                                                  setLoadBtnEnable(false);
                                                } else if (res.status === 404) {
                                                  setFields({
                                                    ...fields,
                                                    nav: '0',
                                                    offer_price: '0',
                                                    load_per_unit: '0',
                                                    applicable_offer_price: '0'
                                                  });
                                                } else {

                                                  setLoadBtnEnable(false);

                                                  toast.error(res.message);

                                                }

                                              });
                                            }
                                            setLoadBtnEnable(false);
                                          } catch (error: any) {
                                            setLoadBtnEnable(false);
                                            if (error.response !== undefined) {
                                              toast.error(error.response.data.message);
                                            } else {
                                              toast.error('Request Failed!');
                                            }
                                          }
                                          setLoadBtnEnable(false);
                                        }}
                                      >Load
                                      </button>
                                    </Col>
                                  </Row>
                                )}

                                <div className="row">
                                  {chechFieldDisplay(headLabel, 'nav') && (
                                    <div className="form-group col-md-6">
                                      <div>NAV Price*</div>
                                      <input
                                        type="number"
                                        value={fields['nav']}
                                        className={'form-control w-100' + ErrorBorder(fields['nav'])
                                        }
                                        readOnly
                                        onChange={(e) => {
                                          let value = decimalValue(e.target.value, 4);
                                          setFields({
                                            ...fields,
                                            nav: value
                                          });

                                        }}
                                      />
                                    </div>
                                  )}

                                  {chechFieldDisplay(headLabel, 'offer_price') && (
                                    <div className="form-group col-md-6">
                                      <div>{(headLabel === 'saleofunit' || headLabel === 'conversionin') ? 'Sale Price' : 'Redemption Price'}</div>
                                      <input
                                        type="number"
                                        value={fields['offer_price']}
                                        className={
                                          'form-control w-100 ' + ErrorBorder(fields['offer_price'])
                                        }
                                        readOnly
                                        onChange={(e) => {
                                          let value = decimalValue(e.target.value, 4);
                                          setFields({
                                            ...fields,
                                            offer_price: value
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>

                                <div className="row">

                                  {chechFieldDisplay(headLabel, 'percentage_of_discount') && (
                                    <div className="form-group col-md-6">
                                      <div>% Discount on Load</div>
                                      <input
                                        type="number"
                                        value={fields['percentage_of_discount'] || 0}
                                        className={
                                          'form-control w-100 ' + ErrorBorder(fields['percentage_of_discount'])
                                        }
                                        readOnly={discount_applicable_ReadOnly}
                                        onChange={(e) => {
                                          let value = e.target.value;
                                          if (headLabel === 'saleofunit' || headLabel === 'conversionin') {
                                            if (value.length < 4) {
                                              let dicountedLoad = (parseFloat(fields['offer_price']) - parseFloat(fields['nav'])) * (1 - parseFloat(value) / 100);
                                              let applicableaOfferPrice = parseFloat(fields['nav']) + dicountedLoad;

                                              setFields({
                                                ...fields,
                                                percentage_of_discount: value,
                                                load_per_unit: dicountedLoad.toFixed(4).toString(),
                                                applicable_offer_price: applicableaOfferPrice.toFixed(4)
                                              });
                                            }
                                          } else if (headLabel === 'conversionout' || headLabel === 'redemptionofunits' || headLabel === 'unitconversion') {
                                            if (value.length < 4) {
                                              let dicountedLoad = (parseFloat(fields['nav']) - parseFloat(fields['offer_price'])) * (1 - parseFloat(value) / 100);
                                              let applicableaOfferPrice = parseFloat(fields['nav']) - dicountedLoad;

                                              setFields({
                                                ...fields,
                                                percentage_of_discount: value,
                                                load_per_unit: dicountedLoad.toFixed(4).toString(),
                                                applicable_offer_price: applicableaOfferPrice.toFixed(4)
                                              });
                                            }
                                          }
                                        }}

                                      />
                                    </div>
                                  )}

                                  {chechFieldDisplay(headLabel, 'load_per_unit') && (
                                    <div className="form-group col-md-6">
                                      <div>Applicable Unit Load</div>
                                      <input
                                        type="number"
                                        value={fields['load_per_unit']}
                                        className={
                                          'form-control w-100 ' + ErrorBorder(fields['load_per_unit'])
                                        }
                                        readOnly
                                        onChange={(e) => {
                                          let value = decimalValue(e.target.value, 4);
                                          setFields({
                                            ...fields,
                                            load_per_unit: value
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>

                                {chechFieldDisplay(headLabel, 'applicable_offer_price') && (
                                  <div className="form-group">
                                    <div>Applicable Price</div>
                                    <input
                                      type="number"
                                      value={fields['applicable_offer_price']}
                                      className={
                                        'form-control w-100 ' + ErrorBorder(fields['applicable_offer_price'])
                                      }
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        // if(value.length<3){
                                        //   let percentagevalue= parseInt(value)/100;
                                        //   let applicableaOfferPrice= parseInt(fields['offer_price'])-(parseInt(fields['load_per_unit'])*percentagevalue);
                                        setFields({
                                          ...fields,
                                          applicable_offer_price: decimalValue(value, 4)
                                        });
                                        // }
                                      }}

                                    />
                                  </div>
                                )}

                                {/* type */}
                                {chechFieldDisplay(headLabel, 'type') && (
                                  <div className="form-group">
                                    <div>Type*</div>
                                    {flowLoading ? (
                                      <div className="form-control w-100">
                                        <i className="fa fa-spinner fa-spin fa-1x" ></i>
                                      </div>
                                    ) : (
                                      <select
                                        className={
                                          'form-control w-100 ' + ErrorBorder(fields['type'])
                                        }
                                        defaultValue={fields['type']}
                                        onChange={(e) => {
                                          setFields({
                                            ...fields,
                                            type: e.target.value,
                                          });
                                        }}
                                      >
                                        <option value="" defaultChecked hidden>
                                          {' '}
                                          Select Type
                                        </option>
                                        {headLabel === 'inflow'
                                          ? renderinFlowTypeDropdown()
                                          : renderOutFlowTypeDropdown()}
                                      </select>
                                    )}
                                  </div>
                                )}

                                {chechFieldDisplay(headLabel, 'units') && (
                                  <div className="form-group">
                                    <div> Units{' '}
                                      {headLabel === 'redemptionofunits'
                                        ? 'Redeemed'
                                        : headLabel === 'saleofunit' ||
                                          headLabel === 'conversionin'
                                          ? 'Sold*'
                                          : headLabel === 'salereturn'
                                            ? 'Returned'
                                            : '*'} {unitsSeparator === '' ? '' : <span className="float-right text-primary">{unitsSeparator}</span>} </div>
                                    <input
                                      type="number"
                                      // value={fields['units']}
                                      className={'form-control w-100 ' + ErrorBorder(fields['units'])}
                                      value={fields['units']}
                                      readOnly={unitsReadOnly}
                                      onChange={(e) => {
                                        let value = decimalValue(e.target.value, 4);
                                        if (fields.payment_type === 'Payment' || headLabel === 'unitconversion') {
                                          if (+e.target.value <= +fields['current_holding']) {
                                            setCurentHolding(value);
                                            setFields({
                                              ...fields,
                                              units: value,
                                            });
                                            value === '' ?
                                              setGrossAmountReadOnly(false)
                                              : setGrossAmountReadOnly(true)
                                            value === '' ? setDiscount_Applicable_ReadOnly(false) : setDiscount_Applicable_ReadOnly(true);
                                          }
                                        } else {
                                          let gamount = (parseFloat(fields['applicable_offer_price'])) * (parseFloat(value));
                                          let gross_amount = gamount.toFixed(2).toString();
                                          let saleload = (parseFloat(value) * parseFloat(fields['load_per_unit'])).toFixed(2);
                                          let netamount = (gamount - parseFloat(saleload.toString().replace('', '0'))).toFixed(2).toString();
                                          let totalholding = (parseFloat(fields['current_holding']) + parseFloat(value)).toFixed(4);

                                          if (gross_amount.split('.')[0].length <= 12) {
                                            setFields({
                                              ...fields,
                                              units: value,
                                              total_holding: totalholding,
                                              gross_amount: gross_amount,
                                              sale_load: saleload.toString(),
                                              net_amount: netamount
                                            });
                                          }
                                          value === '' ?
                                            setGrossAmountReadOnly(false)
                                            : setGrossAmountReadOnly(true)
                                          value === '' ? setDiscount_Applicable_ReadOnly(false) : setDiscount_Applicable_ReadOnly(true);
                                          if (value !== '') {
                                            let a = inWords(gross_amount);
                                            let b = inWords(netamount);
                                            //let c = inWords(value);

                                            //setUnitsInWord(c);
                                            setGrossAmountInWords(a);
                                            setNetAmountInWords(b);
                                            //// separator
                                            let unitSepa = numberWithCommas(value);
                                            let grossAmountSepa = numberWithCommas(gross_amount);
                                            let netAmountSepa = numberWithCommas(netamount);
                                            setUnitsSeparator(unitSepa);
                                            setGrossAmountSeparator(grossAmountSepa);
                                            setNetAmountSeparator(netAmountSepa);
                                          } else {
                                            setGrossAmountInWords('');
                                            setNetAmountInWords('');
                                            setGrossAmountSeparator('');
                                            setNetAmountSeparator('');
                                            setUnitsSeparator('');
                                            //setUnitsInWord('');
                                          }
                                        }

                                      }}
                                    />
                                    {/* <small className="truncate" title={unitsInWords}>{unitsInWords==='' ? '' : (unitsInWords)}</small> */}
                                  </div>
                                )}

                                <div className="row">
                                  {chechFieldDisplay(headLabel, 'current_holding') && (
                                    <div className="form-group col-md-6">
                                      <div>Current Holding*</div>
                                      <input
                                        type="number"
                                        value={fields['current_holding']}
                                        disabled
                                        className={
                                          'form-control w-100'
                                          +
                                          ErrorBorder(fields['current_holding'])
                                        }
                                        onChange={(e) => {
                                          let value = decimalValue(e.target.value, 4);
                                          setFields({
                                            ...fields,
                                            current_holding: value,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}

                                  {chechFieldDisplay(headLabel, 'total_holding') && (
                                    <div className="form-group col-md-6">
                                      <div>Total Holding*</div>
                                      <input
                                        type="number"
                                        value={fields['total_holding']}
                                        disabled
                                        className={
                                          'form-control w-100 ' +
                                          ErrorBorder(fields['total_holding'])
                                        }
                                        onChange={(e) => {
                                          let value = decimalValue(e.target.value, 4);
                                          setFields({
                                            ...fields,
                                            total_holding: value,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}

                                  {chechFieldDisplay(headLabel, 'remain_holding') && (
                                    <div className="form-group col-md-6">
                                      Remaining Holding*
                                      <input
                                        type="number"
                                        value={fields['remain_holding']}
                                        disabled
                                        className={'form-control w-100 ' + ErrorBorder(fields['remain_holding'])
                                        }
                                        onChange={(e) => {
                                          let value = decimalValue(e.target.value, 2);
                                          setFields({
                                            ...fields,
                                            remain_holding: value,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}

                                </div>



                                {/* {chechFieldDisplay(headLabel, 'sale_date') && (
                        <div className="form-group">
                          <div>Sale Date</div>
                          <input
                            type="date"
                            defaultValue={fields['sale_date']}
                            max={disableDates}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['sale_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                sale_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}

                                {chechFieldDisplay(headLabel, 'dividend_date') && (
                                  <div className="form-group">
                                    <div>Dividend Date</div>
                                    <input
                                      type="date"
                                      defaultValue={fields['dividend_date']}
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['dividend_date'])
                                      }
                                      onChange={(e) => {
                                        let date = moment(e.target.value).format('YYYY-MM-DD');
                                        setFields({
                                          ...fields,
                                          dividend_date: date,
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                                {chechFieldDisplay(headLabel, 'dividend_rate') && (
                                  <div className="form-group">
                                    <div>Dividend Rate</div>
                                    <input
                                      type="number"
                                      value={fields['dividend_rate']}
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['dividend_rate'])
                                      }
                                      onChange={(e) => {
                                        let value = decimalValue(e.target.value, 2);
                                        let grossAmount =
                                          parseFloat(value) *
                                          parseFloat(fields['current_holding']) *
                                          parseFloat(fields['face_value']);
                                        setFields({
                                          ...fields,
                                          dividend_rate: value,
                                          gross_amount: grossAmount.toFixed(2),
                                          net_amount: grossAmount.toFixed(2),
                                        });
                                      }}
                                    />
                                  </div>
                                )}

                                {chechFieldDisplay(headLabel, 'sale_txn_no') && (
                                  <div className="form-group">
                                    <div>Sale Txn No</div>
                                    <input
                                      type="number"
                                      defaultValue={fields['sale_txn_no']}
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['sale_txn_no'])
                                      }
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          sale_txn_no: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                                {chechFieldDisplay(headLabel, 'return_date') && (
                                  <div className="form-group">
                                    <div>Return Date</div>
                                    <input
                                      type="date"
                                      defaultValue={fields['return_date']}
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['return_date'])
                                      }
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          return_date: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                                {chechFieldDisplay(headLabel, 'symbol') && (
                                  <div className="form-group">
                                    <div>Symbol*</div>
                                    <select
                                      className={
                                        'form-control w-100 ' + ErrorBorder(fields['symbol'])
                                      }
                                      defaultValue={fields['symbol']}
                                      onChange={(e) => {
                                        let vol = stockMarketDataFund.filter((item, index) => {
                                          return (item.code === e.target.value);
                                        })
                                        setFields({
                                          ...fields,
                                          volume: vol[0].unit,
                                          symbol: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="" defaultChecked hidden>
                                        {' '}
                                        Select Type
                                      </option>
                                      {renderStockMarketData()}
                                    </select>
                                  </div>
                                )}

                                {/* {headLabel==='cashdividend' && ( */}
                                {chechFieldDisplay(headLabel, 'volume') && (
                                  <div className="form-group">
                                    <div>Volume</div>
                                    <input
                                      type="number"
                                      defaultValue={fields['volume']}
                                      className={
                                        'form-control w-100 ' + ErrorBorder(fields['volume'])
                                      }
                                      onChange={(e) => {
                                        let value = decimalValue(e.target.value, 2);
                                        let grossamount = (parseFloat(value) * (parseFloat(fields['dividend_percentage']) / 100) * parseFloat(fields['par_value'] || '0')).toFixed(2);
                                        setFields({
                                          ...fields,
                                          volume: value,
                                          gross_amount: grossamount,
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                                {/* 
                      {chechFieldDisplay(headLabel, 'dividend_percentage') && (
                        <div className="form-group">
                          <div>Dividend Percentage*</div>
                          <input
                            type="number"
                            defaultValue={fields['dividend_percentage']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['dividend_percentage'])
                            }
                            onChange={(e) => {
                              let grossamount = (parseFloat(volume) * (parseFloat(e.target.value)/100) * parseFloat(parValue)).toFixed(2);

                              setFields({
                                ...fields,
                                dividend_percentage: e.target.value,
                                gross_amount: grossamount,
                              });
                            }}
                          />
                        </div>
                      )} */}

                                {/* {headLabel==='cashdividend' && ( */}
                                {chechFieldDisplay(headLabel, 'par_value') && (
                                  <div className="form-group">
                                    <div>Par Value</div>
                                    <input
                                      type="number"
                                      defaultValue={fields['par_value']}
                                      className={
                                        'form-control w-100 ' + ErrorBorder(fields['par_value'])
                                      }
                                      onChange={(e) => {
                                        let value = decimalValue(e.target.value, 2);
                                        let grossamount = (parseFloat(fields['volume'] || '0') * (parseFloat(fields['dividend_percentage']) / 100) * parseFloat(value)).toFixed(2);
                                        setFields({
                                          ...fields,
                                          par_value: value,
                                          gross_amount: grossamount,
                                        });
                                      }}
                                    />
                                  </div>
                                )}

                                {/* {headLabel==='cashdividend' && ( */}
                                {headLabel === 'cashdividend' && (
                                  <div className="form-group w-100 d-flex">
                                    <div className="w-50 flex mr-2">
                                      <div>Announcement Date</div>
                                      <input
                                        type="date"
                                        defaultValue={fields['announcement_date']}
                                        className={
                                          'form-control w-100 ' +
                                          ErrorBorder(fields['announcement_date'])
                                        }
                                        onChange={(e) => {
                                          let date = moment(e.target.value).format('YYYY-MM-DD')
                                          setFields({
                                            ...fields,
                                            announcement_date: date,
                                          });
                                        }}
                                      />
                                    </div>
                                    {headLabel === 'cashdividend' && (
                                      <div className="w-50 flex mr-2">
                                        <div>Description</div>
                                        <input
                                          type="text"
                                          value={fields['detail']}
                                          className={
                                            'form-control w-100'
                                          }
                                          onChange={(e) => {
                                            setFields({
                                              ...fields,
                                              detail: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                                {/* {chechFieldDisplay(headLabel, 'announcement_date') && (
                                <div className="form-group">
                                  <div>Announcement Date</div>
                                  <input
                                    type="date"
                                    defaultValue={fields['announcement_date']}
                                    className={
                                      'form-control w-100 ' +
                                      ErrorBorder(fields['announcement_date'])
                                    }
                                    onChange={(e) => {
                                      let date = moment(e.target.value).format('YYYY-MM-DD')
                                      setFields({
                                        ...fields,
                                        announcement_date: date,
                                      });
                                    }}
                                  />
                                </div>
                              )} */}

                                {/* {chechFieldDisplay(headLabel, 'credit_date') && (
                        <div className="form-group">
                          <div>Credit Date</div>
                          <input
                            type="date"
                            defaultValue={fields['credit_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['credit_date'])
                            }
                            onChange={(e) => {
                              let date = moment(e.target.value).format('YYYY-MM-DD')
                              setFields({
                                ...fields,
                                credit_date: date,
                              });
                            }}
                          />
                        </div>
                      )} */}
                                {chechFieldDisplay(headLabel, 'maturity_type') && (
                                  <div className="form-group">
                                    <div>Maturity Type*</div>
                                    <select
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['maturity_type'])
                                      }
                                      defaultValue={fields['maturity_type']}
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          maturity_type: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="" defaultChecked hidden>
                                        {' '}
                                        Select Type
                                      </option>
                                      <option value="Security Maturity">
                                        Security Maturity
                                      </option>
                                      <option value="Coupon Maturity">
                                        Coupon Maturity
                                      </option>
                                    </select>
                                  </div>
                                )}

                                {chechFieldDisplay(headLabel, 'primary_dealer') && (
                                  <div className="form-group">
                                    <div>Primary Dealer</div>
                                    <select
                                      className="form-control"
                                      // defaultValue={}
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          primary_dealer: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="" defaultChecked hidden>
                                        {' '}
                                        Select Type
                                      </option>
                                      {renderPrimaryDealerDropdown()}
                                    </select>
                                  </div>

                                )}

                                {chechFieldDisplay(headLabel, 'security_type') && (
                                  <div className="form-group">
                                    <div>Security Type*</div>
                                    <select
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['security_type'])
                                      }
                                      defaultValue={fields['security_type']}
                                      onChange={(e) => {
                                        if (
                                          headLabel == 'saleofsecurities' ||
                                          headLabel === 'moneymarketpurchase'
                                        ) {
                                          if (e.target.value == 'PIB') {
                                            setPIBFields(true);
                                          } else {
                                            setPIBFields(false);
                                          }
                                        }
                                        setFields({
                                          ...fields,
                                          security_type: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="" defaultChecked hidden>
                                        {' '}
                                        Select Type
                                      </option>
                                      {renderSecurityDataDropdown()}
                                    </select>
                                  </div>
                                )}
                                {chechFieldDisplay(headLabel, 'issue_date') && (
                                  <div className="form-group">
                                    <div>Issue Date</div>
                                    <input
                                      type="Date"
                                      defaultValue={fields['issue_date']}
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['issue_date'])
                                      }
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          issue_date: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                )}

                                {chechFieldDisplay(
                                  headLabel,
                                  'associated_transaction'
                                ) && (
                                    <div className="form-group">
                                      <div>Associated Txn No*</div>
                                      <input
                                        type="text"
                                        defaultValue={fields['associated_transaction']}
                                        className={
                                          'form-control w-100 ' +
                                          ErrorBorder(fields['associated_transaction'])
                                        }
                                        onChange={(e) => {
                                          setFields({
                                            ...fields,
                                            associated_transaction: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                {chechFieldDisplay(headLabel, 'maturity_date') && (
                                  <div className="form-group">
                                    <div>Maturity Date</div>
                                    <input
                                      type="date"
                                      defaultValue={fields['maturity_date']}
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['maturity_date'])
                                      }
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          maturity_date: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                                {chechFieldDisplay(headLabel, 'coupon_rate') && (
                                  <div className="form-group">
                                    <div>Coupon Rate*</div>
                                    <input
                                      type="number"
                                      value={fields['coupon_rate']}
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['coupon_rate'])
                                      }
                                      onChange={(e) => {
                                        let value = decimalValue(e.target.value, 2);
                                        setFields({
                                          ...fields,
                                          coupon_rate: value,
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                                {/* {PIB_fields && (
                        <div className="form-group">
                          <div>Last Coupon Date</div>
                          <input
                            type="Date"
                            defaultValue={fields['last_coupon_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['last_coupon_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                last_coupon_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {PIB_fields && (
                        <div className="form-group">
                          <div>Next Coupon Date</div>
                          <input
                            type="Date"
                            defaultValue={fields['next_coupon_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['next_coupon_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                next_coupon_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}
                                {chechFieldDisplay(headLabel, 'yield') && (
                                  <div className="form-group">
                                    <div>Yield*</div>
                                    <input
                                      type="number"
                                      value={fields['yield']}
                                      className={
                                        'form-control w-100 ' + ErrorBorder(fields['yield'])
                                      }
                                      onChange={(e) => {
                                        let value = decimalValue(e.target.value, 4);
                                        setFields({
                                          ...fields,
                                          yield: value,
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                                {/* {PIB_fields && (
                        <div className="form-group">
                          <div>Tenor</div>
                          <input
                            defaultValue={fields['tenor']}
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['tenor'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                tenor: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}
                                {chechFieldDisplay(headLabel, 'price') && (
                                  <div className="form-group">
                                    <div>Price*</div>
                                    <input
                                      type="number"
                                      value={fields['price']}
                                      className={
                                        'form-control w-100 ' + ErrorBorder(fields['price'])
                                      }
                                      onChange={(e) => {
                                        let value = decimalValue(e.target.value, 2);
                                        setFields({
                                          ...fields,
                                          price: value,
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                                {chechFieldDisplay(headLabel, 'face_value') && (
                                  <div className="form-group">
                                    <div>Face Value*</div>
                                    <input
                                      type="number"
                                      value={fields['face_value']}
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['face_value'])
                                      }
                                      readOnly
                                      onChange={(e) => {
                                        let value = decimalValue(e.target.value, 2);
                                        setFields({
                                          ...fields,
                                          face_value: value,
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                                {chechFieldDisplay(headLabel, 'money_market_face_value') && (
                                  <div className="form-group">
                                    <div>Money Market Face Value*</div>
                                    <input
                                      type="number"
                                      value={fields['money_market_face_value']}
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['money_market_face_value'])
                                      }
                                      // readOnly
                                      onChange={(e) => {
                                        let value = decimalValue(e.target.value, 2);
                                        setFields({
                                          ...fields,
                                          money_market_face_value: value,
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                                {headLabel !== 'cashdividend' && (
                                  <div className="form-group">
                                    <div>Description</div>
                                    <input
                                      type="text"
                                      value={fields['detail']}
                                      className={
                                        'form-control w-100'
                                      }
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          detail: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                )}


                                {(headLabel === 'equityinvestment' || headLabel === 'advisoryfee' || headLabel === 'auditorsfee' || headLabel === 'managementfee' || headLabel === 'trusteefee' || headLabel === 'custodyfee'
                                  || headLabel === 'shariahadvisoryfee' || headLabel === 'cdcfee' || headLabel === 'listingfee' || headLabel === 'brokagefee' ||
                                  headLabel === 'printingfee' || headLabel === 'professionalfee' || headLabel === 'ratingfee' || headLabel === 'taxpayment' || headLabel === 'secpfee' || headLabel === 'otherpayment') && (
                                    <div className="">
                                      <div> Sales Tax Collector </div>
                                      <select
                                        className={`form-control  w-100 `}
                                        value={fields['sales_tax_collector']}
                                        onChange={(e) => {
                                          setFields({
                                            ...fields,
                                            sales_tax_collector: e.target.value
                                          })
                                        }}
                                      >
                                        {/* <option value="" defaultChecked hidden>	
                          Select Revenue Authority
                        </option>	 */}
                                        <option value="SRB">SRB - Sindh Revenue Board</option>
                                        {/* <option value="FBR">FBR - Federal Board Of Revenue</option>	 */}
                                        <option value="PRA">PRA - Punjab Revenue Authority</option>
                                        <option value="KPRA">KPRA - Khyber Pakhtunkhwa Revenue Authority</option>
                                        <option value="BRA">BRA - Balochistan Revenue Authority</option>
                                        <option value="">None</option>
                                      </select>

                                    </div>
                                  )}

                                {headLabel === 'termdeposit' && (
                                  <>
                                    <div className="form-group">
                                      <div>Type</div>
                                      <select className='form-control w100'
                                        onChange={(e) => {
                                          termDepositEntity['code'] = e.target.value;
                                          // termDepositEntity['name']=e.nativeEvent.target[e.target.selectedIndex].text;
                                          termDepositEntity['name'] = e.nativeEvent.target === null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                                          setTermDepositEntity(termDepositEntity);
                                        }}
                                      >
                                        <option value='' hidden>Select Type</option>
                                        <option value='TDR'>Term Deposit Receipts</option>
                                        <option value='CDR'>Call Deposit Receipts</option>
                                        <option value='LOP'>Loan on Phone</option>
                                      </select>

                                    </div>
                                    <div className="form-group">
                                      <div>Principal Amount</div>
                                      <input
                                        type="number"
                                        // defaultValue={fields['detail']}
                                        className={
                                          'form-control w-100 '
                                        }
                                        onChange={(e) => {
                                          termDepositEntity['principal_amount'] = e.target.value;
                                          setTermDepositEntity(termDepositEntity);
                                        }}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <div>Interest Rate</div>
                                      <input
                                        type="number"
                                        // defaultValue={fields['detail']}
                                        className={
                                          'form-control w-100 '
                                        }
                                        onChange={(e) => {
                                          termDepositEntity['interest_rate'] = e.target.value;
                                          setTermDepositEntity(termDepositEntity);
                                        }}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <div>From Date</div>
                                      <input
                                        type="date"
                                        defaultValue={termDepositEntity['from_date']}
                                        className={
                                          'form-control w-100 '
                                        }
                                        onChange={(e) => {
                                          termDepositEntity['from_date'] = e.target.value;
                                          setTermDepositEntity(termDepositEntity);
                                        }}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <div>To Date</div>
                                      <input
                                        type="date"
                                        defaultValue={termDepositEntity['to_date']}
                                        className={
                                          'form-control w-100 '
                                        }
                                        onChange={(e) => {
                                          termDepositEntity['to_date'] = e.target.value;
                                          setTermDepositEntity(termDepositEntity);
                                        }}
                                      />
                                    </div>
                                  </>
                                )}

                                {/* {chechFieldDisplay(headLabel, 'redemption_date') && (
                        <div className="form-group">
                          <div>Redemption Date</div>
                          <input
                            type="date"
                            defaultValue={fields['redemption_date']}
                            max={disableDates}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['redemption_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                redemption_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}

                                {headLabel !== 'investmentinfund' && chechFieldDisplay(headLabel, 'settlement_by') && (
                                  <div className="form-group mt-3">
                                    <div>Settlement By*</div>
                                    <select
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['settlement_by'])
                                      }
                                      value={fields['settlement_by']}
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          settlement_by: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="" defaultChecked hidden>
                                        {' '}
                                        Select Type
                                      </option>
                                      <option value="NCCPL">NCCPL</option>
                                      <option value="Direct">Direct</option>
                                    </select>
                                  </div>
                                )}
                                {/* {chechFieldDisplay(headLabel, 'settlement_date') && (
                        <div className="form-group">
                          <div>Settlement Date</div>
                          <input
                            type="date"
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['settlement_date'])
                            }
                            defaultValue={fields['settlement_date']}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                settlement_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}
                                {/* {headLabel === 'moneymarketpurchase' && (
                        <div className="form-group">
                          <div>Fund IPS Account</div>
                          <input
                            type="text"
                            defaultValue={fields['fund_ips_account']}
                            className={'form-control w-100 '}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                fund_ips_account: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {headLabel === 'moneymarketpurchase' && (
                        <div className="form-group">
                          <div>Counter Party IPS Account</div>
                          <input
                            type="text"
                            defaultValue={fields['counter_party_ips_account']}
                            className={'form-control w-100 '}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                counter_party_ips_account: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}
                                {chechFieldDisplay(headLabel, 'period') && (
                                  <div className="form-group">
                                    <div>Select Period</div>
                                    <select
                                      className={
                                        'form-control w-100 ' + ErrorBorder(fields['period'])
                                      }
                                      defaultValue={fields['period']}
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          period: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="" defaultChecked hidden>
                                        {' '}
                                        Select period
                                      </option>
                                      <option value="Monthly">Monthly</option>
                                      <option value="Quarterly">Quarterly</option>
                                      <option value="Annual">Annual</option>
                                      <option value="Occasional">Occasional</option>
                                    </select>
                                  </div>
                                )}
                                {chechFieldDisplay(headLabel, 'reinvest') && (

                                  <FormGroup check>
                                    <Label check>
                                      <Input type="checkbox"
                                        checked={reinest}
                                        onChange={(e) => {
                                          setReinvest(!reinest);
                                          setFields({
                                            ...fields,
                                            reinvest: e.target.value,
                                          });
                                        }} />
                                      <span className="form-check-sign" />
                                      Reinvest
                                    </Label>
                                  </FormGroup>
                                )}

                                {chechFieldDisplay(headLabel, 'tax_type') && (
                                  <div className="form-group mt-2">
                                    <div>Tax Type*</div>
                                    <select
                                      className={
                                        'form-control w-100 ' + ErrorBorder(fields['tax_type'])
                                      }
                                      defaultValue={fields['tax_type']}
                                      onChange={(e) => {
                                        setFields({
                                          ...fields,
                                          tax_type: e.target.value,
                                        });
                                      }}
                                    >
                                      <option value="" defaultChecked hidden>
                                        {' '}
                                        Select Type
                                      </option>
                                      {renderTaxTypeDropdown()}
                                    </select>
                                  </div>
                                )}

                                {/* Money Market Securities Modal */}
                                {headLabel === 'debtmarketinvestment' && (
                                  <>

                                    <div className="row">
                                      <div className="col">
                                        <div className="form-group">
                                          <div>Add Money Market Security</div>
                                          <button className=" btn-round btn-icon btn btn-success" onClick={() => {
                                            setMoneyMarketSecuritiesEntity(prevState => ({
                                              ...moneyMarketSecuritiesEntity,
                                              security_type: '',
                                              investment_type: '',
                                              type: '',
                                              symbol: '',
                                              name: '',
                                              issue_date: '',
                                              maturity_date: '',
                                              money_market_face_value: '',
                                              price: '',
                                              units: '',
                                              yield: '',
                                              coupon_rate: '',
                                              next_coupon_date: '',
                                              last_coupon_date: '',
                                              primary_dealer: '',
                                              fund_ips_account: '',
                                              counter_party_ips_account: '',
                                              broker_name: '',
                                              settlement_date: '',
                                              total_face_value: '',
                                              accrued_amount: '',
                                              brokerage: '',
                                              premium_discount: '',
                                              accrued_days: '',
                                              counter_party_name: '',
                                              detail: '',
                                              principal_amount: '',
                                              unredeem_value: '',
                                              coupon_payment: '',
                                              days_of_maturity: ''

                                            }));
                                            setViewMoneyMarketModal(!viewMoneyMarketModal);
                                          }}> <i className="fa fa-plus"></i> </button>
                                        </div>
                                      </div>
                                    </div>

                                  </>
                                )}

                                {moneyMarketSecurities.length > 0 && (
                                  <>
                                    <div className="table-responsive">
                                      <div className="form-group">
                                        <table className="table  ">
                                          <thead>
                                            <tr>
                                              <th>Security Type</th>
                                              <th>Investment Type</th>
                                              <th>Type</th>
                                              <th>Symbol</th>
                                              <th>Name</th>
                                              <th>Issue Date</th>
                                              <th>Maturity Date</th>
                                              <th>Face Value</th>
                                              <th>Price</th>
                                              <th>Units</th>
                                              <th>Yield/Interest Rate</th>
                                              <th>Coupon Rate</th>
                                              <th>Next Coupon Date</th>
                                              <th>Last Coupon Date</th>
                                              <th>Primary Dealer</th>
                                              <th>Fund IPS Account</th>
                                              <th>Counter Party IPS Account</th>
                                              <th>Broker Name</th>
                                              <th>Settlement Amount</th>
                                              <th>Total Face Value</th>
                                              <th>Accrued Amount</th>
                                              <th>Brokerage</th>
                                              <th>Premium Discount</th>
                                              <th>Accrued Days</th>
                                              <th>Counter Party Name</th>
                                              <th>Detail</th>
                                              <th>Principal Amount</th>
                                              <th>UnRedeem Value</th>
                                              <th>Copoun Payment/Year</th>
                                              <th>Days of Maturity</th>
                                              <th>Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>{renderMoneyMarketData()}</tbody>
                                        </table>
                                      </div></div>
                                  </>
                                )}

                                {/* Add Fund Security */}
                                {(headLabel === 'investmentinfund' && securites.length === 0) && (
                                  <div className="row">
                                    <div className="col">
                                      {/* <div className="d-flex justify-content-between"> */}
                                      <div className='mt-2'>
                                        <label >Add Fund Security</label>
                                        <br />
                                        <button className=" btn-round btn-icon btn btn-success"
                                          onClick={() => {
                                            setFundInvestmentSecurityEtity((prevState) => ({
                                              ...fundInvestmentSecurityEtity,
                                              type: '',
                                              symbol: '',
                                              quantity: '',
                                              gross_amount: '',
                                              net_amount: '',
                                              sale_load: '',
                                              redemption_load: '',
                                              tax_cgt: '0',
                                              zakat_amount: '0',
                                            }));
                                            setViewAddFundInvestmentModal(!viewAddFundInvestmentModal);
                                            setCloseRate('');
                                          }}
                                        > <i className="fa fa-plus"></i>  </button>

                                      </div>
                                    </div>
                                  </div>
                                )}

                                {chechFieldDisplay(headLabel, 'settlement_by') &&
                                  securites.length > 0 && (
                                    <div className="form-group">
                                      <div className="d-flex justify-content-center my-4">
                                        Securities
                                      </div>
                                      <div className="table-responsive">
                                        <table className="table  ">
                                          <thead>
                                            <tr>
                                              <th>Type</th>
                                              <th>Inv Type</th>
                                              <th>Mkt</th>
                                              <th>Symbol</th>
                                              <th>Quantity</th>
                                              <th>Rate</th>
                                              {/* <th>Broker</th> */}
                                              <th>Comm Rate</th>
                                              <th>SST On Comm</th>
                                              {/* <th>Capital Value Tax</th> */}
                                              <th>Net Rate</th>
                                              <th>Gross Amount</th>
                                              <th>Commission</th>
                                              <th>Net Amount</th>
                                              <th>Act</th>
                                            </tr>
                                          </thead>
                                          <tbody>{renderPsxData()}</tbody>
                                        </table>
                                        <div className="ml-3" onClick={() => {
                                          securites.length = 0;
                                          setSecurites(securites);
                                          setFields({
                                            ...fields,
                                            gross_amount: '',
                                            net_amount: '0',
                                            broker_commission: '0',
                                            tax_sst: '0'
                                          })
                                          setFile('');
                                          setFileName('');
                                        }}>Clear All</div>
                                      </div>
                                    </div>
                                  )}
                                {headLabel === 'investmentinfund' &&
                                  securites.length > 0 && (
                                    <div className="form-group">
                                      <div className="d-flex justify-content-center my-4">
                                        Securities
                                      </div>
                                      <div className="table-responsive">
                                        <table className="table  ">
                                          <thead>
                                            <tr>
                                              <th>Type</th>
                                              <th>Fund</th>
                                              <th>No of Units</th>
                                              <th>Unit Price</th>
                                              <th>Gross Amount</th>
                                              {
                                                securites[0]['type'] === 'Buy' ? (
                                                  <>
                                                    <th>Sale Load</th>
                                                  </>
                                                ) : (
                                                  <>
                                                    <th>Redemption Load</th>
                                                    <th>CGT</th>
                                                    <th>Zakat</th>
                                                  </>
                                                )
                                              }
                                              <th>Net Amount</th>
                                              <th>Act</th>
                                            </tr>
                                          </thead>
                                          <tbody>{renderInvestmentInFundData()}</tbody>


                                        </table>
                                      </div>
                                    </div>
                                  )}

                                {chechFieldDisplay(headLabel, 'settlement_by') && (
                                  <div className="row">
                                    <div className="col">
                                      {/* <div className="d-flex justify-content-between"> */}
                                      <div className='mt-2'>

                                        <>
                                          <label >Add Security</label>
                                          <br />
                                          <button className=" btn-round btn-icon btn btn-success"
                                            onClick={() => {
                                              setSecuritesEntity((prevState) => ({
                                                ...securitesEntity,
                                                gross_amount: '',
                                                net_amount: '',
                                                market: '',
                                                investment_type: '',
                                                sst_on_commission: '',
                                                commission_rate: '',
                                                // capital_value_tax:'',
                                                net_rate: '',
                                                type: '',
                                                symbol: '',
                                                quantity: '',
                                                avg_rate: '',
                                                commission_charges: '0',
                                                // broker:'',
                                              }));
                                              setViewAddModal(!viewAddModal);
                                              setCloseRate('');
                                            }}
                                          > <i className="fa fa-plus"></i>  </button>
                                        </>
                                      </div>
                                    </div>
                                    {
                                      headLabel !== 'investmentinfund' && (
                                        <div className="col mt-2">
                                          <div>Upload Securities</div>
                                          <div
                                            onClick={() => myRef1?.current?.click()} >
                                            <div className="form-group">
                                              <div className="form-control" data-tip="Upload File">

                                                {filename === '' ? 'Upload File' : filename}
                                              </div>
                                              <input
                                                className="form-control mt-1"
                                                type="file"
                                                ref={myRef1}
                                                style={{ display: 'none' }}
                                                onChange={(e) => {
                                                  uploadCDCFile(e);
                                                }}
                                                onClick={(e) => {
                                                  e.currentTarget.value = '';
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                )}
                                {headLabel == 'equityinvestment' &&
                                  stockMarketData.length > 0 && (
                                    <div className="form-group">
                                      <div className="d-flex justify-content-center my-4">
                                        Current Holding
                                      </div>
                                      <table className="table   px-5">
                                        <thead>
                                          <tr>
                                            <th>Symbol</th>
                                            <th className="text-right">Quantity</th>
                                            <th className="text-right">Avg Rate</th>
                                          </tr>
                                        </thead>
                                        <tbody>{renderStockData()}</tbody>
                                      </table>
                                    </div>
                                  )}
                                {headLabel == 'investmentinfund' &&
                                  InvestmentInFundRecord.length > 0 && (
                                    <div className="form-group">
                                      <div className="d-flex justify-content-center my-4">
                                        Current Holding
                                      </div>
                                      <table className="table   px-5">
                                        <thead>
                                          <tr>
                                            <th>Symbol</th>
                                            <th className="text-right">No of Units</th>
                                            <th className="text-right">Unit price</th>
                                          </tr>
                                        </thead>
                                        <tbody>{renderInvestmentInFundRecord()}</tbody>
                                      </table>
                                    </div>
                                  )}
                                {chechFieldDisplay(headLabel, 'settlement_by') &&
                                  securites.length > 0 && (
                                    <div className="form-group">
                                      <div className="d-flex justify-content-center my-4">
                                        Securities
                                      </div>
                                      <div className="table-responsive">
                                        <table className="table  ">
                                          <thead>
                                            <tr>
                                              <th>Type</th>
                                              <th>Inv Type</th>
                                              <th>Mkt</th>
                                              <th>Symbol</th>
                                              <th>Quantity</th>
                                              <th>Rate</th>
                                              {/* <th>Broker</th> */}
                                              <th>Comm Rate</th>
                                              <th>SST On Comm</th>
                                              {/* <th>Capital Value Tax</th> */}
                                              <th>Net Rate</th>
                                              <th>Gross Amount</th>
                                              <th>Commission</th>
                                              <th>Net Amount</th>
                                              <th>Act</th>
                                            </tr>
                                          </thead>
                                          <tbody>{renderPsxData()}</tbody>


                                        </table>
                                        <div className="ml-3" onClick={() => {
                                          securites.length = 0;
                                          setSecurites(securites);
                                          setFields({
                                            ...fields,
                                            gross_amount: '0',
                                            net_amount: '0',
                                            broker_commission: '0',
                                            tax_sst: '0'
                                          })
                                          setFile('');
                                          setFileName('');
                                        }}>Clear All</div>
                                      </div>
                                    </div>
                                  )}
                                {/* {securites.lenght>0 &&(
                          <>
                          <div className="float-right" onClick={()=>{
                            securites.length=0;
                            setFile('');
                            setFileName('');
                          }}>Clear All</div>
                          </>
                        )} */}

                              </div>
                            )}

                            {(headLabel === 'transferofunits') && (
                              <div className="form-group">
                                <div>Description</div>
                                <input
                                  type="text"
                                  value={fields['detail']}
                                  className={
                                    'form-control w-100'
                                  }
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      detail: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    )}
                    {headLabel === 'cashdividend' && (
                      <>
                        <Col md="4">
                          {headLabel === 'cashdividend' && (
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">Cash Dividend</h4>
                              </div>
                              <div className="card-body">
                                <div>
                                  {chechFieldDisplay(
                                    headLabel,
                                    'dividend_percentage'
                                  ) && (
                                      <div className="form-group">
                                        <div>Dividend Percentage*</div>
                                        <input
                                          type="number"
                                          defaultValue={fields['dividend_percentage']}
                                          className={
                                            'form-control w-100 ' +
                                            ErrorBorder(fields['dividend_percentage'])
                                          }
                                          onChange={(e) => {
                                            // let value=decimalValue(e.target.value,2);
                                            let grossamount = (parseFloat(fields['volume'] || '0') * (parseFloat(e.target.value) / 100) * parseFloat(fields['par_value'] || '0')).toFixed(2);

                                            setFields({
                                              ...fields,
                                              dividend_percentage: e.target.value,
                                              gross_amount: grossamount,
                                            });
                                          }}
                                        />
                                      </div>
                                    )}

                                  {chechFieldDisplay(headLabel, 'credit_date') && (
                                    <div className="form-group">
                                      <div>Dividend Credit Date</div>
                                      <input
                                        type="date"
                                        defaultValue={fields['credit_date']}
                                        className={
                                          'form-control w-100 ' +
                                          ErrorBorder(fields['credit_date'])
                                        }
                                        onChange={(e) => {
                                          let date = moment(e.target.value).format('YYYY-MM-DD')
                                          setFields({
                                            ...fields,
                                            credit_date: date,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}

                                </div>
                              </div>
                            </div>
                          )}
                        </Col>

                        <Col md="4">
                          {headLabel === 'cashdividend' && (
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">Bonus Shares</h4>
                              </div>
                              <div className="card-body">
                                <div>
                                  {chechFieldDisplay(headLabel, 'bonus_percentage') && (
                                    <div className="form-group">
                                      <div>Bonus Percentage*</div>
                                      <input
                                        type="number"
                                        value={fields['bonus_percentage']}
                                        className={
                                          'form-control w-100 ' + ErrorBorder(fields['bonus_percentage'])
                                        }
                                        onChange={(e) => {
                                          let grossvolume = Math.floor(parseFloat(fields['volume'] || '0') * (parseFloat(e.target.value) / 100));

                                          setFields({
                                            ...fields,
                                            bonus_gross_volume: grossvolume.toString(),
                                            bonus_percentage: e.target.value
                                          })
                                        }}
                                      />
                                    </div>
                                  )}

                                  {chechFieldDisplay(headLabel, 'bonus_gross_volume') && (
                                    <div className="form-group">
                                      <div>Gross Bonus Share*</div>
                                      <input
                                        type="number"
                                        value={fields['bonus_gross_volume']}
                                        className={
                                          'form-control w-100 ' + ErrorBorder(fields['bonus_gross_volume'])
                                        }
                                        onChange={(e) => {
                                          let value = e.target.value;
                                          if (!value.includes('.')) {
                                            let tax_it_bonus_amount = Math.floor(parseFloat(value) * (parseFloat(fields['tax_on_bonus'] || '0') / 100));
                                            let net_bonus_volume = Math.floor(parseFloat(value) - tax_it_bonus_amount);
                                            setFields({
                                              ...fields,
                                              bonus_gross_volume: value,
                                              tax_it_bonus_amount: tax_it_bonus_amount.toString(),
                                              net_bonus_volume: net_bonus_volume.toString()
                                            })
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                                  <Row>
                                    <Col>
                                      {chechFieldDisplay(headLabel, 'tax_on_bonus') && (
                                        <div className="form-group">
                                          <div>Tax on Bonus (%)*</div>
                                          <input
                                            type="number"
                                            value={fields['tax_on_bonus']}
                                            className={
                                              'form-control w-100 ' + ErrorBorder(fields['tax_on_bonus'])
                                            }
                                            onChange={(e) => {
                                              let tax_it_bonus_amount = Math.floor(parseFloat(fields['bonus_gross_volume'] || '0') * (parseFloat(e.target.value) / 100));
                                              let net_bonus_volume = Math.floor(parseFloat(fields['bonus_gross_volume'] || '0') - tax_it_bonus_amount);

                                              setFields({
                                                ...fields,
                                                tax_on_bonus: e.target.value,
                                                tax_it_bonus_amount: tax_it_bonus_amount.toString(),
                                                net_bonus_volume: net_bonus_volume.toString()
                                              })
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                    <Col>
                                      {chechFieldDisplay(headLabel, 'tax_it_bonus_amount') && (
                                        <div className="form-group">
                                          <div>Tax on Bonus Shares</div>
                                          <input
                                            type="number"
                                            value={fields['tax_it_bonus_amount']}
                                            className={
                                              'form-control w-100 ' + ErrorBorder(fields['tax_it_bonus_amount'])
                                            }
                                            onChange={(e) => {
                                              let value = e.target.value;
                                              if (!value.includes('.')) {
                                                let net_bonus_volume = Math.floor(parseFloat(fields['bonus_gross_volume'] || '0') - parseFloat(value));

                                                setFields({
                                                  ...fields,
                                                  tax_it_bonus_amount: value,
                                                  net_bonus_volume: net_bonus_volume.toString()
                                                })
                                              }
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  </Row>

                                  {chechFieldDisplay(headLabel, 'net_bonus_volume') && (
                                    <div className="form-group">
                                      <div>Net Bonus Shares</div>
                                      <input
                                        type="number"
                                        value={fields['net_bonus_volume']}
                                        className={
                                          'form-control w-100 ' + ErrorBorder(fields['net_bonus_volume'])
                                        }
                                        onChange={(e) => {
                                          let value = e.target.value;
                                          if (!value.includes('.')) {

                                            setFields({
                                              ...fields,
                                              net_bonus_volume: value,
                                            })
                                          }
                                        }}
                                      />
                                    </div>
                                  )}

                                  {chechFieldDisplay(headLabel, 'bonus_credit_date') && (
                                    <div className="form-group">
                                      <div>Bonus Credit Date</div>
                                      <input
                                        type="date"
                                        defaultValue={fields['bonus_credit_date']}
                                        className={
                                          'form-control w-100 ' + ErrorBorder(fields['bonus_credit_date'])
                                        }
                                        onChange={(e) => {
                                          let date = moment(e.target.value).format('YYYY-MM-DD')
                                          setFields({
                                            ...fields,
                                            bonus_credit_date: date,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}

                                </div>
                              </div>
                            </div>
                          )}
                        </Col>

                        <Col md="4">
                          {headLabel === 'cashdividend' && (
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">Right Shares</h4>
                              </div>
                              <div className="card-body">
                                <div>
                                  {chechFieldDisplay(headLabel, 'right_share_percentage') && (
                                    <div className="form-group">
                                      <div>Right Share Percentage*</div>
                                      <input
                                        type="number"
                                        value={fields['right_share_percentage']}
                                        className={
                                          'form-control w-100 ' + ErrorBorder(fields['right_share_percentage'])
                                        }
                                        onChange={(e) => {
                                          let right_share_gross_volume = Math.floor(parseFloat(fields['volume'] || '0') * (parseFloat(e.target.value) / 100))
                                          setFields({
                                            ...fields,
                                            right_share_percentage: e.target.value,
                                            right_share_gross_volume: right_share_gross_volume.toString()
                                          })
                                        }}
                                      />
                                    </div>
                                  )}

                                  {chechFieldDisplay(headLabel, 'right_share_gross_volume') && (
                                    <div className="form-group">
                                      <div>Gross Right Share*</div>
                                      <input
                                        type="number"
                                        value={fields['right_share_gross_volume']}
                                        className={
                                          'form-control w-100 ' + ErrorBorder(fields['right_share_gross_volume'])
                                        }
                                        onChange={(e) => {
                                          let value = e.target.value;
                                          if (!value.includes('.')) {
                                            setFields({
                                              ...fields,
                                              right_share_gross_volume: value
                                            })
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                                  <Row>
                                    <Col>
                                      {chechFieldDisplay(headLabel, 'tax_on_right') && (
                                        <div className="form-group">
                                          <div>Tax on Right (%)*</div>
                                          <input
                                            type="number"
                                            value={fields['tax_on_right']}
                                            className={
                                              'form-control w-100 ' + ErrorBorder(fields['tax_on_right'])
                                            }
                                            onChange={(e) => {
                                              let tax_it_right_share_amount = Math.floor(parseFloat(fields['right_share_gross_volume'] || '0') * (parseFloat(e.target.value) / 100));
                                              let net_right_share_volume = Math.floor(parseFloat(fields['right_share_gross_volume'] || '0') - tax_it_right_share_amount);

                                              setFields({
                                                ...fields,
                                                tax_on_right: e.target.value,
                                                tax_it_right_share_amount: tax_it_right_share_amount.toString(),
                                                net_right_share_volume: net_right_share_volume.toString()
                                              })
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                    <Col>
                                      {chechFieldDisplay(headLabel, 'tax_it_right_share_amount') && (
                                        <div className="form-group">
                                          <div>Tax on Right Shares</div>
                                          <input
                                            type="number"
                                            value={fields['tax_it_right_share_amount']}
                                            className={
                                              'form-control w-100 ' + ErrorBorder(fields['tax_it_right_share_amount'])
                                            }
                                            onChange={(e) => {
                                              let value = e.target.value;
                                              if (!value.includes('.')) {
                                                let net_right_share_volume = Math.floor(parseFloat(fields['right_share_gross_volume'] || '0') - parseFloat(value));
                                                setFields({
                                                  ...fields,
                                                  tax_it_right_share_amount: value,
                                                  net_right_share_volume: net_right_share_volume.toString()
                                                })
                                              }
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  </Row>

                                  {chechFieldDisplay(headLabel, 'net_right_share_volume') && (
                                    <div className="form-group">
                                      <div>Net Right Shares</div>
                                      <input
                                        type="number"
                                        value={fields['net_right_share_volume']}
                                        className={
                                          'form-control w-100 ' + ErrorBorder(fields['net_right_share_volume'])
                                        }
                                        onChange={(e) => {
                                          let value = e.target.value;
                                          if (!value.includes('.')) {
                                            setFields({
                                              ...fields,
                                              net_right_share_volume: e.target.value
                                            })
                                          }
                                        }}
                                      />
                                    </div>
                                  )}

                                  {chechFieldDisplay(headLabel, 'right_share_credit_date') && (
                                    <div className="form-group">
                                      <div>Right Share Credit Date</div>
                                      <input
                                        type="date"
                                        defaultValue={fields['right_share_credit_date']}
                                        className={
                                          'form-control w-100 ' + ErrorBorder(fields['right_share_credit_date'])
                                        }
                                        onChange={(e) => {
                                          let date = moment(e.target.value).format('YYYY-MM-DD')
                                          setFields({
                                            ...fields,
                                            right_share_credit_date: date,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}

                                </div>
                              </div>
                            </div>
                          )}
                        </Col>
                      </>
                    )}
                    {(headLabel !== 'pledgedmark' && headLabel !== 'pledgedrelease' && headLabel !== 'transferofunits') && (
                      <Col>
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">Amount</h4>
                          </div>
                          <div className="card-body">
                            <div>
                              <div className="form-group">
                                <div>Gross Amount*  {grossAmountSeparator === '' ? '' : <span className="float-right text-primary">{grossAmountSeparator}</span>}</div>
                                <input
                                  type="number"
                                  value={fields['gross_amount']}
                                  className={
                                    'form-control w-100 ' +
                                    ErrorBorder(fields['gross_amount'])
                                  }
                                  readOnly={grossAmountReadOnly}
                                  onChange={(e) => {
                                    let value = decimalValue(e.target.value, 2);
                                    if (value.split('.')[0].length <= 12) {
                                      setTotalRemainHolding(value, "GrossAmount");
                                      value === '' ? setUnitsReadOnly(false) : setUnitsReadOnly(true);
                                      value === '' ? setDiscount_Applicable_ReadOnly(false) : setDiscount_Applicable_ReadOnly(true);
                                    }
                                  }}
                                />
                                <small className="truncate" title={grossAmountInWords}>{grossAmountInWords === '' ? '' : (grossAmountInWords)}</small>
                              </div>

                              {chechFieldDisplay(headLabel, 'sale_load') && (
                                <div className="form-group">
                                  <div>Sale Load</div>
                                  <input
                                    type="number"
                                    value={fields['sale_load']}
                                    className={
                                      'form-control w-100 ' +
                                      ErrorBorder(fields['sale_load'])
                                    }
                                    onChange={(e) => {
                                      let value = decimalValue(e.target.value, 2);
                                      let netamount = parseFloat(fields['gross_amount'].replace('', '0')) - (parseFloat(value.replace('', '0')));
                                      // let unit=parseFloat(fields['net_amount'])/parseFloat(fields['nav']);
                                      // let unit=netamount/parseFloat(fields['nav']);
                                      // let a=unit.toFixed(4).toString();
                                      // let remainingholding=parseFloat(fields['current_holding']) + parseFloat(a)
                                      // let remainholding=remainingholding.toFixed(4).toString();
                                      setFields({
                                        ...fields,
                                        sale_load: value,
                                        net_amount: netamount.toFixed(2)
                                        // units:a,
                                        // total_holding:remainholding
                                      })

                                      let netamountWords = inWords(netamount.toString());
                                      setNetAmountInWords(netamountWords);

                                      let netAmountSeparator = numberWithCommas(netamount.toString());
                                      setNetAmountSeparator(netAmountSeparator);
                                    }}
                                  />
                                </div>
                              )}
                              {chechFieldDisplay(headLabel, 'redemption_load') && (
                                <div className="form-group">
                                  <div>Redemption Load</div>
                                  <input
                                    type="number"
                                    value={fields['redemption_load']}
                                    className={
                                      'form-control w-100 ' +
                                      ErrorBorder(fields['redemption_load'])
                                    }
                                    onChange={(e) => {
                                      let value = decimalValue(e.target.value, 2);
                                      let netamount = (parseFloat(fields['gross_amount']) - (parseFloat(fields['tax_cgt'].replace('', '0'))) - (parseFloat(value.replace('', '0'))) - (parseFloat(fields['zakat_amount'] || '0'))).toFixed(2);
                                      // let unit=parseFloat(netamount)/parseFloat(fields['nav']);
                                      // let a=unit.toFixed(4).toString();

                                      // let remainingholding=parseFloat(fields['current_holding']) - parseFloat(a)
                                      // let remainholding=remainingholding.toFixed(4).toString(); 
                                      setFields({
                                        ...fields,
                                        redemption_load: value,
                                        net_amount: netamount.toString(),
                                        // units:a,
                                        // remain_holding:remainholding
                                      })
                                      let netamountWords = inWords(netamount.toString());
                                      setNetAmountInWords(netamountWords);

                                      let netAmountSeparator = numberWithCommas(netamount.toString());
                                      setNetAmountSeparator(netAmountSeparator);
                                    }}
                                  />
                                </div>
                              )}
                              {chechFieldDisplay(headLabel, 'tax_cgt') && (
                                <div className="form-group">
                                  <div>CGT</div>
                                  <input
                                    type="number"
                                    value={fields['tax_cgt']}
                                    className={
                                      'form-control w-100 ' +
                                      ErrorBorder(fields['tax_cgt'])
                                    }
                                    onChange={(e) => {
                                      let value = decimalValue(e.target.value, 2);
                                      let netamount = (parseFloat(fields['gross_amount']) - (parseFloat(fields['redemption_load'].replace('', '0'))) - (parseFloat(value.replace('', '0'))) - (parseFloat(fields['zakat_amount'] || '0'))).toFixed(2);
                                      // let unit=parseFloat(fields['net_amount'])/parseFloat(fields['nav']);
                                      // let unit=parseFloat(netamount)/parseFloat(fields['nav']);
                                      // let a=unit.toFixed(4).toString();

                                      // let remainingholding=parseFloat(fields['current_holding']) - parseFloat(a)
                                      // let remainholding=remainingholding.toFixed(4).toString(); 
                                      setFields({
                                        ...fields,
                                        tax_cgt: value,
                                        net_amount: netamount.toString(),
                                        // units:a,
                                        // remain_holding:remainholding
                                      })
                                      let netamountWords = inWords(netamount.toString());
                                      setNetAmountInWords(netamountWords);

                                      let netAmountSeparator = numberWithCommas(netamount.toString());
                                      setNetAmountSeparator(netAmountSeparator);
                                    }}
                                  />
                                </div>
                              )}

                              {(headLabel === 'redemptionofunits' || headLabel === 'conversionout' || headLabel === 'unitconversion') && (
                                <div className="form-group">
                                  <div>Zakat</div>
                                  <input
                                    type="number"
                                    value={fields['zakat_amount']}
                                    className={
                                      'form-control w-100 ' +
                                      ErrorBorder(fields['zakat_amount'])
                                    }
                                    onChange={(e) => {
                                      let value = decimalValue(e.target.value, 2);
                                      let netamount = (parseFloat(fields['gross_amount']) - parseFloat(fields['redemption_load'] || '0') - parseFloat(fields['tax_cgt'] || '0') - parseFloat(value)).toFixed(2);
                                      setFields({
                                        ...fields,
                                        zakat_amount: value,
                                        net_amount: netamount,
                                      });
                                      let netamountWords = inWords(netamount.toString());
                                      setNetAmountInWords(netamountWords);

                                      let netAmountSeparator = numberWithCommas(netamount.toString());
                                      setNetAmountSeparator(netAmountSeparator);
                                    }}
                                  />
                                </div>
                              )}

                              {/* <div className="row">
                            <div className="col-md-10">
                                <div className="form-group">
                                    <div>Charges</div>
                                    <input type="text" value={fields['total_charges']} className="form-control w-100 disable-input" readOnly />
                                </div>
                            </div>


                         <div className="col-md-2 mt-3">
                      <ReactTooltip textColor='white' backgroundColor='black' effect="float" />
                        <button data-tip="Add Tax" className=" btn-round btn-icon btn btn-primary" disabled={disableAddTaxBtn} onClick={() => {
                         setViewAddTaxModal(!viewAddTaxModal);
                         setDisableAddBtn(false);
                        }} > <i className="fa fa-plus"></i> </button>
                        </div>
                        </div>   */}

                              {chechFieldDisplay(headLabel, 'tax_sst') && (
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <div>SST/PST %</div>
                                      <input type="number" value={sstPercentage} className="form-control w-100"
                                        onChange={(e) => {
                                          let value = decimalValue(e.target.value, 2);
                                          let points = parseFloat(value || '0') / 100;
                                          let calAmount = (points * parseFloat(fields['gross_amount'])).toFixed(2);
                                          calAmount = calAmount === '0.00' ? '0' : calAmount;
                                          let totalcharges = (parseFloat(fields['gross_amount']) + parseFloat(calAmount)).toFixed(2);
                                          setSSTPercentage(value);
                                          let whtsstamount = ((parseFloat(sstWHTPercentage) / 100) * parseFloat(calAmount)).toFixed(2);
                                          let whtitAmount = ((parseFloat(whtITPercentage) / 100) * parseFloat(totalcharges)).toFixed(2);
                                          let netamount = (parseFloat(totalcharges) - parseFloat(whtitAmount) - parseFloat(whtsstamount)).toFixed(2);
                                          setFields({
                                            ...fields,
                                            tax_sst: calAmount.toString(),
                                            total_charges: totalcharges.toString(),
                                            tax_wht_it: whtitAmount.toString(),
                                            tax_wht_sst: whtsstamount.toString(),
                                            net_amount: netamount.toString()
                                          });

                                          let inwordNetAmount = inWords(netamount);
                                          setNetAmountInWords(inwordNetAmount);
                                          let netAmountSeparator = numberWithCommas(netamount);
                                          setNetAmountSeparator(netAmountSeparator);
                                        }} />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="form-group">
                                      <div>SST/PST Amount</div>
                                      <input
                                        type="number"
                                        value={fields['tax_sst']}
                                        className={'form-control w-100' + ErrorBorder(fields['tax_sst'])}
                                        onChange={(e) => {
                                          let value = decimalValue(e.target.value, 2);
                                          let percentage = (parseFloat(value) / parseFloat(fields['gross_amount']) * 100).toFixed(2);
                                          let totalcharges = (parseFloat(fields['gross_amount']) + parseFloat(value)).toFixed(2);
                                          let sstwhtamount = ((parseFloat(sstWHTPercentage) / 100) * parseFloat(value)).toFixed(2);
                                          let whtitAmount = ((parseFloat(whtITPercentage) / 100) * parseFloat(totalcharges)).toFixed(2);
                                          let netamount = (parseFloat(totalcharges) - parseFloat(whtitAmount) - parseFloat(sstwhtamount)).toFixed(2);
                                          setSSTPercentage(percentage.toString());
                                          setFields({
                                            ...fields,
                                            tax_sst: value,
                                            total_charges: totalcharges.toString(),
                                            tax_wht_it: whtitAmount.toString(),
                                            tax_wht_sst: sstwhtamount.toString(),
                                            net_amount: netamount.toString()
                                          });
                                          let inwordNetAmount = inWords(netamount);
                                          setNetAmountInWords(inwordNetAmount);
                                          let netAmountSeparator = numberWithCommas(netamount);
                                          setNetAmountSeparator(netAmountSeparator);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                              {chechFieldDisplay(headLabel, 'total_charges') && (
                                <div className="form-group">
                                  <div className="d-flex">
                                    <div>Total Amount</div>
                                  </div>
                                  <input
                                    type="number"
                                    value={fields['total_charges']}
                                    className={
                                      'form-control w-100' + ErrorBorder(fields['total_charges'])
                                    }
                                    readOnly
                                    onChange={(e) => {
                                      let value = decimalValue(e.target.value, 2);

                                    }}
                                  />
                                </div>
                              )}
                              {chechFieldDisplay(headLabel, 'tax_wht_it') && (
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <div>WH IT %</div>
                                      <input type="number" value={whtITPercentage} className="form-control w-100"
                                        onChange={(e) => {
                                          let value = decimalValue(e.target.value, 2);
                                          // if(value.length===0){
                                          //   setFields({
                                          //     ...fields,
                                          //     tax_wht_it:'0'
                                          //   });
                                          //   setWHTITPercentage('0');
                                          // }else{
                                          let points = parseFloat(value || '0') / 100;
                                          let calAmount = (points * parseFloat(fields['total_charges'])).toFixed(2);
                                          calAmount = calAmount === '0.00' ? '0' : calAmount;
                                          setWHTITPercentage(value)
                                          let netamount = (parseFloat(fields['total_charges']) - parseFloat(fields['tax_wht_sst']) - parseFloat(calAmount)).toFixed(2);
                                          netamount = netamount === '0.00' ? '0' : netamount;
                                          setFields({
                                            ...fields,
                                            tax_wht_it: calAmount.toString(),
                                            net_amount: netamount.toString()
                                          });
                                          let inwordNetAmount = inWords(netamount);
                                          setNetAmountInWords(inwordNetAmount);
                                          let netAmountSeparator = numberWithCommas(netamount);
                                          setNetAmountSeparator(netAmountSeparator);
                                          // }
                                        }} />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="form-group">
                                      <div>WH Income Tax Amount</div>
                                      <input
                                        type="number"
                                        value={fields['tax_wht_it']}
                                        className={
                                          'form-control w-100' + ErrorBorder(fields['tax_wht_it'])
                                        }
                                        onChange={(e) => {
                                          let value = decimalValue(e.target.value, 2);
                                          let percentage = (parseFloat(value) / parseFloat(fields['total_charges']) * 100).toFixed(1);
                                          // let totalcharges=parseFloat(fields['gross_amount']) + parseFloat(value);
                                          let netamount = (parseFloat(fields['total_charges']) - parseFloat(fields['tax_wht_sst']) - parseFloat(value)).toFixed(2);
                                          setWHTITPercentage(percentage.toString());
                                          setFields({
                                            ...fields,
                                            tax_wht_it: value,
                                            net_amount: netamount.toString()
                                          });
                                          let inwordNetAmount = inWords(netamount);
                                          setNetAmountInWords(inwordNetAmount);
                                          let netAmountSeparator = numberWithCommas(netamount);
                                          setNetAmountSeparator(netAmountSeparator);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                              {chechFieldDisplay(headLabel, 'tax_wht_sst') && (
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <div>WH ST %</div>
                                      <input type="number" value={sstWHTPercentage} className="form-control w-100"
                                        onChange={(e) => {
                                          let value = decimalValue(e.target.value, 2);
                                          // if(value.length===0){
                                          //   setFields({
                                          //     ...fields,
                                          //     tax_wht_sst:'0'
                                          //   });
                                          //   setSSTWHTPercentage('0');
                                          // }else{
                                          let points = parseFloat(value || '0') / 100;
                                          let calAmount = (points * parseFloat(fields['tax_sst'])).toFixed(2);
                                          calAmount = calAmount === '0.00' ? '0' : calAmount;
                                          setSSTWHTPercentage(value)
                                          let netamount = (parseFloat(fields['total_charges']) - parseFloat(fields['tax_wht_it']) - parseFloat(calAmount)).toFixed(2);
                                          netamount = netamount === '0.00' ? '0' : netamount;
                                          setFields({
                                            ...fields,
                                            tax_wht_sst: calAmount.toString(),
                                            net_amount: netamount
                                          });
                                          let inwordNetAmount = inWords(netamount);
                                          setNetAmountInWords(inwordNetAmount);
                                          let netAmountSeparator = numberWithCommas(netamount);
                                          setNetAmountSeparator(netAmountSeparator);
                                          // }
                                        }} />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="form-group">
                                      <div>WH Sale Tax Amount</div>
                                      <input
                                        type="number"
                                        value={fields['tax_wht_sst']}
                                        className={
                                          'form-control w-100' + ErrorBorder(fields['tax_wht_sst'])
                                        }
                                        onChange={(e) => {
                                          let value = decimalValue(e.target.value, 2);
                                          let percentage = (parseFloat(value) / parseFloat(fields['tax_sst']) * 100).toFixed(1);

                                          setSSTWHTPercentage(percentage.toString());
                                          let netamount = (parseFloat(fields['total_charges']) - parseFloat(fields['tax_wht_it']) - parseFloat(value)).toFixed(2);
                                          setFields({
                                            ...fields,
                                            tax_wht_sst: value,
                                            net_amount: netamount.toString()
                                          });
                                          let inwordNetAmount = inWords(netamount);
                                          setNetAmountInWords(inwordNetAmount);
                                          let netAmountSeparator = numberWithCommas(netamount);
                                          setNetAmountSeparator(netAmountSeparator);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}


                              {/* {totalTax.length > 0 && (
                        <div className="form-group mb-3 mx-3">
                          <table className="table  ">
                            <thead>
                              <tr>
                                <th>Tax Name</th>
                                <th>Tax Amount</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>{renderTaxData()}</tbody>
                          </table>
                        </div>
                      )} */}


                              {headLabel === 'equityinvestment' && (
                                <>
                                  <div className="form-group">
                                    <div>Brokerage Commission</div>
                                    <input
                                      type="number"
                                      value={fields['broker_commission']}
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['broker_commission'])
                                      }
                                      onChange={(e) => {
                                        let value = decimalValue(e.target.value, 2);
                                        setFields({
                                          ...fields,
                                          broker_commission: value
                                        })

                                      }}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <div>SST on Commission</div>
                                    <input
                                      type="number"
                                      value={fields['tax_sst']}
                                      className={
                                        'form-control w-100 ' +
                                        ErrorBorder(fields['tax_sst'])
                                      }
                                      onChange={(e) => {
                                        let value = decimalValue(e.target.value, 2);
                                        setFields({
                                          ...fields,
                                          tax_sst: value
                                        })
                                      }}
                                    />
                                  </div>


                                </>
                              )}

                              {headLabel === 'cashdividend' && (

                                <Row>
                                  <Col>
                                    <div className="form-group">
                                      <div>Tax Percentage on Dividend</div>
                                      <input
                                        type="number"
                                        defaultValue={fields['tax_on_dvidend']}
                                        className={
                                          'form-control w-100 ' +
                                          ErrorBorder(fields['tax_on_dvidend'])
                                        }
                                        onChange={(e) => {
                                          // let value=decimalValue(e.target.value,2);
                                          let tax_wht_it = (parseFloat(fields['gross_amount'] || '0') * (parseFloat(e.target.value) / 100)).toFixed(2);
                                          let netamount = (parseFloat(fields['gross_amount'] || '0') - parseFloat(tax_wht_it)).toFixed(2);

                                          setFields({
                                            ...fields,
                                            tax_on_dvidend: e.target.value,
                                            tax_wht_it: tax_wht_it,
                                            net_amount: netamount
                                          });
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="form-group">
                                      <div>Tax Amount on Dividend</div>
                                      <input
                                        type="number"
                                        defaultValue={fields['tax_wht_it']}
                                        className={
                                          'form-control w-100 ' +
                                          ErrorBorder(fields['tax_wht_it'])
                                        }
                                        onChange={(e) => {
                                          // let value=decimalValue(e.target.value,2);
                                          let netamount = (parseFloat(fields['gross_amount']) - parseFloat(e.target.value)).toFixed(2);
                                          setFields({
                                            ...fields,
                                            tax_wht_it: e.target.value,
                                            net_amount: netamount,
                                          });
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                              )}

                              <div className="form-group">
                                {/* <div className="d-flex"> */}
                                <div>Net Amount* {netAmountSeparator === '' ? '' : <span className="float-right text-primary">{netAmountSeparator}</span>}</div>

                                {headLabel === 'equityinvestment' && (
                                  <div
                                    className="ml-2"
                                    style={{
                                      color:
                                        fields.payment_type === 'Receipt'
                                          ? 'red'
                                          : 'green',
                                    }}
                                  >
                                    {headLabel === 'equityinvestment' &&
                                      fields['net_amount'] !== '0.00' &&
                                      securites.length > 0
                                      ? fields.payment_type === 'Payment'
                                        ? '(Debit)'
                                        : fields.payment_type === 'Receipt'
                                          ? '(Credit)'
                                          : ''
                                      : ''}
                                  </div>
                                )}
                                {/* {headLabel === 'debtmarketinvestment'  && (
                          <div
                            className="ml-2"
                            style={{
                              color:
                                fields.payment_type === 'Receipt'
                                  ? 'red'
                                  : 'green',
                            }}
                          >
                            {headLabel === 'debtmarketinvestment'  &&
                            fields['net_amount'] !== '0.00' &&
                            moneyMarketSecurities.length > 0
                              ? fields.payment_type === 'Payment'
                                ? '(Debit)'
                                : fields.payment_type === 'Receipt'
                                ? '(Credit)'
                                : ''
                              : ''}
                          </div>
                        )} */}

                                {/* </div> */}
                                <input
                                  type="number"
                                  value={fields['net_amount']}
                                  className={
                                    'form-control w-100 ' + ErrorBorder(fields['net_amount'])
                                  }
                                  readOnly={netAmountReadOnly}
                                  onChange={(e) => {
                                    let value = decimalValue(e.target.value, 2);
                                    if (value.split('.')[0].length <= 12) {
                                      setTotalRemainHolding(value, "NetAmount");
                                      if (value !== '') {
                                        let a = inWords(value);
                                        setNetAmountInWords(a);

                                        //// separator
                                        let netAmountSeparator = numberWithCommas(value);
                                        setNetAmountSeparator(netAmountSeparator);
                                      } else {
                                        setNetAmountInWords('');
                                        setNetAmountSeparator('');
                                      }
                                    }
                                  }}
                                />
                                <small className="truncate" title={netAmountInWords}>{netAmountInWords === '' ? '' : (netAmountInWords)}</small>
                              </div>

                              <div className="form-group">
                                <div> Payment Type </div>
                                <div className="form-control" >
                                  {fields['payment_type']}
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>

                  {(headLabel !== 'unitconversion' && finishFlag == false) && (
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            createTransaction();
                          }}
                          disabled={Boolean(Loading)}
                        >
                          {Loading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>{tx === 'cashdividend' ? 'Update' : 'Create'}</span>
                          )}
                        </button>
                      </div>
                    </div>
                  )}

                  {headLabel === 'unitconversion' && (
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            // createTransaction();
                            Next();
                          }}
                          disabled={Boolean(Loading)}
                        >
                          {Loading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>{flag ? 'Update' : 'Next'}</span>
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                  {finishFlag == true && (
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            // createTransaction();
                            Finish();
                          }}
                          disabled={Boolean(Loading)}
                        >
                          {Loading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>{flag ? 'Update' : 'Finish'}</span>
                          )}
                        </button>
                      </div>
                    </div>
                  )}

                </div>
                {renderModalForSecuirtyPopup()}
                {renderModalForMoneyMarketSecurities()}
                {renderModalUnitsTransferees()}
                {renderModalAddBranchCity()}
                {renderModalForTaxPopup()}
                {renderModalForBankBalance()}
                {renderModalAddNewUnitHolder()}
                {renderModalInvestmentInFundPopup()}
                {renderModalAddInvestmentInFund()}
              </div>
              <div style={{ display: 'none' }}>{accTilteName}</div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TransactionTxnFields;

