import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

const getDashboardData = async (
  email: string,
  code: string
) => {
  const url = `${Config.baseUrl}/dashboard/amc-dashboard?email=${email}&amc_code=${code}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getDashboardData(email, code)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const getDashboardTransactions = async (
  email: string,
  start_date: string,
  end_date: string,
  amc_code: string
) => {
  const url = `${Config.baseUrl}/dashboard/dashboard-txn?email=${email}&amc_code=${amc_code}&start_date=${start_date}&end_date=${end_date}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getDashboardTransactions(email, start_date, end_date, amc_code)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

const ConvertToExponential = (num, digits) => {
  if (num == 0) {
    return 0;
  } else {
    const symbols = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const numToCheck = Math.abs(num);
    for (let i = symbols.length - 1; i >= 0; i--) {
      if (numToCheck >= symbols[i].value) {
        const newNumber = (num / symbols[i].value).toFixed(digits);
        return `${newNumber}${symbols[i].symbol}`;
      }
    }
    return num.toExponential(digits);
  }
};

export { getDashboardData, getDashboardTransactions, ConvertToExponential };
