import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param email
 * @param name
 * @param code
 * @param companies
 */
const addSecurity = async (
  email: string,
  name: string,
  code: string,
  companies: any
) => {
  const url = `${Config.baseUrl}/security/`;
  try {
    const fd = new FormData();
    fd.set('name', name);
    fd.set('email', email);
    fd.set('code', code);
    fd.set('companies', companies);
    const result: any = await axios.post(url, fd, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await addSecurity(email, name, code, companies)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 */
// const getAllSecurities = async (email: string) => {
//   const url = `${Config.baseUrl}/security?email=${email}`;
//   try {
//     const result: any = await axios.get(url, {
//       headers: {
//         Authorization: sessionStorage.getItem('token') || '',
//       },
//     });
//     return result;
//   } catch (err: any) {
//     if(err.response.data.status == 401) { 
//       let responseToHandle = err.response.data
//       let result = await RefreshTokenHandler.handleIt(responseToHandle)
//       if (result.status) {
//         return await getAllSecurities(email)
//       } else {
//         throw err;
//         // return result
//       }
//     }else {
//       throw err
//     }
//   }
// };

const getAllSecurities = async (
  email: string,
  page_number: string = '',
  page_size: string = ''
) => {
  const url = `${Config.baseUrl}/security?email=${email}&page_number=${page_number}&page_size=${page_size}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getAllSecurities(email,page_number, page_size)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 * @param email
 *
 */
const getAllTransactionFilter = async (
  email: string,
  amc_code: string,
  type_of_security: string
) => {
  const url = `${Config.baseUrl}/security/query-data?email=${email}&amc_code=${amc_code}&type_of_security=${type_of_security}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAllTransactionFilter(email, amc_code, type_of_security)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 *
 */
const getAllPsx = async (email: string) => {
  const url = `${Config.baseUrl}/security/psx?email=${email}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getAllPsx(email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

// Fund add as a security
const addFundSecurity = async (
  email: string,
  name: string,
  code: string,
  price: string,
  // companies: any
) => {
  const url = `${Config.baseUrl}/security/fund_security`;
  try{
  // var fd = new FormData();
  // fd.set('name', name);
  // fd.set('email', email);
  // fd.set('code', code);
  // fd.set('security_type', security_type);
  // fd.set('issue_date', issue_date);
  // fd.set('maturity_date', maturity_date);
  // fd.set('face_value', face_value);
  // fd.set('price', price);
  //   // fd.set('companies', companies);
   let obj= {
    'fund_name': name,
    'email': email,
    'fund_code': code,
    'price': price,
    'security_type':"FUND"
   }
  const result: any = await axios.post(url, obj, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await addFundSecurity(email,name,code,price)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const getAllFundSecurity = async (email: string) => {
  const url = `${Config.baseUrl}/security/fund_security?email=${email}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getAllFundSecurity(email)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};
const getAllActiveFundSecurity = async (email: string) => {
  const url = `${Config.baseUrl}/security/fund_security-active?email=${email}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getAllActiveFundSecurity(email)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

export { getAllPsx, getAllTransactionFilter, addSecurity, getAllSecurities,addFundSecurity,getAllFundSecurity,getAllActiveFundSecurity };
