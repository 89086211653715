// import React from "react";
// import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// // javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
// // react plugin for creating notifications over the dashboard
// import NotificationAlert from "react-notification-alert";

// import routes from "../../routes.js";
// import routes2 from "../../auth-a-routes.js";
// import routes3 from "../../auth-b-routes.js";
// // core components
// import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
// import Header from '../../components/Header';
// import Footer from "../../components/Footer/Footer.js";
// import Sidebar from "../../components/Sidebar/Sidebar.js";
// // import FixedPlugin from "../../components/FixedPlugin/FixedPlugin.js";
// import FixedPlugin from "../../components/FixedPlugin.tsx";
// import logo from "../../assets/macs-logo.png";
// // import Dashboard from "./containers/DashboardPage";
// import TransactionTxnFields from '../../containers/single-beta-txn';
// import FundDividendPayout from '../../containers/fund-dividend-payout-txn';
// import SetupClients from "../../containers/unit-holder/setup-clients.tsx";
// import ViewClient from "../../containers/unit-holder/view-client.tsx";
// import EditClient from "../../containers/unit-holder/edit-client.tsx";
// import BulkUplaod from "../../containers/bulk-upload.tsx";
//  import ComplianceSignatoryTransactions from "../../containers/comp-sign-listing.tsx";
//  import UserManage from "../../containers/auth/user-setting";
//  import SignatoryA from "../../containers/signatory-a";
//  import NotificattionScreen from "../../containers/notification-screen.tsx";

//  // reports
//  import BankStatement from "../../containers/reports/bank-statement";
//  import RecieptPaymentRegisterReport from "../../containers/reports/reciept-payment-register-report";

// var ps;

// const Admin = (props) => {
//   const [activeColor, setActiveColor] = React.useState("blue");
//   const [sidebarMini, setSidebarMini] = React.useState(true);
//   const [opacity, setOpacity] = React.useState(0);
//   const [sidebarOpened, setSidebarOpened] = React.useState(false);
//   const mainPanelRef = React.useRef(null);
//   const notificationAlertRef = React.useRef(null);
//   const location = useLocation();
//   React.useEffect(() => {
//     document.documentElement.scrollTop = 0;
//     document.scrollingElement.scrollTop = 0;
//     if (mainPanelRef.current) {
//       mainPanelRef.current.scrollTop = 0;
//     }
//   }, [location]);
//   React.useEffect(() => {
//     let innerMainPanelRef = mainPanelRef;
//     if (navigator.platform.indexOf("Win") > -1) {
//       document.documentElement.classList.add("perfect-scrollbar-on");
//       document.documentElement.classList.remove("perfect-scrollbar-off");
//       ps = new PerfectScrollbar(mainPanelRef.current);
//       mainPanelRef.current &&
//         mainPanelRef.current.addEventListener("ps-scroll-y", showNavbarButton);
//       let tables = document.querySelectorAll(".table-responsive");
//       for (let i = 0; i < tables.length; i++) {
//         ps = new PerfectScrollbar(tables[i]);
//       }
//     }
//     window.addEventListener("scroll", showNavbarButton);
//     return function cleanup() {
//       if (navigator.platform.indexOf("Win") > -1) {
//         ps.destroy();
//         document.documentElement.classList.add("perfect-scrollbar-off");
//         document.documentElement.classList.remove("perfect-scrollbar-on");
//         innerMainPanelRef.current &&
//           innerMainPanelRef.current.removeEventListener(
//             "ps-scroll-y",
//             showNavbarButton
//           );
//       }
//       window.removeEventListener("scroll", showNavbarButton);
//     };
    
//   }, []);
//   const showNavbarButton = () => {
//     if (
//       document.documentElement.scrollTop > 50 ||
//       document.scrollingElement.scrollTop > 50 ||
//       (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)
//     ) {
//       setOpacity(1);
//     } else if (
//       document.documentElement.scrollTop <= 50 ||
//       document.scrollingElement.scrollTop <= 50 ||
//       (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
//     ) {
//       setOpacity(0);
//     }
//   };
//   const getRoutes = (routes) => {
//     return routes.map((prop, key) => {
//       if (prop.collapse) {
//         return getRoutes(prop.views);
//       }
//       if (prop.layout === "/admin") {
//         return (
//           <Route
//             path={prop.layout + prop.path}
//             component={prop.component}
//             key={key}
//           />
//         );
//       } else {
//         return null;
//       }
//     });
//   };
//   const getActiveRoute = (routes) => {
//     let activeRoute = "Default Brand Text";
//     for (let i = 0; i < routes.length; i++) {
//       if (routes[i].collapse) {
//         let collapseActiveRoute = getActiveRoute(routes[i].views);
//         if (collapseActiveRoute !== activeRoute) {
//           return collapseActiveRoute;
//         }
//       } else {
//         if (
//           window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
//         ) {
//           return routes[i].name;
//         }
//       }
//     }
//     return activeRoute;
//   };
//   const handleActiveClick = (color) => {
//     setActiveColor(color);
//   };
//   const handleMiniClick = () => {
//     let notifyMessage = "Sidebar mini ";
//     if (document.body.classList.contains("sidebar-mini")) {
//       setSidebarMini(false);
//       // notifyMessage += "deactivated...";
//     } else {
//       setSidebarMini(true);
//       // notifyMessage += "activated...";
//     }
//     // let options = {};
//     // options = {
//     //   place: "tr",
//     //   message: notifyMessage,
//     //   type: "primary",
//     //   icon: "tim-icons icon-bell-55",
//     //   autoDismiss: 7,
//     // };
//     // notificationAlertRef.current.notificationAlert(options);
//     document.body.classList.toggle("sidebar-mini");
//   };
//   const toggleSidebar = () => {
//     setSidebarOpened(!sidebarOpened);
//     document.documentElement.classList.toggle("nav-open");
//   };
//   const closeSidebar = () => {
//     setSidebarOpened(false);
//     document.documentElement.classList.remove("nav-open");
//   };
//   return (
//     <div className="wrapper">
//       <div className="rna-container">
//         <NotificationAlert ref={notificationAlertRef} />
//       </div>
//       <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
//         <button
//           className="minimize-sidebar btn btn-link btn-just-icon"
//           onClick={handleMiniClick}
//         >
//           <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
//           <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
//         </button>
//       </div>
//       <Sidebar
//         {...props}
//         routes={sessionStorage.getItem('role')==="TXN_CREATOR" ? routes : sessionStorage.getItem('role')==="AUTHORIZER-A" ? routes2 : sessionStorage.getItem('role')==="AUTHORIZER-B" ? routes3 : ''}
//         activeColor={activeColor}
//         logo={{
//           outterLink: "https://digitalcustodian.co/",
//           text: "Front Office",
//           imgSrc: logo,
//         }}
//         closeSidebar={closeSidebar}
//       />
//       <div className="main-panel" ref={mainPanelRef} data={activeColor}>
//         {/* <AdminNavbar
//           {...props}
//           handleMiniClick={handleMiniClick}
//           brandText={getActiveRoute(routes)}
//           sidebarOpened={sidebarOpened}
//           toggleSidebar={toggleSidebar}
//         /> */}
//         <Header />
//         <Switch>
//           {getRoutes(routes)}
//           <Route		
//             path="/admin/beta-transaction/:type"	
//             component={TransactionTxnFields}	
//           />
//           <Route		
//             path="/admin/fund-dividend-payout"	
//             component={FundDividendPayout}	
//           />
//           <Route path="/admin/setup-clients" component={SetupClients} />
//           <Route path="/admin/view-client" component={ViewClient} />
//           <Route path="/admin/edit-client" component={EditClient} />
//           {/* BulkUplaod /admin/bulk-upload*/}
//           <Route path="/admin/bulk-upload" component={BulkUplaod} />
          
//           <Route path="/admin/comp-sign-transactions/:type" component={ComplianceSignatoryTransactions} />
//           <Route path="/admin/signatory-a/:type" component={SignatoryA} />
//           <Route path="/admin/user-setting" component={UserManage} />
//           <Route path="/admin/notification-view" component={NotificattionScreen} />
//           <Redirect from="*" to="/admin/dashboard" />
//         </Switch>
//         {
//           // we don't want the Footer to be rendered on full screen maps page
//           props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
//             <Footer fluid />
//           )
//         }
//       </div>
//       {/* <FixedPlugin
//         activeColor={activeColor}
//         sidebarMini={sidebarMini}
//         handleActiveClick={handleActiveClick}
//         handleMiniClick={handleMiniClick}
//       /> */}
//       <FixedPlugin />
//     </div>
//   );
// };

// export default Admin;

// import React from "react";
// import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// // javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
// // react plugin for creating notifications over the dashboard
// import NotificationAlert from "react-notification-alert";

// import routes from "../../routes.js";
// import routes2 from "../../auth-a-routes.js";
// import routes3 from "../../auth-b-routes.js";
// // core components
// import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
// import Header from '../../components/Header';
// import Footer from "../../components/Footer/Footer.js";
// import Sidebar from "../../components/Sidebar/Sidebar.js";
// // import FixedPlugin from "../../components/FixedPlugin/FixedPlugin.js";
// import FixedPlugin from "../../components/FixedPlugin.tsx";
// import logo from "../../assets/macs-logo.png";
// // import Dashboard from "./containers/DashboardPage";
// import TransactionTxnFields from '../../containers/single-beta-txn';
// import FundDividendPayout from '../../containers/fund-dividend-payout-txn';
// import SetupClients from "../../containers/unit-holder/setup-clients.tsx";
// import ViewClient from "../../containers/unit-holder/view-client.tsx";
// import EditClient from "../../containers/unit-holder/edit-client.tsx";
// import BulkUplaod from "../../containers/bulk-upload.tsx";
//  import ComplianceSignatoryTransactions from "../../containers/comp-sign-listing.tsx";
//  import UserManage from "../../containers/auth/user-setting";
//  import SignatoryA from "../../containers/signatory-a";
//  import NotificattionScreen from "../../containers/notification-screen.tsx";

//  // reports
//  import BankStatement from "../../containers/reports/bank-statement";
//  import RecieptPaymentRegisterReport from "../../containers/reports/reciept-payment-register-report";

// var ps;

// const Admin = (props) => {
//   const [activeColor, setActiveColor] = React.useState("blue");
//   const [sidebarMini, setSidebarMini] = React.useState(true);
//   const [opacity, setOpacity] = React.useState(0);
//   const [sidebarOpened, setSidebarOpened] = React.useState(false);
//   const mainPanelRef = React.useRef(null);
//   const notificationAlertRef = React.useRef(null);
//   const location = useLocation();
//   React.useEffect(() => {
//     document.documentElement.scrollTop = 0;
//     document.scrollingElement.scrollTop = 0;
//     if (mainPanelRef.current) {
//       mainPanelRef.current.scrollTop = 0;
//     }
//   }, [location]);
//   React.useEffect(() => {
//     let innerMainPanelRef = mainPanelRef;
//     if (navigator.platform.indexOf("Win") > -1) {
//       document.documentElement.classList.add("perfect-scrollbar-on");
//       document.documentElement.classList.remove("perfect-scrollbar-off");
//       ps = new PerfectScrollbar(mainPanelRef.current);
//       mainPanelRef.current &&
//         mainPanelRef.current.addEventListener("ps-scroll-y", showNavbarButton);
//       let tables = document.querySelectorAll(".table-responsive");
//       for (let i = 0; i < tables.length; i++) {
//         ps = new PerfectScrollbar(tables[i]);
//       }
//     }
//     window.addEventListener("scroll", showNavbarButton);
//     return function cleanup() {
//       if (navigator.platform.indexOf("Win") > -1) {
//         ps.destroy();
//         document.documentElement.classList.add("perfect-scrollbar-off");
//         document.documentElement.classList.remove("perfect-scrollbar-on");
//         innerMainPanelRef.current &&
//           innerMainPanelRef.current.removeEventListener(
//             "ps-scroll-y",
//             showNavbarButton
//           );
//       }
//       window.removeEventListener("scroll", showNavbarButton);
//     };
    
//   }, []);
//   const showNavbarButton = () => {
//     if (
//       document.documentElement.scrollTop > 50 ||
//       document.scrollingElement.scrollTop > 50 ||
//       (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)
//     ) {
//       setOpacity(1);
//     } else if (
//       document.documentElement.scrollTop <= 50 ||
//       document.scrollingElement.scrollTop <= 50 ||
//       (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
//     ) {
//       setOpacity(0);
//     }
//   };
//   const getRoutes = (routes) => {
//     return routes.map((prop, key) => {
//       if (prop.collapse) {
//         return getRoutes(prop.views);
//       }
//       if (prop.layout === "/admin") {
//         return (
//           <Route
//             path={prop.layout + prop.path}
//             component={prop.component}
//             key={key}
//           />
//         );
//       } else {
//         return null;
//       }
//     });
//   };
//   const getActiveRoute = (routes) => {
//     let activeRoute = "Default Brand Text";
//     for (let i = 0; i < routes.length; i++) {
//       if (routes[i].collapse) {
//         let collapseActiveRoute = getActiveRoute(routes[i].views);
//         if (collapseActiveRoute !== activeRoute) {
//           return collapseActiveRoute;
//         }
//       } else {
//         if (
//           window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
//         ) {
//           return routes[i].name;
//         }
//       }
//     }
//     return activeRoute;
//   };
//   const handleActiveClick = (color) => {
//     setActiveColor(color);
//   };
//   const handleMiniClick = () => {
//     let notifyMessage = "Sidebar mini ";
//     if (document.body.classList.contains("sidebar-mini")) {
//       setSidebarMini(false);
//       // notifyMessage += "deactivated...";
//     } else {
//       setSidebarMini(true);
//       // notifyMessage += "activated...";
//     }
//     // let options = {};
//     // options = {
//     //   place: "tr",
//     //   message: notifyMessage,
//     //   type: "primary",
//     //   icon: "tim-icons icon-bell-55",
//     //   autoDismiss: 7,
//     // };
//     // notificationAlertRef.current.notificationAlert(options);
//     document.body.classList.toggle("sidebar-mini");
//   };
//   const toggleSidebar = () => {
//     setSidebarOpened(!sidebarOpened);
//     document.documentElement.classList.toggle("nav-open");
//   };
//   const closeSidebar = () => {
//     setSidebarOpened(false);
//     document.documentElement.classList.remove("nav-open");
//   };
//   return (
//     <div className="wrapper">
//       <div className="rna-container">
//         <NotificationAlert ref={notificationAlertRef} />
//       </div>
//       <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
//         <button
//           className="minimize-sidebar btn btn-link btn-just-icon"
//           onClick={handleMiniClick}
//         >
//           <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
//           <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
//         </button>
//       </div>
//       <Sidebar
//         {...props}
//         routes={sessionStorage.getItem('role')==="TXN_CREATOR" ? routes : sessionStorage.getItem('role')==="AUTHORIZER-A" ? routes2 : sessionStorage.getItem('role')==="AUTHORIZER-B" ? routes3 : ''}
//         activeColor={activeColor}
//         logo={{
//           outterLink: "https://digitalcustodian.co/",
//           text: "Front Office",
//           imgSrc: logo,
//         }}
//         closeSidebar={closeSidebar}
//       />
//       <div className="main-panel" ref={mainPanelRef} data={activeColor}>
//         {/* <AdminNavbar
//           {...props}
//           handleMiniClick={handleMiniClick}
//           brandText={getActiveRoute(routes)}
//           sidebarOpened={sidebarOpened}
//           toggleSidebar={toggleSidebar}
//         /> */}
//         <Header />
//         <Switch>
//           {getRoutes(routes)}
//           <Route		
//             path="/admin/beta-transaction/:type"	
//             component={TransactionTxnFields}	
//           />
//           <Route		
//             path="/admin/fund-dividend-payout"	
//             component={FundDividendPayout}	
//           />
//           <Route path="/admin/setup-clients" component={SetupClients} />
//           <Route path="/admin/view-client" component={ViewClient} />
//           <Route path="/admin/edit-client" component={EditClient} />
//           {/* BulkUplaod /admin/bulk-upload*/}
//           <Route path="/admin/bulk-upload" component={BulkUplaod} />
          
//           <Route path="/admin/comp-sign-transactions/:type" component={ComplianceSignatoryTransactions} />
//           <Route path="/admin/signatory-a/:type" component={SignatoryA} />
//           <Route path="/admin/user-setting" component={UserManage} />
//           <Route path="/admin/notification-view" component={NotificattionScreen} />
//           <Redirect from="*" to="/admin/dashboard" />
//         </Switch>
//         {
//           // we don't want the Footer to be rendered on full screen maps page
//           props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
//             <Footer fluid />
//           )
//         }
//       </div>
//       {/* <FixedPlugin
//         activeColor={activeColor}
//         sidebarMini={sidebarMini}
//         handleActiveClick={handleActiveClick}
//         handleMiniClick={handleMiniClick}
//       /> */}
//       <FixedPlugin />
//     </div>
//   );
// };

// export default Admin;


import React from "react";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

// core components
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Header from '../../components/Header';
import Footer from "../../components/Footer/Footer.js";
import Sidebar from "../../components/Sidebar/Sidebar.js";
// import FixedPlugin from "../../components/FixedPlugin/FixedPlugin.js";
import FixedPlugin from "../../components/FixedPlugin.tsx";
import logo from "../../assets/macs-logo.png";
import TransactionTxnFields from '../../containers/single-beta-txn';
import PendingTransactions from "../../containers/pending-transactions";
import TransactionListing from "../../containers/transaction-listing";
import ConcernedOfficer from "../../containers/officer-listing";
import AuthorizerListing from "../../containers/authorizer-listing";
import Dashboard from "../../containers/DashboardPage";
import BetaTxnType from "../../containers/beta-txn-type";
import RejectedTransaction from "../../containers/rejected-transactions";
import NAV from "../../containers/nav";
// import PendingTransactions from "../../containers/pending-transactions";
import ComplianceSignatoryTransactions from "../../containers/comp-sign-listing";
import SignatoryA from "../../containers/signatory-a";
import NotificationScreen from '../../containers/notification-screen';
import UserSetting from '../../containers/user-setting';	
import FundDividendPayout from '../../containers/fund-dividend-payout-txn';
import BulkUplaod from "../../containers/bulk-upload";

 // reports
import BankStatement from "../../containers/reports/bank-statement";
import RecieptPaymentRegisterReport from "../../containers/reports/reciept-payment-register-report";
import UnitHolderStatement from "../../containers/reports/unit-holder-statement";
import TransactionReportScreen from "../../containers/reports/transaction-report";

import ClientList from "../../containers/unit-holder/client-listing";
import SetupClients from "../../containers/unit-holder/setup-clients";
import EditClient from "../../containers/unit-holder/edit-client";
import ViewClient from "../../containers/unit-holder/view-client";

import HomePage from "../../components/home-page";

var ps;

const Admin = (props) => {
  const history = useHistory();
  const [activeColor, setActiveColor] = React.useState("blue");
  const [sidebarMini, setSidebarMini] = React.useState(true);
  const [opacity, setOpacity] = React.useState(0);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const mainPanelRef = React.useRef(null);
  const notificationAlertRef = React.useRef(null);
  const [features, setFeatures] = React.useState([]);
  let [nodes, setNodes] = React.useState([]);
  let [routes, setRoutes] = React.useState([]);
  let fullFeatures=[];

  const data = JSON.parse(sessionStorage.getItem("features")) || "";

  document.addEventListener(
    "DOMContentLoaded",
    function () {
      onLoad();
    },
    false
  );
  async function onLoad() {
    try {
      let isvalid = true;
      const path = 
      window.location.pathname == "/admin/dashboard"
          ? "/admin/dashboard"
        :window.location.pathname == "/admin/details/compliance"
          ? "/admin/compliance"
          : window.location.pathname == "/admin/details/authorizer-a"
          ? "/admin/signatory-a"
          : window.location.pathname == "/admin/details/authorizer-b"
          ? "/admin/signatory-b"
          : window.location.pathname == "/admin/beta-transaction/saleofunit"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/transaction-types"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/unitconversion"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/conversionin"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/conversionout"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/beta-transaction/redemptionofunits"
          ? "/admin/create-transaction"
          : window.location.pathname==="/admin/home"
          ? '/admin/dashboard'
          : window.location.pathname == "/admin/bulk-upload"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/fundtransfer"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/outflow"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/inflow"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/beta-transaction/debtmarketinvestment"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/termdeposit"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/bankprofit"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/beta-transaction/equityinvestment"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/cashdividend"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/cashmargin"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/marginreturn"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/advisoryfee"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/auditorsfee"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/bankcharges"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/managementfee"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/trusteefee"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/custodyfee"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/beta-transaction/shariahadvisoryfee"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/cdcfee"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/listingfee"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/printingfee"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/beta-transaction/professionalfee"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/ratingfee"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/taxpayment"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/secpfee"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/brokagefee"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/otherpayment"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/fund-dividend-payout"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/setup-clients"
          ? "/admin/unit-holders"
          : window.location.pathname == "/admin/view-client"
          ? "/admin/unit-holders"
          : window.location.pathname == "/admin/edit-client"
          ? "/admin/unit-holders"
          : window.location.pathname;

      const arr = routes;

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].views !== undefined) {
          const views = arr[i].views;
          for (var a = 0; a < views.length; a++) {
            if (path == "/admin" + views[a].path) {
              //
              isvalid = true;
              return;
            } else {
              isvalid = false;
            }
          }
        } else if (path == "/admin" + arr[i].path) {
          isvalid = true;
          return;
        }
      }
      if (isvalid === false) {
        if (sessionStorage.getItem("isAuthenticated") === "true") {
          history.replace("/admin/view");
          alert("You are not authorized!");
        } else {
          history.replace("/login");
        }
        // toast.error("You are not authorized!");
      }
    } catch (e) {
      alert(e.message);
    }
  }

  // let routes = [];
  // data.map((item, i) => {
  //   let obj = {
  //     path: `/${item.feature.replace(/\s+/g, "-").toLowerCase()}`,
  //     name: item.feature,
  //     rtlName: "لوحة القيادة",
  //     icon: "tim-icons icon-chart-pie-36",
  //     component: Dashboard,
  //     layout: "/admin",
  //   };
  //   routes.push(obj);
  // });

  const handleSetAllRoutes = (data) => {
    let newRoute = [];
    data.map((item, i) => {
      if (item.submenu) {
        if (item.enabled) {
          let obj = {
            collapse: true,
            name: item.label,
            rtlName: `${i}خرائط`,
            icon: "tim-icons icon-single-02 " + item.label.replace(" ", ""),
            state: item.label,
          };
          let subMenuArray = [];
          item.submenu.map((submenu, j) => {
            if (submenu.enabled) {
              let comp =
                submenu.label == "Create Transaction"
                  ? BetaTxnType
                  : submenu.label == "Transaction Status"
                  ? PendingTransactions
                  : submenu.label == "Rejected Transactions"
                  ? RejectedTransaction
                  : submenu.label == "Transaction Listing"
                  ? TransactionListing
                  : submenu.label == "Authorizer-A"
                  ? ComplianceSignatoryTransactions
                  : submenu.label == "Authorizer-B"
                  ? ComplianceSignatoryTransactions
                  : submenu.label == "Concerned Officers"
                  ? ConcernedOfficer
                  : submenu.label==="Transaction Creators"
                  ? AuthorizerListing
                  : submenu.label == "NAV Update"
                  ? NAV
                  : submenu.label == "Transaction Report"
                  ? TransactionReportScreen
                  : submenu.label==="Unit Holder Statement"
                  ? UnitHolderStatement
                  : submenu.label==="Bank Statements"
                  ? BankStatement
                  : submenu.label==="Receipt Payment Register Report"
                  ? RecieptPaymentRegisterReport
                  : submenu.label == "Unit Holders"
                  ? ClientList
                  : Dashboard;
              var matches = submenu.label.match(/\b(\w)/g); // ['J','S','O','N']
              var acronym = matches.join("");
              let subObj = {
                path: `/${submenu.label.replace(/\s+/g, "-").toLowerCase()}`,
                name: submenu.label,
                rtlName: `${j}خرائط`,
                mini: acronym,
                rtlMini: "زم",
                component: comp,
                layout: "/admin",
                isAuthenticated: "true",
              };
              subMenuArray.push(subObj);
            }
          });
          if (subMenuArray.length !== 0) {
            Object.assign(obj, { views: subMenuArray });

            newRoute.push(obj);
          }
        }
      } else {
        if (item.enabled) {
        //   // let obj = {
        //   //   path: `/${item.label.replace(/\s+/g, "-").toLowerCase()}`,
        //   //   name: item.label,
        //   //   rtlName: "لوحة القيادة",
        //   //   icon: "tim-icons icon-chart-pie-36",
        //   //   component: Dashboard,
        //   //   layout: "/admin",
        //   // };

          if(item.label==='Transaction Status'){
            let obj = {
              path: `/${item.label.replace(/\s+/g, "-").toLowerCase()}`,
              name: item.label,
              rtlName: "لوحة القيادة",
              icon: "tim-icons icon-bullet-list-67",
              component: PendingTransactions,
              layout: "/admin",
              isAuthenticated: "true",
            };
                    
          newRoute.push(obj);
          }
          if(item.label==='Dashboard'){
            let obj = {
              path: `/${item.label.replace(/\s+/g, "-").toLowerCase()}`,
              name: item.label,
              rtlName: "لوحة القيادة",
              icon: "tim-icons icon-chart-pie-36",
              component: Dashboard,
              layout: "/admin",
              isAuthenticated: "true",
            };
                    
          newRoute.push(obj);
          }

        }
      }
    });

    setRoutes(newRoute);
  };

  const onload1 = async()=>{
    let fullData = JSON.parse(sessionStorage.getItem('allFeatures') || '');
    fullData.unshift({
      id: -1,
      feature: 'Trustee',
      parent_id: null,
    });
    for (let index = 0; index <fullData.length; index++) {
      if (fullData[index].parent_id === 0) {
        fullData[index].parent_id = -1;
      }
      fullData[index].value =
      fullData[index].id;
      fullData[index].label =
      fullData[index].feature;
    }
    var data = fullData;
    fullFeatures.push(...fullData);
    var root;
    const idMapping = data.reduce((acc, el, i) => {
      acc[el.id] = i;
      return acc;
    }, {});
    data.forEach((el) => {
      // Handle the root element
      if (el.parent_id === null) {
        root = el;
        return;
      }
      // Use our mapping to locate the parent element in our data array
      const parentEl = data[idMapping[el.parent_id]];
      // Add our current el to its parent's `children` array
      parentEl.children = [...(parentEl.children || []), el];
    });
    setNodes([root]);
   }

  // route
  React.useEffect(() => {
    onload1();
    let template = [];

    const buildDefaultTemplate = () => {
      template = [
        {
          label: "Dashboard",
          enabled: false,
        },

        {
          label: "Transaction Status",
          enabled: false,
        },

        
        {
          label: "Transaction Creator",
          enabled: false,
          submenu: [
            {
              enabled: false,
              label: "Create Transaction",
            },
            {
              enabled: false,
              label: "Rejected Transactions",
            },
            {
              enabled: false,
              label: "Transaction Listing",
            },
          ],
        },

        {
          label: "Processing",
          enabled: false,
          submenu: [
            {
              enabled: false,
              label: "Authorizer-A",
            },
            {
              enabled: false,
              label: "Authorizer-B",
            },
          ],
        },        

        {
          label: "Daily Tasks",
          enabled: false,
          submenu: [
            {
              enabled: false,
              label: "NAV Update",
            },
          ],
        },

        {
          label: "Reports",
          enabled: false,
          submenu: [
            {
              enabled: false,
              label: "Bank Statements",
            },
            {
              enabled: false,
              label: "Transaction Report",
            },
            {
              enabled: false,
              label: "Unit Holder Statement",
            },
            {
              enabled: false,
              label: "Receipt Payment Register Report",
            },
          ],
        },

        {
          label: "Management",
          enabled: false,
          submenu: [
            {
              label: "Unit Holders",
              enabled: false,
            },
          ],
        },

        {
          label: "Authorized Users",
          enabled: false,
          submenu: [
            {
              label: "Concerned Officers",
              enabled: false,
            },
            {
              label: "Transaction Creators",
              enabled: false,
            },
          ],
        },

      ];
      if (sessionStorage.getItem("features") !== "undefined" && sessionStorage.getItem("features") !== null) {
        let featuesList = JSON.parse(sessionStorage.getItem("features"));

         // remove element of array.
         fullFeatures.shift();
        if(sessionStorage.getItem("additional_features")!==null && sessionStorage.getItem("additional_features")!=='[]' && sessionStorage.getItem("additional_features").value!=='' && sessionStorage.getItem("additional_features")!==''){
          let additionalaccess=JSON.parse(sessionStorage.getItem("additional_features") || '');
          featuesList=[...featuesList, ...additionalaccess];
        }

        if(sessionStorage.getItem("denied_features")!==null && sessionStorage.getItem("denied_features")!=='[]' && sessionStorage.getItem("denied_features").value!=='' && sessionStorage.getItem("denied_features")!==''){
          let deniedaccess=JSON.parse(sessionStorage.getItem("denied_features") || '');
          let updatedFeaturesList = featuesList.filter(mainFeature => {
            if(deniedaccess.some((dFeature => dFeature.id == mainFeature.id))) {
              return false
            }else {
              return true
            }
          })      
          featuesList=[...updatedFeaturesList];
        }

         let copyArray=[]; 
         for(let i=0;i<featuesList.length;i++){
           for (let j=0;j<fullFeatures.length;j++){
             if(fullFeatures[j].children!==undefined){
               for(let n=0;n<fullFeatures[j].children.length;n++){
                 if(featuesList[i].label===fullFeatures[j].children[n].label /*&& featuesList[i].label!=='AMC'*/){
                   copyArray.push(fullFeatures[j]);
                 }
               }
             }
           }
         }
 
         let finalFeatures = [...copyArray, ...featuesList];


        let labelArr = [];
        for (let index = 0; index < finalFeatures.length; index++) {
          for (let j_index = 0; j_index < template.length; j_index++) {
            const result = searchTree(
              template[j_index],
              finalFeatures[index].feature
            );
            if (result !== null) {
              labelArr.push(result.label);
              break;
            }
          }
        }

        template.forEach(function iter(a) {
          if (labelArr.includes(a.label)) {
            a.enabled = true;
          }
          Array.isArray(a.children) && a.children.forEach(iter);
        });
      }
      // const menu = Menu.buildFromTemplate(template);
      // Menu.setApplicationMenu(menu);
      handleSetAllRoutes(template);
    };

    const searchTree = (element, matchingTitle) => {
      if (element.label === matchingTitle) {
        element.enabled = true;
        return element;
      } else if (element.submenu != null) {
        var result = null;
        for (let i = 0; result == null && i < element.submenu.length; i++) {
          result = searchTree(element.submenu[i], matchingTitle);
        }
        return result;
      }
      return null;
    };
    buildDefaultTemplate();
  }, []);

  // end route
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  React.useEffect(() => {
    let innerMainPanelRef = mainPanelRef;
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current);
      mainPanelRef.current &&
        mainPanelRef.current.addEventListener("ps-scroll-y", showNavbarButton);
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", showNavbarButton);
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
        innerMainPanelRef.current &&
          innerMainPanelRef.current.removeEventListener(
            "ps-scroll-y",
            showNavbarButton
          );
      }
      window.removeEventListener("scroll", showNavbarButton);
    };
  }, []);
  const showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)
    ) {
      setOpacity(1);
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
    ) {
      setOpacity(0);
    }
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            isAuthenticated={prop.isAuthenticated}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const handleActiveClick = (color) => {
    setActiveColor(color);
  };
  const handleMiniClick = () => {
    // let notifyMessage = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
      // notifyMessage += "deactivated...";
    } else {
      setSidebarMini(true);
      // notifyMessage += "activated...";
    }
    // let options = {};
    // options = {
    //   place: "tr",
    //   message: notifyMessage,
    //   type: "primary",
    //   icon: "tim-icons icon-bell-55",
    //   autoDismiss: 7,
    // };
    // notificationAlertRef.current.notificationAlert(options);
    document.body.classList.toggle("sidebar-mini");
  };
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle("nav-open");
  };
  const closeSidebar = () => {
    setSidebarOpened(false);
    document.documentElement.classList.remove("nav-open");
  };
  return (
    <div className="wrapper">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
        <button
          className="minimize-sidebar btn btn-link btn-just-icon"
          onClick={handleMiniClick}
        >
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
        </button>
      </div>
      <Sidebar
        {...props}
        routes={routes}
        activeColor={activeColor}
        logo={{
          outterLink: "https://digitalcustodian.co/",
          text: "MACS Front-Office",
          imgSrc: logo,
        }}
        closeSidebar={closeSidebar}
      />
      <div className="main-panel" ref={mainPanelRef} data={activeColor}>
        {/* <AdminNavbar
          {...props}
          handleMiniClick={handleMiniClick}
          brandText={getActiveRoute(routes)}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar}
        /> */}
        <Header
          {...props}
          handleMiniClick={handleMiniClick}
          brandText={getActiveRoute(routes)}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar} />
        <Switch>
          {getRoutes(routes)}

          {/* <Route path="/admin/create-transaction" component={BetaTxnType} /> */}
          <Route
            path="/admin/home"
            component={HomePage}
          />
          <PrivateRoute
            path="/admin/transaction-types"
            component={BetaTxnType}
          />
          <PrivateRoute
            path="/admin/comp-sign-transactions/:type"
            component={ComplianceSignatoryTransactions}
          />
          {/* <Route path="/admin/signatory-a/:type" component={SignatoryA} /> */}
          <PrivateRoute path="/admin/details/:type" component={SignatoryA} />
          <PrivateRoute
            path="/admin/notification-view"
            component={NotificationScreen}
          />
          <PrivateRoute
            path="/admin/beta-transaction/:type"
            component={TransactionTxnFields}
          />
          <PrivateRoute
            path="/admin/fund-dividend-payout"
            component={FundDividendPayout}
          />

          <PrivateRoute path="/admin/bulk-upload" component={BulkUplaod} />
          <PrivateRoute path="/admin/dashboard" component={Dashboard} />
          <PrivateRoute path="/admin/user-setting" component={UserSetting} />

          <PrivateRoute path="/admin/unit-holders" component={ClientList} />
          <PrivateRoute path="/admin/setup-clients" component={SetupClients} />
          <PrivateRoute path="/admin/edit-client" component={EditClient} />
          <PrivateRoute path="/admin/view-client" component={ViewClient} />

          {/* <Redirect from="*" to="/admin/dashboard" /> */}
        </Switch>
        {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
            <Footer fluid />
          )
        }
      </div>
      {/* <FixedPlugin
        activeColor={activeColor}
        sidebarMini={sidebarMini}
        handleActiveClick={handleActiveClick}
        handleMiniClick={handleMiniClick}
      /> */}
      <FixedPlugin />
    </div>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      sessionStorage.getItem("isAuthenticated") === "true" ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      )
    }
  />
);

export default Admin;

